import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Text,
  Textarea,
  useCheckboxGroup,
} from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import { Label } from "components/common/form/form-style";
import React, { useState } from "react";
import ChooseTicketForRuleset from "./parts/ChooseTicketForRuleset";
import { Ruleset } from "../../InterfaceRulesets";
import { FormikErrors, getIn } from "formik";
import { IEvents } from "lib/stores/user/UserStore";
import Identifiers from "./parts/identifiers";

const RulesetItem = ({
  r,
  index,
  remove,
  handleChange,
  errors,
  eventsData,
  options,
  ruleType,
  setRuleType,
  setFieldValue
}: {
  r: Ruleset;
  index: number;
  remove: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  errors: FormikErrors<any>;
  eventsData: IEvents[];
  options: string[];
  ruleType: string | null;
  setRuleType: React.Dispatch<React.SetStateAction<string | null>>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>
}) => {
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: r?.tickets,
    value: r?.tickets,
  });

  const group = getCheckboxProps();

  const handleRuleTypeChange = (value: string) => {
    setRuleType(value);
  };

  return (
    <Flex flexDirection={"column"} gap={"16px"}>
      <Flex justifyContent={"space-between"}>
        <Text fontFamily={"AVB"} fontWeight={600} fontSize={"18px"} color={"gray.0"}>
          {`Rule ${index + 1}`}
        </Text>
        <IconButton
          _hover={{ bg: "transparent" }}
          bg={"transparent"}
          icon={<AppIcons.Delete />}
          aria-label="remove icon"
          onClick={() => remove(index)}
        />
      </Flex>
      <Label>
        <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={"16px"} lineHeight={"24px"}>
          Rule Type
        </Text>
        <Select
          _hover={{ backgroundColor: "gray.1000" }}
          size={"lg"}
          fontFamily={"AVR"}
          fontSize={"16px"}
          variant="filled"
          bg={"gray.1000"}
          rounded={"8px"}
          placeholder="Select Event Type"
          color={"gray.500"}
          name={`rules.${index}.gated`}
          value={r.gated}
          onChange={(e) => {
            handleChange(e);
            handleRuleTypeChange(e.target.value);
          }}
        >
          <option value="Discount">Discount</option>
          <option value="Gated">Exclusive (Gated)</option>
        </Select>
        <Text color={"red.600"}>{getIn(errors, `rules[${index}].gated`)}</Text>
        <Flex gap={"4px"} alignItems={"center"}>
          {ruleType === "Discount" && r.discount !== 0 ? (
            <Flex alignItems={'start'} gap={'4px'}>
               <AppIcons.IconInfoRuleset style={{minWidth:'16px', minHeight: '16px', marginTop:'3px'}} />
              <Text fontWeight={"400"} fontSize={"14px"} color={"gray.600"}>
                Offer discount to verified customers.
              </Text>
            </Flex>
          ) : ruleType === "Gated" ? (
            <Flex alignItems={'start'} gap={'4px'}>
              <AppIcons.IconInfoRuleset style={{minWidth:'16px', minHeight: '16px', marginTop:'3px'}} />
              <Text fontWeight={"400"} fontSize={"14px"} color={"gray.600"}>
                Only verified wallet owners have access to the tickets.
              </Text>
            </Flex>
          ) : null}
        </Flex>
      </Label>
      {ruleType === "Discount" && (
        <Label>
          <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={"16px"} lineHeight={"24px"}>
            Discount value
          </Text>
          <InputGroup>
            <Input
              variant="unstyled"
              color={"gray.0"}
              bg={"gray.1000"}
              fontFamily={"AVR"}
              type="number"
              id="discount"
              placeholder="0"
              padding={"12px"}
              name={`rules.${index}.discount`}
              value={r.discount}
              _placeholder={{ color: "gray.800" }}
              onChange={handleChange}
            />
            <InputRightElement top={"4px"}>
              <AppIcons.Percent />
            </InputRightElement>
          </InputGroup>
          <Flex alignItems={'start'} gap={'4px'}>
            <AppIcons.IconInfoRuleset style={{minWidth:'16px', minHeight: '16px', marginTop:'3px'}} />
            <Text fontWeight={"400"} fontSize={"14px"} color={"gray.600"}>
              If you do not want to give a discount, enter the value 0.
            </Text>
          </Flex>
          <Text color={"red.600"}>{getIn(errors, `rules[${index}].discount`)}</Text>
        </Label>
      )}
      <Label>
        <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={"16px"} lineHeight={"24px"}>
          Whitelist
        </Text>
        <Text fontWeight={"400"} fontSize={"14px"} color={"gray.600"}>
          Enter individual wallet addresses
        </Text>
        <Identifiers index={index} nft_identifiers={r.nft_identifiers} setFieldValue={setFieldValue}/>
        <Text color={"red.600"}>{getIn(errors, `rules[${index}].nft_identifiers`)}</Text>
      </Label>
      <Label>
        <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={"16px"} lineHeight={"24px"}>
          Ticket for the ruleset
        </Text>
        <Text fontWeight={"400"} fontSize={"14px"} color={"gray.600"}>
          Select the tickets to be included in this rule
        </Text>
        {eventsData?.length > 0 ? (
          <HStack
            {...group}
            display={"flex"}
            flexWrap={"wrap"}
            bg={"gray.1000"}
            rounded={"8px"}
            padding={"12px"}
            onChange={handleChange}
          >
            {options?.map((value, i) => {
              return (
                <ChooseTicketForRuleset
                  key={value}
                  {...getCheckboxProps({ value: value })}
                  name={`rules.${index}.tickets.[${i}]`}
                >
                  {value}
                </ChooseTicketForRuleset>
              );
            })}
          </HStack>
        ) : (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        )}
        <Text color={"red.600"}>{getIn(errors, `rules[${index}].tickets`)}</Text>
      </Label>
    </Flex>
  );
};

export default RulesetItem;
