//Chakra
import { Flex, Skeleton, SkeletonCircle, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";


const ExploreSkeleton = () => {
    return (
        <Flex flexDirection={"column"} alignItems={"flex-start"} alignSelf={"stretch"} gap={"12px"} padding={"8px"} border={"1px solid #434343"} borderRadius={"8px"} width={"100%"}>
            <Skeleton width={"100%"} height={"200px"} borderRadius={"8px"} />
            <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                <Skeleton width={'120px'} height={'10px'} />
                <Skeleton width={'200px'} height={'15px'} />
                <Flex alignItems={'center'} alignSelf={'stretch'} gap={'4px'}>
                    <Text color={'gray.400'} fontFamily={'AVR'} fontSize={'12px'} fontWeight={400}>Hosted by</Text>
                    <SkeletonCircle width={'24px'} height={'24px'} />
                    <Skeleton width={'80px'} height={'15px'} />
                </Flex>
                <Flex alignItems={"center"} alignSelf={"stretch"} gap={"4px"}>
                    <AppIcons.Location width={"13px"} height={"16px"} strokeWidth={"1.5px"} />
                    <Skeleton width={'100px'} height={'10px'} />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ExploreSkeleton;
