import { Chain, Network } from "../../Chains";
import { ChainProvider, WalletNotFoundException } from "../../chainProvider";
import { metamaskLogin, isMetamaskInstalled, getAccounts, isWalletConnected, isChainCorrect, changeChain } from "./evmLogin";
import { EVMrecordMerch } from "./evmRecord";

export class EVMProvider implements ChainProvider {
    chain: Chain = Chain.BINANCE;
    network: Network = Network.TESTNET;
    address: string = "";
    constructor(_chain: Chain, _network: Network) {
        this.chain = _chain;
        this.network = _network;
    }
    setAddress(address: string): ChainProvider {
        this.address = address;
        return this;
    }
    async handleWallet(_address: string) {
        if (!isMetamaskInstalled())
            throw new WalletNotFoundException("Metamask is not installed");
        let accs = await getAccounts();
        if (!isWalletConnected() || accs.length == 0) {
            let { address } = await this.walletLogin();
            if (_address.toLocaleLowerCase() != address.toLocaleLowerCase()) {
                await (window as any).ethereum.request({
                    method: 'wallet_requestPermissions',
                    params: [{
                        eth_accounts: {},
                    }]
                });
                this.handleWallet(_address);
                // throw new AccountChangedException("The current account on your wallet is not the one you've logged in with!");
            }
        }
        if (!await isChainCorrect(this.chain, this.network)) {
            await changeChain(this.chain, this.network);
        }
        if (String(accs[0]).toLocaleLowerCase() != _address.toLocaleLowerCase()) {
            await (window as any).ethereum.request({
                method: 'wallet_requestPermissions',
                params: [{
                    eth_accounts: {},
                }]
            });
            this.handleWallet(_address);
            // throw new AccountChangedException("The current account on your wallet is not the one you've logged in with!");
        }
    }
    async walletLogin() {
        let { address, signature } = await metamaskLogin(this.chain, this.network);
        this.address = address;
        return { address, signature };
    }
    async mintNFT(properties: any, title: string, description: string, imageUrl: string, price: number, amount: number, apiKey: string): Promise<string> {
        await this.handleWallet(this.address);
        return await EVMrecordMerch(this.chain, this.network, properties, this.address, title, description, imageUrl, price, amount, 0, apiKey);
    }
    
}