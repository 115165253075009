//Chakra
import { chakra } from "@chakra-ui/react";

//Styles
const CustomSwitchDiv = chakra("div", {
    baseStyle: {
        display: "inline-block",
        height: "24px",
        position: "relative",
        width: "40px",
        borderRadius: "34px",
    },
});

const Slider = chakra("div", {
    baseStyle: {
        backgroundColor: "#7C7C7C",
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        cursor: "pointer",
        transition: "0.3s",
        borderRadius: "34px",
    },
});

const SliderBefore = chakra("div", {
    baseStyle: {
        backgroundColor: "#fff",
        content: "''",
        position: "absolute",
        bottom: "4px",
        height: "16px",
        width: "16px",
        transition: "0.3s",
        borderRadius: "50%",
    },
});


function CustomSwitch({isChecked, onClickHandler}: {isChecked: boolean, onClickHandler: any}) {

    return (
        <>
        <CustomSwitchDiv onClick={onClickHandler}>
            <Slider bgColor={isChecked ? "#2EC99E" : "#7C7C7C"}>
                <SliderBefore
                style={{
                    left: isChecked ? "20px" : "4px",
                }}
                />
            </Slider>
        </CustomSwitchDiv>
        </>
    );
}

export default CustomSwitch;
