import React, { Children } from "react";
import { Box, Button, Divider, Flex, Modal, ModalHeader, Text } from "@chakra-ui/react";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { ModalContainer, ModalContent, ModalWrapperComponent } from "./AppModal-style";
import IconBox from "../iconInfoBox/iconBox";
import AppIcons from "assets/icons/AppIcons";
import { Link } from "react-router-dom";

interface AppModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: string | JSX.Element | JSX.Element[];
}

const AppModal: React.FC<AppModalProps> = ({ isOpen, setIsOpen, children }) => {
  const y = useMotionValue(0);
  const controls = useAnimation();
  const threshold = 400;
  const successThreshold = 200;
  const handleDragEnd = (event: any, info: any) => {
    if (info.offset.y > threshold) {
      if (info.offset.y > successThreshold) {
        setIsOpen(false);
      }
      controls.start({ y: 500 });
    } else {
      controls.start({ y: 0 });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalWrapperComponent>
        <ModalContent>
          <motion.div
            style={{
              width: "100%",
              height: "100%",
              // minHeight:'100%',
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            drag="y"
            dragConstraints={{ top: 0, bottom: threshold }}
            dragElastic={0}
            dragMomentum={false}
            onDragEnd={handleDragEnd}
            animate={controls}
            initial={{ y: 0 }}
          >
            {/* <ModalHeader pos={"relative"}>
              
            </ModalHeader> */}
            {children}
          </motion.div>
        </ModalContent>
      </ModalWrapperComponent>
    </Modal>
  );
};

export default AppModal;
