//Chakra
import { Flex, Text } from "@chakra-ui/react";

//Components
import IconBox from 'components/common/iconInfoBox/iconBox';
import useAppToast from "functions/hooks/toast/useToast";

//Interfaces
import { IPaymentMethodItemProps } from '../interfaces'

const PaymentMethodItem = ({paymentMethod, setPaymentMethod, icon, title, totalPrice, paymentMethodNumber, hasWalletAddress}: IPaymentMethodItemProps) => {
    const { showToast } = useAppToast();

    return (
        <Flex
            cursor={"pointer"}
            marginTop={"15px"}
            padding={"15px"}
            border={paymentMethod === paymentMethodNumber ? "1px solid #084836" : "1px solid #434343"}
            borderRadius={"12px"}
            bgColor={paymentMethod === paymentMethodNumber ? "rgba(46, 201, 158, 0.05)" : ""}
            display={"flex"}
            alignItems={"center"}
            gap={"15px"}
            width={'100%'}
            onClick={() => {
                paymentMethodNumber === 1 ? 
                setPaymentMethod(paymentMethodNumber)
                :
                hasWalletAddress ? setPaymentMethod(paymentMethodNumber) : showToast("Please set the wallet address first", 'warning')
                }
            }
        >
            <Flex alignItems={"center"} justifyContent={"center"} borderRadius={"12px"} backgroundColor={"gray.700"} padding={"6px"} width={"48px"} height={"48px"}>
            <IconBox boxIcon={icon} />
            </Flex>
            <Flex display={"flex"} flexDirection={"column"} gap={"2px"}>
            <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={paymentMethod === paymentMethodNumber ? "primary.600" : "white"}>
                {title}
            </Text>
            <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"20px"} color={paymentMethod === paymentMethodNumber ? "primary.600" : "gray.600"}>
                {totalPrice === 0 ? "Free" : paymentMethodNumber === 1 ? `$ ${totalPrice}` : `${totalPrice} MATIC`}
            </Text>
            </Flex>
        </Flex>
    );
};

export default PaymentMethodItem;