import { useEffect } from 'react';
import { Link } from 'react-router-dom'

//Chakra
import { Flex, Text, Button } from '@chakra-ui/react'

//Icons
import AppIcons from 'assets/icons/AppIcons';

const ErrorPage = () => {

    useEffect(() => {
        // Prevent going back to the previous page
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", handleBackButtonPress);

        return () => {
            window.removeEventListener("popstate", handleBackButtonPress);
        };
    }, []);

    const handleBackButtonPress = () => {
        window.history.pushState(null, document.title, window.location.href);
    };

    return (
        <Flex flexDirection={'column'} gap={'12px'} height={'85vh'}>
            <Flex height={'100%'} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gap={"40px"} padding={"16px"} alignSelf={"stretch"} flex={"1 0 0"} borderRadius={"16px"} bgColor={"gray.900"} boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}>
                <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'}>
                    <AppIcons.Alert />
                    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'8px'}>
                        <Text textAlign={'center'} color={'primary.600'} fontFamily={'AVB'} fontSize={'20px'} fontWeight={500}>Oops :(</Text>
                        <Text textAlign={'center'} color={'primary.600'} fontFamily={'AVSB'} fontSize={'20px'} fontWeight={500}>There is something wrong</Text>
                        <Text textAlign={'center'} color={'gray.800'} fontFamily={'AVR'} fontSize={'16px'} fontWeight={400}>Clicking the "Back to Home Page" button might resolve the issue</Text>
                    </Flex>
                </Flex>
            </Flex>
            <Link to={`/user/home`}>
                <Button bg={"primary.600"} color={"gray.900"} _active={{bgColor: 'primary.900'}} _hover={{ bg: "primary.900" }} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} width={"100%"}>
                    Back to Home Page
                </Button>
            </Link>
        </Flex>
    );
};

export default ErrorPage;