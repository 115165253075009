import useAppToast from "../toast/useToast";
import axiosInstance from "lib/apis/axiosConfig";

type HookProps = {
  url: string;
  props?: any;
};

export function useApi() {
  const { showToast } = useAppToast();

  const postApi = async ({ url, props }: HookProps) => {
    // console.log(props);
    try {
      const res = await axiosInstance.post(`${url}`, props);
      return res.data.data;
    } catch (err: any) {
      if (err?.response?.data) {
        //
        showToast(`${err?.response?.data?.data?.message}`, "error");
      } else {
        showToast(`${err?.message}`, "error");
      }
      return false;
    }
  };

  const patchApi = async ({ url, props }: HookProps) => {
    try {
      const res = await axiosInstance.patch(`${url}`, props);
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        showToast(`${err?.response?.data?.data?.message}`, "error");
      } else {
        showToast(`${err?.message}`, "error");
      }
      return false;
    }
  };

  const getApi = async ({ url, props }: HookProps) => {
    try {
      const res = await axiosInstance.get(`${url}`, props);

      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        const errorMessage = err.response.data?.data?.message || err.response.data?.message || err.message;
        showToast(errorMessage, "error");
        if (err?.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      } else {
        showToast(err.message, "error");
      }
      return false;
    }
  };

  const deleteApi = async ({ url, props }: HookProps) => {
    try {
      const res = await axiosInstance.delete(`${url}`, props);
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        showToast(`${err?.response?.data?.data?.message}`, "error");
      } else {
        showToast(`${err?.message}`, "error");
      }
      return false;
    }
  };

  const putApi = async ({ url, props }: HookProps) => {
    try {
      const res = await axiosInstance.put(`${url}`, props);
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        showToast(`${err?.response?.data?.data?.message}`, "error");
      } else {
        showToast(`${err?.message}`, "error");
      }
      return false;
    }
  };

  return {
    postApi,
    patchApi,
    getApi,
    deleteApi,
    putApi,
  };
}
