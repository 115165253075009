import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Flex, Text, Button } from '@chakra-ui/react'
import AppIcons from 'assets/icons/AppIcons';

const Onboarding = () => {
    const [selectedItem, setSelectedItem] = useState('host')
    const navigate = useNavigate()

    const clickHandler = (selectedItemValue: string) => {
        if (selectedItemValue === 'host') {
            navigate("/user/create/info")
        } else {
            navigate("/")
        }
    }
    return (
        <Flex flexDirection={'column'} alignItems={'flex-start'} width={'418px'} borderRadius={'16px'} bgColor={'gray.900'} overflow={'hidden'}>
            <Flex justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'} gap={'16px'} padding={'16px 24px'}>
                <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'2px'} flex={'1 0 0'}>
                    <Text fontFamily={"AVSB"} color={"gray.0"} fontSize={'18px'} fontWeight={600}>
                        What would you like to next?
                    </Text>
                    <Text fontFamily={'AVR'} color={'gray.600'} fontSize={'16px'} fontWeight={400}>Let’s get started by hosting or attending any events!</Text>
                </Flex>
                <Flex cursor={'pointer'} onClick={() => window.history.back()}><AppIcons.XcloseWithoutBorder /></Flex>
            </Flex>

            <Flex alignItems={'center'} justifyContent={'flex-start'} flexDirection={'column'} alignSelf={'stretch'} gap={'24px'} padding={'24px'}>
                <Flex cursor={'pointer'} onClick={() => clickHandler('host')} alignItems={'center'} gap={'12px'} alignSelf={'stretch'} padding={'16px'} borderRadius={'12px'} border={selectedItem === 'host' ? '1px solid #084836' : '1px solid #434343'} bgColor={selectedItem === 'host' ? 'rgba(46, 201, 158, 0.05)' : ''}>
                    <Flex alignItems={'center'} gap={'12px'} flex={'1 0 0'}>
                        <AppIcons.GreenCalendar/>
                        <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'4px'} flex={'1 0 0'}>
                            <Text color={selectedItem === 'host' ? 'primary.600' : 'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Be a host</Text>
                            <Text color={selectedItem === 'host' ? 'primary.600' : 'gray.600'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400}>Create an event with few clicks!</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex cursor={'pointer'} onClick={() => clickHandler('attend')} alignItems={'center'} gap={'12px'} alignSelf={'stretch'} padding={'16px'} borderRadius={'12px'} border={selectedItem === 'attend' ? '1px solid #084836' : '1px solid #434343'} bgColor={selectedItem === 'attend' ? 'rgba(46, 201, 158, 0.05)' : ''}>
                    <Flex alignItems={'center'} gap={'12px'} flex={'1 0 0'}>
                        <AppIcons.GreenCalendar/>
                        <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'4px'} flex={'1 0 0'}>
                            <Text color={selectedItem === 'attend' ? 'primary.600' : 'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Attend an event</Text>
                            <Text color={selectedItem === 'attend' ? 'primary.600' : 'gray.600'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400}>RSVP, enjoy, and earn digital collectibles!</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            {/* <Flex alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'} padding={'16px 32px'}>
                <Button display={'flex'} alignItems={'center'} justifyContent={'center'} flex={'1 0 0'} padding={'8px 16px'} borderRadius={'8px'} bgColor={'primary.600'} onClick={() => btnClickHandler(selectedItem)}>
                    <Text color={'primary.900'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500}>Get started</Text>
                </Button>
            </Flex> */}
        </Flex>
    );
};

export default Onboarding;