import { useEffect, useState } from "react";

//Chakra
import { Flex, Text, Box, Grid, Spinner } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Component
import ExploreItem from "./ExploreItem";
import EmptyPageMessage from "components/desktop-common/empty-page-message/EmptyPageMessage";

import { IEvents, useUserStore } from "lib/stores/user/UserStore";
import { useQuery } from "@tanstack/react-query";
import SkeletonCard from "components/desktop-common/skeleton-card/SkeletonCard";

const Explore = () => {
  const { getExploreEvents } = useEvents();
  const userId = useUserStore((state) => state.user?.id);

  const { data: events, isLoading, isError } = useQuery({ queryKey: ["exploreEvents"], queryFn: getExploreEvents, cacheTime: 60 * 60 * 1000 });

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"32px"} flex={"1 0 0"}>
      <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} height={"100%"} width={"100%"} color={"gray.0"} rounded={"8px"} gap={"24px"}>
        <Text color={"gray.0"} fontWeight={"600"} fontSize={"18px"} fontFamily={"AVSB"}>
          Explore
        </Text>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {isLoading ? (
            Array.from({ length: 10 }).map((_, index) => <SkeletonCard key={index} />)
          ) : events?.length > 0 ? (
            events.map((events: any, index: number) => <ExploreItem events={events} key={index} currentUserId={userId} />)
          ) : (
            <EmptyPageMessage msg="No projects to show" desc="We’ll make sure to let you know you when new projects are released" icon={<AppIcons.FileSearch />} createBtn={false} />
          )}
        </Grid>
      </Flex>
    </Box>
  );
};

export default Explore;
