//Chakra
import { Box, Text, Flex, IconButton } from "@chakra-ui/react";
//Vaul
import { Drawer } from "vaul";
//Icons
import AppIcons from 'assets/icons/AppIcons';

export function Vaul({children, isOpen, setIsOpen, headerTitle}: {children: any, isOpen: boolean, setIsOpen: any, headerTitle: string}) {
    
    const overlayStyle = {
        position: 'fixed' as "fixed",
        inset: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    };
    
    const contentStyle = {
        display: 'flex',
        flexDirection: 'column' as 'column',
        backgroundColor: '#1c1c1c',
        borderTop: '1px solid #434343',
        position: 'fixed' as 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        top: '25vh',
        padding: '15px 0 0 0',
        maxHeight: '96%',
        borderRadius: '25px 25px 0 0',
        zIndex: 1000,
    };
    
    return (
        <Drawer.Root open={isOpen}>
            {/* <Drawer.Trigger asChild onClick={() => setIsOpen(true)}>
                {openBtn}
            </Drawer.Trigger> */}
            <Drawer.Portal>
                <Drawer.Overlay style={overlayStyle} />
                <Drawer.Content style={contentStyle}>
                    <>
                        <Flex alignItems={"center"} justifyContent={"center"} marginBottom={'10px'}>
                            <Box textAlign={"center"} rounded={"5px"} zIndex={"50"} width={"48px"} height={"4px"} bgColor={"gray.300"} />
                        </Flex>
                        <Flex position={'relative'} justifyContent={"center"} alignItems={"center"} marginBottom={'10px'}>
                            <Text fontSize={"16px"} marginTop={"0px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"24px"} color={"white"}>
                                {headerTitle}
                            </Text>
                            <IconButton
                                position={"absolute"}
                                rounded={"8px"}
                                bgColor={"green.800"}
                                top={"0px"}
                                right={"12px"}
                                minW={"32px"}
                                w={"32px"}
                                h={"32px"}
                                _hover={{ bg: "green.800" }}
                                aria-label="document"
                                icon={<AppIcons.X />}
                                onClick={() => setIsOpen(false)}
                            />
                        </Flex>
                    </>
                    <Box width={'100%'} maxWidth={'32rem'} marginX={'auto'} overflow={'auto'} padding={'1rem'} borderRadius={'10px 10px 0 0'}>
                        {children}
                    </Box>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    );
}
