import { Outlet } from "react-router-dom";

//Chakra
import { Box, Flex } from "@chakra-ui/react";

//Components
import Menu from "./menu/Menu";

//User Data
import { useUserStore } from "lib/stores/user/UserStore";


function Layout() {
    const jwtToken = useUserStore((state) => state.jwtToken);
    return (
    <Flex>
        {jwtToken && (
        <Box flex={1}>
            <Menu/>
        </Box>
        )}
        <Box flex={{ base: 3.5, xl: 4 }} height={'100%'}>
            <Outlet />
        </Box>
    </Flex>
    );
}

export default Layout;
