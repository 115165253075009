export const initialState = {
  ticketQuantities: {},
  selectedTicketType: null,
  isDisabled: true,
  price: 0,
  polygonPrice: 0,
  totalPrice: 0,
  totalPolygonPrice: 0,
}

export const purchaseReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_TICKETS_ID':
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          ticketQuantities: action.payload.reduce((acc: any, ticketId: any) => {
            acc[ticketId] = 0;
            return acc;
          }, {}),
        };
      }
      return state;

    case 'INCREASE_TICKET':
      return {
        ...state,
        ticketQuantities: {
          ...state.ticketQuantities,
          [action.payload]: state.ticketQuantities[action.payload] + 1,
        },
      };

    case 'DECREASE_TICKET':
      if (state.ticketQuantities[action.payload] > 0) {
        return {
          ...state,
          ticketQuantities: {
            ...state.ticketQuantities,
            [action.payload]: state.ticketQuantities[action.payload] - 1,
          },
        };
      }
      return state;

    case 'SET_TICKET_TYPE':
      return {
        ...state,
        selectedTicketType: action.payload,
      };

    case 'SET_IS_DISABLED':
      return {
        ...state,
        isDisabled: action.payload,
      };

    case 'TICKET_PRICE':
      return {
        ...state,
        price: action.payload,
      };

    case 'TICKET_POLYGON_PRICE':
        return {
          ...state,
          polygonPrice: action.payload,
        };

    case 'CALC_TOTAL_PRICE':
      return {
        ...state,
        totalPrice: action.payload,
      };

    case 'CALC_POLYGON_TOTAL_PRICE':
      return {
        ...state,
        totalPolygonPrice: action.payload,
      };

    default:
      return state;
  }
};