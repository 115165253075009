import { useEffect, useState } from "react";

//Chakra
import { Box, Flex } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

//Components
import ComingUpEvents from "./parts/ComingUpEvents";
import UnclaimedRewards from "./parts/unclaimRewards/UnclaimedRewards";
import Explore from "./parts/explor/Explore";

//Interfaces
import { IEvents, useUserStore } from "lib/stores/user/UserStore";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { useProfile } from "functions/hooks/user/useProfile";

const LoginHome = () => {
  const jwtToken = useUserStore((state) => state.jwtToken);

  const { isLoading, getUserAttendEvents } = useEvents();
  const [loading, setLoading] = useState(false);
  const [attendEvents, setAttendEvents] = useState<IEvents[]>([]);
  const { userUnclaimedRewards } = useProfile();
  const [unclaimedRewards, setUnclaimedRewadrs] = useState<any>([]);

  //Attend events
  const attendEventGetter = async () => {
    const res = await getUserAttendEvents();
    if (res) {
      setAttendEvents(res);
    }
  };

  //Unclaimed rewards
  const unclaimedRewardsGetter = async () => {
    const res = await userUnclaimedRewards();
    if (res) {
      setUnclaimedRewadrs(res);
    }
  };

  useEffect(() => {
    attendEventGetter();
    jwtToken && unclaimedRewardsGetter();
  }, []);

  // Check if there are events with a date greater than today
  const hasFutureEvents = attendEvents?.some((event) => {
    const eventDate = new Date(event?.start);
    const today = new Date();
    return eventDate > today;
  });

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <Flex position={"relative"} flexDirection={"column"} gap={"12px"}>
          {hasFutureEvents && <ComingUpEvents event={attendEvents} />}
          {unclaimedRewards?.length > 0 && (
            <UnclaimedRewards unclaimedRewardsData={unclaimedRewards} />
          )}
          <Explore />
        </Flex>
      )}
    </Box>
  );
};

export default LoginHome;
