import { useState } from "react";
import * as rdd from "react-device-detect";

export function DeviceDetector() {
  const isMobile = rdd.isMobile;

  return {
    isMobile,
  };
}
