import { Formik, Form, Field } from 'formik'
//Chakra
import { Box, Flex, Button, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react'
//Custom Hooks
import { useProfile } from 'functions/hooks/user/useProfile';
//Components
import BackBtn from 'components/common/back-btn/BackBtn';

const ForgetPassword = () => {
    const { isLoading, forgetPassword } = useProfile()


    const validateEmail = (value: string) => {
        let error: string | undefined;
        if (!value) {
            error = 'Email is required';
        } else if (!/^\S+@\S+\.\S+$/.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }
    
    return (
        <Flex flexDirection={'column'} gap={'14px'}>
            <BackBtn/>
            <Box bgColor={'gray.900'} padding={'16px'} borderRadius={'8px'}>
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={(values, actions) => {
                        forgetPassword(values.email)
                        setTimeout(() => {
                            actions.setSubmitting(false);
                        }, 1000);
                    }}
                >
                    {(props) => (
                        <Form>
                            <Field name='email' validate={validateEmail}>
                                {({ field, form }: any) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                        <FormLabel color={"gray.400"} fontFamily={"IM"} fontWeight={"500"}>Email</FormLabel>
                                        <Input border={'none'} bgColor={'gray.1000'} fontFamily={"IM"} padding={'12px'} color={'gray.0'} {...field} placeholder='Enter your email...' />
                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Button
                                type='submit'
                                width={'100%'}
                                mt={5}
                                bgColor={'primary.600'}
                                color={"primary.900"}
                                fontFamily={"IM"}
                                fontSize={"16px"}
                                fontWeight={500}
                                _hover={{bgColor: 'primary.600'}}
                                _disabled={{ bgColor: "#353535", color: "gray.900" }}
                                _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
                                isLoading={isLoading}
                                isDisabled={!props.isValid || !props.dirty}
                            >
                                Send
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Flex>
    )
};

export default ForgetPassword;
