import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

//Chakra
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import BackBtn from "components/common/back-btn/BackBtn";
import SaveDraftBtn from "components/desktop-common/save-draft-btn/SaveDraftBtn";
import NextBtn from "components/desktop-common/next-btn/NextBtn";
import { CustomForm } from "components/common/form/form-style";
import ImageInput from "pages/event-creation/deploy-activate-event/UploadImage";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import useAppToast from "functions/hooks/toast/useToast";
import { useProfile } from "functions/hooks/user/useProfile";

//Data
import { IEvents, useUserStore } from "lib/stores/user/UserStore";
import { WalletTypesEnum } from "types/enums/ProfileEnums";
import { Chain, Network } from "lib/nft/chains/Chains";
import { getNetworkProvider } from "lib/nft/chains/chainProvider";
import CustomSwitch from "components/common/custom-switch/CustomSwitch";
import { appDevelopment } from "lib/utils/development/Development";

const DeployEvent = () => {
  const { isLoading, publishEvent, getEventDetail, eventRecord } = useEvents();
  // const { onActivateContract } = useProfile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState("");
  const [activating, setActivating] = useState("active");
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [eventById, setEventById] = useState<IEvents>();
  const { showToast } = useAppToast();
  const user = useUserStore((state) => state.user);
  const [isGasFeeActive, setIsGasFeeActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deployHash, setDeployHash] = useState<String>();
  const [modalState, setModalState] = useState("");
  const [nftTransferMethod, setNftTransferMethod] = useState("")

  // const { values, handleChange, errors } = useFormik({
  //   initialValues: {
  //     toggleSwitch: false,
  //   },
  //   onSubmit: async () => {},
  // });

  const gasFeeSwitchHandler = () => {
    setIsGasFeeActive(!isGasFeeActive);
  };

  const getEventStatus = async () => {
    let event;
    if (eventId) event = await getEventDetail(eventId);
    setEventById(event);
    if (event) {
      // setIsEventPublished(event?.status === "PUBLISHED");
      // setTitle(event?.title);
      // setStart(new Date(event?.start));
      if (event?.contract && event?.contract?.status !== "CREATED") {
        setActivating("activated");
      }
    }
  };

  const isMetamaskInstalled = () => {
    const { ethereum }: any = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  };

  useEffect(() => {
    getEventStatus();
  }, [eventId]);

  const activateContract = async () => {
    setLoading(true);
    try {
      if (!image) {
        showToast("Please first upload an NFT image", "error");
        setLoading(false);
      } else if (!isMetamaskInstalled()) {
        showToast("Metamask is not installed", "error");
        setLoading(false);
        return;
      }
      if (eventById && process.env.REACT_APP_NFT_STORAGE_API_KEY) {
        let userAddress: string;
        if (
          !user?.walletAddress &&
          user?.walletType !== WalletTypesEnum.POLYGON
        ) {
          userAddress = await getNetworkProvider(
            Chain.POLYGON,
            Network.TESTNET,
            ""
          )
            ?.walletLogin()
            .then((data) => {
              return data?.address;
            });
        } else {
          userAddress = user?.walletAddress;
        }
        setActivating("pending");
        let ticketCount: number = 0;
        let firstTicketPrice: number = 0;
        let maxTicketPrice: number = 0;

        if (eventById && eventById.tickets) {
          eventById.tickets.map((item) => (ticketCount += item.total));
        }

        if (
          eventById &&
          Array.isArray(eventById?.tickets) &&
          eventById?.tickets.length > 0
        ) {
          firstTicketPrice = eventById.tickets.sort(
            (a, b) => a.price - b.price
          )[0].price;

          maxTicketPrice = Math.max(
            ...eventById.tickets.map((ticket: any) => ticket.price)
          );

          // console.log("First Ticket Price:", firstTicketPrice);
          // console.log("Max Ticket Price:", maxTicketPrice);
        }

        let chainProvider = getNetworkProvider(
          Chain.POLYGON,
          appDevelopment ? Network.TESTNET : Network.MAINNET,
          userAddress
        );
        let result = await chainProvider?.mintNFT(
          {
            type: "event-ticket",
            "other-features": "something else",
          },
          eventById?.title,
          eventById?.description,
          image,
          maxTicketPrice * 100,
          ticketCount,
          process.env.REACT_APP_NFT_STORAGE_API_KEY
        );
        // console.log(result); // <- prints the transaction-hash(id)
        setDeployHash(result);
        if (result) {
          showToast("contract deployed", "success");
          setActivating("activated");

          const sent = await eventRecord({
            deploy_hash: result,
            eventId,
            recorded_quantity: ticketCount,
            nft_image: image,
            nft_proof_of: nftTransferMethod
          });
        } else {
          setActivating("failed");
        }
      }
    } catch (error) {
      showToast(`You denied transaction signature.`, "info");
    }
    setLoading(false);
  };

  return (
    <Flex
      alignItems={"flex-start"}
      flexDirection={"column"}
      alignSelf={"stretch"}
      gap={"24px"}
      width={"100%"}
    >
      <Flex
        alignItems={"flex-start"}
        flexDirection={"column"}
        gap={"8px"}
        alignSelf={"stretch"}
        width={"100%"}
      >
        <Text
          fontSize={"20px"}
          fontWeight={600}
          fontFamily={"AVSB"}
          color={"gray.0"}
        >
          Deploy event
        </Text>
        <Text
          fontSize={"14px"}
          fontWeight={400}
          fontFamily={"AVR"}
          color={"gray.600"}
          alignSelf={"stretch"}
        >
          Deploying a smart contract & publish your event
        </Text>
      </Flex>
      <CustomForm autoComplete="off" width={"100%"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          rounded={"16px"}
          gap={"16px"}
          padding={"16px"}
          bg={"gray.900"}
          width={"100%"}
        >
          <Flex gap={"4px"} alignItems={"center"}>
            <Text
              fontFamily={"AVB"}
              fontWeight={600}
              fontSize={"18px"}
              color={"gray.0"}
            >
              Fee Coverage
            </Text>
            <IconButton
              _hover={{ bg: "transparent" }}
              bg={"transparent"}
              icon={<AppIcons.InfoCircle />}
              aria-label="circle info"
              //   onClick={}
            />
          </Flex>
          <Text
            color={"gray.600"}
            fontSize={"14px"}
            fontFamily={"AVR"}
            fontWeight={"400"}
          >
            There's a gas-fee for each ticket. You can cover it for your
            customers.
          </Text>
          <Flex gap={"8px"} alignItems={"center"}>
            <CustomSwitch
              isChecked={isGasFeeActive}
              onClickHandler={gasFeeSwitchHandler}
            />
            <Text color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
              Cover the gas-fee
            </Text>
          </Flex>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          rounded={"16px"}
          gap={"16px"}
          padding={"16px"}
          bg={"gray.900"}
        >
          <Text
            fontFamily={"AVB"}
            fontWeight={600}
            fontSize={"18px"}
            color={"gray.0"}
          >
            Deploy event
          </Text>
          <Text
            color={"gray.600"}
            fontSize={"14px"}
            fontFamily={"AVR"}
            fontWeight={"400"}
          >
            Upload an artwork for your proof of attendance.
          </Text>
          <ImageInput image={image} setImage={setImage} />
          <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"8px"}>
            <Text
              fontFamily={"AVR"}
              fontSize={"16px"}
              fontWeight={600}
              color={"gray.0"}
            >
              When will NFT be transferred?
            </Text>
            <Stack spacing={2} direction="column">
              <Checkbox colorScheme="teal" isChecked={nftTransferMethod === "ATTENDEES" && true} onChange={() => setNftTransferMethod("ATTENDEES")} color={"gray.0"} fontFamily={"AVR"} size={"md"}>
                NFT as proof of attendees
              </Checkbox>
              <Checkbox colorScheme="teal" isChecked={nftTransferMethod === "PURCHASE" && true} onChange={() => setNftTransferMethod("PURCHASE")} color={"gray.0"} fontFamily={"AVR"} size={"md"}>
                NFT as proof of purchase
              </Checkbox>
            </Stack>
          </Flex>
          <Alert
            status="warning"
            bgColor={"#453700"}
            color={"#DFB204"}
            rounded={"16px"}
            display={"flex"}
            alignItems={"flex-start"}
            gap={"8px"}
          >
            <IconButton
              _hover={{ bg: "transparent" }}
              bg={"transparent"}
              icon={<AppIcons.Alert />}
              aria-label="Warning icon"
              //   onClick={}
            />
            <AlertDescription
              fontSize={"14px"}
              fontFamily={"AVR"}
              fontWeight={"400"}
            >
              This is a blockchain transaction so once it goes through, ticket &
              ruleset won't be editable.
            </AlertDescription>
          </Alert>
          <Button
            padding={"8px"}
            height={"32px"}
            fontFamily={"IM"}
            fontSize={"14px"}
            color={"gray.0"}
            fontWeight={500}
            bg={"gray.800"}
            onClick={activateContract}
            isLoading={loading}
          >
            Activate
          </Button>
          <Text
            color={"gray.600"}
            fontSize={"12px"}
            fontFamily={"AVR"}
            fontWeight={"400"}
          >
            Once you click "Activate", sign the transaction on your wallet to
            proceed.
          </Text>
        </Box>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          alignSelf={"stretch"}
        >
          <Flex alignSelf={"center"}>
            <BackBtn optionalUrl={`/user/create/acceptPayment/${eventId}`} />
          </Flex>
          <Flex justifyContent={"flex-end"} alignItems={"center"} gap={"12px"}>
            <Button
              bg={"green.800"}
              color={"gray.0"}
              padding={"12px 16px"}
              fontSize={"16px"}
              fontWeight={500}
              fontFamily={"IM"}
              onClick={() => {
                setModalState("draft");
                onOpen();
              }}
            >
              Save draft
            </Button>
            <Button
              bg={"primary.600"}
              color={"primary.900"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              _hover={{ bgColor: "primary.600" }}
              _disabled={{ bgColor: "gray.900", color: "gray.700" }}
              _active={{ bgColor: "primary.900" }}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                setModalState("publish");
                onOpen();
              }}
            >
              Publish
            </Button>
          </Flex>
        </Flex>
      </CustomForm>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor={"gray.900"} alignSelf={"center"}>
          <ModalHeader>
            <Text
              color={"gray.0"}
              fontFamily={"AVR"}
              fontSize={"18px"}
              fontWeight={600}
            >
              {modalState === "publish" ? "Publish event" : "Save as draft"}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              color={"gray.600"}
              fontFamily={"IM"}
              fontSize={"14px"}
              fontWeight={400}
              alignSelf={"stretch"}
            >
              {modalState === "publish"
                ? "The most recent updates about your event will be published and guests will be notified."
                : "Would you like to save your work before leaving this page?"}
            </Text>
          </ModalBody>

          <ModalFooter
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"16px"}
            width={"100%"}
          >
            <Button
              bgColor={"green.800"}
              color={"gray.0"}
              width={"100%"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              onClick={() => {
                modalState === "publish" ? setModalState("draft") : onClose();
              }}
            >
              {modalState === "publish" ? "Save as draft" : "Close"}
            </Button>
            <Button
              bgColor={"primary.600"}
              color={"primary.900"}
              width={"100%"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              _hover={{ bgColor: "primary.600" }}
              _disabled={{ bgColor: "gray.900", color: "gray.700" }}
              _active={{ bgColor: "primary.900" }}
              isDisabled={isLoading}
              onClick={() => {
                modalState === "publish"
                  ? eventId && publishEvent(eventId)
                  : navigate(`/user/create/saveDraft/${eventId}`);
                onClose();
              }}
            >
              {modalState === "publish" ? "Confirm" : "Save as draft"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DeployEvent;
