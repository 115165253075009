import { Link } from "react-router-dom";
//Chakra
import { Flex, Image, Skeleton, Text } from "@chakra-ui/react";
//Interfaces
import { IEvents } from "lib/stores/user/UserStore";

const ProfileEventItem = ({ events, isAttend, isUserProfile, isLoading }: { events: IEvents[]; isAttend?: boolean; isUserProfile?: boolean; isLoading?: boolean }) => {

  return (
    <Flex flexDirection={"column"} gap={"12px"} rounded={"8px"} bg={"gray.900"} padding={"16px"}>
      <Text fontWeight={"600"} fontSize={"18px"} lineHeight={"28px"} fontFamily={"AVR"} color={"gray.0"}>
        Distribution
      </Text>
      <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"} gap={"12px"}>
        {isLoading ? (
          Array.from({ length: 15 }).map((_, index) => (
            <Skeleton key={index} width={"70px"} height={"70px"} rounded={"10px"} />
          ))
        ) : (
          events?.map((stuff) => (
            <Link to={isUserProfile ? `/user/events/${stuff?._id}` : `ticketDetail/${stuff?._id}`} key={stuff?._id} style={{ textDecoration: "none" }}>
              <Flex gap={"12px"}>
                <Image width={"70px"} height={"70px"} rounded={"10px"} objectFit={"cover"} src={stuff?.banner} />
              </Flex>
            </Link>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default ProfileEventItem;
