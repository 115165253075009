//Chakra
import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";

//Components
import { UTCConverter } from "lib/utils/time/UTCConverter";


const PublishItem = ({ event, loading }: { event: any; loading: any }) => {
  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <Flex padding={"16px"} gap={"12px"} borderColor={"gray.700"} alignItems={'center'} rounded={"16px"} bg={"gray.900"}>
          <Image width={"60px"} height={"60px"} rounded={"10px"} src={event?.banner} />
          <Flex gap={"4px"} justifyContent={"flex-start"} flexDirection={"column"}>
            <Text fontFamily={"AVSB"} fontSize={"14px"} color={"gray.0"}>
              {event?.title}
            </Text>
            <Text fontFamily={"AVR"} fontSize={"12px"} color={"gray.0"}>
              {UTCConverter(event?.start)}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default PublishItem;
