import { WalletTypesEnum } from "types/enums/ProfileEnums";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface ITicket {
  title: string;
  description: string;
  price: number;
  total: number;
  sold: number;
  created_at: string;
  updated_at: string;
  id: string;
}

interface IAgenda {
  start: string;
  description: string;
  id: string;
}

export interface IEvents {
  creator: string;
  title: string;
  eventTitle: string;
  description: string;
  banner: string;
  eventBanner: string;
  status: string;
  display_time: boolean;
  attendees: string[];
  agendas: IAgenda[];
  rules: any[];
  tickets: ITicket[];
  members: string[];
  created_at: string;
  updated_at: string;
  end: string;
  location: string;
  start: string;
  _id: string;
  id: string;
  eventID: string;
  ticket: string;
  sold: number;
}
export interface IUserData {
  user: IUser;
  events: IEvents;
}

interface IUserStore {
  userData: IUserData | null;
  isLoggedIn: boolean;
  user: IUser;
  events: IEvents[];
  jwtToken: string | null;
  setUserData: (data: IUser) => void;
  setUserEventsData: (data: IEvents) => void;
  setJwtToken: (jwt: string) => void;
}


export enum SocialMediaEnum {
  TELEGRAM = "TELEGRAM",
  LINKEDIN = "LINKEDIN",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
}

export interface ISocialMediasLink {
  name : SocialMediaEnum;
  link : string | "";
}

export enum PaymentsEnum {
  STRIPE = "STRIPE",
  STACKS = "STACKS",
  POLYGON= "POLYGON",
}

export interface IPaymentMethods {
  name: PaymentsEnum;
  address?: string;
  isActive: boolean;
}

export interface IUser {
  name: string | "";
  bio: string | "";
  image: string | "";
  socialMediasLink: ISocialMediasLink[];
  paymentMethods : IPaymentMethods[];
  email: string | "";
  id: string;
  walletAddress: string;
  walletType: WalletTypesEnum | null;
  publicKey: string;
  stripeCustomerID: string;
}

let store: any = (set: any): IUserStore => {
  return {
    userData: null,
    isLoggedIn: false,
    user: {
      name : "",
      bio : "",
      image : "",
      socialMediasLink : [],
      paymentMethods : [],
      email: "",
      id: "",
      walletAddress: "",
      walletType: null,
      publicKey: "",
      stripeCustomerID: "",
    },
    events: [],
    jwtToken: null,
    setUserData: (user: IUser) => {
      // const user = data;
      set({
        user,
        isLoggedIn: user !== null,
      });
      // set({ userData: data });
    },
    setUserEventsData: (data: IEvents) => {
      const events = data;
      set({
        events,
      });
      // set({ userData: data });
    },
    setJwtToken: (token) => set({ jwtToken: token }),
  };
};

store = persist(store, { name: "userStore" });
store = devtools(store);
export const useUserStore = create<IUserStore>(store);
