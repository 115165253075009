import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Flex, Image, Spinner, Text, Skeleton } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import TicketInfoDetail from "./part/TicketInfoDetail";
import UniqueQRcode from "./part/UniqueQRcode";
import IconQRcodeBox from "components/common/iconQRcodeBox/IconQRcodeBox";
import BackBtn from "components/common/back-btn/BackBtn";
import { Vaul } from "components/common/vaul/Vaul";
import { useProfile } from "functions/hooks/user/useProfile";
import { useEvents } from "functions/hooks/events/useEvents";
import QrCodeScanner from "../qrCodeScanner/QrCodeScanner";
import { useUserStore } from "lib/stores/user/UserStore";


const TicketDetail = () => {
  const userId = useUserStore((state) => state.user?.id);

  const { eventId } = useParams();
  const { getEventDetail, getUserAttendEvents, attendeesDataWithEventId } = useEvents();
  const { getCurrentUserData, getUsersProfileDetail } = useProfile();
  const [data, setData] = useState<any>({
    event: null,
    loading: false,
    showModal: false,
    bought: false,
    currentUserData: null,
    userProfileDetail: null,
    userAttendData: null,
    userAttendId: null,
    isUserHostEvent: false,
    attendQrCode: null,
    walletAddress: '',
    eventAttendeesData: null,
  });

  
  const findUserAttendId = async (currentUserAttendancesData: any) => {
    // let commonAttendeesIds: any = [];
    if (currentUserAttendancesData) {
      currentUserAttendancesData?.map((item: any) => {
        if (item.eventID === eventId) {
          setData((prevData: any) => ({ ...prevData, attendQrCode: item.qrcode }));
        }
      })
    }
    // Check if commonAttendeesIds is not undefined before calling attendeesData
    // if (commonAttendeesIds) {
    //   console.log(commonAttendeesIds);
    //   console.log(commonAttendeesIds.join().split(',')[0]);
    //   const attendId = commonAttendeesIds.length > 0 ? commonAttendeesIds.join().split(',')[0] : commonAttendeesIds.join() 
    //   setData((prevData: any) => ({ ...prevData, userAttendId: attendId }));
  
    //   const userAttendData = await attendeesData(attendId);
    //   setData((prevData: any) => ({ ...prevData, userAttendData }));
    // }
  };
  
  const isHostEvent = (event: any, currentUser: any, currentUserProfileData: any) => {
    if (event && currentUser) {
      if (event?.creator === currentUser?.id) {
        setData((prevData: any) => ({ ...prevData, isUserHostEvent: true }));
      }else {
        findUserAttendId(currentUserProfileData?.attendances);
      }
    }
  }

  useMemo(async () => {
    if (eventId) {
      setData((prevData: any) => ({ ...prevData, loading: true }));
      const res = await getEventDetail(eventId);
      const userTickets = await getUserAttendEvents();
      const currentUserData = await getCurrentUserData();
      const currentUserProfileData = await getUsersProfileDetail(userId);
      const attendeesData = !(res?.creator === currentUserData?.id) && await attendeesDataWithEventId(eventId);
      const indexFinder = userTickets?.findIndex((userEvents) => userEvents._id === res.id);
      let polygonAddress = currentUserData?.paymentMethods?.filter((item: any) => item.name === "POLYGON")[0]?.address
      isHostEvent(res, currentUserData, currentUserProfileData)
      setData((prevData: any) => ({ ...prevData, currentUserData, bought: indexFinder === -1 ? false : true, event: res, userProfileDetail: currentUserProfileData, walletAddress: polygonAddress, eventAttendeesData: attendeesData }));
      setData((prevData: any) => ({ ...prevData, loading: false }));
    }

  }, [eventId]);
  
  
  const ShowQRcodeModal = () => {};

  const shortedWalletAddress = data.walletAddress && `${data.walletAddress?.slice(0, 4)}...${data.walletAddress?.slice(38, )}`;

  return (
    <Box>
      <BackBtn />
        <Flex position={"relative"} flexDirection={"column"} gap={"12px"} mt={"10px"}>
          <Flex flexDirection={"column"} bg={"gray.900"} color={"gray.0"} rounded={"16px"} padding={"16px"} gap={"16px"}>
            {data.loading ? 
            <Skeleton width={'100%'} height={'200px'} rounded={"8px"} />
            :
            <Image rounded={"8px"} alt="event banner" src={data.event?.banner} />
            }
            {data.loading ? 
            <Skeleton width={'150px'} height={'15px'} />
            :
            <Text fontFamily={"AVR"} fontSize={"22px"} lineHeight={"28px"} fontWeight={"700"}>
              {data.event?.title}
            </Text>
            }
            {/* <IconInfoBox 
              boxIcon={<AppIcons.GreenCalendar />} 
              label="Event" 
              primaryTitle={data.event?.title} 
              isInTicketDetail={true}
            /> */}
            <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'8px'}>
              <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Ticket details</Text>
              <TicketInfoDetail event={data.event} walletAddress={shortedWalletAddress} isUserHostEvent={data.isUserHostEvent} orderData={data.eventAttendeesData?.orderID} />
            </Flex>
            <Box>
              <IconQRcodeBox
                boxIcon={<AppIcons.QRcodePT />}
                label={data.isUserHostEvent ? "Scan QR Code" : "QR Code"}
                primaryTitle={data.isUserHostEvent ? "Check in guests" : data.eventAttendeesData?.orderID?.type === "POLYGON" ? shortedWalletAddress : "Credit/debit card"}
                onClickShowModal={() => setData((prevData: any) => ({ ...prevData, showModal: !prevData.showModal }))}
                isLoading={data.loading}
              />
            </Box>
            <Vaul isOpen={data.showModal} setIsOpen={() => setData((prevData: any) => ({ ...prevData, showModal: !prevData.showModal }))} headerTitle={data.isUserHostEvent ? "Scan QR Code" : "QR Code"}>
              {data.isUserHostEvent ? <QrCodeScanner eventId={eventId}/> : <UniqueQRcode event={data.event} qrCode={data.attendQrCode} walletAddress={shortedWalletAddress} />}
            </Vaul>
          </Flex>
        </Flex>
    </Box>
  );
};

export default TicketDetail;
