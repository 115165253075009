import { useState } from 'react'


//Chakra
import { Box, Button, Flex, Image, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Text, useDisclosure } from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Time Converter
import { UTCConverter } from "lib/utils/time/UTCConverter";
import { useEvents } from 'functions/hooks/events/useEvents';
import useAppToast from 'functions/hooks/toast/useToast';

const HostItem = ({events, removeDeletedEvent}: {events: any, removeDeletedEvent: any}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deletingEvent, cancelingEvent } = useEvents();
  const { showToast } = useAppToast();
  const navigate = useNavigate()


  const handleEditClick = (eventId: string, status: string) => {
    let editRoute = "";
    switch (status) {
      case "INTRODUCTION":
        editRoute = `/user/create/info/${eventId}`;
        break;
      case "TICKETS":
        editRoute = `/user/create/tickets/${eventId}`;
        break;
      case "RULES":
        editRoute = `/user/create/rulesets/${eventId}`;
        break;
      case "PAYMENT_METHOD":
        editRoute = `/user/create/acceptPayment/${eventId}`
        break;
      case "PUBLISHED":
        showToast("You cannot edit the published event", "warning")
        break;
      case "CANCELED":
        editRoute = `/user/create/info/${eventId}`;
        break;
      case "Draft":
        editRoute = `/user/create/info/${eventId}`;
        break;

      default:
        editRoute = `/user/events/${eventId}`;
        break;
    }
    navigate(editRoute);
  };


  const onConfirmDelete = (eventId: any) => {
    removeDeletedEvent(eventId);
  }

  return (
    // <ChakraLink as={ReactRouterLink} to={`/user/events/${events.events?._id}`} style={{ textDecoration: "none" }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        gap={"12px"}
        padding={"16px"}
        border={"1px solid #434343"}
        borderRadius={"8px"}
        width={"100%"}
        height={"100%"}
        flex={"1 0 0"}
        bgColor={"gray.1000"}
        position={'relative'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Image src={events?.banner} width={"100%"} height={"130px"} display={"flex"} alignSelf={"stretch"} borderRadius={"8px"} objectFit={"cover"} />
        <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
          <Text fontFamily={"AVR"} fontSize={"12px"} fontWeight={400} color={"gray.300"}>
            {events?.start && UTCConverter(events?.start)}
          </Text>
          <ChakraLink as={ReactRouterLink} to={`/user/events/${events?._id}`} style={{ textDecoration: "none" }}>
            <Text fontFamily={"AVR"} fontSize={"14px"} fontWeight={600} color={"gray.0"}>
              {events?.title}
            </Text>
          </ChakraLink>
          <Flex alignItems={"center"} alignSelf={"stretch"} gap={"4px"}>
            <AppIcons.Location width={"13px"} height={"16px"} strokeWidth={"1.5px"} />
            <Text fontFamily={"AVR"} fontSize={"12px"} fontWeight={400} color={"gray.300"}>
              {events?.location}
            </Text>
          </Flex>
        </Flex>
        {isHovered &&
        <Popover placement="auto">
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Flex alignItems={'center'} justifyContent={'center'} gap={'8px'} padding={'10px'} position={'absolute'} top={'24px'} right={'24px'} borderRadius={'8px'} bgColor={'green.800'} cursor={'pointer'}>
                  <AppIcons.DotsHorizontal />
                </Flex>
              </PopoverTrigger>
              <PopoverContent width={"auto"} bgColor={"transparent"} border={"none"}>
                <List
                  display={"flex"}
                  flexDirection={"column"}
                  right={"-10px"}
                  zIndex={"990"}
                  padding={"8px"}
                  bg={"green.800"}
                  rounded={"8px"}
                  boxShadow={
                    "box-shadow: 0px 4px 6px -2px rgba(27, 27, 27, 0.03), 0px 12px 16px -4px rgba(27, 27, 27, 0.08)"
                  }
                >
                  <ListItem
                    display={"flex"}
                    alignItems={"center"}
                    padding={"12px"}
                    gap={"8px"}
                    cursor={"pointer"}
                    onClick={() => handleEditClick(events?._id, events?.status)}
                  >
                    <AppIcons.Edit />
                    <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                      Edit
                    </Text>
                  </ListItem>
                  <ListItem
                    display={"flex"}
                    alignItems={"center"}
                    padding={"12px"}
                    gap={"8px"}
                    cursor={"pointer"}
                    onClick={onOpen}
                  >
                    {events?.status === "PUBLISHED" ? <AppIcons.PauseSquare /> : <AppIcons.TrashPopover />}
                    <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                      {events?.status === "PUBLISHED" ? "Cancel event" : "Delete"}
                    </Text>
                  </ListItem>
                </List>
              </PopoverContent>
            </>
          )}
        </Popover>}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent flexDirection={'column'} alignItems={'flex-start'} borderRadius={'16px'} bgColor={'gray.900'} width={'418px'} alignSelf={'center'}>
              <ModalHeader>
                  <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>
                    {events?.status === "PUBLISHED" ? "Cancel event" : "Delete event"}
                  </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
                      <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'24x'} padding={'0 24px'}>
                          <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'}>
                            <Text color={'gray.600'} fontFamily={'IM'} fontSize={'14px'} fontWeight={400}>This event is currently active. Are you sure you want to delete it? Your guests will be notified of any updates.</Text>
                          </Flex>
                      </Flex>
                      <Flex alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'} padding={'16px 24px'}>
                          <Button width={'100%'} bgColor={'green.800'} color={'gray.0'} display={'flex'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'8px'} padding={'12px 16px'} borderRadius={'8px'} _hover={{ bg: "green.800" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'gray.700', color: 'gray.0'}} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} onClick={onClose}>Cancel</Button>
                          <Button 
                            width={'100%'}
                            bgColor={'primary.600'} 
                            color={'primary.900'} 
                            display={'flex'} 
                            alignItems={'center'} 
                            justifyContent={'center'} 
                            alignSelf={'stretch'} 
                            gap={'8px'} 
                            padding={'12px 16px'} 
                            borderRadius={'8px'} 
                            _hover={{ bg: "primary.600" }} 
                            _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
                            _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} 
                            fontFamily={"IM"} 
                            fontSize={"16px"} 
                            fontWeight={500} 
                            onClick={() => {
                              if (events?.status === "PUBLISHED") {
                                if (events?.sold === 0) {
                                  cancelingEvent(events?._id)
                                  onConfirmDelete(events?._id);
                                  showToast(`Your event has been successfully cancelled`, "success")
                                  onClose();
                                } else {
                                  showToast(`Your event has participants, so it cannot be canceled`, "warning")
                                  onClose();
                                }
                              } else if (events?.status !== "PUBLISHED") {
                                deletingEvent(events?._id);
                                onConfirmDelete(events?._id);
                                onClose();
                                showToast(`Your event has been successfully deleted`, "success")
                              } else {
                                onConfirmDelete(events?._id);
                                onClose();
                              }
                            }}
                          >
                            Confirm
                          </Button>
                      </Flex>
              </ModalBody>                
          </ModalContent>
      </Modal>
      </Box>
    // </ChakraLink>
  );
};

export default HostItem;
