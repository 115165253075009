import { useNavigate } from 'react-router-dom'

//Chakra
import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

const Settings = () => {
  const navigate = useNavigate()

  const logoutHandler = () => {
    localStorage.clear()
    navigate('/signin')
  }

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} gap={"12px"} width={"100%"} minWidth={"250px"} padding={"16px 0"} borderRadius={"8px"} bgColor={"gray.900"}>
      <Flex flexDirection={"column"} padding={"16px"} alignItems={"flex-start"} gap={"10px"} alignSelf={"stretch"} borderRadius={"8px"}>
        <ChakraLink as={ReactRouterLink} to="paymentsetup/" style={{ textDecoration: "none" }} justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
          <Flex justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
            <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
              Payment setup
            </Text>
            <AppIcons.ChevronRight />
          </Flex>
        </ChakraLink>
      </Flex>
      <Flex flexDirection={"column"} padding={"16px"} alignItems={"flex-start"} gap={"10px"} alignSelf={"stretch"} borderRadius={"8px"}>
        <Flex justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
          <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
            Native currency
          </Text>
          <Flex alignItems={"center"} gap={"8px"}>
            <Text color={"gray.0"} fontSize={"16px"} fontWeight={400} lineHeight={"24px"} fontFamily={"AVR"}>
              USD
            </Text>
            <AppIcons.ChevronRight />
          </Flex>
        </Flex>
      </Flex>
      <ChakraLink as={ReactRouterLink} to="share-profile" style={{ textDecoration: "none" }} justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
        <Flex flexDirection={"column"} padding={"16px"} alignItems={"flex-start"} gap={"10px"} alignSelf={"stretch"} borderRadius={"8px"}>
          <Flex justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
            <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
              Profile QR code
            </Text>
            <AppIcons.ChevronRight />
          </Flex>
        </Flex>
      </ChakraLink>
      <Flex padding={"0 16px"} alignItems={"flex-start"} alignSelf={"stretch"}>
        <Divider orientation="horizontal" display={"flex"} height={"0"} justifyContent={"center"} alignItems={"center"} flex={"1 0 0"} bg={"gray.700"} />
      </Flex>
      <Flex flexDirection={"column"} padding={"16px"} alignItems={"flex-start"} gap={"10px"} alignSelf={"stretch"} borderRadius={"8px"}>
        <Flex justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
          <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
            Add account
          </Text>
          <AppIcons.ChevronRight />
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} padding={"16px"} alignItems={"flex-start"} gap={"10px"} alignSelf={"stretch"} borderRadius={"8px"} onClick={logoutHandler}>
        <Flex justifyContent={"space-between"} alignItems={"center"} alignSelf={"stretch"}>
          <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
            Logout
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Settings;
