//Chakra
import { Flex } from "@chakra-ui/layout";

//Components
import UnclaimedRewardItem from "./UnclaimedRewardItem";

const UnclaimedRewards = ({unclaimedRewardsData}: {unclaimedRewardsData: any}) => {
  const firstTwoItems = unclaimedRewardsData?.slice(0, 2);

  return (
    <Flex flexDirection={"column"} alignItems={"flex-start"} color={"gray.0"} gap={"12px"} flex={"1 0 0"}>
      {firstTwoItems?.map((item: any, index: number) => <UnclaimedRewardItem key={index} nftData={item} />)}
    </Flex>
  );
};

export default UnclaimedRewards;
