//Chakra
import { Divider, Flex, Skeleton, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";
import { usePayment } from "functions/hooks/payment/usePayment";
import { useMemo, useState } from "react";


const EventForm = ({
  event,
  decreaseTicketQuantity,
  increaseTicketQuantity,
  quantity,
  ticketRules,
  polygonRate,
}: {
  event: any;
  decreaseTicketQuantity: (id: any, price: number, polygonPrice?: any) => void;
  increaseTicketQuantity: (id: any, total: any, price: number, sold: number, polygonPrice?: any) => void;
  quantity: number;
  ticketRules: any;
  polygonRate: number;
}) => {
  const { getLatestAnswer } = usePayment();
  const [polygonPrice, setPolygonPrice] = useState()

  if (event?.sold === event?.total) {
    console.log(`ticket ${event?.id} sold out`);
  }

  const isSoldOut = event?.sold === event?.total;

  const getDiscountForTicket = (ticketId: string): number => {
    const rule = ticketRules.find((rule: any) => rule.tickets.includes(ticketId));
    return rule && rule.discount;
  };

  const discount = getDiscountForTicket(event.id);
  const priceAfterDiscount: any = discount ? parseFloat((event?.price - ((event?.price * discount) / 100)).toFixed(2)) : event?.price;

  const convertUsdToMatic = async (usdPrice: number) => {
    const res: any = await getLatestAnswer(usdPrice);
    return (res / 1e18).toFixed(2);
  };


  useMemo( async () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const result: any = await convertUsdToMatic(event?.price)
    setPolygonPrice(result)
  }, [event?.price])


  let priceInPolygonAfterDiscount = 0

  if (polygonPrice) {
    priceInPolygonAfterDiscount = discount ? parseFloat((polygonPrice - ((polygonPrice * discount) / 100)).toFixed(2)) : polygonPrice; 
  }


  return (
    <Flex key={event?.id} backdropFilter={isSoldOut ? 'blur(500px) saturate(200%)' : ''} bgColor={isSoldOut ? '' : ''} border={"1px solid #434343"} borderRadius={"12px"} flexDirection={"column"} alignSelf={"stretch"} alignItems={"flex-start"} padding={"16px"} gap={"8px"}>
      <Flex width={"100%"} justifyContent={"space-between"} alignItems={"center"} alignSelf={"flex-start"}>
        <Text fontSize={"16px"} fontWeight={"600"} lineHeight={"24px"} alignItems={"center"} color={"gray.0"} fontFamily={"AVR"} width={"194px"} alignSelf={"stretch"}>
          {event?.title}
        </Text>
        {isSoldOut && 
        <Text fontSize={"16px"} fontWeight={"600"} lineHeight={"24px"} alignItems={"center"} color={"error.600"} fontFamily={"AVR"} width={"194px"} alignSelf={"stretch"}>
          Sold Out
        </Text>}
        <Flex alignItems={"center"} gap={"8px"}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gap={'8px'}
            padding={'8px 6px'}
            height={'24px'}
            width={'24px'}
            borderRadius={"4px"} 
            bgColor={"gray.700"}
            cursor={'pointer'}
            onClick={() => !isSoldOut && decreaseTicketQuantity(event?.id, priceAfterDiscount, priceInPolygonAfterDiscount)}
          >
            <AppIcons.Minus/>
          </Flex>
          <Text fontSize={"14px"} fontWeight={600} fontFamily={"AVR"} color={"gray.0"} userSelect={'none'}>
            {quantity}
          </Text>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gap={'8px'}
            padding={'8px 6px'}
            height={'24px'}
            width={'24px'}
            borderRadius={"4px"} 
            bgColor={"primary.600"}
            cursor={'pointer'}
            onClick={() => !isSoldOut && increaseTicketQuantity(event?.id, event?.total, event?.sold, priceAfterDiscount, priceInPolygonAfterDiscount)}
          >
            <AppIcons.Plus/>
          </Flex>
        </Flex>
      </Flex>
      <Divider height={"1px"} bg={"gray.700"} width={"100%"} margin={"0 auto"} orientation="horizontal" />
      <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"4px"} alignSelf={"stretch"}>
        <Flex alignItems={"center"} gap={"4px"}>
          <AppIcons.MATICToken />
          {!polygonPrice ? 
          <Skeleton width={'50px'} height={'15px'} />
          :
          <Text fontSize={"14px"} fontWeight={600} fontFamily={"AVR"} color={"gray.0"}>
            {event?.price === 0 ? "Free" : polygonPrice}
          </Text>
          }
          {discount ?
          <Text fontSize={"14px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
            {`(${discount}% off)`}
          </Text>
          :
          <></>
          }
        </Flex>
        {discount ? 
          <Text fontSize={"14px"} fontWeight={"400"} lineHeight={"16px"} marginTop={"14px"} fontFamily={"AVR"} color={"gray.0"}>
            {event?.price === 0 ? "Free" : `~ ${event?.price} USD (${discount}% off)`}
          </Text>
          :
          <Text fontSize={"14px"} fontWeight={"400"} lineHeight={"16px"} marginTop={"14px"} fontFamily={"AVR"} color={"gray.0"}>
            {event?.price === 0 ? "Free" : `~ ${event?.price} USD `}
          </Text>
        }
      </Flex>
      <Text fontSize={"14px"} fontWeight={"400"} lineHeight={"20px"} color={"gray.600"} overflowWrap={'break-word'} wordBreak={'break-word'}>
        {event?.description?.length >= 75 ? `${event?.description.slice(0,75)}...` : event?.description}
      </Text>
    </Flex>
  );
};

export default EventForm;
