import { Flex, IconButton, Text, Tooltip, Skeleton } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import { UTCConverter } from "lib/utils/time/UTCConverter";
import React from "react";

const TicketInfoDetail = ({ event, walletAddress, isUserHostEvent, orderData }: { event: any, walletAddress?: string, isUserHostEvent?: boolean, orderData?: any }) => {
  return (
    <Flex border={"1px solid #434343"} borderRadius={"12px"} flexDirection={"column"} padding={"12px"} gap={"16px"} width={'100%'}>
      <Flex display={"flex"} justifyContent={"space-between"}>
        {!event?.title ? 
        <Skeleton width={'50px'} height={'10px'} /> 
        :
        <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"#fff"}>
          {isUserHostEvent ? "Quantity purchased" : event?.title}
        </Text>
        }
        {!event?.title ? 
        <Skeleton width={'50px'} height={'10px'} /> 
        :
        <Text
          fontSize={"14px"}
          fontStyle={"normal"}
          fontWeight={"400"}
          lineHeight={"16px"}
          color={"#fff"}
        >
          {isUserHostEvent ? event?.sold : `×${orderData?.quantity}`}
        </Text>
        }
      </Flex>
      <Flex justifyContent={"space-between"} alignItems={'center'}>
        {!event ? 
        <Skeleton width={'50px'} height={'10px'} />
        :
        <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"#fff"}>
          {isUserHostEvent ? "Status" : "Pay with"}
        </Text>
        }
        {event?.title ?
        isUserHostEvent ? 
          <Text color={event?.status === "PUBLISHED" ? "primary.600" : event?.status === "CANCELED" ? 'red.600' : "gray.0"} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>{event?.status}</Text> 
          :
          orderData?.type === "POLYGON" ? 
          <Flex alignItems={"center"} gap={"4px"}>
            <AppIcons.MATICToken />
            <Text fontSize={"12px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
              {walletAddress}
            </Text>
          </Flex>
        :
        <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"#fff"}>
          Credit/debit card
        </Text>
        :
        <Skeleton width={'70px'} height={'10px'} />
        }
      </Flex>
      <Flex display={"flex"} justifyContent={"space-between"}>
        <Flex justifyContent={"center"} alignItems={"center"} gap={"4px"}>
          {!event ? 
          <Skeleton width={'50px'} height={'10px'} />
          :
          <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"#fff"}>
            {isUserHostEvent ? "Start date" : `Fee`}
          </Text>
          }
          {event && !isUserHostEvent && 
          <Tooltip
            hasArrow
            label="This is an estimated fee."
            placement="right"
            fontWeight={"400"}
            fontSize={"12px"}
            fontFamily={"IM"}
            lineHeight={"16px"}
            borderRadius={"8px"}
            padding={"8px 12px"}
            textAlign={"center"}
          >
            <IconButton
              margin={0}
              padding={0}
              height={0}
              minWidth={0}
              _hover={{ bg: "transparent" }}
              bg={"transparent"}
              aria-label="document"
              icon={<AppIcons.SmallInfo />}
              //   onClick={}
            />
          </Tooltip>}
        </Flex>
        {event ?
        isUserHostEvent ? 
        <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'12px'} fontWeight={500}>{UTCConverter(event?.start)}</Text>
        :
        orderData?.type === "POLYGON" ? 
        <Flex flexDirection={"column"} alignItems={"flex-end"}>
          <Text fontSize={"12px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
            0.00006 MATIC
          </Text>
          <Text fontSize={"10px"} fontWeight={400} fontFamily={"AVR"} color={"gray.400"}>
            ~0.0002 USD
          </Text>
        </Flex>
        :
        <Text
          fontSize={"14px"}
          fontStyle={"normal"}
          fontWeight={"400"}
          lineHeight={"16px"}
          color={"#fff"}
        >
          $0
        </Text>
        :
          <Skeleton width={'50px'} height={'10px'} />
        }
      </Flex>
    </Flex>
  );
};

export default TicketInfoDetail;
