import { useMemo, useState } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

//Chakra
import { Avatar, Button, Flex, Grid, Image, Skeleton, SkeletonCircle, Spinner, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import BackBtn from "components/common/back-btn/BackBtn";
import { UTCConverter } from "lib/utils/time/UTCConverter";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { useNavigator } from "functions/hooks/navigator/useNavigator";

//Data
import { IEvents, useUserStore } from "lib/stores/user/UserStore";

//Enums
import { UserTicketStatusEnum } from "types/enums/UserTicketEnums";
import { useProfile } from "functions/hooks/user/useProfile";
import { useQuery } from "@tanstack/react-query";
import ExploreItem from "pages/desktop-pages/home/explore/ExploreItem";
import SkeletonCard from "components/desktop-common/skeleton-card/SkeletonCard";

const EventPage = () => {
  const userId = useUserStore((state) => state.user?.id);
  const jwtToken = useUserStore((state) => state.jwtToken);
  const { getUserAttendEvents, getNewEventDetails, eventDetailViaTicketId } = useEvents();
  const { getCurrentUserData } = useProfile();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const location = useLocation()
  const { shareLink } = useNavigator();
  const [event, setEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const [bought, setBought] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<any>([])
  const [showAllAttendees, setShowAllAttendees] = useState(false);
  const { getExploreEvents } = useEvents();
  const { data: events, isLoading, isError } = useQuery({ queryKey: ["exploreEvents"], queryFn: getExploreEvents, cacheTime: 60 * 60 * 1000 });

  const isFromUserProfile = location.state && location.state.isUserProfile


  const isEventExpired = (startDate: string) => {
    const eventDate = new Date(startDate);
    const today = new Date();
    return eventDate < today;
  }

  function calculateSoldAndTotal(items: any) {
    let totalSold = 0;
    let totalTicket = 0;
  
    items?.forEach((item: any) => {
      if (item.sold !== undefined && item.total !== undefined) {
        totalSold += item.sold;
        totalTicket += item.total;
      }
    });
  
    return { totalSold, totalTicket };
  }


  useMemo(async () => {
    if (eventId) {
      setLoading(true);      
      const res = await getNewEventDetails(eventId);
      const userTickets = await getUserAttendEvents();
      setCurrentUserData(await getCurrentUserData());
      if (userTickets && userTickets?.length > 0) {
        const indexFinder = userTickets?.findIndex((userEvents) => userEvents?._id === res?.event?._id);
        setBought(indexFinder === -1 ? false : true);
      } else {
        setBought(false)
      }
      
      setEvent(res);
      setLoading(false);
    }
  }, [eventId]);

  const buttonHandler = () => {
    jwtToken ? (!bought ? navigate(`/user/purchase/${event?.event?._id}`) : navigate(`/user/ticketDetail/${event?.event?._id}`)) : navigate(`/signup`);
  };

  const { totalSold, totalTicket } = calculateSoldAndTotal(event?.event?.tickets);

  return (
    <>
      <Flex flexDirection={'column'} gap={'16px'}>
        <BackBtn />
        <Flex alignItems={'flex-start'} flexDirection={'column'} alignSelf={'Stretch'} gap={'24px'}>
          <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'16px'} padding={'16px'} borderRadius={'16px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
            <Flex width={'838px'} height={'240px'} flexShrink={'0'} position={'relative'}>
              {loading ? 
              <Skeleton width={'100%'} height={'100%'} borderRadius={'8px'} /> 
              : 
              <Image src={event?.event?.banner} objectFit={'cover'} width={'100%'} height={'100%'} borderRadius={'8px'} bgColor={'black'} />}
              {!loading && !(event?.event?.status === "INTRODUCTION" || event?.event?.status === "TICKETS" || event?.event?.status === "RULES" || event?.event?.status === "PAYMENT_METHOD") && 
              <Flex justifyContent={'center'} alignItems={'center'} gap={'8px'} padding={'10px'} bgColor={'green.800'} borderRadius={'8px'} position={'absolute'} top={'8px'} right={'8px'} cursor={'pointer'} onClick={() => shareLink(`${window.location.origin}/user/events/${event?.event?._id}`, event?.event?.title, "Check out this event:")}>
                  <AppIcons.Share2/>
              </Flex>
              }
              <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} padding={'8px'} borderRadius={'10px'} bgColor={'rgba(41, 41, 41, 0.80)'} position={'absolute'} top={'8px'} left={'8px'}>
                  <Flex alignItems={'center'} alignSelf={'Stretch'} gap={'4px'}>
                      {loading ? <SkeletonCircle width={"24px"} height={"24px"} />
                      : 
                      <Avatar
                          bgColor={"primary.600"}
                          src={event?.event?.creator?.image}
                          name={event?.event?.creator?.name}
                          size={'xs'}
                          width={"24px"}
                          height={"24px"}
                      />
                      }
                      <Text
                          fontSize={"14px"}
                          fontFamily={"AVR"}
                          fontWeight={400}
                          color={"gray.400"}
                      >
                          Hosted by
                      </Text>
                      {loading ? <Skeleton width={'50px'} height={'20px'} /> 
                      : 
                      <Link to={event?.event?.creator?.id === userId ? `/user/profile`  : `/user/profile/${event?.event?.creator?.id}`}>
                          <Text
                              fontSize={"14px"}
                              fontFamily={"AVSB"}
                              fontWeight={600}
                              color={event?.event?.creator?.id === userId ? 'primary.600' : "gray.0"}
                          >
                              {event?.event?.creator?.id === userId ? "You" : event?.event?.creator?.name}
                          </Text>
                      </Link>
                      }
                  </Flex>
              </Flex>
            </Flex>
            {loading ? 
            <Skeleton width={'160px'} height={'35px'} /> 
            : 
            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'30px'} fontWeight={600}>{event?.event?.title}</Text>
            }
            <Flex alignItems={'flex-start'} alignSelf={'stretch'} gap={'16px'}>
              <IconInfoBox boxIcon={<AppIcons.GreenCalendar />} label="Date" primaryTitle={event?.event?.start && UTCConverter(event?.event?.start)} secondaryTitle={event?.event?.end && UTCConverter(event?.event?.end)} />
              <IconInfoBox boxIcon={<AppIcons.GreenLocation />} label="Location" primaryTitle={event?.event?.location} />
            </Flex>
          </Flex>

          {isEventExpired(event?.event?.start) ? (
            <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500} isDisabled={true}>Event has expired</Button>
          ) : 
            (event?.event?.status === "INTRODUCTION" || event?.event?.status === "TICKETS" || event?.event?.status === "RULES" || event?.event?.status === "PAYMENT_METHOD") ?
              <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500} isDisabled={true}>Awaiting completion</Button>
            :
            (
              loading ? 
              <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500} isDisabled={true}>Loading...</Button>
              :
              totalSold === totalTicket ? (
                <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500} isDisabled={true}>Sold out</Button>
              ) : userId === event?.event?.creator?.id ? (
                <></>
              ) : bought ? (
                <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500}>Check Ticket</Button>
              ) : (
                <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500}>{jwtToken ? (!bought ? "RSVP" : "Check Ticket") : "Create Account"}</Button>
              )
            )
          }

          <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'} borderRadius={'16px'} padding={'16px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>About</Text>
            {loading ? 
            <>
              <Skeleton width={'100%'} height={'10px'} />
              <Skeleton width={'100%'} height={'10px'} />
              <Skeleton width={'100%'} height={'10px'} />
              <Skeleton width={'200px'} height={'10px'} />
            </>
            : 
            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400} alignSelf={'stretch'}>{event?.event?.description}</Text>}
          </Flex>

          <Flex flexDirection={"column"} bg={"gray.900"} color={"gray.0"} borderRadius={"12px"} padding={"16px"} gap={"18px"} width={'100%'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Text color={"gray.0"} fontSize={"18px"} fontFamily={"AVSB"} fontWeight={600} textAlign={"left"}>
                Guests ({event?.attendees?.length || 0})
              </Text>
              {event?.event?.creator?.id === userId && 
                event?.attendees?.length > 5 && <Text color={"primary.600"} fontSize={"14px"} fontFamily={"AVSB"} fontWeight={600} cursor={"pointer"} onClick={() => setShowAllAttendees(!showAllAttendees)}>{showAllAttendees ? "Collapse" : "See All"}</Text>
              }
            </Flex>
            {event?.event?.creator?.id === userId && 
              event?.attendees?.length > 0 && (
                <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'16px'}>
                  {event?.attendees?.slice(0, showAllAttendees ? undefined : 5).map((users: any) => (
                    <Flex key={users.userID} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
                      <Flex key={users.userID} alignItems={'center'} gap={'12px'} width={'100%'}>
                        <Avatar bgColor={"primary.600"} src={users.image} width={'24px'} height={'24px'} />
                        <Link to={users.userID === userId ? `/user/profile` : `/user/profile/${users.userID}`}>
                          <Text fontSize={'14px'} fontFamily={'AVR'} fontWeight={400} color={users.userID === userId ? 'primary.600' : 'gray.0'} flex={'1 0 0'}>
                            {users.userID === userId ? "You" : users.name}
                          </Text>
                        </Link>
                      </Flex>
                      {users.status === UserTicketStatusEnum.CHECKEDIN && <AppIcons.CheckRegular />}
                    </Flex>
                  ))}
                </Flex>
              )}
          </Flex>

          {events?.length > 0 && (
            <Flex flexDirection={'column'} alignItems={'center'} alignSelf={'stretch'} gap={'16px'}>
              <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600} alignSelf={'flex-start'}>Related events</Text>
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                {loading
                  ? Array.from({ length: 4 }).map((_, index) => <SkeletonCard key={index} />)
                  : events?.slice(0, 4).map((event: any, index: number) => <ExploreItem events={event} key={index} currentUserId={userId} />)
                }
              </Grid>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default EventPage;