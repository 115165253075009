import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import React from "react";
import { Label } from "../form/form-style";

const IconDetailPublish = ({
  boxIcon,
  primaryTitle,
  secondaryTitle,
  thirdTitle,
}: {
  primaryTitle: string;
  boxIcon:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  secondaryTitle?: string;
  thirdTitle?: string;
}) => {
  const { hasCopied, onCopy } = useClipboard(thirdTitle ?? "");

  const handleCopyClick = () => {
    onCopy();
  };

  return (
    <Flex
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      alignSelf={'stretch'}
      gap={"40px"}
      padding={"16px"}
      flex={'1 0 0'}
      boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
      borderRadius={'16px'}
      bgColor={'gray.900'}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"stretch"}
        gap={"16px"}
        paddingTop={'80px'}
      >
        <IconButton
          _hover={{ bg: "transparent" }}
          bg={"transparent"}
          aria-label="document"
          icon={boxIcon}
        />
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          alignSelf={'stretch'}
          gap={'8px'}
        >
          <Text
            color={"gray.0"}
            fontWeight={"600"}
            fontSize={"30px"}
            fontFamily={"AVSB"}
            textAlign={"center"}
          >
            {primaryTitle}
          </Text>
          <Text
            color={"gray.600"}
            fontSize={"14px"}
            textAlign={"center"}
            fontFamily={"AVR"}
            paddingX={"16px"}
          >
            {secondaryTitle}
          </Text>
        </Flex>
      </Flex>
      <Flex
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={'4px'}
        width={'100%'}
      >
        <Text
          color={"gray.400"}
          fontFamily={"AVSB"}
          fontWeight={"600"}
          fontSize={"16px"}
        >
          Share this link
        </Text>
        <InputGroup>
          <Input
            defaultValue={thirdTitle}
            readOnly
            border={"none"}
            color={"gray.0"}
            bg={"gray.1000"}
            fontFamily={"AVR"}
            type="text"
            id="url"
            placeholder="Name"
            padding={"12px"}
            width={'100vw'}
          />

          <InputRightElement onClick={handleCopyClick}>
            <AppIcons.CopyBtn />
          </InputRightElement>
        </InputGroup>
      </Flex>

      <Text size="sm" top={2} right={2} colorScheme="teal" variant="outline">
        {hasCopied ? "Copied!" : ""}
      </Text>
    </Flex>
  );
};

export default IconDetailPublish;
