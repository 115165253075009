import React from 'react';
import { Outlet } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

const SimpleLayout = () => {
    return (
        <Flex 
            alignItems={"center"}
            justifyContent={"center"}
            bgColor={"rgba(20, 20, 20, 0.80)"}
            inset={"0px"}
            position={'fixed'}
            top={'50%'}
            left={'50%'}
            transform={'translate(-50%, -50%)'}
        >
            <Outlet/>
        </Flex>
    );
};

export default SimpleLayout;