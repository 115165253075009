import {
  getUsersProfile,
  getCurrentUser,
  loginUser,
  postForgetPassword,
  postLoginViaWallet,
  postResetPassword,
  postUserVerify,
  putUserPaymentMethods,
  putUserProfile,
  singUpUser,
  getGoogleAccount,
  getUserUnclaimedRewards,
  getUserClaimedRewards,
  patchReceiveUnclaimedRewards,
} from "lib/apis/user/userApi";
import {
  ISocialMediasLink,
  IPaymentMethods,
  useUserStore,
} from "lib/stores/user/UserStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEvents } from "../events/useEvents";
import {
  useOpenSignMessage,
  useAuth,
  useAccount,
  useOpenContractDeploy,
  useOpenStxTokenTransfer,
} from "@micro-stacks/react";
import { useApi } from "../useApi/useApi";
import useAppToast from "../toast/useToast";
import {
  deploySmartContractApi,
  generateSmartContractCodeApi,
} from "lib/apis/contract/contractApi";
import { PolygonLogin } from 'lib/blockchain/polygon/polygon-login'
import { appDevelopment } from "lib/utils/development/Development";
import { WalletTypesEnum } from "types/enums/ProfileEnums";


export function useProfile() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const setJwtToken = useUserStore((state) => state.setJwtToken);
  const setUserData = useUserStore((state) => state.setUserData);
  const { openAuthRequest, isRequestPending, signOut, isSignedIn } = useAuth();
  const { openSignMessage } = useOpenSignMessage();
  const { openContractDeploy } = useOpenContractDeploy();
  const { openStxTokenTransfer } = useOpenStxTokenTransfer();
  const { postApi, putApi, patchApi, getApi } = useApi();
  const { showToast } = useAppToast();
  const { stxAddress } = useAccount();

  const onSubmit = async (values: {
    email: string;
    password: string;
    terms: boolean;
  }, isFromConfirmation?: boolean) => {
    setIsLoading(true);
    if (!values.terms) {
      showToast("You must agree to the Terms & Conditions", "error");
      return setIsLoading(false);
    }
    const data = await postApi(
      singUpUser({
        email: values.email,
        password: values.password,
      })
    );
    if (data) {
      localStorage.setItem("email", values.email);
      !isFromConfirmation && navigate(`/confirmation`, { state: values });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onSubmitLogin = async (values: { email: string; password: string; metadata?: any }) => {
    setIsLoading(true);
  
    try {
      const response = await postApi(
        loginUser({
          email: values.email,
          password: values.password,
          metadata: values?.metadata,
        })
      );
  
      if (response) {
        const { jwt, user, metadata } = response;
  
        if (metadata && metadata.redirectUri && metadata.key) {
          setJwtToken(jwt);
          setUserData(user);
          window.location.href = `${metadata.redirectUri}?key=${metadata.key}`;
        } else if (jwt) {
          setJwtToken(jwt);
          setUserData(user);
          navigate(`/`);
        }
      }
    } catch (error) {
      showToast("Login failed, Please try again", "error");
    } finally {
      setIsLoading(false);
    }
  };
  
  const verifyEmail = async (jwt: string) => {
    setIsLoading(true);
    const veriftyRes = await postApi(postUserVerify({ jwt }));
    if (veriftyRes) {
      // console.log(veriftyRes);
      // const jwt = veriftyRes.jwt;
      // setJwtToken(jwt);
      // setUserData(veriftyRes.user);
      setIsLoading(false);
      navigate(`/signin`);
    }
    setIsLoading(false);
  };

  const updateProfile = async (values: {
    name: string;
    bio: string;
    image: string;
    socialMediasLink: ISocialMediasLink[];
  }) => {
    setIsLoading(true);
    const profileRes = await putApi(
      putUserProfile({
        name: values.name,
        bio: values.bio,
        image: values.image,
        socialMediasLink: values.socialMediasLink,
      })
    );
    if (profileRes.user) {
      setUserData(profileRes.user);
      navigate("/user/profile");
    }
    setIsLoading(false);
  };

  const updatePaymentMethods = async (value: {
    paymentMethods: IPaymentMethods[];
  }) => {
    setIsLoading(true);
    const paymentMethodsRes = await putApi(
      putUserPaymentMethods({ paymentMethods: value.paymentMethods })
    ).then(data => showToast('Payment Methods update successfully', 'success'))
    setIsLoading(false)
  };

  const deployContract = async (
    eventId: string,
    contractName: string,
    code: string
  ) => {
    let address = stxAddress + "." + contractName;
    return await openContractDeploy({
      contractName: contractName,
      codeBody: code,
      onFinish: (payload) => {
        return patchApi(
          deploySmartContractApi(eventId, {
            address: address,
            transaction_id: payload.txId,
          })
        );
      },
    });
  };

  const onActivateContract = async (
    artwork: string,
    eventId: string,
    start: any,
    title: string
  ) => {
    // if (activating === "active" && stxAddress) {
    // setActivating("pending");
    console.log(artwork);
    let result = await postApi(generateSmartContractCodeApi(eventId, artwork));
    let code = "";
    if (result) {
      code = result.code;
    }

    const year = start.getYear() + 1900;
    const month = start.getMonth();

    const pattern = /[^A-Za-z0-9]/g;
    const contractName =
      "dp-events-" +
      title
        .replace(pattern, " ")
        .replace(/  +/g, " ")
        .substring(0, 20)
        .trim()
        .replace(/ /g, "-")
        .toLowerCase() +
      "-" +
      year +
      "-" +
      month;

    let deployResult = await deployContract(eventId, contractName, code);
    if (deployResult) {
      return true;

      // showToast("contract deployed");
      // setActivating("activated");
      // } else {
      // setActivating("failed");
    } else return false;
    // }
  };

  const transferStx = async (stxAmount: number, recipientWallet: string) => {
    return await openStxTokenTransfer({
      amount: (stxAmount * 10e5).toString(),
      recipient: recipientWallet,
    });
  };

  const loginViaStacks = async () => {
    setIsLoading(true);
    if (isSignedIn) await signOut();
    else
      try {
        await openAuthRequest({
          onFinish(session) {
            if (true) {
              signMessage(session?.addresses?.testnet);
            } else {
              signMessage(session?.addresses?.mainnet);
            }
          },
          onCancel() {
            setIsLoading(false);
          },
        });
      } catch (error) {
        showToast("Leather is not installed", "error");
      }
    setIsLoading(false);
  };

  const callConnectWalletApi = async (
    stxAddress: string,
    walletResponse: any
  ) => {
    let result = await postApi(
      postLoginViaWallet({
        wallet: stxAddress,
        publicKey: walletResponse.publicKey,
        signature: walletResponse.signature,
        type: WalletTypesEnum.STACKS,
      })
    );
    if (result) {
      const jwt = result.jwt;
      setJwtToken(jwt);
      setUserData(result.user);
      navigate(`/onboarding`);
    }
  };

  const signMessage = async (stxAddress: string) => {
    if (stxAddress) {
      setIsLoading(true);
      let message = stxAddress;
      await openSignMessage({
        message,
        onFinish: (walletResponse) => {
          callConnectWalletApi(stxAddress, walletResponse);
        },
        onCancel() {
          setIsLoading(false);
        },
      });
      setIsLoading(false);
    }
  };


  const loginViaPolygon = async () => {
    setIsLoading(true);
    try {
      const polygonRes = await PolygonLogin(
        appDevelopment ? "testnet" : "mainnet"
      );
      if (polygonRes) {
        let result = await postApi(
          postLoginViaWallet(
            {
              wallet: polygonRes.address,
              publicKey: polygonRes.address,
              signature: polygonRes.signature,
              type: WalletTypesEnum.POLYGON,
            }
          )
        );
        if (result)
        {
          const jwt = result.jwt;
          setJwtToken(jwt);
          setUserData(result.user);
          navigate(`/onboarding`);
        }
      }
    } catch (error: any) {
      showToast(error, 'error');
    }

    setIsLoading(false);
  };

  const forgetPassword = async (email: string) => {
    setIsLoading(true)
    let result = await postApi(
      postForgetPassword({
        email
      })
    );
    if (result) {
      showToast("Email sent succesfully", 'success')
    }
    setIsLoading(false)
  }

  const resetPassword = async (values: any) => {
    setIsLoading(true)
    let result = await postApi(
      postResetPassword({
        email: values.email,
        newPassword: values.password,
        confirmPassword: values.passwordConfirmation,
      }, values.token)
    )
    if (result) {
      showToast("password changed succesfully", 'success')
      navigate('/signin')
    }
    setIsLoading(false)
  }

  const loginViaGoogle = async (redicertUrl?: any) => {
    setIsLoading(true)
    if (appDevelopment) {
      window.location.href = redicertUrl ? `https://eventapidev.droplinked.com/auth/google?redirectUrl=${redicertUrl}` : 'https://eventapidev.droplinked.com/auth/google' 
    } else {
      window.location.href = redicertUrl ? `https://eventapi.droplinked.com/auth/google?redirectUrl=${redicertUrl}` : 'https://eventapi.droplinked.com/auth/google';
    }
    // const res = await getApi(getGoogleAccount())
    // console.log(res);
    setIsLoading(false);
  }

  const getCurrentUserData = async () => {
    setIsLoading(true)
    const res = await getApi(getCurrentUser())
    setIsLoading(false)
    return res.user;
  }

  const getUsersProfileDetail = async (userId: string) => {
    setIsLoading(true)
    const res = await getApi(getUsersProfile(userId))
    setIsLoading(false)
    return res;
  }

  const userUnclaimedRewards = async () => {
    setIsLoading(true)
    const res = await getApi(getUserUnclaimedRewards())
    if (res) {
      return res;
    }
    setIsLoading(false)
  }

  const userClaimedRewards = async () => {
    setIsLoading(true)
    const res = await getApi(getUserClaimedRewards())
    if (res) {
      return res;
    }
    setIsLoading(false)
  }

  const recieveUnclaimedRewards = async (rewardId: string) => {
    setIsLoading(true)
    const res = await patchApi(await patchReceiveUnclaimedRewards(rewardId))
    if (res) {
      setIsLoading(false)
      return res;
    }
    setIsLoading(false)
  }

  return {
    onSubmit,
    isLoading,
    verifyEmail,
    onSubmitLogin,
    updateProfile,
    loginViaStacks,
    onActivateContract,
    updatePaymentMethods,
    loginViaPolygon,
    forgetPassword,
    resetPassword,
    loginViaGoogle,
    getCurrentUserData,
    getUsersProfileDetail,
    userUnclaimedRewards,
    userClaimedRewards,
    recieveUnclaimedRewards,
  };
}