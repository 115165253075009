import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const EventsStepper = ({ step }: { step: number }) => {
  const steps = [
    { title: "Event details", description: "Setup your attractive event" },
    {
      title: "Ticket details",
      description:
        "Create tickets for your event with custom options such as VIP or discounts. For free events, simply set the ticket price to 0.",
    },
    {
      title: "Ticket rulesets",
      description: "Set your rules for ticket discounts or limit access based on a whitelist. Learn more",
    },
    {
      title: "Acceptance payment methods",
      description: "Choose the payment methods you would like to use for receiving payments.",
    },
    {
      title: "Deploy event",
      description: "Deploying a smart contract & publish your event",
    },
  ];

  const prog: any = [];
  for (let i = 0; i < 5; i++) {
    prog.push(
      <Box
        key={i}
        rounded={"100px"}
        paddingX={"6vw"}
        paddingY={"3px"}
        bg={i < step ? "gray.600" : i === step ? "primary.600" : "gray.800"}
      />
    );
  }

  return (
    <Flex flexDirection={"column"} gap={"16px"} mt={'16px'}>
      <Flex flexDirection={"column"} gap={"8px"}>
        <Text fontFamily={"AVB"} color={"gray.0"} fontSize={"24px"} fontWeight={600}>
          {steps[step].title}
        </Text>
        <Text fontFamily={"AVR"} color={"gray.600"} fontSize={"14px"} fontWeight={400} textAlign={"justify"}>
          {steps[step].description}
        </Text>
      </Flex>
      <Flex gap={"4px"}>{prog}</Flex>
    </Flex>
  );
};

export default EventsStepper;
