import { Box, Button, IconButton, Image, Input, Spinner, Text } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import axios from "axios";
import ToastMessage from "components/common/toastMessage/ToastMessage";
import useAppToast from "functions/hooks/toast/useToast";
import React, { ChangeEvent, useRef, useState } from "react";

interface ImageInputProps {
  image: string;
  setImage: (image: string) => void;
  label?: string;
  imageUse?: string;
}

const ImageInput: React.FC<ImageInputProps> = ({ image, setImage, imageUse = "a artwork" }) => {
  const { showToast } = useAppToast();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  const clickInputFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const changeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      try {
        const response = await axios.post("https://d2kpv1k2vro2sk.cloudfront.net/upload", formData);
        setLoading(false);
        showToast("Image Uploaded Successfuly", "success");
        setImage(response.data.original);
      } catch (error: any) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Input id="imageUpload" type="file" accept=".jpg , .png, .jpeg" display="none" ref={inputFile} onChange={changeImage} />
      {image === "" ? (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <Spinner />
          ) : (
            <Box
              border={"2px dashed"}
              borderColor={"gray.700"}
              rounded={"12px"}
              padding={"40px"}
              bg={"gray.1000"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"8px"}
              width={"100%"}
            >
              <IconButton _hover={{ bg: "transparent" }} bg={"transparent"} icon={<AppIcons.UploadImage />} aria-label="upload icon" />
              <Text fontFamily={"AVR"} fontSize={"14px"} fontWeight={400} textAlign={"center"}>
                Browse or drag and drop your image file here.
              </Text>
              <Button onClick={clickInputFile} fontFamily={"IM"} fontSize={"14px"} fontWeight={500} rounded={"8px"} color={"gray.0"} bg={"gray.800"} padding={"8px 12px"}>
                Browse
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"} onClick={clickInputFile}>
          {loading ? <Spinner /> : <Image rounded={"12px"} src={image} alt="banner" />}
        </Box>
      )}
    </>
  );
};

export default ImageInput;
