import { Flex, Text } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";

const EventCreationBackBtn = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex justifyContent={"flex-start"} alignItems={"center"} alignSelf={"flex-start"} gap={"6px"}>
      <Text
        display={"inline-flex"}
        gap={"6px"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"primary.600"}
        fontSize={"14px"}
        fontWeight={400}
        lineHeight={"20px"}
        cursor={"pointer"}
        fontFamily={"AVSB"}
        onClick={onClick}
      >
        <AppIcons.ChevronLeft />
        Back
      </Text>
    </Flex>
  );
};

export default EventCreationBackBtn;
