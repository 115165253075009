import { useState, useEffect } from 'react'

//Chakra
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";

//Components
import ComingUpEvents from "./comingup-events/ComingUpEvents";
import Explore from "./explore/Explore";
import Navbar from "components/desktop-layout/navbar/Navbar";
import UnclaimedRewards from "./unclaim-rewards/UnclaimedRewards";
import { useEvents } from "functions/hooks/events/useEvents";
import { IEvents, useUserStore } from 'lib/stores/user/UserStore';
import { useProfile } from 'functions/hooks/user/useProfile';

const Home = () => {
  const jwtToken = useUserStore((state) => state.jwtToken);

  const { getUserAttendEvents } = useEvents();
  const { userUnclaimedRewards } = useProfile();

  const [attendEvents, setAttendEvents] = useState<IEvents[]>([]);
  const [unclaimedRewards, setUnclaimedRewadrs] = useState<any>([])


  //Attend events
  const attendEventGetter = async () => {
    const res = await getUserAttendEvents();
    if (res) {
      setAttendEvents(res);
    }
  };

  //Unclaimed rewards
  const unclaimedRewardsGetter = async () => {
    const res = await userUnclaimedRewards()
    if (res) {
      setUnclaimedRewadrs(res)
    }
  }

  useEffect(() => {
    attendEventGetter()
    jwtToken && unclaimedRewardsGetter()
  },[])

  // Check if there are events with a date greater than today
  const hasFutureEvents = attendEvents?.some((event) => {
    const eventDate = new Date(event?.start);
    const today = new Date();
    return eventDate > today;
  });

  return (
    <Box padding={"48px"}>
      <Navbar title="Welcome!" />
      <Grid marginTop={"32px"} gap={"24px"}>
        {jwtToken &&
        <>
          {hasFutureEvents && 
          <GridItem colSpan={2}>
            <Flex flexDirection={"column"} gap={"24px"}>
              <Text color={"gray.0"} fontSize={"18px"} fontFamily={"AVSB"} fontWeight={"600"} flex={"1 0 0"}>
                Coming up event
              </Text>
              <ComingUpEvents events={attendEvents} hasFutureEvents={hasFutureEvents} />
            </Flex>
          </GridItem>}
          {unclaimedRewards?.length > 0 &&
          <GridItem colSpan={2}>
            <Flex flexDirection={"column"} gap={"24px"}>
              <Text color={"gray.0"} fontSize={"18px"} fontFamily={"AVSB"} fontWeight={"600"} flex={"1 0 0"}>
                Unclaimed rewards
              </Text>
              <UnclaimedRewards unclaimedRewardsData={unclaimedRewards} />
            </Flex>
          </GridItem>}
        </>
        }
        <GridItem colSpan={4}>
          <Explore />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Home;
