import { useMemo } from "react";
import EmptyDashboard from "./parts/empty/EmptyDashboard";
import DashboardGateModel from "functions/admin/parts/gate/model/DashboardGateModel";
import { useUserStore } from "lib/stores/user/UserStore";
import userHOC from "functions/admin/userHOC";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const jwtToken = useUserStore((state) => state.jwtToken);
  const user = useUserStore((state) => state.user);
  // userEvents();
  const permission = useMemo(() => DashboardGateModel.checkPermission({ user, jwtToken }), [user, jwtToken]);

  return <>{!permission ? <Outlet /> : null}</>;
};

export default userHOC(Dashboard);
