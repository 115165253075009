//Chakra
import { Box, Flex, Grid, Spinner } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import EmptyPageMessage from "components/desktop-common/empty-page-message/EmptyPageMessage";
import HostItem from "./HostItem";

//Interface
import { IEvents } from "lib/stores/user/UserStore";
import SkeletonCard from "components/desktop-common/skeleton-card/SkeletonCard";

const Host = ({ events, isLoading, removeDeletedEvent }: { events: IEvents[], isLoading: boolean, removeDeletedEvent: any }) => {

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"32px"} flex={"1 0 0"}>
      <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} height={"100%"} width={"100%"} color={"gray.0"} rounded={"8px"} gap={"24px"}>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {isLoading ? (
            Array.from({ length: 10 }).map((_, index) => <SkeletonCard key={index} />)
          ) : events.length > 0 ? (
            events.map((events, index) => <HostItem events={events} key={index} removeDeletedEvent={removeDeletedEvent} />)
          ) : (
            <EmptyPageMessage msg="No events created, yet" desc="Create event to take your first steps as an event host." icon={<AppIcons.Stars />} createBtn={true} />
          )}
        </Grid>
      </Flex>
    </Box>
  );
};

export default Host;
