import { useState, useEffect } from "react";

import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Button, Flex, Spinner, Text } from "@chakra-ui/react";

const CheckoutForm = ({orderId}: string | any) => {
  const [isStripeLoading, setIsStripLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const clickOnDiscart = async () => {
    window.history.back()
  };

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element?.on("ready", () => {
        setIsStripLoading(false);
      });
    }
  }, [elements]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    setMessage(null);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/user/purchase/thank-you/${orderId}`,
      },
    });

    if (result.error) {
      setMessage(result?.error?.message as string);
      setLoading(false);
      // Show error to your customer (for example, payment details incomplete)
    } else {
      setLoading(false);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {message && (
        <Text fontFamily="AVR" fontSize="12px" fontWeight="500" color="red" py="8px">
          {message}
        </Text>
      )}
      {!isStripeLoading && (
        <Flex pt="16px" w="100%" justifyContent="flex-end" gap="16px">
          <Button
            width={"100%"}
            p="8px 16px"
            color="#FFFFFF"
            bg={"#08090A"}
            border="1px solid"
            borderRadius="4px"
            fontFamily="AVR"
            fontSize="16px"
            fontWeight="400"
            borderColor="#27262B"
            onClick={clickOnDiscart}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            width={"100%"}
            p="8px 16px"
            color={"primary.600"}
            bg={"#08090A"}
            border="1px solid"
            borderRadius="4px"
            fontFamily="AVR"
            fontSize="16px"
            fontWeight="400"
            borderColor={"primary.600"}
            type="submit"
            _hover={{ bgColor: "primary.600", color: "primary.900" }}
            _active={{ bgColor: "primary.600", color: "primary.900" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </Flex>
      )}
    </form>
  );
};

export default CheckoutForm;
