export class statuesModule {
  user: any;
  jwtToken: string | null;

  constructor(user: any, jwtToken: string | null) {
    this.user = user;
    this.jwtToken = jwtToken;
  }

  userInfo = () => {
    return !Boolean(this.user.id && this.jwtToken) && "/signup";
  };
}
