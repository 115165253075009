import { Flex, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import AppIcons from 'assets/icons/AppIcons';
import { useUserStore } from 'lib/stores/user/UserStore';

const SkeletonCard = () => {
    const jwtToken = useUserStore((state) => state.jwtToken);
    
    return (
        <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
            alignSelf={"stretch"}
            gap={"12px"}
            padding={"16px"}
            border={"1px solid #434343"}
            borderRadius={"8px"}
            width={jwtToken ? "200px" : "275px"}
            height={"100%"}
            flex={"1 0 0"}
            bgColor={"gray.1000"}
        >
            <Skeleton width={"100%"} height={"130px"} borderRadius={'8px'} />
            <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                <Skeleton width={'50px'} height={'10px'} />
                <Skeleton width={'100px'} height={'20px'} />
                
                <Flex alignItems={"center"} alignSelf={"stretch"} gap={"4px"}>
                    <AppIcons.Location width={"13px"} height={"16px"} strokeWidth={"1.5px"} />
                    <Skeleton width={'70px'} height={'10px'} />
                </Flex>
                <Flex alignItems={'center'} alignSelf={'stretch'} gap={'4px'}>
                    <Text color={'gray.400'} fontFamily={'AVR'} fontSize={'12px'} fontWeight={400}>Hosted by</Text>
                    <SkeletonCircle width={'24px'} height={'24px'} />
                    <Skeleton width={'50px'} height={'10px'} />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SkeletonCard;