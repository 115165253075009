import React, { useState } from 'react';
import {
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  VStack,
  Flex,
  Text,
} from '@chakra-ui/react';

const DoormanEmailInput = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');



  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      if (isValidEmail(inputValue.trim())) {
        // Add the email to the selectedEmails list
        setSelectedEmails((prevSelectedEmails) => [
          ...prevSelectedEmails,
          inputValue.trim(),
        ]);
        setInputValue('');
        setErrorMessage('');
      } else {
        setErrorMessage('Please inter a valid email');
      }
    }
  };
  
  // Function to validate email using RegExp
  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  

  const removeSelectedEmail = (email: string) => {
    setSelectedEmails((prevSelectedEmails) =>
      prevSelectedEmails.filter((e) => e !== email)
    );
  };

  return (
    <VStack spacing={4} width={'100%'}>
      <Flex flexWrap={'wrap'} gap={'10px'}>
        {selectedEmails.map((email) => (
          <Tag key={email} size="lg" variant="solid" bgColor={"primary.600"} color={'primary.900'} fontFamily={'AVSB'} fontWeight={500} fontSize={'14px'}>
            <TagLabel>{email}</TagLabel>
            <TagCloseButton onClick={() => removeSelectedEmail(email)} />
          </Tag>
        ))}
      </Flex>
      <Flex
        flexDirection={'column'}
        alignItems={'flex-start'}
        width={'100%'}
      >
        <Input
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          variant="unstyled"
          color={"gray.0"}
          bg={"gray.1000"}
          fontFamily={"AVR"}
          type="text"
          id="doorman"
          placeholder="Enter email"
          padding={"12px"}
          _placeholder={{ color: "gray.800" }}
        />
        <Text color={'red.600'}>{errorMessage}</Text>
      </Flex>
    </VStack>
  );
};

export default DoormanEmailInput;