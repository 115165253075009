import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const StripeComponent = ({ clientSecret, appea, orderId }: { clientSecret: string; appea: "stripe" | "night" | "flat"; orderId: string | any }) => {
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: appea,
      variables: {
        fontFamily: "Metropolis, system-ui, sans-serif",
        colorBackground: '#08090A',
        colorPrimary: '#2EC99E',
        accessibleColorOnColorPrimary: '#084836',
        colorText: 'white',
        colorTextSecondary: '#fff',
        colorTextPlaceholder: '#727F96',
      }
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm orderId={orderId} />
    </Elements>
  );
};

export default StripeComponent;
