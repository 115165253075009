//Chakra
import { Flex, Image, Skeleton, Text } from "@chakra-ui/react";

//Components
import { UTCConverter } from "lib/utils/time/UTCConverter";

const EventInfo = ({ event }: { event: any }) => {
  return (
    <Flex
      alignItems={"center"}
      height={"100px"}
      bgColor={"gray.900"}
      color={"gray.0"}
      padding={"16px"}
      gap={"12px"}
      borderRadius={"8px"}
      boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
      width={'100%'}
    >
      {!event?.banner ? 
      <Skeleton width={"60px"} height={"60px"} borderRadius={"10px"} />
      :
      <Image width={"60px"} height={"60px"} borderRadius={"10px"} flexShrink={0} objectFit={"cover"} alt="event banner" src={event?.banner} />
      }
      <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"4px"}>
        {!event?.title ? 
        <Skeleton width={'100px'} height={'15px'} />
        :
        <Text color={"gray.0"} fontSize={"14px"} fontFamily={"AVSB"} fontWeight={600} alignSelf={"stretch"}>
          {event?.title}
        </Text>
        }
        {!event?.start ? 
        <Skeleton width={'150px'} height={'10px'} />
        :
        <Text color={"gray.300"} fontSize={"12px"} fontFamily={"AVR"} fontWeight={400} alignSelf={"stretch"}>
          {event?.start ? UTCConverter(event?.start) : ""}
        </Text>
        }
      </Flex>
    </Flex>
  );
};

export default EventInfo;
