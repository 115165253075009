import { createContext, useState } from 'react';
import type { SetStateAction } from 'react';

export const CreateEventDataContext = createContext<{
        eventData: any;
        ticketData: any;
        updateEventData: (newData: any) => void;
        updateTicketData: (newData: any) => void;
    }>({
        eventData: [],
        ticketData: [],
        updateEventData: () => {}, 
        updateTicketData: () => {}, 
    });

const InputDataContext = ({ children }: any) => {

    const [eventData, setEventData] = useState<never[]>([]);
    const [ticketData, setTicketData] = useState<never[]>([]);

    const updateEventData = (newData: SetStateAction<never[] | any>) => {
        setEventData(newData);
    };

    const updateTicketData = (newData: SetStateAction<never[] | any>) => {
        setTicketData(newData);
    };

    return (
        <CreateEventDataContext.Provider value={{ eventData, updateEventData, ticketData, updateTicketData }}>
            {children}
        </CreateEventDataContext.Provider>
    );
};

export default InputDataContext;
