import { useMemo, useState, useReducer } from "react";
import { useParams, useNavigate } from "react-router-dom";

//Chakra
import { Box, Button, Flex, Spinner, Text, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";

//Components
import AppModalEmail from "components/common/appModal/AppModalEmail";
import EventInfo from "./parts/EventInfo";
import ChoosePaymentModal from "./parts/ChoosePaymentModal";
import EventForm from "./parts/EventForm";
import BackBtn from "components/common/back-btn/BackBtn";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { usePayment } from "functions/hooks/payment/usePayment";
import { useProfile } from "functions/hooks/user/useProfile";
import useAppToast from "functions/hooks/toast/useToast";

//Data
import { useUserStore } from "lib/stores/user/UserStore";

//Reducer
import { initialState, purchaseReducer } from "./reducer/PurchaseReducer";

//Functions
import { calculateTicketPrice } from "./funcs/PurchaseFuncs";
import { appDevelopment } from "lib/utils/development/Development";

function Purchase() {
  const [ticketsState, ticketsDispatch] = useReducer(
    purchaseReducer,
    initialState
  );

  //Data
  const email = useUserStore((state) => state.user?.email);
  const name = useUserStore((state) => state.user?.name);

  //States
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState<any>({
    event: null,
    loading: false,
    currentUserData: null,
    walletAddress: "",
    polygonRate: 0,
  });

  //Custom Hooks
  const { getEventDetail, getUserAttendEvents } = useEvents();
  const { createOrders, payViaPolygon, payWithMatic, checkoutPaymentTransactions, handleFreeOrder } = usePayment();
  const { getCurrentUserData } = useProfile();
  const { showToast } = useAppToast();

  //react router dom Hooks
  const { eventId } = useParams();
  const navigate = useNavigate();

  const totalTicketCount = Object.values(ticketsState.ticketQuantities).reduce(
    (total: number, quantity: any) => total + quantity,
    0
  );

  const ticketDetail = {
    name,
    email: email ? email : userEmail,
    ticket: ticketsState.selectedTicketType,
    quantity: Object.values(ticketsState.ticketQuantities).reduce(
      (sum: number, qty: any) => sum + qty,
      0
    ),
  };

  const checkUserCredentials = async () => {
    if (!ticketDetail.name) {
      navigate("/user/profile");
      showToast("Please complete your profile first", "info");
    } else if (!ticketDetail.email) {
      setShowModal(false);
      setShowEmailModal(true);
    } else {
      if (ticketsState.price === 0) {
        const res = await createOrders(ticketDetail);
        if (res && res?.finalPrice === 0) {
          console.log(res);
          const freePurchaseRes: any = handleFreeOrder(res?.id)
          if (freePurchaseRes) {
            showToast("Your ticket has been successfully purchased, enjoy!", "success") 
            navigate("/user/events")
          }  
        }
      } else if (paymentMethod === 1) {
        const res = await createOrders(ticketDetail);
        res && navigate(`/user/payment/stripe/${res?.id}`);
      } else if (paymentMethod === 2) {
        setIsLoading(true)
        setShowEmailModal(false)
        const res = await createOrders(ticketDetail);
        if (res) {
          const paymentRes: any = await payViaPolygon(res?.id);
          // console.log("payment response ->", paymentRes);
          try {
            const polygonRes = await payWithMatic(
              paymentRes?.receiverAddress,
              paymentRes?.usdPrice,
              appDevelopment ? "testnet" : "mainnet"
              );
              // console.log("polygon response ->", polygonRes);
              const checkoutPaymentRes = await checkoutPaymentTransactions(res?.id, polygonRes)
              console.log("checkoutPaymentRes", checkoutPaymentRes);
              if (checkoutPaymentRes) {
                navigate(`/user/purchase/thank-you/${res?.id}`)
              } else {
                showToast("Payment failed. Please try again", 'error')
              }
          } catch (error) {
            showToast(`You denied transaction signature.`, "info")
          }
        }
        setIsLoading(false)
      }
    }
  }

  //Increase Ticket quantity
  const increaseTicketQuantity = (
    ticketId: string,
    total: number,
    sold: number,
    price: number,
    polygonPrice?: any
  ) => {
    if (ticketsState.selectedTicketType === null) {
      ticketsDispatch({ type: "SET_TICKET_TYPE", payload: ticketId });

      // Increase ticket quantity and calc price
      ticketsDispatch({ type: "INCREASE_TICKET", payload: ticketId });
      const newTotal = calculateTicketPrice(
        price,
        ticketsState.ticketQuantities[ticketId] + 1
      );
      ticketsDispatch({ type: "CALC_TOTAL_PRICE", payload: newTotal });
      ticketsDispatch({ type: "TICKET_PRICE", payload: price });

      // Update Polygon price and total amount
      if (polygonPrice) {
        const newPolygonPrice = calculateTicketPrice(
          polygonPrice,
          ticketsState.ticketQuantities[ticketId] + 1
        );
        ticketsDispatch({
          type: "CALC_POLYGON_TOTAL_PRICE",
          payload: newPolygonPrice,
        });
        ticketsDispatch({
          type: "TICKET_POLYGON_PRICE",
          payload: polygonPrice,
        });
      }

      ticketsDispatch({ type: "SET_IS_DISABLED", payload: false });
    } else if (ticketsState.selectedTicketType !== ticketId) {
      showToast(`You can't select two different ticket types`, "error");
    } else {
      if (ticketsState.ticketQuantities[ticketId] === total - sold) {
        showToast(`This ticket has no more than ${total - sold} available.`, "error");
      } else {
        ticketsDispatch({ type: "SET_IS_DISABLED", payload: false });

        // Increase ticket quantity and calc price
        ticketsDispatch({ type: "INCREASE_TICKET", payload: ticketId });
        const newTotal = calculateTicketPrice(
          price,
          ticketsState.ticketQuantities[ticketId] + 1
        );
        ticketsDispatch({ type: "CALC_TOTAL_PRICE", payload: newTotal });
        ticketsDispatch({ type: "TICKET_PRICE", payload: price });

        // Update Polygon price and total amount
        if (polygonPrice) {
          const newPolygonPrice = calculateTicketPrice(
            polygonPrice,
            ticketsState.ticketQuantities[ticketId] + 1
          );
          ticketsDispatch({
            type: "CALC_POLYGON_TOTAL_PRICE",
            payload: newPolygonPrice,
          });
          ticketsDispatch({
            type: "TICKET_POLYGON_PRICE",
            payload: polygonPrice,
          });
        }
      }
    }
  };

  //Decrease Ticket quantity
  const decreaseTicketQuantity = (
    ticketId: string,
    price: number,
    polygonPrice?: any
  ) => {
    if (ticketsState.ticketQuantities[ticketId] > 0) {
      if (ticketsState.selectedTicketType !== ticketId) {
        showToast(`You can't select two different ticket types`, "error");
      } else {
        ticketsDispatch({ type: "SET_IS_DISABLED", payload: false });

        // Decrease ticket quantity and calc price
        ticketsDispatch({ type: "DECREASE_TICKET", payload: ticketId });
        const newTotal = calculateTicketPrice(
          price,
          ticketsState.ticketQuantities[ticketId] - 1
        );
        ticketsDispatch({ type: "CALC_TOTAL_PRICE", payload: newTotal });
        ticketsDispatch({ type: "TICKET_PRICE", payload: price });

        // Update Polygon price and total amount
        if (polygonPrice) {
          const newPolygonPrice = calculateTicketPrice(
            polygonPrice,
            ticketsState.ticketQuantities[ticketId] - 1
          );
          ticketsDispatch({
            type: "CALC_POLYGON_TOTAL_PRICE",
            payload: newPolygonPrice,
          });
          ticketsDispatch({
            type: "TICKET_POLYGON_PRICE",
            payload: polygonPrice,
          });
        }

        if (ticketsState.ticketQuantities[ticketId] - 1 === 0) {
          ticketsDispatch({ type: "SET_TICKET_TYPE", payload: null });

          if (ticketsState.selectedTicketType !== ticketId) {
            showToast(`You can't select two different ticket types`, "error");
          } else {
            ticketsDispatch({ type: "SET_IS_DISABLED", payload: true });
          }
        }
      }
    } else if (ticketsState.ticketQuantities[ticketId] === 0) {
      if (ticketsState.selectedTicketType === null) {
        showToast(
          `You haven't selected a ticket yet. Please choose a ticket.`,
          "warning"
        );
      } else if (ticketsState.selectedTicketType !== ticketId) {
        showToast(`You can't select two different ticket types`, "error");
      } else {
        ticketsDispatch({ type: "SET_IS_DISABLED", payload: true });
      }
    }
  };

  const nextBtnHandler = () => {
    if (totalTicketCount === 0) {
      showToast("Please select at least one ticket", "warning");
    } else {
      setShowModal(true);
    }
  };

  const getCommonTicketIds = (userAttendEvents: any, eventTicketIds: any) => {
    let commonTicketIds: any = [];
    userAttendEvents?.forEach((item: any) => {
      const commonIds = item?.tickets?.filter((ticket: any) =>
        eventTicketIds.includes(ticket._id)
      );
      commonTicketIds = [...commonTicketIds, ...commonIds];
    });
    return commonTicketIds;
  };

  useMemo(async () => {
    if (eventId) {
      setData((prevData: any) => ({ ...prevData, loading: true }));
      const currentUser = await getCurrentUserData();
      const userAttendEvents = await getUserAttendEvents();
      const res = await getEventDetail(eventId);
      const eventTicketIds =
        res?.tickets?.map((ticket: any) => ticket._id) || [];
      const commonTicketIds = getCommonTicketIds(
        userAttendEvents,
        eventTicketIds
      );
      if (commonTicketIds.length > 0) {
        showToast("You have already purchased this event", "info");
        navigate(`/user/ticketDetail/${eventId}`);
      } else {
        const ticketsId = res?.tickets?.map((item: any) => item.id) || [];
        ticketsDispatch({ type: "SET_TICKETS_ID", payload: ticketsId });
        const polygonAddress = currentUser?.paymentMethods?.filter(
          (item: any) => item.name === "POLYGON"
        )[0]?.address;
        setData((prevData: any) => ({
          ...prevData,
          event: res,
          currentUserData: currentUser,
          walletAddress: polygonAddress,
          loading: false,
        }));
      }
    }

  }, [eventId]);

  return (
    <Box>
      <BackBtn />
      <Box position={"relative"} zIndex={"1"} mb={"65px"} mt={"10px"}>
        {data?.loading ? (
          <Spinner />
        ) : (
          <Flex position={"relative"} flexDirection={"column"} gap={"12px"}>
            <EventInfo event={data?.event} />
            <Flex
              flexDirection={"column"}
              bg={"gray.900"}
              color={"gray.0"}
              rounded={"8px"}
              padding={"16px"}
              gap={"16px"}
              justifyContent={"center"}
            >
              {data?.event?.tickets?.length > 0 ? (
                data?.event?.tickets?.map((ticket: any) => (
                  <EventForm
                    key={ticket.id}
                    event={ticket}
                    quantity={ticketsState.ticketQuantities[ticket.id]}
                    increaseTicketQuantity={increaseTicketQuantity}
                    decreaseTicketQuantity={decreaseTicketQuantity}
                    ticketRules={data?.event?.rules}
                    polygonRate={data?.polygonRate}
                  />
                ))
              ) : (
                <Text textAlign={"center"}>
                  There are no tickets for this event.
                </Text>
              )}
            </Flex>
            <PrimaryBtn
              btnText={"Next"}
              btnOnClick={nextBtnHandler}
              btnTextColor="primary.900"
              btnIsDisabled={ticketsState.isDisabled || isLoading}
              btnHasBg={true}
              btnIsLodaing={isLoading}
            />
          </Flex>
        )}
      </Box>

      {/* Payment Modal */}
      <ChoosePaymentModal
        showModal={showModal}
        setShowModal={setShowModal}
        total={ticketsState.totalPrice}
        event={data?.event}
        price={ticketsState.price}
        checkUserCredentials={checkUserCredentials}
        quantity={totalTicketCount}
        walletAddress={data?.walletAddress}
        polygonPrice={ticketsState.polygonPrice}
        totalPolygonPrice={ticketsState.totalPolygonPrice}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        isLoading={isLoading}
      />
      {/* <AppModalEmail isOpen={showEmailModal} setIsOpen={setShowEmailModal}>
        <Box
          backgroundColor={"#292929"}
          width={"100%"}
          height={"100%"}
          borderRadius={"25px"}
        >
          <Flex
            marginTop={"20px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Text color={"white"} fontFamily={"AVSB"} fontWeight={"500"}>
              Please provide your email
            </Text>
            <Input
              type="text"
              placeholder="Enter your email address"
              color={"gray.0"}
              bgColor={"gray.1000"}
              padding={"10px"}
              marginTop={"20px"}
              width={"80%"}
              border={"none"}
              borderRadius={"5px"}
              outline={"none"}
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <Flex
              justifyContent={"space-evenly"}
              width={"full"}
              padding={"16px"}
              bottom={"0"}
              display={"flex"}
              flexDirection={"row"}
            >
              <Button
                isDisabled={ticketsState.isDisabled}
                bg={"primary.600"}
                color={"gray.0"}
                width={"30%"}
                fontFamily={"IM"}
                fontSize={"16px"}
                fontWeight={500}
                _hover={{ bg: "primary.600" }}
                _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                _active={{
                  bg: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E",
                  color: "gray.0",
                }}
                isLoading={isLoading}
                onClick={checkUserCredentials}
              >
                Next
              </Button>
              <Button
                isDisabled={ticketsState.isDisabled}
                bg={"gray.500"}
                color={"gray.0"}
                width={"30%"}
                fontFamily={"IM"}
                fontSize={"16px"}
                fontWeight={500}
                onClick={() => setShowEmailModal(!showEmailModal)}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Box>
      </AppModalEmail> */}

      <Modal isOpen={showEmailModal} onClose={() => setShowEmailModal(false)} trapFocus={false}>
        <ModalOverlay />
        <ModalContent bgColor={'gray.900'} width={'90%'} alignSelf={'center'}>
            <ModalHeader>
                <Text color={"gray.0"} fontFamily={"AVSB"} fontWeight={500}>
                    Please provide your email
                </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody display={'flex'} alignItems={'center'} justifyContent={'center'} alignSelf={'center'} width={'100%'}>
                <Input
                    type="text"
                    placeholder="Enter your email address"
                    color={"gray.0"}
                    bgColor={"gray.1000"}
                    padding={"10px"}
                    width={"100%"}
                    border={"none"}
                    borderRadius={"5px"}
                    outline={"none"}
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
            </ModalBody>

            <ModalFooter display={'flex'} justifyContent={'space-evenly'} width={'100%'} gap={'16px'}>
                <Button
                    isDisabled={false}
                    bg={"gray.500"}
                    color={"gray.0"}
                    width={"100%"}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    onClick={() => setShowEmailModal(!showEmailModal)}
                >
                    Cancel
                </Button>
                <Button
                    isDisabled={false}
                    bgColor={"primary.600"}
                    color={"gray.0"}
                    width={"100%"}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    _hover={{ bgColor: "primary.900" }}
                    _active={{
                    bgColor: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E",
                    color: "gray.0",
                    }}
                    isLoading={isLoading}
                    onClick={() => checkUserCredentials()}
                >
                    Next
                </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
export default Purchase;
