import { Link } from 'react-router-dom';

//Chakra
import { Flex , Text , Avatar } from '@chakra-ui/react'

//Data
import { useUserStore } from 'lib/stores/user/UserStore';


const Navbar = () => {

    const user = useUserStore((state) => state.user);

    return (
        <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100vw'}
            padding={'20px 40px'}
            bgColor={'dark.bg'}
            backdropBlur={'12px'}
            borderBottom={'1px solid #24292E'}
        >
            <Link to={'/'}>
                <Text fontSize={'20px'} fontFamily={'AVR'} fontWeight={700} textAlign={'center'} color={'primary.600'}>droplinked</Text>
            </Link>
            <Flex alignItems={'center'} gap={'12px'}>
                <Flex
                    alignItems={'flex-start'}
                    padding={'6px'}
                    borderRadius={'50%'}
                    border={'1px solid #24292E'}
                    bgColor={'dark.interactive'}
                    boxShadow={'0px 1px 3px 0px'}
                    cursor={'pointer'}
                >
                    <Flex
                        width={'20px'}
                        height={'20px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        color={'dark.900'}
                    >
                        <Text fontSize={'16px'} fontWeight={400} fontFamily={'AVR'}>?</Text>
                    </Flex>
                </Flex>
                <Flex
                    alignItems={'center'}
                    gap={'8px'}
                >
                    <Text fontSize={'12px'} fontFamily={'AVR'} fontWeight={600} color={'dark.700'} textAlign={'center'}>{user.name}</Text>
                    {user.image ? 
                        <Avatar bgColor={"primary.600"} src={user.image} width={'32px'} height={'32px'} /> 
                        : 
                        <Avatar bgColor={"primary.600"} width={'32px'} height={'32px'} />
                    }
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Navbar;