import { useEffect, useState } from "react";

//Chakra
import { Box, Button, Flex, Image, Skeleton, Spinner, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { useNavigator } from "functions/hooks/navigator/useNavigator";

//Interfaces
import { IEvents } from "lib/stores/user/UserStore";

const UniqueQRcode = ({event, qrCode, walletAddress}: {event: IEvents, qrCode: any,walletAddress: string}) => {
  const { isLoading } = useEvents();
  const [image, setImage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { shareLink } = useNavigator();

  const putImage = async () => {
    setLoading(true);
    setImage(qrCode);
    setLoading(false);
  };

  useEffect(() => {
    putImage();
  }, []);
  
  return (
    <Flex flexDirection={"column"} padding={"16px"} gap={"12px"}>
      {image === "" ? (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex
              flexDirection={"column"}
              gap={"16px"}
              padding={"8px 23px 24px 24px"}
            >
              {event ? 
                <Image
                  boxSize={"218px"}
                  rounded={"12px"}
                  src={image}
                  alt="qrcode"
                />
                :
                <Skeleton width={'218px'} height={'218px'} />
              }
              <Text fontWeight={"600"} fontSize={"18px"} lineHeight={"28px"}>
                {event?.title}
              </Text>
              <Text></Text>
            </Flex>
          )}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex
              flexDirection={"column"}
              gap={"16px"}
              padding={"8px 23px 24px 24px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {image && event ? 
                <Image
                  boxSize={"218px"}
                  rounded={"12px"}
                  src={image}
                  alt="qrcode"
                />
                :
                <Skeleton width={'218px'} height={'218px'} borderRadius={'12px'} />
              }
              <Text
                color={"gray.0"}
                fontWeight={"600"}
                fontSize={"18px"}
                lineHeight={"28px"}
              >
                {event?.title}
              </Text>
              <Text
                color={"gray.0"}
                fontWeight={"400"}
                fontSize={"14px"}
                lineHeight={"20px"}
              >
                {walletAddress || "Credit/debit card"}
              </Text>
            </Flex>
          )}
        </Box>
      )}
      {/* <Button
        bg={"primary.600"}
        color={"primary.900"}
        fontFamily={"IM"}
        fontSize={"16px"}
        fontWeight={500}
        leftIcon={<AppIcons.WalletGreen />}
        _hover={{ bg: "primary.600" }} 
        _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
        _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
        // onClick={}
      >
        {isLoading ? <Spinner color="white" /> : "Add Wallet"}
      </Button> */}
      <Button
        bg={"green.800"}
        color={"gray.0"}
        fontFamily={"IM"}
        fontSize={"16px"}
        fontWeight={500}
        leftIcon={<AppIcons.Share />}
        _hover={{ bg: "green.800" }} 
        _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
        _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), green.800', color: "gray.0"}}
        onClick={() => shareLink(`${window.location.origin}/user/events/${event?._id}`, event?.title, "Check out this event:")}
      >
        {isLoading ? <Spinner color="white" /> : "Share"}
      </Button>
    </Flex>
  );
};

export default UniqueQRcode;
