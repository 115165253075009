import { useState , useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Chakra
import { Avatar, Box, Button, Flex, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

//Icons
import AppIcons from 'assets/icons/AppIcons';

//User Data
import { useUserStore } from 'lib/stores/user/UserStore';


const Menu = () => {

    const user = useUserStore((state) => state.user);

    const [activeItem , setActiveItem] = useState('home')

    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate = useNavigate();

    const handleMenuItemClick = (itemName : any) => {
        setActiveItem(itemName);

        if (itemName === "logout") {
            localStorage.clear();
            navigate('/signin');
        }
    };

    
    useEffect(() => {
        const urlPath = window.location.pathname.slice(1,);
        if (urlPath === 'user/home') {
            setActiveItem('home')
        }else if (urlPath === 'user/events') {
            setActiveItem('events')
        } else if (urlPath === 'user/settings') {
            setActiveItem('settings')
        }
    } , [])

    return (
        <Box
            display={'flex'}
            alignItems={'flex-start'}
            flexDirection={'column'}
            flexShrink={'0'}
            width={'280px'}
            height={'100%'}
            bgColor={'gray.900'}
            position={'fixed'}
            overflowY={'hidden'}
            zIndex={999}
        > 
            <Flex
                padding={'48px 32px'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                alignSelf={'stretch'}
                gap={'10px'}
            >
                <AppIcons.DroplinkedText/>
            </Flex>
            <Flex>
                <List >
                    <Link to='/user/home'>
                        <ListItem 
                            display={'flex'} 
                            alignItems={'center'} 
                            alignSelf={'stretch'} 
                            gap={'12px'} 
                            height={'48px'} 
                            width={'120%'}
                            padding={'2px 32px'}
                            flexShrink={'0'} 
                            borderLeft={activeItem === 'home' ? '4px solid #2EC99E' : '4px solid transparent'}
                            onClick={() => handleMenuItemClick('home')}
                        >
                            {activeItem === 'home' ? <AppIcons.HomeGreen/> : <AppIcons.Home/>}
                            <Text fontSize={'14px'} fontWeight={600} fontFamily={'AVR'} color={activeItem === 'home' ? 'gray.0' : 'gray.600'}>Home</Text>
                        </ListItem>
                    </Link>
                    <Link to='/user/events'>
                        <ListItem 
                            display={'flex'} 
                            alignItems={'center'} 
                            alignSelf={'stretch'} 
                            gap={'12px'} 
                            height={'48px'} 
                            width={'120%'}
                            padding={'2px 32px'}
                            flexShrink={'0'} 
                            borderLeft={activeItem === 'events' ? '4px solid #2EC99E' : '4px solid transparent'}
                            onClick={() => handleMenuItemClick('events')}
                        >
                            {activeItem === 'events' ? <AppIcons.HeartCalendar2Green/> : <AppIcons.HeartCalendar2/>}
                            <Text fontSize={'14px'} fontWeight={600} fontFamily={'AVR'} color={activeItem === 'events' ? 'gray.0' : 'gray.600'}>My events</Text>
                        </ListItem>
                    </Link>
                    <Flex
                        flexDirection={'column'}
                        gap={'20px'}
                        position={'absolute'}
                        bottom={0}
                        left={0}
                        right={0}
                        padding={'16px 0'}
                    >
                        <List>
                            <Link to={'/user/settings'}>
                                <ListItem 
                                    display={'flex'} 
                                    alignItems={'center'} 
                                    alignSelf={'stretch'} 
                                    gap={'12px'} 
                                    height={'48px'} 
                                    padding={'2px 32px'}
                                    flexShrink={'0'} 
                                    borderLeft={activeItem === 'settings' ? '4px solid #2EC99E' : '4px solid transparent'}
                                    onClick={() => handleMenuItemClick('settings')}
                                >
                                    {activeItem === 'settings' ? <AppIcons.SettingsGreen/> : <AppIcons.Settings/>}
                                    <Text fontSize={'14px'} fontWeight={600} fontFamily={'AVR'} color={activeItem === 'settings' ? 'gray.0' : 'gray.600'}>Settings</Text>
                                </ListItem>
                            </Link>
                            <ListItem 
                                display={'flex'} 
                                alignItems={'center'} 
                                alignSelf={'stretch'} 
                                gap={'12px'} 
                                height={'48px'} 
                                padding={'2px 32px'}
                                flexShrink={'0'} 
                                cursor={'pointer'}
                                borderLeft={activeItem === 'logout' ? '4px solid #2EC99E' : '4px solid transparent'}
                                onClick={() => onOpen()}
                            >
                                {activeItem === 'logout' ? <AppIcons.LogoutGreen/> : <AppIcons.Logout/>}
                                <Text fontSize={'14px'} fontWeight={600} fontFamily={'AVR'} color={activeItem === 'logout' ? 'gray.0' : 'gray.600'}>Log out</Text>
                            </ListItem>
                        </List>

                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent bgColor={'gray.900'} alignSelf={'center'}>
                            <ModalHeader>
                                <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>
                                    Logout
                                </Text>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text color={'gray.600'} fontFamily={'IM'} fontSize={'14px'} fontWeight={400} alignSelf={'stretch'}>
                                    Are you sure you want to log out? Logging out will end your current session and you'll need to log in again to access your account.
                                </Text>
                            </ModalBody>

                            <ModalFooter display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'16px'} width={'100%'}>
                                <Button 
                                    bgColor={'green.800'} 
                                    color={'gray.0'}
                                    width={'100%'}
                                    fontFamily={"IM"}
                                    fontSize={"16px"}
                                    fontWeight={500}
                                    onClick={() => {
                                        onClose()
                                    }}
                                >
                                    Close
                                </Button>
                                <Button
                                    bgColor={"primary.600"}
                                    color={"primary.900"}
                                    width={'100%'}
                                    fontFamily={"IM"}
                                    fontSize={"16px"}
                                    fontWeight={500}
                                    _hover={{ bgColor: "primary.600" }}
                                    _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                                    _active={{ bgColor: "primary.900" }}
                                    onClick={() => {
                                        handleMenuItemClick("logout")
                                        onClose()
                                    }}
                                >
                                    Logout
                                </Button>
                            </ModalFooter>
                            </ModalContent>
                        </Modal>

                        <Flex 
                            alignItems={'center'}
                            alignSelf={'stretch'}
                            padding={'0 24px'}
                            gap={'12px'}
                        >
                            {user.image ? 
                                <Avatar bgColor={"primary.600"} src={user.image} width={'40px'} height={'40px'} /> 
                                : 
                                <Avatar bgColor={"primary.600"} width={'40px'} height={'40px'} />
                            }
                            <Flex flexDirection={'column'} alignItems={'flex-start'} >
                                <Link to={user.name ? '/user/profile' : '/user/profile/editprofile'}>
                                    <Text fontSize={'14px'} fontFamily={'AVR'} fontWeight={600} color={'gray.0'}>{user.name ? user.name : "Edit profile"}</Text>
                                </Link>
                                <Text fontSize={'14px'} fontFamily={'AVR'} fontWeight={400} color={'gray.600'}>{user.email}</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </List>
            </Flex>
        </Box>
    );
};

export default Menu;