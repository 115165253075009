import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Icons
import AppIcons from "assets/icons/AppIcons";
//Components
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import { UTCConverter } from "lib/utils/time/UTCConverter";
//Chakra
import { Flex, Text } from "@chakra-ui/layout";
import { Image as ChakraImage, Skeleton } from "@chakra-ui/react";
//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
//Interfaces
import { IEvents } from "lib/stores/user/UserStore";

const ComingUpEvents = ({event}: any) => {
  const img = new Image();
  const { findNearestEvent } = useEvents()
  const [loading, setLoading] = useState(true)
  const [nearestEvent , setNearestEvent] = useState<IEvents>()
  
  useEffect(() => {
    setLoading(true)
    setNearestEvent(findNearestEvent(event))
    img.src = `${nearestEvent?.banner}`;
    img.onload = () => setLoading(false);
    setLoading(false)
  },[]);


  return (
    <Flex flexDirection={"column"} bg={"gray.900"} color={"gray.0"} rounded={"16px"} padding={"16px"} gap={"16px"}>
      <Text color={"gray.0"} fontWeight={"600"} fontSize={"18px"} fontFamily={"AVSB"}>
        Upcoming events
      </Text>
      
      {!loading ? 
      <Link to={`/user/events/${nearestEvent?._id}`}>
        <ChakraImage width={'100%'} bgColor={'black'} rounded={"8px"} alt="event banner" src={nearestEvent?.banner} />
      </Link>
      :
      <Skeleton borderRadius={'8px'} height={'150px'} width={'100%'}/>
      }
      
      {!loading ? 
        <Link to={`/user/events/${nearestEvent?._id}`}>
          <Text color={"gray.0"} fontWeight={"600"} fontSize={"30px"} fontFamily={"AVSB"}>
            {nearestEvent?.title}
          </Text>
        </Link>
        :
        <Skeleton isLoaded={!loading} height={'20px'}/>
        }
      <IconInfoBox
        boxIcon={<AppIcons.GreenCalendar />}
        label="Date"
        primaryTitle={nearestEvent?.start && `${UTCConverter(nearestEvent?.start)}`}
        secondaryTitle={nearestEvent?.end && `${UTCConverter(nearestEvent?.end)}` }
      />
      <IconInfoBox
        boxIcon={<AppIcons.GreenLocation />}
        label="Location"
        primaryTitle={nearestEvent?.location}
        // secondaryTitle="Piers Pier 62, New York, NY 10011, USA"
      />
    </Flex>
  );
};

export default ComingUpEvents;
