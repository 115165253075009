import { IEvents } from "lib/stores/user/UserStore";
import { NavigateFunction } from "react-router-dom";

export const getEvent = async ({
  eventId,
  formikRef,
  setEventsData,
  getUserEvents,
}: {
  eventId: any;
  formikRef: React.MutableRefObject<any>;
  setEventsData: React.Dispatch<React.SetStateAction<IEvents[]>>;
  getUserEvents: () => Promise<IEvents[] | undefined>;
}) => {
  if (eventId) {
    const events = await getUserEvents();
    if (events) {
      const filteredEventData = events.filter((event) => event._id === eventId);
      setEventsData(filteredEventData);
      if (filteredEventData.length > 0) {
        const initialTickets = filteredEventData[0].tickets.map((t) => ({
          title: t.title,
          description: t.description,
          price: t.price,
          total: t.total,
          id: t.id,
        }));
        if (formikRef.current) {
          formikRef.current.setValues({ tickets: initialTickets });
        }
      }
    }
  }
};

// Navigate back to the Intro Page route
export const handleBack = ({ navigate, eventId }: { eventId: string | undefined; navigate: NavigateFunction }) => {
  navigate(`/user/create/info/${eventId}`);
};
