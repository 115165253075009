import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom'

//Chakra
import { Box , Flex , Text } from '@chakra-ui/react'

//Components
import Navbar from './navbar/Navbar';
import Stepper from './stepper/Stepper';
import EventPreview from './event-preview/EventPreview';
import BackBtn from 'components/common/back-btn/BackBtn';

//Context
import CreateEventDataContextProvider from "contexts/CreateEventDataContextProvider";


const CreateEventLayout = () => {
    const location = useLocation()

    const [urlPath, setUrlPath] = useState<string[]>([])

    useEffect(() => {
        if (location.pathname.split("/") === urlPath) {
            return console.log("empty");
        } else {
            setUrlPath(location.pathname.split("/"));
        }

    }, [location.pathname]);

    const stepHandler = () => {
        let stepNumber: number = 0;

        switch (urlPath[3]) {
            case 'info':
                return stepNumber = 1;
            case 'tickets':
                return stepNumber = 2;
            case 'rulesets':
                return stepNumber = 3;
            case 'acceptPayment':
                return stepNumber = 4;
            case 'deployEvent':
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                return stepNumber = 5;
            default:
                // Set a default value or handle unknown paths
                break;
        }
    };
    
    

    return (
        <Box
            display={'inline-flex'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={'46px'}
            paddingBottom={'0px'}
            bgColor={'gray.1000'}
        >
            <Navbar/>
            <Flex
                flexDirection={'column'}
                alignItems={'flex-start'}
                gap={'40px'}
                width={'100vw'}
            >
                {/* Header */}
                <Flex
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    gap={'12px'}
                    padding={'0 80px'}
                >
                    <BackBtn optionalUrl={`/user/events`}/>
                    <Text fontSize={'30px'} color={'gray.0'} fontWeight={600} fontFamily={'AVR'}>Create event</Text>
                </Flex>

                {/* Main Content */}
                <CreateEventDataContextProvider>
                    <Flex alignItems={'flex-start'} gap={'32px'} width={'100vw'} padding={'0 80px'} position={'relative'}>
                        <Flex flex={1} position={'sticky'} top={'20px'} left={0}>
                            <Stepper step={stepHandler()}/>
                        </Flex>
                        <Flex flex={4} paddingBottom={'20px'}>
                            <Outlet/>
                        </Flex>
                        <Flex flex={2} position={'sticky'} top={'20px'} right={0} paddingBottom={'20px'} >
                            <EventPreview/>
                        </Flex>
                    </Flex>
                </CreateEventDataContextProvider>

            </Flex>
        </Box>
    );
};

export default CreateEventLayout;