import { useLocation } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
//Chakra
import { Box, Button, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react'
//Custom Hooks
import { useProfile } from 'functions/hooks/user/useProfile';

const ResetPassword = () => {
    const { isLoading, resetPassword } = useProfile()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    const validateEmail = (value: string) => {
        let error: string | undefined;
        if (!value) {
            error = 'Email is required';
        } else if (!/^\S+@\S+\.\S+$/.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }

    const validatePassword = (value: string) => {
        let error: string | undefined;
    
        if (!value) {
            error = 'Password is required';
        }
    
        return error;
    }
    

    const validatePasswordConfirmation = (value: string, values: any) => {
        let error: string | undefined;
        if (!value) {
            error = 'Password confirmation is required';
        } else if (value !== values.password) {
            error = 'Passwords do not match';
        }
        return error;
    }
    
    return (
        <Box bgColor={'gray.900'} padding={'16px'} borderRadius={'8px'}>
            <Formik
                initialValues={{ email: '', password: '', passwordConfirmation: '', token }}
                onSubmit={(values, actions) => {
                    resetPassword(values)
                    setTimeout(() => {
                        actions.setSubmitting(false);
                        // actions.resetForm()
                    }, 1000);
                }}
            >
                {(props) => (
                    <Form>
                        <Field name='email' validate={validateEmail}>
                            {({ field, form }: any) => (
                                <FormControl mb={'16px'} isInvalid={form.errors.email && form.touched.email}>
                                    <FormLabel color={"gray.400"} fontFamily={"IM"} fontWeight={"500"}>Email</FormLabel>
                                    <Input border={'none'} bgColor={'gray.1000'} color={'gray.0'} fontFamily={"IM"} padding={'12px'} {...field} placeholder='Enter your email...' />
                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name='password' validate={validatePassword}>
                            {({ field, form }: any) => (
                                <FormControl mb={'16px'} isInvalid={form.errors.password && form.touched.password}>
                                    <FormLabel color={"gray.400"} fontFamily={"IM"} fontWeight={"500"}>Password</FormLabel>
                                    <Input border={'none'} bgColor={'gray.1000'} color={'gray.0'} fontFamily={"IM"} padding={'12px'} type="password" {...field} placeholder='New Password' />
                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name='passwordConfirmation' validate={(value: string) => validatePasswordConfirmation(value, props.values)}>
                            {({ field, form }: any) => (
                                <FormControl isInvalid={form.errors.passwordConfirmation && form.touched.passwordConfirmation}>
                                    <FormLabel color={"gray.400"} fontFamily={"IM"} fontWeight={"500"}>Confirm Password</FormLabel>
                                    <Input border={'none'} bgColor={'gray.1000'} color={'gray.0'} fontFamily={"IM"} padding={'12px'} type="password" {...field} placeholder='Confirm New Password' />
                                    <FormErrorMessage>{form.errors.passwordConfirmation}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Button
                            type='submit'
                            width={'100%'}
                            mt={5}
                            bgColor={'primary.600'}
                            color={"primary.900"}
                            fontFamily={"IM"}
                            fontSize={"16px"}
                            fontWeight={500}
                            _hover={{bgColor: 'primary.600'}}
                            _disabled={{ bgColor: "#353535", color: "gray.900" }}
                            _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
                            isLoading={isLoading}
                            isDisabled={!props.isValid || !props.dirty}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    )
};

export default ResetPassword;
