import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

function EventCreation() {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
      {/* <Box>{getSectionComponent()}</Box> */}
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default EventCreation;