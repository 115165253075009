import { Flex, Skeleton } from '@chakra-ui/react';
import AppIcons from 'assets/icons/AppIcons';

const EventSkeleton = () => {
    return (
        <Flex padding={"16px"} gap={"12px"} border="1px" borderColor={"gray.700"} rounded={"16px"}>
            <Skeleton width={'80px'} height={'60px'} borderRadius={'10px'} />
            <Flex gap={"4px"} justifyContent={"flex-start"} flexDirection={"column"} width={"full"}>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                    <Skeleton width={'100px'} height={'15px'} />
                </Flex>
                <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"4px"}>
                    <Skeleton width={'170px'} height={'10px'} />
                    <Flex alignItems={"center"} justifyContent={"flex-start"} gap={"4px"}>
                        <AppIcons.Guest />
                        <Skeleton width={'50px'} height={'12px'} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EventSkeleton;