import { Button, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';
import AppIcons from 'assets/icons/AppIcons';
import { useEvents } from 'functions/hooks/events/useEvents';
import { usePayment } from 'functions/hooks/payment/usePayment';
import EventInfo from 'pages/purchase/parts/EventInfo';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ThanksPage = () => {
    const { eventDetailViaTicketId } = useEvents();
    const { findOrder } = usePayment();
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [eventDetail, setEventDetail] = useState<any>([])
    const [orderData, setOrderData] = useState<any>([])


    useEffect(() => {
        if (orderId) {
            const fetchData = async () => {
                const orderRes = await findOrder(orderId)
                const eventRes = await eventDetailViaTicketId(orderRes?.order?.ticket)
                setOrderData(orderRes?.order)
                setEventDetail(eventRes?.event)
            }

            fetchData()
        }
    }, [orderId])

    return (
        <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'16px'} width={'100%'}>
            <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'}>
                <EventInfo event={eventDetail} />
                <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'40px'} padding={'80px 120px'} bgColor={'gray.900'} borderRadius={'16px'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'}>
                        {orderData?.status === "PENDING" ? <Spinner color='primary.600' /> : <AppIcons.CheckCircle2/>}
                        <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'8px'}>
                            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'30px'} fontWeight={600} alignSelf={'stretch'} textAlign={'center'}>{orderData?.status === "PENDING" ? "Pending..." : "Thank you for your purchase"}</Text>
                            <Text color={'gray.600'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400} alignSelf={'stretch'} textAlign={'center'}>{orderData?.status === "PENDING" ? "Refresh the page after a few moments" : "Here is your unclaimed ticket"}</Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} alignSelf={'stretch'} gap={'12px'} padding={'16px'} borderRadius={'12px'} border={'1px solid #434343'} bgColor={'gray.900'}>
                        <Flex alignItems={'flex-start'} gap={'4px'} alignSelf={'stretch'}>
                        {eventDetail?.title && orderData?.quantity ? 
                            <>
                                <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'12px'} fontWeight={400} flex={'1 0 0'}>{eventDetail?.title}</Text>
                                <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'12px'} fontWeight={400}>× {orderData?.quantity}</Text>
                            </>
                            :
                            <Skeleton width={'100%'} height={'15px'} />
                        }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex flexDirection={'column'} alignItems={'flex-end'} justifyContent={'center'} gap={'12px'} alignSelf={'stretch'}>
                <Flex alignItems={'center'} gap={'12px'} width={'100%'}>
                    <Button color={'primary.600'} border={'1px solid'} borderColor={'primary.600'} bgColor={'green.800'} padding={'12px 16px'} borderRadius={'8px'} width={'100%'} _hover={{ bgColor: "primary.600", color: "green.800" }} _active={{ bgColor: "primary.600", color: "green.800" }} onClick={() => navigate(`/user/events/${eventDetail?._id}`)}>Check event</Button>
                    <Button color={'primary.900'} bgColor={'primary.600'} padding={'12px 16px'} borderRadius={'8px'} width={'100%'} _hover={{ bgColor: "primary.600", color: "primary.900" }} _active={{ bgColor: "primary.900", color: "green.800" }} onClick={() => navigate(`/user/ticketDetail/${eventDetail?._id}`)}>Check ticket</Button>
                </Flex>
                <Button color={'gray.0'} bgColor={'green.800'} padding={'12px 16px'} borderRadius={'8px'} width={'100%'} _hover={{ bgColor: "gray.800", color: "gray.0" }} _active={{ bgColor: "gray.800", color: "gray.0" }} onClick={() => navigate(`/user/home`)}>Go home</Button>
            </Flex>
        </Flex>
    );
};

export default ThanksPage;