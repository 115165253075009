import axiosInstance from "../axiosConfig";
import { ICreateOrder, IStripeIntent } from "./Interfaces";

export const postCreateOrder = (props: ICreateOrder) => {
  return { url: "orders", props };
};

export const postCreateStripeIntent = (props: IStripeIntent) => {
  return { url: "payments/stripe", props };
};

export const postPayViaPolygon = (props: any) => {
  return { url: `/checkout/payments/POLYGON/MATIC`, props}
}

export const postCheckoutPayment = (props: any) => {
  return { url: `/checkout/payments/transactions`, props}
}

export const postHandleFreeOrder = (props: any) => {
  return { url: `/payments/handel-free-order`, props}
}