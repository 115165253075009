import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import InputImage from "./ImageUpload";
import { useEvents } from "functions/hooks/events/useEvents";
import { introPageSchema } from "schemas/schema";
import { Form, Formik } from "formik";
import EventsStepper from "components/common/stepper/EventsStepper";
import BackBtn from "components/common/back-btn/BackBtn";
import { IEvents } from "lib/stores/user/UserStore";
import { IInfoPageValues, getEvent, initials } from "./parts/functions/IntroPageFunc";
import EventType from "./parts/eventType/EventType";
import Name from "./parts/name/Name";
import Description from "./parts/description/Description";
import Location from "./parts/location/Location";
import StartDateTime from "./parts/dateAndTime/StartDateTime";
import EndDateTime from "./parts/dateAndTime/EndDateTime";


const IntroPage = () => {
    const location = useLocation();
    // const isFromOnboarding = location?.state?.isFromOnboarding;
    const isFromMyEvents = location?.state?.isFromMyEvents;
    // console.log(location);

    // Handle date and time value    
    // const updateDateTime = () => {
    //     const newDateTime = new Date();

    //     const newTime = newDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    //     setInitialValues(prevValues => ({ ...prevValues, startTime: newTime, endTime: newTime }));
    // };

    // Effect to start updating date and time every one minute
    // useEffect(() => {
    //     const intervalId = setInterval(updateDateTime, 60000);
    //     return () => clearInterval(intervalId);
    // }, []);

    const { eventId } = useParams();
    const [image, setImage] = useState<string | null>(null);
    const { SubmitBasicInfo, isLoading, getUserEvents } = useEvents();
    const [initialValues, setInitialValues] = useState<IInfoPageValues>(initials());
    const getInitials = useCallback(async () => await getEvent({ eventId, getUserEvents, setInitialValues }), [eventId]);
    useEffect(() => { eventId && getInitials() }, [eventId]);
    if (eventId && !initialValues.eventId) return <Spinner size="lg" color="primary.600" />;


    return (
        <Box>
            <BackBtn optionalUrl={isFromMyEvents ? "/user/events" : "/user/home"} />
            <EventsStepper step={0} />
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={introPageSchema}
                onSubmit={async (values: any) => {
                    if (values) {
                        let result = await SubmitBasicInfo(values);
                    }
                }}
            >
                {({ values, setFieldValue, setValues, errors, handleChange, isValid, dirty, handleBlur }) => (
                    <Form
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        <Flex flexDirection={"column"} width={"100%"} rounded={"16px"} gap={"16px"} padding={"16px"} bg={"gray.900"} marginTop={"10px"}>
                            <Text fontFamily={"AVB"} fontWeight={600} fontSize={"18px"} color={"gray.0"}>
                                Basic info
                            </Text>
                            {/* <EventType /> */}
                            <Name errors={errors} values={values} handleChange={handleChange} handleBlur={handleBlur} />
                            <Description errors={errors} values={values} handleChange={handleChange} handleBlur={handleBlur} />
                            <InputImage image={image || values?.banner } setImage={setImage} setFieldValue={setFieldValue} />
                        </Flex>
                        <Flex width={"100%"} flexDirection={"column"} rounded={"16px"} gap={"16px"} padding={"16px"} bg={"gray.900"}>
                            <Text fontFamily={"IM"} fontSize={"18px"} fontWeight={600} color={"gray.0"}>
                                Location & Time
                            </Text>
                            <Flex flexDirection={"column"} gap={"16px"}>
                                <Location errors={errors} values={values} handleChange={handleChange} handleBlur={handleBlur} />
                                <StartDateTime errors={errors} values={values} handleChange={handleChange} handleBlur={handleBlur} setValues={setValues} />
                                <EndDateTime errors={errors} values={values} handleChange={handleChange} handleBlur={handleBlur} />
                            </Flex>
                        </Flex>
                        <Button
                            width={"100%"}
                            type="submit"
                            bg={"primary.600"}
                            color={"primary.900"}
                            fontFamily={"IM"}
                            fontSize={"16px"}
                            fontWeight={500}
                            _hover={{ bgColor: "primary.600" }}
                            _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                            _active={{ bgColor: "primary.900" }}
                            isDisabled={isLoading || !isValid}
                        >
                            {isLoading ? <Spinner color="white" /> : "Next"}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default IntroPage;
