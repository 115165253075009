import React, { useEffect, useState } from "react";
//Chakra
import { Skeleton, Spinner } from "@chakra-ui/react";
import { Button, IconButton } from "@chakra-ui/button";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Image as ChakraImage } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

const UnclaimedRewardItem = ({nftData, claimHandler, eventData, isLoading}: any) => {


  const img = new Image();
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    img.src = nftData?.nft_image;
    img.onload = () => setLoading(false);
  },[]);


  return (
    <Box position={"relative"}>
      <Skeleton borderRadius={'16px'} height={'100%'} isLoaded={!loading}>
        <IconButton
          _hover={{ bg: "transparent" }}
          bg={"transparent"}
          position={"absolute"}
          right={0}
          icon={<AppIcons.WhiteInfo />}
          aria-label="circle info"
          //   onClick={}
        />
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          bg={"gray.900"}
          color={"gray.0"}
          rounded={"16px"}
          padding={"12px"}
          position={"absolute"}
          margin={"16px"}
          left={0}
          right={0}
          bottom={0}
        >
          <Flex flexDirection={"column"} gap={"4px"}>
            {!eventData?.event?.title ?
            <Skeleton width={'50px'} height={'10px'} />
            :
            <Text fontSize={"14px"} fontWeight={"600"}>
              {`${eventData?.event?.title} #${nftData?.token_id}`}
            </Text>
            }
            <Text fontSize={"12px"} fontWeight={"400"}>
              {eventData?.event?.sold} Owners
            </Text>
          </Flex>
          <Button
            bg={"primary.600"}
            color={"primary.900"}
            fontFamily={"IM"}
            fontSize={"14px"}
            fontWeight={"500"}
            padding={"8px 12px"}
            _hover={{bgColor: 'primary.600'}}
            _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
            isLoading={isLoading}
            isDisabled={nftData?.status === "PENDING" ? true : false}
            onClick={claimHandler}
          >
            {nftData?.status === "PENDING" ? (
              <Flex gap={'4px'} alignItems={'center'}>
                <Text>Pending</Text>
                <Spinner size={'xs'} />
              </Flex>
              ) : (
                "Claim"
              )
            }
          </Button>
        </Flex>
        {!nftData?.nft_image ?
        <Skeleton width={'100%'} height={'200px'} rounded={"8px"} />
        :
        <ChakraImage
          width={'100%'}
          height={'200px'}
          rounded={"8px"}
          alt="event banner"
          src={nftData?.nft_image}
          objectFit={'cover'}
          />
        }
      </Skeleton>
    </Box>
  );
};

export default UnclaimedRewardItem;
