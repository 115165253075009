export const UTCConverter = (utcTimeString: string): string => {
  const date = new Date(utcTimeString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return `${formattedDate} (Local time)`;
};
