import { useState, useEffect } from "react";

//Chakra
import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightAddon, InputRightElement, Spinner, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import BackBtn from "components/common/back-btn/BackBtn";
import CustomSwitch from "components/common/custom-switch/CustomSwitch";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";

//Custom Hooks
import { useProfile } from "functions/hooks/user/useProfile";

//Data
import { PaymentsEnum } from "lib/stores/user/UserStore";

//Inputs Data
const inputsData = [
  // {
  //   id: 1,
  //   name: "STX",
  //   icon: <AppIcons.STXToken />,
  // },
  {
      id : 2,
      name : 'POLYGON',
      icon : <AppIcons.MATICToken/>,
  },
  // {
  //     id : 3,
  //     name : 'BTC',
  //     icon : <AppIcons.BTCToken/>,
  // },
  // {
  //     id : 4,
  //     name : 'ETH',
  //     icon : <AppIcons.ETHToken/>,
  // },
  // {
  //     id : 5,
  //     name : 'SOL',
  //     icon : <AppIcons.SOLToken/>,
  // },
];

const PaymentSetup = () => {

  const { updatePaymentMethods, getCurrentUserData, isLoading } = useProfile();

  const [isVerify , setIsVerify] = useState(false)
  
  const [stripeSwitch , setStripeSwitch] = useState(true)
  const [currentUserData, setCurrentUserData] = useState<any>([])


  
  //Inputs value
  const [inputData, setInputData] = useState<Record<string, string>>({
    STX: "",
    POLYGON: "",
    BTC: "",
    ETH: "",
    SOL: "",
  });


  //Switchs state
  const [switchStates, setSwitchStates] = useState<Record<string, boolean>>({
    STX: false,
    POLYGON: false,
    BTC: true,
    ETH: true,
    SOL: true,
  });

  //inputs validation state
  const [inputValidation, setInputValidation] = useState<Record<string, boolean>>({
    STX: true,
    POLYGON: true,
    BTC: true,
    ETH: true,
    SOL: true,
  });
  
  //inputs error message
  const [inputErrors, setInputErrors] = useState<Record<string, string>>({
    STX: "Invalid Stacks address",
    POLYGON: "Invalid Polygon address",
    BTC: "",
    ETH: "",
    SOL: "",
  });

  const saveHandler = () => {
    updatePaymentMethods({
      paymentMethods: [
        {
          name: PaymentsEnum.STRIPE,
          isActive: true,
        },
        {
          name: PaymentsEnum.POLYGON,
          address: inputData.POLYGON,
          isActive: switchStates.POLYGON,
        },
      ],
    });
  };
  
  const stripeSwitchHandler = () => {
    setStripeSwitch(!stripeSwitch)
  }

  //Handle Switchs value
  const handleSwitchClick = (name: string) => {
    setSwitchStates((prevSwitchStates) => ({
      ...prevSwitchStates,
      [name]: !prevSwitchStates[name],
    }));
  };
  
  
  //Handle inputs value
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    //Validate input value
    const isValidInput = value !== '';
    if (isValidInput !== undefined) {
      setInputValidation((prevValidation) => ({
        ...prevValidation,
        [name]: isValidInput as boolean,
      }));
    }

    //if not valid -> show error message
    if (!isValidInput) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Invalid ${name} address`,
      }));
      
    } else {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  
    //update input value
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCurrentUserData();
      setCurrentUserData(data);
      dataHandler(data);
    }
  
    fetchData();
  }, []);
  
  const dataHandler = (data: any) => {
    if (data) {
      data?.paymentMethods?.map((item: any) => {
        if (item?.name === "STRIPE") {
          // eslint-disable-next-line array-callback-return
          return;
        } else {
          setSwitchStates((prevSwitchStates) => ({
            ...prevSwitchStates,
            [item?.name]: item?.isActive,
          }));
          setInputData((prevData) => ({
            ...prevData,
            [item?.name]: item?.address || "",
          }));
        }

        if (item?.name === "POLYGON" && item?.address !== "") {
          setIsVerify(true)
        }
      });
    }
  }
  
  
  // console.log(currentUserData?.paymentMethods);
  // console.log(switchStates);
  // console.log(inputData);
  // console.log(inputData["POLYGON"]);
  // console.log(isVerify);

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gap={"12px"} width={"100%"} minWidth={"300px"} position={"relative"}>
      <BackBtn />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"24px"}
        padding={"16px"}
        width={"100%"}
        minWidth={"300px"}
        borderRadius={"12px"}
        bgColor={"gray.900"}
        marginBottom={"50px"}
      >
        <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"8px"} alignSelf={"stretch"}>
          <Text fontSize={"18px"} color={"gray.0"} fontWeight={600} lineHeight={"28px"} fontFamily={"AVSB"}>
            Payment setup
          </Text>
        </Flex>
        <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"16px"} width={"100%"}>
          <Flex alignItems={"center"} width={"100%"} minWidth={"300px"} gap={"8px"}>
            <CustomSwitch isChecked={stripeSwitch} onClickHandler={stripeSwitchHandler} />
            <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVSB"}>
              Stripe
            </Text>
          </Flex>

          {isLoading ? <Spinner color={"primary.600"}/> : 
          inputsData.map((item) => (
            <Flex key={item.id} alignItems={"center"} width={"100%"} minWidth={"300px"} gap={"8px"}>
              <Flex marginBottom={inputValidation[item.name] ? "" : "24px"}>
                <CustomSwitch isChecked={switchStates[item.name]} onClickHandler={() => handleSwitchClick(item.name)}/>
              </Flex>
              <Flex flexDirection={"column"} alignItems={"center"} width={"100%"} gap={"4px"}>
                <Flex alignItems={"center"} alignSelf={"stretch"} gap={"10px"} borderRadius={"8px"} bgColor={"gray.1000"} width={"100%"}>
                  <InputGroup border={`1px solid ${inputValidation[item.name] ? "none" : "#940404"}`} borderRadius={"inherit"}>
                    <InputLeftElement>
                      <Flex justifyContent={"center"} alignItems={"center"} width={"20xp"} height={"20px"} borderRadius={"50%"}>
                        {item.icon}
                      </Flex>
                    </InputLeftElement>
                    <Input placeholder={`Enter ${item.name} wallet address`} border={"none"} name={item.name} value={inputData[item.name]} onChange={handleChange} color={"gray.0"}  />
                    {isVerify ? (
                      <InputRightElement>
                        <AppIcons.CheckCircle2 />
                      </InputRightElement>
                    ) : (
                      // <InputRightAddon
                      //   bgColor={"green.800"}
                      //   color={"gray.0"}
                      //   display={"flex"}
                      //   alignItems={"center"}
                      //   justifyContent={"center"}
                      //   padding={"8px 12px"}
                      //   gap={"6px"}
                      //   borderRadius={"0px 8px 8px 0px"}
                      //   border={"none"}
                      //   cursor={"pointer"}
                      // >
                      //   <Text fontSize={"14px"} fontWeight={500} lineHeight={"20px"} fontFamily={"IM"}>
                      //     Verify
                      //   </Text>
                      // </InputRightAddon>
                      <></>
                    )}
                  </InputGroup>
                </Flex>
                {!inputValidation[item.name] && (
                  <Text display={"flex"} alignItems={"center"} alignSelf={"flex-start"} gap={"4px"} color={"error.600"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} fontFamily={"IM"}>
                    <AppIcons.ErrorInfoCircle />
                    {inputErrors[item.name]}
                  </Text>
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Box>
      <PrimaryBtn
        btnText={'save'}
        btnOnClick={saveHandler}
        btnTextColor={"gray.50"}
        btnHasBg={true}
      />
    </Box>
  );
};

export default PaymentSetup;
