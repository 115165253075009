import { Text, Textarea } from "@chakra-ui/react";
import { Label } from "components/common/form/form-style";
import { FormikErrors } from "formik";

const Description = ({
  errors,
  values,
  handleChange,
  handleBlur,
}: {
  errors: FormikErrors<any>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: any;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}) => {
  return (
    <Label>
      <Text color={"gray.400"} fontFamily={"IM"} fontWeight={"600"}>
        Description
      </Text>
      <Textarea
        border={errors?.title && "1px"}
        borderColor={errors?.title && "error.600"}
        variant="unstyled"
        height={"120px"}
        color={"gray.0"}
        bg={"gray.1000"}
        fontFamily={"IM"}
        id="description"
        rounded={"12px"}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Description"
        padding={"12px"}
        _placeholder={{ color: "gray.800" }}
        value={values?.description}
      />
      <Text color={values?.description?.length < 1000 ? "gray.600" : "error.600"} textAlign={"right"} fontFamily={"IM"}>
        {values?.description?.length}/1000
      </Text>
    </Label>
  );
};

export default Description;
