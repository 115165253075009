import { Link, useNavigate } from "react-router-dom";

//Chakra
import {
  Box,
  Flex,
  IconButton,
  Image,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Interfaces
import { IEvents } from "lib/stores/user/UserStore";

//Components
import { UTCConverter } from "lib/utils/time/UTCConverter";
import { useEvents } from "functions/hooks/events/useEvents";
import React, { useState } from "react";
import ConfirmationModal from "components/common/appModal/ConfirmationModal";
import useAppToast from "functions/hooks/toast/useToast";

const EventItem = ({
  events,
  isAttend,
  removeDeletedEvent,
}: {
  events: IEvents[];
  isAttend?: boolean;
  removeDeletedEvent: (eventId: string) => void;
}) => {
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvents>();
  const { showToast } = useAppToast();

  const handleEditClick = (eventId: string, status: string) => {
    let editRoute = "";
    switch (status) {
      case "INTRODUCTION":
        editRoute = `/user/create/info/${eventId}`;
        break;
      case "TICKETS":
        editRoute = `/user/create/tickets/${eventId}`;
        break;
      case "RULES":
        editRoute = `/user/create/rulesets/${eventId}`;
        break;
      case "PAYMENT_METHOD":
        editRoute = `/user/create/acceptPayment/${eventId}`;
        break;
      case "PUBLISHED":
        showToast("You cannot edit the published event", "warning");
        break;
      case "CANCELED":
        editRoute = `/user/create/info/${eventId}`;
        break;
      case "Draft":
        editRoute = `/user/create/info/${eventId}`;
        break;
        
      default:
        editRoute = `/user/events/${eventId}`;
        break;
    }
    navigate(editRoute);
  };



  return (
    <Flex flexDirection={"column"} padding={"16px"} gap={"12px"} rounded={"8px"} bg={"gray.900"} marginBottom={"20px"}>
      {events.map((stuff) => (
        <React.Fragment key={stuff._id}>
          <Flex padding={"16px"} gap={"12px"} border="1px" borderColor={"gray.700"} rounded={"16px"}>
            <Image
              cursor={"pointer"}
              width={"60px"}
              height={"60px"}
              rounded={"10px"}
              objectFit={"cover"}
              src={stuff.banner}
              onClick={() => navigate(`/user/events/${stuff._id}`)}
            />
            <Flex gap={"4px"} justifyContent={"flex-start"} flexDirection={"column"} width={"full"}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Link to={`/user/events/${stuff._id}`} key={stuff._id} style={{ textDecoration: "none" }}>
                  <Text
                    fontFamily={"AVSB"}
                    fontSize={"14px"}
                    color={"gray.0"}
                    fontWeight={600}
                    overflowWrap={"break-word"}
                    wordBreak={"break-word"}
                  >
                    {stuff.title}
                  </Text>
                </Link>
                {!isAttend && (
                  <Popover placement="bottom-start">
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <IconButton
                            _hover={{ bg: "transparent" }}
                            bg={"transparent"}
                            aria-label="options"
                            icon={<AppIcons.DotsVertical />}
                          />
                        </PopoverTrigger>
                        <PopoverContent width={"auto"} bgColor={"transparent"} border={"none"}>
                          <List
                            display={"flex"}
                            flexDirection={"column"}
                            right={"-10px"}
                            zIndex={"990"}
                            padding={"8px"}
                            bg={"green.800"}
                            rounded={"8px"}
                            boxShadow={
                              "box-shadow: 0px 4px 6px -2px rgba(27, 27, 27, 0.03), 0px 12px 16px -4px rgba(27, 27, 27, 0.08)"
                            }
                          >
                            <ListItem
                              display={"flex"}
                              alignItems={"center"}
                              padding={"12px"}
                              gap={"8px"}
                              cursor={"pointer"}
                              onClick={
                                () => handleEditClick(stuff._id, stuff.status)
                              }
                            >
                              <AppIcons.Edit />
                              <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                                Edit
                              </Text>
                            </ListItem>
                            <ListItem
                              display={"flex"}
                              alignItems={"center"}
                              padding={"12px"}
                              gap={"8px"}
                              cursor={"pointer"}
                              // onClick={() => deletingEvent(stuff._id)}
                              onClick={() => {
                                setSelectedEvent(stuff); // Set the selected event
                                setShowConfirmModal(true);
                              }}
                            >
                              {stuff.status === "PUBLISHED" ? <AppIcons.PauseSquare /> : <AppIcons.TrashPopover />}
                              <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                                {stuff.status === "PUBLISHED" ? "Cancel event" : "Delete"}
                              </Text>
                            </ListItem>
                          </List>
                        </PopoverContent>
                      </>
                    )}
                  </Popover>
                )}
              </Flex>
              <Link to={`/user/events/${stuff._id}`} key={stuff._id} style={{ textDecoration: "none" }}>
                <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"4px"}>
                  {!isAttend && (
                    <Box
                      bg={stuff.status === "PUBLISHED" ? "primary.600" : "gray.700"}
                      rounded={"100px"}
                      width={stuff.status === "PUBLISHED" ? "85px" : "55px"}
                      padding={"4px 13px 4px 13px"}
                      color={stuff.status === "PUBLISHED" ? "primary.900" : "gray.0"}
                      fontFamily={"IM"}
                      fontSize={"12px"}
                      textAlign={"center"}
                    >
                      {stuff.status === "PUBLISHED" ? "Published" : "Draft"}
                    </Box>
                  )}

                  <Text fontFamily={"AVR"} fontSize={"12px"} color={"gray.0"}>
                    {stuff.start && UTCConverter(stuff.start)}
                  </Text>
                  <Flex alignItems={"center"} justifyContent={"flex-start"} gap={"4px"}>
                    <AppIcons.Guest />
                    {!isAttend ? (
                      <Text fontFamily={"AVR"} fontSize={"12px"} color={"gray.0"}>
                        {stuff.tickets.reduce((sold, ticket) => sold + ticket.sold, 0)}/{stuff.tickets.reduce((total, ticket) => total + ticket.total, 0)}{" "}
                        guests
                      </Text>
                    ) : (
                      <Text fontFamily={"AVR"} fontSize={"12px"} color={"gray.0"}>
                        {stuff.tickets.reduce((sold, ticket) => sold + ticket.sold, 0)} guests
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Link>
            </Flex>
          </Flex>
          <ConfirmationModal
            isOpen={showConfirmModal}
            setIsOpen={setShowConfirmModal}
            stuff={selectedEvent}
            onConfirmDelete={(eventId: any) => {
              removeDeletedEvent(eventId);
            }}
          />
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default EventItem;
