import React from 'react';
import { Flex } from '@chakra-ui/react'
import Navbar from 'components/desktop-layout/navbar/Navbar';
import PaymentSetup from './parts/PaymentSetup';
import ProfileQrCode from './parts/ProfileQrCode';
import NativeCurrency from './parts/NativeCurrency';

const Settings = () => {
    return (
        <Flex flexDirection={'column'}  gap={'32px'} flex={'1 0 0'} padding={'48px 48px 0 48px'}>
            <Navbar title='Settings' dontShowCreateBtn={true}/>
            <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'24px'} marginBottom={'30px'}>
                <PaymentSetup />
                {/* <NativeCurrency /> */}
                <ProfileQrCode />
            </Flex>
        </Flex>
    );
};

export default Settings;