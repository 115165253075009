import { Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import { Label } from "components/common/form/form-style";
import { FormikErrors } from "formik";
import React from "react";

const Location = ({
  errors,
  values,
  handleChange,
  handleBlur,
}: {
  errors: FormikErrors<{
    event: string;
    title: string;
    description: string;
    banner: string;
    location: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
  }>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: any;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}) => {
  return (
    <Label>
      <Text color={"gray.400"} fontFamily={"IM"} fontWeight={"600"}>
        Location
      </Text>
      <Text color={"gray.600"} fontSize={"14px"} fontFamily={"IM"} fontWeight={"400"}>
        Help people in the area discover your event & allow attendees auto check-in
      </Text>
      <InputGroup height={"48px"}>
        <InputLeftElement mt={"2px"}>
          <AppIcons.Location />
        </InputLeftElement>
        <Input
          variant="unstyled"
          color={"gray.0"}
          bg={"gray.1000"}
          fontFamily={"IM"}
          type="text"
          id="location"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="What's the address?"
          _placeholder={{ color: "gray.800" }}
          value={values?.location}
        />
      </InputGroup>
      {errors.location && <Text color={"red.600"}>{errors.location}</Text>}
    </Label>
  );
};

export default Location;
