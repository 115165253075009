import {
  ILoginProps,
  IPaymentMethodsProps,
  IProfileProps,
  ISignUpProps,
  IVerifyUser,
  IForgetPassword,
  IResetPassword,
} from "./Interfaces";

import { ILoginViaWallet } from "types/interfaces/ApiInterfaces"

export const singUpUser = (props: ISignUpProps) => {
  return { url: `auth/signup`, props };
};

export const loginUser = (props: ILoginProps) => {
  return { url: `auth/signin`, props };
};

export const putUserProfile = (props: IProfileProps) => {
  return { url: `users/profile`, props };
};

export const postUserVerify = (props: IVerifyUser) => {
  return { url: `auth/verify`, props };
};

export const putUserPaymentMethods = (props: IPaymentMethodsProps) => {
  return { url: `users/payment-methods`, props };
};

export const postLoginViaWallet = (props: ILoginViaWallet) => {
  return { url: `auth/connect-wallet`, props };
};

export const postForgetPassword = (props: IForgetPassword) => {
  return { url: `/auth/forget-password`, props };
};

export const postResetPassword = (props: IResetPassword, token: string) => {
  return { url: `/auth/reset-password?token=${token}`,  props };
};

export const getGoogleAccount = () => {
  return { url: `/auth/google` };
};

export const getCurrentUser = () => {
  return { url: `/users/current`};
}

export const getUsersProfile = (userId: string) => {
  return { url: `/users/${userId}/detail`};
}

export const getUserUnclaimedRewards = () => {
  return { url: `/rewards/unclaimed-rewards`};
}

export const getUserClaimedRewards = () => {
  return { url: `/rewards/claimed-rewards`};
}

export const patchReceiveUnclaimedRewards = (rewardId: string) => {
  return { url: `/rewards/${rewardId}/receive-rewards`}
}