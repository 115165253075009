//Chakra
import { Avatar, AvatarGroup, Box, Flex, Image, Text } from "@chakra-ui/react";
import { Link, Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Time Converter
import { UTCConverter } from "lib/utils/time/UTCConverter";

const ExploreItem = ({events, currentUserId}: {events: any, currentUserId: string}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} alignSelf={"stretch"} gap={"12px"} padding={"8px"} border={"1px solid #434343"} borderRadius={"8px"} width={"100%"}>
        <ChakraLink as={ReactRouterLink} to={`/user/events/${events?.event?._id}`} style={{ textDecoration: "none" }} width={"100%"}>
          <Image bgColor={'black'} src={events?.event?.banner} width={"100%"} height={"200px"} display={"flex"} alignSelf={"stretch"} borderRadius={"8px"} objectFit={"contain"} />
        </ChakraLink>
        <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
          <ChakraLink as={ReactRouterLink} to={`/user/events/${events?.event?._id}`} style={{ textDecoration: "none" }} width={"100%"}>
            <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
              <Text fontFamily={"AVR"} fontSize={"12px"} fontWeight={400} color={"gray.300"}>
                {events?.event?.start && UTCConverter(events?.event?.start)}
              </Text>
              <Text fontFamily={"AVR"} fontSize={"14px"} fontWeight={600} color={"gray.0"}>
                {events?.event?.title}
              </Text>
            </Flex>
          </ChakraLink>
          <Flex alignItems={'center'} alignSelf={'stretch'} gap={'4px'}>
            <Text color={'gray.400'} fontFamily={'AVR'} fontSize={'12px'} fontWeight={400}>Hosted by</Text>
            <Avatar src={events?.event?.creator?.image}  name={events?.event?.creator?.name} size={'xs'} width={'24px'} height={'24px'} />
            <Link to={events?.event?.creator?._id === currentUserId ? `/user/profile`  : `/user/profile/${events?.event?.creator?._id}`}>
              <Text color={events?.event?.creator?._id === currentUserId ? 'primary.600' : "gray.0"} fontFamily={'AVR'} fontSize={'12px'} fontWeight={400}>
                {events?.event?.creator?._id === currentUserId ? "You" : events?.event?.creator?.name}
              </Text>
            </Link>
          </Flex>
          <ChakraLink as={ReactRouterLink} to={`/user/events/${events?.event?._id}`} style={{ textDecoration: "none" }} width={"100%"}>
            <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
              <Flex alignItems={"center"} alignSelf={"stretch"} gap={"4px"}>
                <AppIcons.Location width={"13px"} height={"16px"} strokeWidth={"1.5px"} />
                <Text fontFamily={"AVR"} fontSize={"12px"} fontWeight={400} color={"gray.300"}>
                  {events?.event?.location === "iran" ? "canada" : events?.event?.location}
                </Text>
              </Flex>
              {events?.attendees.length > 0 && 
              <AvatarGroup display={"flex"} alignItems={"flex-start"} gap={"4px"} color={'gray.700'} size={"xs"} max={4}>
                {events?.attendees?.map((item: any) =>
                  <Avatar key={item?._id} width={"24px"} height={"24px"} border={"1px solid #1c1c1c"} name={item?.name} src={item?.image}/>
                  )}
                  {events?.attendees?.length === 5 && 
                    Array.from({ length: events?.event?.sold - 5 }).map((_, index) => <Avatar key={index} width={"24px"} height={"24px"} border={"1px solid #1c1c1c"} />)
                  }
              </AvatarGroup>
              }
            </Flex>
          </ChakraLink>
        </Flex>
      </Box>
  );
};

export default ExploreItem;
