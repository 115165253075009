//Chakra
import { Button, Flex, Link, Spinner, Text } from '@chakra-ui/react';

const RegisterBtn = ({isLoading, btnTitle, btnSubtitle, btnSubtitleLink, btnlinkTitle}: {isLoading: boolean, btnTitle: string, btnSubtitle: string, btnSubtitleLink: string, btnlinkTitle: string}) => {
    return (
        <Flex bgColor={'gray.1000'} flexDirection={"column"} position={"fixed"} left={"0px"} right={"0px"} bottom={"0px"} padding={"16px"}>
            <Button type="submit" color={"primary.900"} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} fontFamily={"IM"} fontWeight={"500"} fontSize={"16px"} bg={"primary.600"} py={"12px"} px={"16px"} isDisabled={isLoading}>
                {isLoading ? <Spinner /> : btnTitle}
            </Button>
            <Text fontFamily={"AVR"} fontSize={"12px"} textAlign={"center"} fontWeight={"400"} color={"grey.600"} mt={"12px"}>
                {btnSubtitle}{" "}
                <Link href={`/${btnSubtitleLink}`} fontFamily={"AVR"} fontWeight={"600"} color={"primary.600"}>
                    {btnlinkTitle}
                </Link>
            </Text>
        </Flex>
    );
};

export default RegisterBtn;