import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Chakra
import { Flex } from "@chakra-ui/layout";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconAndDitailRegisteration from "components/common/icon-and-detail-box/IconAndDitailRegisteration";

//Custom Hooks
import { useProfile } from "functions/hooks/user/useProfile";

const VerifyEmail = () => {
    const { verifyEmail } = useProfile();
    const location = useLocation();
    const navigate = useNavigate();

    const getParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get("token");
    };

    useEffect(() => {
        const verifyEmailFunction = async () => {
            const jwt = getParams();
            if (jwt) await verifyEmail(jwt);
        };
        verifyEmailFunction();
    }, [location.search]);

    return (
        <Flex flexDirection={"column"} alignItems={"flex-start"} width={"418px"} borderRadius={"16px"} bgColor={"gray.900"}>
            <Flex alignItems={"center"} justifyContent={"flex-end"} alignSelf={"stretch"} padding={"16px 24px 0 24px"}>
                <Flex cursor={"pointer"} onClick={() => navigate("/")}>
                    <AppIcons.XcloseWithoutBorder />
                </Flex>
            </Flex>
            <Flex flexDirection={"column"} alignItems={"center"} gap={"24px"} alignSelf={"stretch"} padding={"0 24px"}>
                <IconAndDitailRegisteration boxIcon={<AppIcons.CheckVerified />} primaryTitle="Email verified" secondaryTitle="You will be redirected shortly..." />
            </Flex>
        </Flex>
    );
};

export default VerifyEmail;
