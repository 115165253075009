//Chakra
import { Flex, IconButton, Text } from "@chakra-ui/react";


const WalletItem = ({title, itemOnClick, itemIcon, itemBgColor}: {title: string; itemOnClick: () => void; itemIcon: any, itemBgColor?: string}) => {
  return (
    <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={"16px"} width={'60px'} onClick={itemOnClick}>
      <IconButton height={'full'} overflow={'hidden'} rounded={'12px'} _hover={{ bg: "transparent" }} bg={itemBgColor ? itemBgColor : "transparent"} aria-label="document" icon={itemIcon} />
      <Text color={"gray.0"} fontFamily={"IM"} fontSize={"14px"} fontWeight={"600"} lineHeight={"20px"}>
        {title}
      </Text>
    </Flex>
  );
};

export default WalletItem;
