import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as Notification } from "./notification.svg";
import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as HeartCalendar } from "./calendar.svg";
import { ReactComponent as Document } from "./search-doc.svg";
import { ReactComponent as Google } from "./google.svg";
import { ReactComponent as Guest } from "./guest.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Time } from "./time.svg";
import { ReactComponent as Date } from "./date.svg";
import { ReactComponent as Stars } from "./stars.svg";
import { ReactComponent as UploadImage } from "./upload-image.svg";
import { ReactComponent as CurrencyDollar } from "./currency-dollar.svg";
import { ReactComponent as Ticket } from "./ticket-1.svg";
import { ReactComponent as Delete } from "./trash.svg";
import { ReactComponent as GreenLocation } from "./green-location.svg";
import { ReactComponent as GreenCalendar } from "./green-calendar.svg";
import { ReactComponent as InfoCircle } from "./info-circle.svg";
import { ReactComponent as Alert } from "./alert-triangle.svg";
import { ReactComponent as CopyBtn } from "./copyButton.svg";
import { ReactComponent as Check } from "./check-Icons.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Group } from "./Group.svg";
import { ReactComponent as CreditCard } from "./CreditCard.svg";
import { ReactComponent as Tajob } from "./tajob.svg";
import { ReactComponent as CheckCircle } from "./check-circle.svg";
import { ReactComponent as QRCode } from "./qr-code-02.svg";
import { ReactComponent as Share } from "./share-07.svg";
import { ReactComponent as Wallet } from "./wallet-01.svg";
import { ReactComponent as WalletGreen } from "./wallet-green.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Telegram } from "./telegram.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as ChevronDown } from "./chevron-down.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left.svg";
import { ReactComponent as ChevronRight } from "./chevron-right.svg";
import { ReactComponent as ChevronUp } from "./chevron-up.svg";
import { ReactComponent as Trash } from "./trash-03.svg";
import { ReactComponent as WhiteInfo } from "./white-info.svg";
import { ReactComponent as Email } from "./mail.svg";
import { ReactComponent as CheckVerified } from "./check-verified.svg";
import { ReactComponent as XClose } from "./x-closeButtons.svg";
import { ReactComponent as SampleWallet } from "./Wallet.svg";
import { ReactComponent as CheckCircle2 } from "./check-circle-2.svg";
import { ReactComponent as MATICToken } from "./MATIC-token.svg";
import { ReactComponent as MATICTokenSquare } from "./square-MATIC-token.svg";
import { ReactComponent as BTCToken } from "./BTC-token.svg";
import { ReactComponent as ETHToken } from "./ETH-token.svg";
import { ReactComponent as SOLToken } from "./SOL-token.svg";
import { ReactComponent as STXToken } from "./STX-token.svg";
import { ReactComponent as ErrorInfoCircle } from "./error-info-circle.svg";
import { ReactComponent as FileSearch } from "./file-search.svg";
import { ReactComponent as CircleUploadImage } from "./circle-upload-image.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as SmallInfo } from "./small-info-circle-icon.svg";
import { ReactComponent as DroplinkedText } from "./deoplinked-text.svg";
import { ReactComponent as HomeGreen } from "./home-green.svg";
import { ReactComponent as HeartCalendar2 } from "./calendar-heart-2.svg";
import { ReactComponent as HeartCalendar2Green } from "./calendar-heart-2-green.svg";
import { ReactComponent as SettingsGreen } from "./settings-green.svg";
import { ReactComponent as QRcodePT } from "./QRcodeIcons.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as Share2 } from "./share-03.svg";
import { ReactComponent as IconInfoRuleset } from "./IconInfoRuleset.svg";
import { ReactComponent as Percent } from "./percent-02.svg";
import { ReactComponent as X } from "./x.svg";
import { ReactComponent as DotsVertical } from "./dots-vertical.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as PauseSquare } from "./pause-square.svg";
import { ReactComponent as TrashPopover } from "./trash-popover.svg";
import { ReactComponent as CheckRegular } from "./check-regular.svg";
import { ReactComponent as HighligtEvent } from "./Highlight-Icons.svg";
import { ReactComponent as XcloseWithoutBorder } from "./xclose-withoutBorder.svg";
import { ReactComponent as Hearts } from "./hearts.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as LogoutGreen } from "./logout-green.svg";
import { ReactComponent as DotsHorizontal} from "./dots-horizontal.svg"
import { ReactComponent as CheckCircle3} from "./check-circle-3.svg"

const AppIcons = {
  Share,
  Wallet,
  WalletGreen,
  QRCode,
  CheckCircle,
  Tajob,
  CreditCard,
  Menu,
  Group,
  Notification,
  Home,
  Settings,
  HeartCalendar,
  Document,
  Google,
  Guest,
  UploadImage,
  Location,
  Plus,
  Minus,
  Time,
  Date,
  Stars,
  CurrencyDollar,
  Ticket,
  Delete,
  InfoCircle,
  GreenLocation,
  GreenCalendar,
  Alert,
  CopyBtn,
  Check,
  Pencil,
  Telegram,
  Linkedin,
  Twitter,
  Instagram,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Trash,
  WhiteInfo,
  Email,
  CheckVerified,
  XClose,
  Close,
  SampleWallet,
  CheckCircle2,
  MATICToken,
  BTCToken,
  ETHToken,
  SOLToken,
  STXToken,
  ErrorInfoCircle,
  FileSearch,
  CircleUploadImage,
  User,
  SmallInfo,
  DroplinkedText,
  HomeGreen,
  HeartCalendar2,
  HeartCalendar2Green,
  SettingsGreen,
  QRcodePT,
  ArrowRight,
  Share2,
  IconInfoRuleset,
  Percent,
  X,
  DotsVertical,
  Edit,
  PauseSquare,
  MATICTokenSquare,
  TrashPopover,
  CheckRegular,
  HighligtEvent,
  XcloseWithoutBorder,
  Hearts,
  Copy,
  ArrowLeft,
  Logout,
  LogoutGreen,
  DotsHorizontal,
  CheckCircle3,
};
export default AppIcons;
