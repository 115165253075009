import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Modal, Spinner, Text } from "@chakra-ui/react";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import { ModalContent3, ModalWrapperComponent3 } from "./AppModal-style";
import AppIcons from "assets/icons/AppIcons";
import { IEvents } from "lib/stores/user/UserStore";
import { useEvents } from "functions/hooks/events/useEvents";
import useAppToast from "functions/hooks/toast/useToast";

interface AppModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // children: string | JSX.Element | JSX.Element[];
  stuff: IEvents | undefined;
  onConfirmDelete: (eventId: any) => void;
}

const ConfirmationModal: React.FC<AppModalProps> = ({ isOpen, setIsOpen, stuff, onConfirmDelete }) => {
  const { deletingEvent, isLoading, cancelingEvent } = useEvents();
  const { showToast } = useAppToast();

  const modalTitle = stuff?.status === "PUBLISHED" ? "Cancel event" : "Delete event";

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalWrapperComponent3>
        <ModalContent3>
          <motion.div
            initial={{ opacity: 0, scale: 0.1, x: 0, y: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Flex
              backgroundColor={"gray.900"}
              width={"100%"}
              height={"100%"}
              borderRadius={"16px"}
              border={"1px"}
              borderColor={"gray.700"}
              padding={"16px 32px"}
              flexDirection={"column"}
              gap={"24px"}
            >
              <Flex justifyContent={"space-between"}>
                <Flex alignItems={"center"} gap={"16px"}>
                  <AppIcons.HighligtEvent />
                  <Text fontFamily={"IM"} fontSize={"18px"} color={"gray.0"}>
                    {modalTitle}
                  </Text>
                </Flex>
                <AppIcons.XcloseWithoutBorder onClick={() => setIsOpen(false)} />
              </Flex>
              <Text fontFamily={"IM"} fontWeight={"400"} fontSize={"14px"} color={"gray.600"} lineHeight={"20px"}>
                This event is currently active. Are you sure you want to delete it? Your guests will be notified of any
                updates.
              </Text>
              <Flex justifyContent={"center"} alignItems={"center"} gap={"8px"}>
                <Button
                  paddingX={"8px"}
                  paddingY={"16px"}
                  width={"full"}
                  bg={"gray.800"}
                  color={"gray.0"}
                  fontFamily={"IM"}
                  fontSize={"16px"}
                  fontWeight={500}
                  borderRadius={"8px"}
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  paddingX={"8px"}
                  paddingY={"16px"}
                  bg={"primary.600"}
                  color={"primary.900"}
                  fontFamily={"IM"}
                  fontSize={"16px"}
                  fontWeight={500}
                  width={"full"}
                  borderRadius={"8px"}
                  _hover={{ bgColor: "primary.600" }}
                  _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                  _active={{ bgColor: "primary.600" }}
                  isDisabled={isLoading}
                  onClick={() => {
                    if (stuff?.status === "PUBLISHED") {
                      if (stuff?.sold === 0) {
                        cancelingEvent(stuff?._id);
                        onConfirmDelete(stuff?._id);
                        setIsOpen(false);
                        showToast(`Your event has been successfully cancelled`, "success");
                      } else {
                        showToast(`Your event has participants, so it cannot be canceled`, "warning");
                      }
                    } else if (stuff?.status !== "PUBLISHED") {
                      deletingEvent(stuff?._id);
                      onConfirmDelete(stuff?._id);
                      setIsOpen(false);
                      showToast(`Your event has been successfully deleted`, "success");
                    } else {
                      onConfirmDelete(stuff?._id);
                      setIsOpen(false);
                    }
                  }}
                >
                  {isLoading ? <Spinner color="white" /> : "Confirm"}
                </Button>
              </Flex>
              {/* {children} */}
            </Flex>
          </motion.div>
        </ModalContent3>
      </ModalWrapperComponent3>
    </Modal>
  );
};

export default ConfirmationModal;
