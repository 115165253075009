//Chakra
import { Button, Flex } from '@chakra-ui/react';

const PrimaryBtn = ({btnText, btnOnClick, btnTextColor, btnIsDisabled, btnHasBg, isModalBtn, btnIsLodaing}: {btnText: string | any, btnOnClick: any, btnTextColor: string, btnIsDisabled?: boolean, btnHasBg?: boolean, isModalBtn?: boolean, btnIsLodaing?: boolean}) => {
    return (
        <Flex justifyContent={"center"} bgColor={isModalBtn ? "gray.900" : (btnHasBg ? "gray.1000" : "transparent")} width={"full"} position={"fixed"} right={0} left={0} padding={"16px"} bottom={0} zIndex={100}> 
            <Button bg={"primary.600"} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: btnTextColor}} color={btnTextColor} width={"full"} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} onClick={btnOnClick} isDisabled={btnIsDisabled} isLoading={btnIsLodaing}>
                {btnText}
            </Button>
        </Flex>
    );
};

export default PrimaryBtn;