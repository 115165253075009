import { useNavigate} from 'react-router-dom'

//Chakra
import { Flex, Spinner, Button } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconAndDitailRegisteration from "components/common/icon-and-detail-box/IconAndDitailRegisteration";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

const EmailConfirmation = () => {
    const email = localStorage.getItem("email");
    const { isLoading } = useEvents();
    const navigate = useNavigate();

    return (
        <Flex flexDirection={"column"} alignItems={'flex-start'} width={'418px'} borderRadius={'16px'} bgColor={"gray.900"}>
            <Flex alignItems={'center'} justifyContent={'space-between'} alignSelf={'stretch'} padding={'0 24px'}>
                <Flex cursor={'pointer'} onClick={() => navigate('/')}><AppIcons.ArrowLeft /></Flex>
                <Flex cursor={'pointer'} onClick={() => navigate('/')}><AppIcons.XcloseWithoutBorder /></Flex>
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
                <IconAndDitailRegisteration
                    boxIcon={<AppIcons.Email />}
                    primaryTitle="Check your email for a link"
                    secondaryTitle={`Verification link has been sent to ${email}`}
                />
            </Flex>
            <Flex alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'} padding={'16px 24px'}>
                <Button bg={"green.800"} color={"gray.0"} fontFamily={"IM"} width={"100%"} fontSize={"16px"} fontWeight={500} padding={'16px 24px'} borderRadius={'8px'}>
                    {isLoading ? <Spinner color="white" /> : "Resend Email"}
                </Button>
            </Flex>
        </Flex>
    );
};

export default EmailConfirmation;
