//Chakra
import { Flex, Text } from "@chakra-ui/react"

const SummaryItem = ({leftCol, rightCol}: {leftCol: any, rightCol: any}) => {
    return (
        <Flex display={"flex"} justifyContent={"space-between"}>
            <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"gray.0"} display={"flex"} alignItems={"center"} fontFamily={"AVR"}>
                {leftCol}
            </Text>
            <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"gray.0"} fontFamily={"AVR"}>
                {rightCol}
            </Text>
        </Flex>
    );
};

export default SummaryItem;