import { IDeploySmartContract } from "./Interfaces";

export const generateSmartContractCodeApi = (
  eventId: string,
  artwork: string
) => {
  return { url: `events/${eventId}/contract`, props: { artwork } };
};

export const deploySmartContractApi = (
  eventId: string,
  props: IDeploySmartContract
) => {
  return { url: `events/${eventId}/contract`, props };
};

export const getSmartContractCodeApi = (eventId: string) => {
  return { url: `events/${eventId}/contract` };
};
