import { useEffect } from 'react';
import { useLocation, useNavigate} from 'react-router-dom'

// Chakra
import { Flex, Text } from '@chakra-ui/react'

//User Data Store
import { useUserStore } from "lib/stores/user/UserStore";
import { useProfile } from 'functions/hooks/user/useProfile';

// Icons
import AppIcons from 'assets/icons/AppIcons';

const SuccessLogin = () => {
    const {setUserData, setJwtToken} = useUserStore();
    const { getCurrentUserData } = useProfile()
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token")
    const redirectUrl = queryParams.get("redirectUrl")
    const apiKey = queryParams.get("apiKey")


    const getCurrentUser = async () => {
        if (token) {
            setJwtToken(token);
            const userData = await getCurrentUserData();
            setUserData(userData);
            setTimeout(() => {
                navigate(`/onboarding`);
            }, 3000);
        }
    }
    
    useEffect(() => {
        if (redirectUrl && apiKey) {
            window.location.href = `${redirectUrl}?key=${apiKey}`;
        } else {
            getCurrentUser();
        }
    }, []);
    
    return (
        <Flex flexDirection={'column'} gap={'12px'} height={'70vh'}>
            <Flex height={'100%'} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gap={"40px"} padding={"16px"} alignSelf={"stretch"} flex={"1 0 0"} borderRadius={"16px"} bgColor={"gray.900"} boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}>
                <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'}>
                    <AppIcons.Check />
                    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'8px'}>
                        <Text textAlign={'center'} color={'primary.600'} fontFamily={'AVSB'} fontSize={'22px'} fontWeight={500}>Logged in successfully</Text>
                        <Text textAlign={'center'} color={'gray.600'} fontFamily={'AVSB'} fontSize={'16px'}>You will be sent to the on boarding page in a few seconds</Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SuccessLogin;