import { IEvents } from "lib/stores/user/UserStore";
import { NavigateFunction } from "react-router-dom";

export const getEvent = async ({
  getUserEvents,
  setEventsData,
  setOptions,
  setRuleType,
  formikRef,
  eventId,
}: {
  getUserEvents: () => Promise<IEvents[] | undefined>;
  setEventsData: React.Dispatch<React.SetStateAction<IEvents[]>>;
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
  setRuleType: React.Dispatch<React.SetStateAction<string | null>>;
  formikRef: React.MutableRefObject<any>;
  eventId: string | undefined;
}) => {
  const events = await getUserEvents();
  if (events) {
    const filteredEventData = events.filter((event) => event._id === eventId);
    setEventsData(filteredEventData);

    const ticketTitles = filteredEventData?.map((event) => event?.tickets?.map((ticket) => ticket.title)).flat();
    setOptions([...ticketTitles]);

    if (filteredEventData.length > 0) {
      // console.log("eventRules:", filteredEventData[0].rules);

      const hasGatedRules = filteredEventData[0].rules?.some((r) => r.gated === true);
      setRuleType(hasGatedRules ? "Gated" : "Discount");

      const initialRulesets = filteredEventData[0].rules?.map((r) => ({
        gated: r.gated === true ? "Gated" : "Discount",
        discount: r.discount,
        nft_identifiers: r.nft_identifiers,
        tickets: r.tickets?.map((ticketId: any) => {
          const ticket = filteredEventData[0].tickets?.find((t) => t.id === ticketId);
          return ticket ? ticket?.title.split(",") : "";
        }),
        blockchain: r.blockchain,
        id: r.id,
      }));
      if (formikRef.current) {
        formikRef.current.setValues({ rules: initialRulesets });
      }
    }
  }
};

// Navigate back to the Ticket Page route
export const handleBack = ({ navigate, eventId }: { navigate: NavigateFunction; eventId: string | undefined }) => {
  navigate(`/user/create/tickets/${eventId}`);
};
