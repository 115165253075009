import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const WhatIsWallet = () => {
  return (
    <Flex bg={"gray.900"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={"8px"} padding={"32px"} borderTop={"1px"} borderColor={"gray.800"}>
      <Text fontSize={"16px"} marginTop={"10px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"24px"} color={"white"}>
        What is a Wallet?
      </Text>
      <Text color={"gray.600"} fontWeight={"400"} fontSize={"16px"} fontFamily={"AVSB"} lineHeight={"24px"} textAlign={"center"}>
        A wallet is used to send, receive, store, and display digital assets. It’s also a new way to log in, without needing to create new accounts and passwords on every website.
      </Text>
    </Flex>
  );
};

export default WhatIsWallet;
