export enum Chain {
    POLYGON,
    BINANCE,
    STACKS,
    RIPPLESIDECHAIN,
    NEAR,
    SKALE
}
export enum Network {
    MAINNET,
    TESTNET
}