import { useCustomNavigate } from "functions/hooks/useCustomeNavigate/useCustomNavigate";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import DashboardGateModel from "./model/DashboardGateModel";
import { useUserStore } from "lib/stores/user/UserStore";

function AdminGate({ children }: { children: React.ReactNode }) {
  const user = useUserStore((state) => state.user);
  const jwtToken = useUserStore((state) => state.jwtToken);
  const { checkPermission, registerGate } = DashboardGateModel;
  const { eventsNavigate } = useCustomNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      const check = checkPermission({ user, jwtToken });
      if (check)
        registerGate({
          to: check,
          redirect: eventsNavigate,
          pathname: location.pathname,
          user,
          jwtToken,
        });
    }
  }, [user, location]);

  return <>{user ? children : <Navigate to={"/"} />}</>;
}

export default AdminGate;
