import { useLocation, useNavigate } from "react-router-dom";

//this hook have been used for handle shop and user data
export function useCustomNavigate() {

  const navigate = useNavigate();
  const location = useLocation()

  const eventsNavigate = (path: string, checkCurrentPath = false) => {
    const check = checkCurrentPath ? path !== location.pathname : true
    if (check) navigate(path);
  }

  return {
    eventsNavigate,
  };
}
