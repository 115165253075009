import { useNavigate, useParams } from "react-router-dom";
import { Ruleset } from "./InterfaceRulesets";
import { useEvents } from "functions/hooks/events/useEvents";
import { FieldArray, Form, Formik } from "formik";
import { rulesetSchema } from "schemas/schema";
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import EventsStepper from "components/common/stepper/EventsStepper";
import IconAndDetailBox from "components/common/icon-and-detail-box/IconAndDetailBox";
import AppIcons from "assets/icons/AppIcons";
import RulesetItem from "./parts/rulset-item/RulesetItem";
import { useEffect, useRef, useState } from "react";
import { IEvents } from "lib/stores/user/UserStore";
import EventCreationBackBtn from "components/common/back-btn/EventCreationBackBtn";
import { getEvent, handleBack } from "./parts/functions/RulsetFunc";

interface initialValuesType {
  rules: Ruleset[];
}

const initialValues: initialValuesType = {
  rules: [],
};

const RulesetsPage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { isLoading, SubmitRuleset, getUserEvents } = useEvents();
  const [eventsData, setEventsData] = useState<IEvents[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [ruleType, setRuleType] = useState<string | null>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    getEvent({
      getUserEvents,
      setEventsData,
      setOptions,
      setRuleType,
      formikRef,
      eventId,
    }).then(() => {
      setDataLoaded(true)
    }).catch((error) => {
      console.error("Error fetching event data:", error);
    })
  }, [eventId]);

  useEffect(() => {
    if (dataLoaded && eventsData.length > 0) {
      const initialRulesets = eventsData[0].rules?.map((r) => ({
        gated: r.gated === true ? "Gated" : "Discount",
        discount: r.discount,
        nft_identifiers: r.nft_identifiers,
        tickets: r.tickets?.map((ticketId: any) => {
          const ticket = eventsData[0].tickets?.find((t) => t.id === ticketId);
          return ticket ? ticket?.title.split(",") : "";
        }),
        blockchain: r.blockchain,
        id: r.id,
      }));
      if (formikRef.current) {
        formikRef.current.setValues({ rules: initialRulesets });
      }
    }
  })

  if (!dataLoaded) {
    // if (eventsData.length === 0) {
      return <Spinner size="lg" color="primary.600" />;
    // }
  }

  return (
    <>
      <EventCreationBackBtn onClick={() => handleBack({ navigate, eventId })} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={async (values: any) => {
          const FinalValues = values.rules.map((rules: any) => ({
            gated: rules.gated,
            discount: rules.discount || 0,
            nft_identifiers: rules.nft_identifiers,
            tickets: rules.tickets
              ?.map((ticketTitle: any) => {
                const mainTickets = String(ticketTitle);
                const ticketIds: any[] = [];
                if (mainTickets) {
                  eventsData.forEach((eventData) => {
                    const foundTicket = eventData?.tickets?.find((t) => t.title === mainTickets);
                    if (foundTicket) {
                      ticketIds.push(foundTicket.id);
                    }
                  });
                }
                return ticketIds.length > 0 ? ticketIds : null;
              })
              .flat()
              .filter(Boolean),
            blockchain: "POLYGON",
          }));
          // console.log("FinalValues", FinalValues);
          if (eventId) {
            let result = await SubmitRuleset(FinalValues, eventId);
          }
        }}
        validationSchema={rulesetSchema}
      >
        {({ values, errors, handleChange, isValid, dirty, setFieldValue }) => (
          <Form>
            <FieldArray name="rules">
              {({ remove, push }: { remove: any; push: any }) => (
                <Flex flexDirection={"column"} gap={"16px"} marginBottom={"55px"}>
                  <EventsStepper step={2} />
                  {values?.rules.length > 0 ? (
                    values?.rules.map((r: Ruleset, index: number) => (
                      <Box key={index} rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                        <RulesetItem
                          r={r}
                          index={index}
                          remove={remove}
                          handleChange={handleChange}
                          errors={errors}
                          eventsData={eventsData}
                          options={options}
                          ruleType={ruleType}
                          setRuleType={setRuleType}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                      <IconAndDetailBox
                        boxIcon={<AppIcons.Stars />}
                        primaryTitle="No rule set up yet"
                        secondaryTitle="Set up a ruleset now"
                      />
                    </Box>
                  )}
                  <Button
                    padding={"8px"}
                    height={"32px"}
                    fontFamily={"IM"}
                    fontSize={"14px"}
                    color={"gray.0"}
                    fontWeight={500}
                    bg={"gray.900"}
                    onClick={() => push({ gated: "", discount: "", nft_identifiers: [], tickets: [] })}
                  >
                    + Add new rule
                  </Button>

                  {values?.rules.length >= 1 &&
                  <Button
                    type="submit"
                    bg={"primary.600"}
                    color={"primary.900"}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    _hover={{ bgColor: "primary.600" }}
                    _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                    _active={{ bgColor: "primary.900" }}
                    isDisabled={isLoading || !isValid || !dirty}
                  >
                    {isLoading ? <Spinner color="white" /> : "Next"}
                  </Button>}
                </Flex>
              )}
            </FieldArray>
            {values?.rules.length < 1 && 
            <Button
              mt={"80%"}
              type="submit"
              bg={"primary.600"}
              color={"primary.900"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              position={"fixed"}
              left={"16px"}
              right={"16px"}
              bottom={"16px"}
              _hover={{ bgColor: "primary.600" }}
              _disabled={{ bgColor: "gray.900", color: "gray.700" }}
              _active={{ bgColor: "primary.900" }}
              isDisabled={isLoading}
              onClick={() => navigate(`/user/create/acceptPayment/${eventId}`)}
            >
              {isLoading ? <Spinner color="white" /> : "Skip"}
            </Button>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RulesetsPage;
