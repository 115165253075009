import { useNavigate } from "react-router-dom";
import { ICreateOrder } from "lib/apis/order/Interfaces";
import { createOrder, getOrder } from "lib/apis/order/OrderApi";
import {
  postCheckoutPayment,
  postCreateOrder,
  postCreateStripeIntent,
  postHandleFreeOrder,
  postPayViaPolygon,
} from "lib/apis/payment/paymentApi";
import { useUserStore } from "lib/stores/user/UserStore";
import { useState } from "react";
import { useApi } from "../useApi/useApi";
import { ethers } from "ethers";
import useAppToast from "../toast/useToast";

export function usePayment() {
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const jwtToken = useUserStore((state) => state.jwtToken);

  const [paymentData, setPaymentData] = useState<any>();
  const { postApi, getApi } = useApi();
  const navigate = useNavigate();
  const { showToast } = useAppToast();


  //create order
  const createOrders = async (props: ICreateOrder) => {
    setIsLoading(true);
    setPaymentData(props);
    const data = await postApi(createOrder(props));
    if (data) {
      const order = data?.order;
      localStorage.setItem("orderId", order.id);
      setIsLoading(false);
      return order;
    }
    setIsLoading(false);
  };

  const findOrder = async (orderId: string) => {
    setIsLoading(true)
    const res = await getApi(getOrder(orderId))
    if (res) {
      setIsLoading(false);
      return res;
    }
    setIsLoading(false);
  }

  const payViaStripe = async () => {
    setIsLoading(true);
    // set order id localStorage =>
    let orderId = localStorage.getItem("orderId");
    if (orderId) {
      //<= ========================
      const stripe = await postApi(
        postCreateStripeIntent({
          orderId,
        })
      );
      if (stripe) {
        setClientSecret(stripe.client_secret);
        localStorage.removeItem("orderId");
      } else {
        navigate("/oops")
      }
    }
    // }
    setIsLoading(false);
  };

  const payViaPolygon = async (orderId: string) => {
    setIsLoading(true);
    // let orderId = localStorage.getItem("orderId");

    if (orderId) {
      const polygonRes = await postApi(postPayViaPolygon({"orderId" : orderId}));

      if (polygonRes) {
        localStorage.removeItem("orderId");
        return polygonRes;
      }
    }
  }

  const checkoutPaymentTransactions = async (orderId: string, transactionId: string) => {
    setIsLoading(true)
    const res = await postApi(postCheckoutPayment({
      "orderId": orderId,
      "transaction_id": transactionId
    }))
    
    if (res) {
      setIsLoading(false)
      return res;
    }

    setIsLoading(false)
  }

  const handleFreeOrder = async (orderId: string) => {
    setIsLoading(true)
    const res = await postApi(postHandleFreeOrder({"orderId": orderId})) 
    if (res) {

    }
    setIsLoading(false)
  }

  ////////////////////////////////////////////////////////////

  const isMetamaskInstalled = () => {
    const { ethereum }: any = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  };

  const chainLinkABI = [
    {
      inputs: [],
      name: "latestAnswer",
      outputs: [
        {
          internalType: "int256",
          name: "",
          type: "int256",
        },
      ],
      stateMutability: "view",
      type: "function",
    }
  ];
  async function getLatestAnswer(usdAmount: any, network = "testnet" || "mainnet") {
    const provider = new ethers.providers.JsonRpcProvider(
      (network as any) === "mainnet"? "https://polygon-mainnet.infura.io/v3/55bd630c4e164c04bb27fff3f59babb0": "https://polygon-mumbai.infura.io/v3/55bd630c4e164c04bb27fff3f59babb0"
    );
    const chainLink = new ethers.Contract(
      (network as any) === "mainnet"? "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0": "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
      chainLinkABI,
      provider
    );
    let latestAnswer = await chainLink.latestAnswer();
    return ethers.BigNumber.from(BigInt(Math.floor(usdAmount * (1e24 / Number(latestAnswer)))) * BigInt(100))
  }

  
  async function payWithMatic(destinationWalletAddress: any, usdAmount: any, network = "testnet" || "mainnet") {
    if (!isMetamaskInstalled()) {
      showToast("Metamask is not installed", "error");
      return;
    }
    // check if the user's metamsk is on the right network
    const networkId = await (window as any).ethereum.request({ method: "eth_chainId" });
    if (networkId !== "0x13881" && (network as any) === "testnet") {
      // add the polygon testnet (0x13881) to metamask
      await (window as any).ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x13881",
            chainName: "Mumbai",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
            blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
          },
        ],
      });
      // should switch to polygon testnet (0x13881)
      await (window as any).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x13881" }],
      });
    } else if (networkId !== "0x89" && (network as any) === "mainnet") {
      // add the polygon mainnet (0x89) to metamask
      await (window as any).ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x89",
            chainName: "Polygon Mainnet",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://polygon-rpc.com/"],
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ],
      });
      // should switch to polygon mainnet (0x89)
      await (window as any).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      });
    }
    let maticPrice = await getLatestAnswer(usdAmount);
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const params = [
      {
        from: await provider.getSigner().getAddress(),
        to: destinationWalletAddress,
        value: maticPrice._hex
      },
    ];
    const transactionHash = await (window as any).ethereum.request({
      method: "eth_sendTransaction",
      params,
    });
    return transactionHash;
  }
  

  // ****************************************************************  //

  const donePayment = async () => {
    setIsLoading(true);
    if (jwtToken) {
      // navigate("success-payment/" + orderId);
    } else {
    }
    setIsLoading(false);
  };

  return {
    payViaStripe,
    donePayment,
    clientSecret,
    isLoading,
    createOrders,
    payViaPolygon,
    getLatestAnswer,
    payWithMatic,
    checkoutPaymentTransactions,
    handleFreeOrder,
    findOrder,
  };
}
