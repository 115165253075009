import { useNavigate } from "react-router-dom";

//Chakra
import { Button, Flex, Spinner, Text } from "@chakra-ui/react";

//Components
import ProfileEventItem from "./eventItems/ProfileEventItem";

const UserEvents = ({events , isLoading, isUserProfile}: {events: any , isLoading: boolean, isUserProfile?: boolean}) => {
  
  const navigate = useNavigate()

  return isLoading ? (
    <ProfileEventItem events={events} isUserProfile={isUserProfile} isLoading={isLoading} />
  ) : events?.length > 0 ? (
    <ProfileEventItem events={events} isUserProfile={isUserProfile} />
  ) : (
    <Flex justifyContent={"center"} alignItems={"center"} flexDirection={"column"} alignSelf={"stretch"} rounded={"16px"} gap={"12px"} padding={"16px"} bg={"gray.900"} height={"497px"}>
      <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} alignSelf={"stretch"} gap={"8px"}>
        <Text color={"gray.0"} textAlign={"center"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
          {isUserProfile ? 'There is no event created yet' : "You have no event created yet"}
        </Text>
        {!isUserProfile && 
        <Text color={"gray.600"} textAlign={"center"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} fontFamily={"AVR"}>
          Try create one and share to your community
        </Text>}
      </Flex>
      {!isUserProfile && 
      <Button
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"6px"}
        width={"120px"}
        height={'32px'}
        padding={"8px 12px"}
        borderRadius={"8px"}
        bgColor={"primary.600"}
        color={"gray.50"}
        fontFamily={"IM"}
        fontWeight={500}
        fontSize={"16px"}
        _hover={{ bg: "primary.600" }}
        _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
        _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E'}}
        onClick={() => navigate(`/user/create/info`)}
      >
        Create event
      </Button>}
    </Flex>
  );
};

export default UserEvents;
