import { ComponentType, FC } from 'react';
import AdminGate from './parts/gate/AdminGate';

function userHOC<TProps>(WrappedComponent: ComponentType<any>): FC<TProps> {
    const WithLoading: FC<TProps> = ({ ...props }) => {
        return (
            <AdminGate>
                    <WrappedComponent {...props as TProps} />
            </AdminGate>
        )
    };

    return WithLoading;
}

export default userHOC;