import { useEffect, useState } from "react";

//Chakra
import { Box, ButtonGroup, Spinner } from "@chakra-ui/react";

//Components
import MyEventsButton from "./parts/buttons/MyEventsButtons";
import UserEvents from "./userEvents/UserEvents";
import AttendEvents from "./attendEvents/AttendEvents";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Interfaces
import { IEvents, useUserStore } from "lib/stores/user/UserStore";
import { useProfile } from "functions/hooks/user/useProfile";

const MyEvents = () => {
  const jwtToken = useUserStore((state) => state.jwtToken);

  const [selectedButton, setSelectedButton] = useState("Attend");
  const { isLoading, getUserEvents, getUserAttendEvents, cancelEvent } = useEvents();
  const [hostEvents, setHostEvents] = useState<IEvents[]>([]);
  const [attendEvents, setAttendEvents] = useState<IEvents[]>([]);
  const { getCurrentUserData } = useProfile();
  const [currentUserData, setCurrentUserData] = useState<any>([])



  const hostEventGetter = async () => {
    const res = await getUserEvents();
    if (res) {
      setHostEvents(res);
    }
  };

  const attendEventGetter = async () => {
    const res = await getUserAttendEvents();
    if (res) {
      setAttendEvents(res);
    }
  };

  const currentUserDataGetter = async () => {
    const res = await getCurrentUserData();
    if (res) {
      setCurrentUserData(res);
    }
  };

  const handleButtonClick = (buttonText: string) => {
    setSelectedButton(buttonText);
  };

  useEffect(() => {
    jwtToken && attendEventGetter();
    jwtToken && hostEventGetter();
    jwtToken && currentUserDataGetter();
  }, []);

  const removeDeletedEvent = (eventId: string) => {
    const eventToUpdate = hostEvents.find((event) => event._id === eventId);

    if (eventToUpdate && eventToUpdate.status === "PUBLISHED") {
      eventToUpdate.status = "Draft";
      setHostEvents([...hostEvents]);
    } else {
      const updatedEvents = hostEvents.filter((event) => event._id !== eventId);
      setHostEvents(updatedEvents);
    }
  };

  //   const updatedHostEvents = hostEvents.map((event) => {
  //     if (event._id === eventId) {
  //       // Create a new event object with the updated status
  //       return { ...event, status: "Draft" };
  //     }
  //     return event; // Keep other events unchanged
  //   });

//   setHostEvents(updatedHostEvents);
// }


  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"} marginBottom={"30px"}>
      <ButtonGroup
        width={"100%"}
        p={"8px"}
        gap={"4px"}
        bg={"gray.900"}
        rounded={"12px"}
        display={"flex"}
        justifyContent={"center"}
      >
        <MyEventsButton
          isSelected={selectedButton === "Attend"}
          text="Attend"
          number={attendEvents.length}
          isLoading={isLoading}
          onClick={() => handleButtonClick("Attend")}
        />
        <MyEventsButton
          isSelected={selectedButton === "Host"}
          text="Host"
          number={hostEvents.length}
          isLoading={isLoading}
          onClick={() => handleButtonClick("Host")}
        />
      </ButtonGroup>
      {selectedButton === "Host" ? (
        <UserEvents events={hostEvents} removeDeletedEvent={removeDeletedEvent} userPaymentMethods={currentUserData?.paymentMethods} isLoading={isLoading} />
      ) : (
        <AttendEvents events={attendEvents} removeDeletedEvent={removeDeletedEvent} isLoading={isLoading} />
      )}
    </Box>
  );
};

export default MyEvents;
