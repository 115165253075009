import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useAppToast = () => {
  const customId = "custom-id-yes";
  const showToast = (
    message: string | JSX.Element,
    type: "success" | "warning" | "error" | "info",
    options?: any
  ) => {
    options ? (options.toastId = customId) : (options = { toastId: customId });
    if (options && !options.icon) {
      options.icon = false;
    }
    toast[type](message, options);
  };

  return { showToast };
};

export default useAppToast;
