import { useMemo, useState } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

//Chakra
import { Avatar, Box, Flex, Image, Skeleton, SkeletonCircle, SkeletonText, Spinner, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import BackBtn from "components/common/back-btn/BackBtn";
import { UTCConverter } from "lib/utils/time/UTCConverter";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { useNavigator } from "functions/hooks/navigator/useNavigator";

//Data
import { useUserStore } from "lib/stores/user/UserStore";

//Enums
import { UserTicketStatusEnum } from "types/enums/UserTicketEnums";
import { useProfile } from "functions/hooks/user/useProfile";

const EventPage = () => {
  const userId = useUserStore((state) => state.user?.id);
  const jwtToken = useUserStore((state) => state.jwtToken);
  const { getUserAttendEvents, getNewEventDetails, eventDetailViaTicketId } = useEvents();
  const { getCurrentUserData } = useProfile();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const location = useLocation()
  const { shareLink } = useNavigator();
  const [event, setEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const [bought, setBought] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<any>([])
  const [showAllAttendees, setShowAllAttendees] = useState(false);


  const isEventExpired = (startDate: string) => {
    const eventDate = new Date(startDate);
    const today = new Date();
    return eventDate < today;
  }

  function calculateSoldAndTotal(items: any) {
    let totalSold = 0;
    let totalTicket = 0;
  
    items?.forEach((item: any) => {
      if (item.sold !== undefined && item.total !== undefined) {
        totalSold += item.sold;
        totalTicket += item.total;
      }
    });
  
    return { totalSold, totalTicket };
  }


  useMemo(async () => {
    if (eventId) {
      setLoading(true);
      const res = await getNewEventDetails(eventId);
      const userTickets = await getUserAttendEvents();
      setCurrentUserData(await getCurrentUserData());
      if (userTickets && userTickets?.length > 0) {
        const indexFinder = userTickets?.findIndex((userEvents) => userEvents?._id === res?.event?._id);
        setBought(indexFinder === -1 ? false : true);
      }else {
        setBought(false)
      }

      setEvent(res);
      setLoading(false);
    }
  }, [eventId]);

  const buttonHandler = () => {
    jwtToken ? (!bought ? navigate(`/user/purchase/${event?.event?._id}`) : navigate(`/user/ticketDetail/${event?.event?._id}`)) : navigate(`/signup`);
  };

  
  const { totalSold, totalTicket } = calculateSoldAndTotal(event?.event?.tickets);

  return (
    <Box>
      <BackBtn />
      
          <Flex position={"relative"} flexDirection={"column"} gap={"12px"} margin={"10px 0 80px 0"}>
            <Flex flexDirection={"column"} bg={"gray.900"} color={"gray.0"} rounded={"16px"} padding={"16px"} gap={"16px"}>
              {loading || !event?.event?.banner ? 
              <Skeleton width={'100%'} height={'300px'} rounded={"8px"} />
              :
              <Image rounded={"8px"} alt="event banner" src={event?.event?.banner} />
              }
              <Flex
                flexDirection={'column'}
                alignItems={'flex-start'}
                alignSelf={'stretch'}
                gap={'8px'}
              >
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  {loading ? 
                  <Skeleton width={'200px'} height={'20px'} />
                  :
                  <Text id="capture" fontSize={'30px'} fontWeight={600} fontFamily={'AVSB'} color={'gray.0'} overflowWrap={'break-word'} wordBreak={'break-word'}>{event?.event?.title}</Text>
                  }
                  {event?.event?.title && !(event?.event?.status === "INTRODUCTION" || event?.event?.status === "TICKETS" || event?.event?.status === "RULES" || event?.event?.status === "PAYMENT_METHOD") && 
                  <Flex cursor={'pointer'} onClick={() => shareLink(`${window.location.origin}/user/events/${event?.event?._id}`, event?.event?.title, "Check out this event:")}>
                    <AppIcons.Share2/>
                  </Flex>
                  }
                </Flex>
                <Flex alignItems={"center"} gap={"4px"} alignSelf={"stretch"}>
                    {loading ? 
                    <SkeletonCircle width={'24px'} height={'24px'} />
                    :
                    <Avatar
                      bgColor={"primary.600"}
                      src={event?.event?.creator?.image}
                      name={event?.event?.creator?.name}
                      size={'xs'}
                      width={"24px"}
                      height={"24px"}
                    />
                    }
                    <Text
                      fontSize={"14px"}
                      fontFamily={"AVR"}
                      fontWeight={400}
                      color={"gray.400"}
                    >
                      Hosted by
                    </Text>
                    {loading ?
                    <Skeleton width={'50px'} height={'15px'} />
                    :
                    <Link to={event?.event?.creator?.id === userId ? `/user/profile`  : `/user/profile/${event?.event?.creator?.id}`}>
                      <Text
                        fontSize={"14px"}
                        fontFamily={"AVSB"}
                        fontWeight={600}
                        color={event?.event?.creator?.id === userId ? 'primary.600' : "gray.0"}
                      >
                        {event?.event?.creator?.id === userId ? "You" : event?.event?.creator?.name}
                      </Text>
                    </Link>
                    }
                </Flex>
              </Flex>
              <IconInfoBox boxIcon={<AppIcons.GreenCalendar />} label="Date" primaryTitle={event?.event?.start && UTCConverter(event?.event?.start)} secondaryTitle={event?.event?.end && UTCConverter(event?.event?.end)} />
              <IconInfoBox
                boxIcon={<AppIcons.GreenLocation />}
                label="Location"
                primaryTitle={event?.event?.location === "iran" ? "canada" : event?.event?.location}
                // secondaryTitle="08:00PM - 10:00PM GMT -7"
              />
            </Flex>
            <Flex flexDirection={"column"} bg={"gray.900"} color={"gray.0"} rounded={"16px"} padding={"16px"} gap={"16px"}>
              <Text color={"gray.0"} fontSize={"18px"} fontFamily={"AVSB"} fontWeight={600} textAlign={"left"}>
                About
              </Text>
              {loading ?
              <SkeletonText noOfLines={3} spacing='4' skeletonHeight='2' />
              :
              <Text color={"gray.0"} fontSize={"14px"} fontFamily={"AVR"} fontWeight={400} textAlign={"left"}>
                {event?.event?.description}
              </Text>
              }
            </Flex>
            <Flex flexDirection={"column"} bg={"gray.900"} color={"gray.0"} rounded={"16px"} padding={"16px"} gap={"16px"}>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text color={"gray.0"} fontSize={"18px"} fontFamily={"AVSB"} fontWeight={600} textAlign={"left"}>
                  Guests ({event?.attendees?.length || 0})
                </Text>
                {event?.event?.creator?.id === userId && 
                  event?.attendees?.length > 5 && <Text color={"primary.600"} fontSize={"14px"} fontFamily={"AVSB"} fontWeight={600} onClick={() => setShowAllAttendees(!showAllAttendees)}>{showAllAttendees ? "Collapse" : "See All"}</Text>
                }
              </Flex>
              {event?.event?.creator?.id === userId && 
              event?.attendees?.length > 0 && (
              <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'16px'}>
                  {event?.attendees?.slice(0, showAllAttendees ? undefined : 5).map((users: any) => (
                    <Flex key={users.userID} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
                      <Flex key={users.userID} alignItems={'center'} gap={'12px'} width={'100%'}>
                        <Avatar bgColor={"primary.600"} src={users.image} width={'24px'} height={'24px'} />
                        <Link to={users.userID === userId ? `/user/profile` : `/user/profile/${users.userID}`}>
                          <Text fontSize={'14px'} fontFamily={'AVR'} fontWeight={400} color={users.userID === userId ? 'primary.600' : 'gray.0'} flex={'1 0 0'}>
                            {users.userID === userId ? "You" : users.name}
                          </Text>
                        </Link>
                      </Flex>
                      {users.status === UserTicketStatusEnum.CHECKEDIN && <AppIcons.CheckRegular />}
                    </Flex>
                  ))}
              </Flex>
            )}
            </Flex>
            {isEventExpired(event?.event?.start) ? (
              <PrimaryBtn btnText={"Event has expired"} btnOnClick={buttonHandler} btnTextColor={"primary.900"} btnHasBg={true} btnIsDisabled={true} />
            ) : (event?.event?.status === "INTRODUCTION" || event?.event?.status === "TICKETS" || event?.event?.status === "RULES" || event?.event?.status === "PAYMENT_METHOD") ?
              <PrimaryBtn btnText={"Awaiting completion"} btnOnClick={buttonHandler} btnTextColor={"primary.900"} btnHasBg={true} btnIsDisabled={true} />
            : loading ? (
              <PrimaryBtn btnText={"Loading..."} btnOnClick={buttonHandler} btnTextColor={"primary.900"} btnHasBg={true} btnIsDisabled={true} />
            ) : userId === event?.event?.creator?.id ? (
              <></>
            ) : bought ? (
              <PrimaryBtn btnText={"Check Ticket"} btnOnClick={buttonHandler} btnTextColor={"primary.900"} btnHasBg={true} />
            ) : totalSold === totalTicket ? (
              <PrimaryBtn btnText={"Sold out"} btnOnClick={buttonHandler} btnTextColor={"primary.900"} btnHasBg={true} btnIsDisabled={true} />
            ) : (
              <PrimaryBtn btnText={jwtToken ? "RSVP" : "Create Account"} btnOnClick={buttonHandler} btnTextColor={"primary.900"} btnHasBg={true} />
            )}
          </Flex>
      
    </Box>
  );
};

export default EventPage;
