import React, { useState } from "react";

//Chakra
import { Divider, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import PaymentMethodItem from "./PaymentMethodItem";
import SummaryItem from "./SummaryItem";
import { Vaul } from "components/common/vaul/Vaul";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";

const ChoosePaymentModal = ({
  showModal,
  setShowModal,
  total,
  event,
  price,
  checkUserCredentials,
  quantity,
  walletAddress,
  polygonPrice,
  totalPolygonPrice,
  paymentMethod,
  setPaymentMethod,
  isLoading,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  total: number;
  event: any;
  price: number;
  checkUserCredentials: () => Promise<void>;
  quantity: number;
  walletAddress: any | string;
  polygonPrice: number;
  totalPolygonPrice: number;
  paymentMethod: number;
  setPaymentMethod: any;
  isLoading: boolean;
}) => {
  const shortedWalletAddress = walletAddress && `${walletAddress?.slice(0, 4)}...${walletAddress?.slice(38, )}`;
  
  return (
    <Vaul isOpen={showModal} setIsOpen={setShowModal} headerTitle="Complete purchase">
        <>
          <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"} fontFamily={"AVSB"}>
            Pay with
          </Text>
          <PaymentMethodItem paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} icon={<AppIcons.CreditCard />} title={"Credit/debit card"} totalPrice={total} paymentMethodNumber={1}/>
          <PaymentMethodItem paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} icon={<AppIcons.MATICToken />} title={walletAddress ? shortedWalletAddress : "Sign your wallet address"} totalPrice={totalPolygonPrice} paymentMethodNumber={2} hasWalletAddress={walletAddress ? true : false}/>
          <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"} marginTop={"15px"}>
            Summary
          </Text>
          <Flex mb={'70px'} border={"1px solid #434343"} borderRadius={"12px"} display={"flex"} flexDirection={"column"} marginTop={"15px"} padding={"10px"} gap={"16px"}>
            <SummaryItem leftCol={`${event?.title} × ${quantity}`} rightCol={price === 0 ? "Free" : paymentMethod === 1 ? `${price} USD` : `${polygonPrice} MATIC`} />
            <SummaryItem 
              leftCol={"Pay with"} 
              rightCol={paymentMethod === 1 ? "Credit/debit card"
                : 
                (
                  <Flex alignItems={"center"} gap={"4px"}>
                    <AppIcons.MATICToken />
                    <Text fontSize={"12px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
                      {walletAddress ? shortedWalletAddress : "Sign your wallet address"}
                    </Text>
                  </Flex>
                )
              } 
            />
            <SummaryItem 
              leftCol={(
                <Flex alignItems={"center"} gap={"4px"}>
                    <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"gray.0"}>
                      Fee{" "}
                      <Tooltip
                        hasArrow
                        label="This is an estimated fee."
                        placement="right"
                        fontWeight={"400"}
                        fontSize={"12px"}
                        fontFamily={"IM"}
                        lineHeight={"16px"}
                        borderRadius={"8px"}
                        padding={"8px 12px"}
                        textAlign={"center"}
                        alignSelf={"center"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <IconButton
                          margin={0}
                          padding={0}
                          height={0}
                          minWidth={0}
                          _hover={{ bg: "transparent" }}
                          bg={"transparent"}
                          aria-label="document"
                          icon={<AppIcons.SmallInfo />}
                          //   onClick={}
                        />
                      </Tooltip>
                    </Text>
                  </Flex>
                  )} 
              rightCol={paymentMethod === 1 ? (
                <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"gray.0"}>
                  $ 0
                </Text>
              ) : (
                <Flex flexDirection={"column"} alignItems={"flex-end"}>
                  <Text fontSize={"12px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
                    0.00006 MATIC
                  </Text>
                  <Text fontSize={"10px"} fontWeight={400} fontFamily={"AVR"} color={"gray.400"}>
                    ~0.0002 USD
                  </Text>
                </Flex>
              )} 
            />
            
            <Divider height={"0.75px"} bg={"#434343"} orientation="horizontal" />
            <Flex display={"flex"} justifyContent={"space-between"}>
              <Text display={"flex"} fontSize={"14px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"}>
                total
              </Text>
              {total === 0 ? 
                <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"}>
                  Free
                </Text> 
                : 
              paymentMethod === 1 ? (
                <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"}>
                  {`${total} USD`}
                </Text>
              ) : (
                <Flex flexDirection={"column"} alignItems={"flex-end"}>
                  <Text fontSize={"12px"} fontWeight={600} fontFamily={"AVSB"} color={"gray.0"}>
                    {`${totalPolygonPrice} MATIC`}
                  </Text>
                  <Text fontSize={"10px"} fontWeight={400} fontFamily={"AVR"} color={"gray.400"}>
                    {`~${total} USD`}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          <PrimaryBtn 
            btnText={`Pay ${paymentMethod === 1 ? `${total} USD` : `${totalPolygonPrice} MATIC`}`} 
            btnOnClick={checkUserCredentials}
            btnTextColor="primary.900"
            isModalBtn={true}
            btnIsLodaing={isLoading}
            btnIsDisabled={isLoading}
          />
        </>
    </Vaul>
  );
};

export default ChoosePaymentModal;
