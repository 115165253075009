//Chakra
import { Flex , Text } from '@chakra-ui/react'
import AppIcons from 'assets/icons/AppIcons';


const Stepper = ({step} : { step: any }) => {

    const steps = [
        {
            id : 1,
            title : 'Event details',
        },
        {
            id : 2,
            title : 'Ticket details',
        },
        {
            id : 3,
            title : 'Ticket rulesets',
        },
        {
            id : 4,
            title : 'Payment methods',
        },
        {
            id : 5,
            title : 'Deploy event',
        },
    ]

    return (
        <Flex
            flexDirection={'column'}
            alignItems={'flex-start'}
            gap={'12px'}
        >
            {steps.map((item) => (
                <Flex
                    key={item.id}
                    width={'180px'}
                    padding={'12px'}
                    alignItems={'center'}
                    gap={'8px'}
                    bgColor={step === item.id ? 'green.800' : ''}
                    borderRadius={'8px'}
                    cursor={'default'}
                >
                    <Flex
                        width={'20px'}
                        height={'20px'}
                        padding={'3.333px 0px 2.667px 0px'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexShrink={'0'}
                        borderRadius={'50%'}
                        bgColor={item.id >= step ? (step === item.id ? 'gray.300' : 'gray.700') : "primary.600"}
                    >
                        {item.id >= step ? 
                            <Text fontSize={'10px'} fontWeight={600} fontFamily={'AVR'} color={step === item.id ? 'gray.1000' : 'gray.600'}>{item.id}</Text>
                            :
                            <AppIcons.CheckCircle3/>
                        }
                    </Flex>
                    <Text fontSize={'14px'} fontFamily={'AVR'} fontWeight={600} color={step === item.id ? 'gray.0' : 'gray.600'}>{item.title}</Text>
                </Flex>
            ))}
        </Flex>
    );
};

export default Stepper;