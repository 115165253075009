import { useState } from "react";
import { useNavigate } from "react-router-dom";

//Chakra
import { Spinner, Flex, Text, Box, Button, Modal, useDisclosure, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Components
import EventItem from "../parts/eventItem/EventItem";

//Interfaces
import { IEvents } from "lib/stores/user/UserStore";

//Icons
import AppIcons from "assets/icons/AppIcons";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";
import ConfirmationModal from "components/common/appModal/ConfirmationModal";
import EventSkeleton from "components/common/event-card-skeleton/EventSkeleton";

const UserEvents = ({
  events,
  removeDeletedEvent,
  userPaymentMethods,
  isLoading,
}: {
  events: IEvents[];
  removeDeletedEvent: (eventId: string) => void;
  userPaymentMethods: any;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCreateEvent = () => {
    const isUserHaveWalletAddress = userPaymentMethods?.filter((item: any) => item?.name === "POLYGON")?.[0]?.address;
    if (isUserHaveWalletAddress === undefined || isUserHaveWalletAddress === '') {
      onOpen()
    } else {
      navigate(`/user/create/info`, {state: {isFromMyEvents: true}});
    }
  };

  const skeletonArray = Array.from({ length: 10 });

  return (
    <Box position={"relative"} paddingBottom={"20px"}>
      {isLoading ? (
        <Flex flexDirection={"column"} padding={"16px"} gap={"12px"} rounded={"8px"} bg={"gray.900"} marginBottom={"20px"}>
        {skeletonArray.map((_, index) => (
          <EventSkeleton key={index} />
        ))}
      </Flex>
      ) : events.length > 0 ? (
        <>
          <EventItem events={events} removeDeletedEvent={removeDeletedEvent} />
        </>
      ) : (
        <Flex
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"12px"}
          padding={"16px"}
          borderRadius={"8px"}
          bgColor={"gray.900"}
          boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
        >
          <Flex flexDirection={"column"} alignItems={"center"} alignSelf={"stretch"} gap={"12px"} padding={"32px 0"}>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              gap={"10px"}
              width={"40px"}
              height={"40px"}
              padding={"4px"}
              borderRadius={"12px"}
              bgColor={"gray.700"}
            >
              <AppIcons.Stars />
            </Flex>
            <Text fontSize={"16px"} fontWeight={600} fontFamily={"AVR"} color={"gray.0"}>
              No events created, yet
            </Text>
          </Flex>
        </Flex>
      )}
      <PrimaryBtn
        btnText={isLoading ? <Spinner color="white" /> : "+ Create event"}
        btnOnClick={handleCreateEvent}
        btnTextColor={"gray.0"}
        btnHasBg={true}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor={'gray.900'} width={'90%'} alignSelf={'center'}>
          <ModalHeader>
              <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>
                Set up payment method
              </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <Text color={'gray.600'} fontFamily={'IM'} fontSize={'14px'} fontWeight={400} alignSelf={'stretch'}>
                Set up at least a payment method to proceed with the event creation.
              </Text>
          </ModalBody>

          <ModalFooter display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'16px'} width={'100%'}>
              <Button 
              bgColor={'green.800'} 
              color={'gray.0'}
              width={'100%'}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              onClick={() => {
                  onClose()
              }}
              >
                  Close
              </Button>
              <Button
              bgColor={"primary.600"}
              color={"primary.900"}
              width={'100%'}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              _hover={{ bgColor: "primary.600" }}
              _disabled={{ bgColor: "gray.900", color: "gray.700" }}
              _active={{ bgColor: "primary.900" }}
              onClick={() => {
                  navigate(`/user/settings`)
                  onClose()
              }}
              >
                  Set up now
              </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default UserEvents;
