import { Link, Outlet, useNavigate } from 'react-router-dom';

//Chakra
import { Flex, Text, Avatar, Button } from '@chakra-ui/react'

//Data
import { useUserStore } from 'lib/stores/user/UserStore';


const HeaderLayout = () => {
    const jwtToken = useUserStore((state) => state.jwtToken);
    const user = useUserStore((state) => state.user);

    const navigate = useNavigate();

    const btnClickHandler = (btnName: string) => {
        if (btnName === 'login') {
            navigate('/signin')
        } else if (btnName === 'register') {
            navigate('/signup')
        }
    }

    return (
        <>
            {/* Header */}
            <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100vw'}
                padding={'20px 40px'}
                bgColor={'dark.bg'}
                backdropBlur={'12px'}
                borderBottom={'1px solid #24292E'}
            >
                <Link to={'/'}>
                    <Text fontSize={'20px'} fontFamily={'AVR'} fontWeight={700} textAlign={'center'} color={'primary.600'}>droplinked</Text>
                </Link>

                {jwtToken ? 
                <Flex alignItems={'center'} gap={'12px'}>
                    <Flex
                        alignItems={'flex-start'}
                        padding={'6px'}
                        borderRadius={'50%'}
                        border={'1px solid #24292E'}
                        bgColor={'dark.interactive'}
                        boxShadow={'0px 1px 3px 0px'}
                        cursor={'pointer'}
                    >
                        <Flex
                            width={'20px'}
                            height={'20px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            color={'dark.900'}
                        >
                            <Text fontSize={'16px'} fontWeight={400} fontFamily={'AVR'}>?</Text>
                        </Flex>
                    </Flex>
                    <Flex
                        alignItems={'center'}
                        gap={'8px'}
                    >
                        <Link to={'/user/profile'}>
                            <Text fontSize={'12px'} fontFamily={'AVR'} fontWeight={600} color={'dark.700'} textAlign={'center'}>{user.name}</Text>
                        </Link>
                        {user.image ? 
                            <Avatar bgColor={"primary.600"} src={user.image} width={'32px'} height={'32px'} /> 
                            : 
                            <Avatar bgColor={"primary.600"} width={'32px'} height={'32px'} />
                        }
                    </Flex>
                </Flex>
                :
                <Flex alignItems={'center'} gap={'12px'}>
                    <Button display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'8px 12px'} borderRadius={'8px'} bgColor={'green.800'} color={'gray.0'} height={'32px'} _hover={{ bg: "green.800" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'gray.900', color: 'gray.100'}} onClick={() => btnClickHandler('login')} fontFamily={'IM'} fontSize={'14px'} fontWeight={500}>Login</Button>
                    <Button display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'8px 12px'} borderRadius={'8px'} bgColor={'primary.600'} color={'primary.900'} height={'32px'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} onClick={() => btnClickHandler('register')} fontFamily={'IM'} fontSize={'14px'} fontWeight={500}>Register</Button>
                </Flex>
                }
            </Flex>

            {/* Content */}
            <Flex alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} width={'100%'} padding={'48px 285px 30px 285px'}>
                <Outlet/>
            </Flex>
        </>
    );
};

export default HeaderLayout;