import { Box, Flex, Input, Tag, TagCloseButton, TagLabel, Textarea } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import { ethers } from "ethers";
import { FormikErrors } from "formik";
import useAppToast from "functions/hooks/toast/useToast";
import { useState } from "react";
import { nftIdentifierSchema } from "schemas/schema";
import * as yup from "yup";
const Identifiers = ({
    index,
    nft_identifiers,
    setFieldValue,
}: {
    index: number;
    nft_identifiers: string[];
    setFieldValue: (field: string, value: string[], shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
}) => {
    const [textArea, setTextArea] = useState<string>("");
    const { showToast } = useAppToast();
    const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") return null;
        event.preventDefault();
        const isValid = await nftIdentifierSchema.isValid(textArea);
        const isUnique = !nft_identifiers.includes(textArea);
        if (isUnique && textArea.trim() !== "" && isValid) {
            setFieldValue(`rules.${index}.nft_identifiers`, nft_identifiers.concat(textArea));
            setTextArea("");
        } else {
            showToast(!isValid ? "The address you provided appears to be invalid." : "Duplicate Entry", 'warning');
        }
    };

    const removeIdentifier = (identifier: string) =>
        setFieldValue(
            `rules.${index}.nft_identifiers`,
            nft_identifiers.filter((each) => each !== identifier)
        );

    return (
        <Flex backgroundColor="gray.1000" width={"full"} padding={6} gap={3} flexWrap="wrap" rounded={"8px"} alignItems="baseline" justifyContent={"start"}>
            {nft_identifiers?.length && Array.isArray(nft_identifiers)
                ? nft_identifiers?.map((identifier: string) => (
                      <Tag
                          key={identifier}
                          padding="4px 8px"
                          borderRadius="100px"
                          variant="solid"
                          cursor="pointer"
                          bgColor={"primary.600"}
                          color={"primary.900"}
                          fontFamily={"AVSB"}
                          maxW={{base:"40%", sm: '25%'}}
                          fontWeight={400}
                          fontSize={"10px"}
                          display={"flex"}
                          justifyContent={"space-between"}
                      >
                          <TagLabel w={"auto"}>{identifier}</TagLabel>
                          <AppIcons.Close
                              style={{ maxWidth: "1.5rem", maxHeight: "1.5rem", minWidth: "1.2rem", minHeight: "1.2rem", width: "30%", height: "auto" }}
                              onClick={() => removeIdentifier(identifier)}
                          />
                      </Tag>
                  ))
                : null}
            <Input
                variant="unstyled"
                color={"gray.0"}
                bg={"gray.1000"}
                width={`${textArea.length * 8}px`}
                minW={'180px'}
                fontSize={"12px"}
                fontFamily={"AVR"}
                id="description"
                placeholder="Copy and paste wallet address"
                name={`rules.${index}.nft_identifiers`}
                value={textArea}
                border={"none"}
                _placeholder={{ color: "gray.800" }}
                onChange={(e) => setTextArea(e.target.value)}
                onKeyDown={handleInputKeyDown}
            />
        </Flex>
    );
};

export default Identifiers;
