import { IEvents } from "lib/stores/user/UserStore";
import { local_time } from "./utils";

export interface IInfoPageValues {
    event: string;
    title: string;
    description: string;
    banner: string;
    location: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    eventId: string;
}
export const getEvent = async ({
    eventId,
    getUserEvents,
    setInitialValues,
}: {
    eventId: string | undefined;
    getUserEvents: () => Promise<IEvents[] | undefined>;
    setInitialValues: React.Dispatch<React.SetStateAction<IInfoPageValues>>;
}) => {
    const events = await getUserEvents();
    const filteredEventData = events && events.filter((event) => event._id === eventId);
    if (events && filteredEventData) {
        const local_times = local_time(filteredEventData[0].start, filteredEventData[0].end);
        return setInitialValues({
            event: "Event",
            title: filteredEventData[0].title || "",
            description: filteredEventData[0].description || "",
            banner: filteredEventData[0].banner,
            location: filteredEventData[0].location || "",
            startDate: local_times.start.date,
            startTime: local_times.start.time,
            endDate: local_times.end.date,
            endTime: local_times.end.time,
            eventId: filteredEventData[0]._id || "",
        });
    }else return initials()
};

export const initials = (): IInfoPageValues => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns 0-indexed month
    const currentDay = String(currentDate.getDate()).padStart(2, "0");
    const currentHours = String(currentDate.getHours()).padStart(2, "0");
    const currentMinutes = String(currentDate.getMinutes()).padStart(2, "0");
    const defaultStartDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const defaultStartTime = `${currentHours}:${currentMinutes}`;

    // Calculate default end time
    const endDate = new Date(currentDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours to start time
    let defaultEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;
    let defaultEndTime = `${String(endDate.getHours()).padStart(2, "0")}:${String(endDate.getMinutes()).padStart(2, "0")}`;

    // Check if end time is on the next day
    if (endDate.getDate() > currentDate.getDate()) {
        defaultEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;
    }

    return {
        event: "Event",
        title: "",
        description: "",
        banner: "",
        location: "",
        startDate: defaultStartDate,
        startTime: defaultStartTime,
        endDate: defaultEndDate,
        endTime: defaultEndTime,
        eventId: "",
    };
};

