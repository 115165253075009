import { useMemo, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

//Chakra
import { Avatar, Button, Flex, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Skeleton, SkeletonCircle, Spinner, Text, useDisclosure } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { useNavigator } from "functions/hooks/navigator/useNavigator";

//Data
import { useUserStore } from "lib/stores/user/UserStore";

//Enums
import { useProfile } from "functions/hooks/user/useProfile";
import TicketInfoDetail from "pages/profile/ticketDetail/part/TicketInfoDetail";
import BackBtn from "components/common/back-btn/BackBtn";
import QrCodeScanner from "../qr-code-scanner/QrCodeScanner";

const TicketDetail = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const userId = useUserStore((state) => state.user?.id);
    const { shareLink } = useNavigator();
    const navigate = useNavigate();
    const { eventId } = useParams();
    const { getEventDetail, getUserAttendEvents, attendeesDataWithEventId, getNewEventDetails } = useEvents();
    const { getCurrentUserData,getUsersProfileDetail } = useProfile();
    const [data, setData] = useState<any>({
        event: null,
        loading: false,
        showModal: false,
        bought: false,
        currentUserData: null,
        userAttendData: null,
        userAttendId: null,
        isUserHostEvent: false,
        newEvent: null,
        walletAddress: '',
        attendQrCode: null,
        userProfileDetail: null,
        eventAttendeesData: null,
    });

    
    const findUserAttendId = async (currentUserAttendancesData: any) => {
        // let commonAttendeesIds: any = [];
        if (currentUserAttendancesData) {
            currentUserAttendancesData?.map((item: any) => {
                if (item.eventID === eventId) {
                setData((prevData: any) => ({ ...prevData, attendQrCode: item.qrcode }));
                }
            })
        }
        // Check if commonAttendeesIds is not undefined before calling attendeesData
        // if (commonAttendeesIds) {
        // console.log(commonAttendeesIds);
        // console.log(commonAttendeesIds.join().split(',')[0]);
        // const attendId = commonAttendeesIds.length > 0 ? commonAttendeesIds.join().split(',')[0] : commonAttendeesIds.join() 
        // setData((prevData: any) => ({ ...prevData, userAttendId: attendId }));
    
        // const userAttendData = await attendeesData(attendId);
        // setData((prevData: any) => ({ ...prevData, userAttendData }));
        // }
    };

    const isHostEvent = (event: any, currentUser: any, currentUserProfileData: any) => {
        if (event && currentUser) {
        if (event?.creator === currentUser?.id) {
            setData((prevData: any) => ({ ...prevData, isUserHostEvent: true }));
        }else {
            findUserAttendId(currentUserProfileData?.attendances);
        }
        }
    }

    useMemo(async () => {
        if (eventId) {
            setData((prevData: any) => ({ ...prevData, loading: true }));
            const res = await getEventDetail(eventId);
            const newEventDetails = await getNewEventDetails(eventId)
            const userTickets = await getUserAttendEvents();
            const currentUserData = await getCurrentUserData();
            const currentUserProfileData = await getUsersProfileDetail(userId);
            const attendeesData = !(res?.creator === currentUserData?.id) && await attendeesDataWithEventId(eventId);
            const indexFinder = userTickets?.findIndex((userEvents) => userEvents._id === res.id);
            let polygonAddress = currentUserData?.paymentMethods?.filter((item: any) => item.name === "POLYGON")[0]?.address
            isHostEvent(res, currentUserData, currentUserProfileData)
            setData((prevData: any) => ({ ...prevData, currentUserData, bought: indexFinder === -1 ? false : true, event: res, newEvent: newEventDetails, walletAddress: polygonAddress, userProfileDetail: currentUserProfileData, eventAttendeesData: attendeesData }));
            setData((prevData: any) => ({ ...prevData, loading: false }));
        }

    }, [eventId]);
    
    const buttonHandler = () => {
        navigate(`/user/events/${data.event?._id}`)
    };

    console.log(data);

    const shortedWalletAddress = data.walletAddress && `${data.walletAddress?.slice(0, 4)}...${data.walletAddress?.slice(38, )}`;

    return (
        <>
            <Flex flexDirection={'column'} gap={'16px'}>
                <BackBtn/>
                <Flex alignItems={'flex-start'} flexDirection={'column'} alignSelf={'Stretch'} gap={'24px'}>
                    <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'16px'} padding={'16px'} borderRadius={'16px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                        <Flex width={'838px'} height={'240px'} flexShrink={'0'} position={'relative'}>
                            {data.loading ? 
                            <Skeleton width={'100%'} height={'100%'} borderRadius={'8px'}/> 
                            : 
                            <Image src={data.event?.banner} objectFit={'cover'} width={'100%'} height={'100%'} borderRadius={'8px'} />
                            }
                            {!data.loading && 
                            <Flex justifyContent={'center'} alignItems={'center'} gap={'8px'} padding={'10px'} bgColor={'green.800'} borderRadius={'8px'} position={'absolute'} top={'8px'} right={'8px'} cursor={'pointer'} onClick={() => shareLink(`${window.location.origin}/user/events/${data.event?._id}`, data.event?.title, "Check out this event:")}>
                                <AppIcons.Share2/>
                            </Flex>
                            }
                            <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} padding={'8px'} borderRadius={'10px'} bgColor={'rgba(41, 41, 41, 0.80)'} position={'absolute'} top={'8px'} left={'8px'}>
                                <Flex alignItems={'center'} alignSelf={'Stretch'} gap={'4px'}>
                                    {data.loading ? 
                                    <SkeletonCircle width={'24px'} height={'24px'} />
                                    :
                                    <Avatar
                                        bgColor={"primary.600"}
                                        src={data.newEvent?.event?.creator?.image}
                                        width={"24px"}
                                        height={"24px"}
                                    />}
                                    <Text
                                        fontSize={"14px"}
                                        fontFamily={"AVR"}
                                        fontWeight={400}
                                        color={"gray.400"}
                                    >
                                        Hosted by
                                    </Text>
                                    {data.loading ? 
                                    <Skeleton width={'50px'} height={'20px'} />
                                    :
                                    <Link to={data.newEvent?.event?.creator?.id === userId ? `/user/profile`  : `/user/profile/${data.newEvent?.event?.creator?.id}`}>
                                        <Text
                                            fontSize={"14px"}
                                            fontFamily={"AVSB"}
                                            fontWeight={600}
                                            color={data.newEvent?.event?.creator?.id === userId ? 'primary.600' : "gray.0"}
                                        >
                                            {data.newEvent?.event?.creator?.id === userId ? "You" : data.newEvent?.event?.creator?.name}
                                        </Text>
                                    </Link>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>

                        {data.loading ? 
                        <Skeleton width={'160px'} height={'35px'} />
                        :
                        <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'30px'} fontWeight={600} overflowWrap={'break-word'} wordBreak={'break-word'}>{data.event?.title}</Text>
                        }
                        <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'8px'}>
                            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Ticket detail</Text>
                            <TicketInfoDetail event={data.event} walletAddress={shortedWalletAddress} isUserHostEvent={data.isUserHostEvent} orderData={data.eventAttendeesData?.orderID} />
                        </Flex>
                        
                        <Flex flexDir={"column"} gap={"8px"} width={'100%'}>
                            <Text
                                color={"gray.0"}
                                fontSize={"14px"}
                                fontFamily={"AVSB"}
                                fontWeight={600}
                                textAlign={"left"}
                            >
                                {data.isUserHostEvent ? "Scan QR coed" : "QR code"}
                            </Text>
                            <Flex
                                gap={"12px"}
                                padding={"8px"}
                                border={"1px"}
                                borderColor={"gray.700"}
                                alignItems={"center"}
                                rounded={"12px"}
                                width={"full"}
                                justifyContent={'space-between'}
                            >
                                <Flex alignItems={'center'} gap={'12px'}>
                                    <IconButton
                                        _hover={{ bg: "transparent" }}
                                        bg={"transparent"}
                                        aria-label="document"
                                        icon={<AppIcons.QRcodePT />}
                                        cursor={'default'}
                                    />
                                    <Flex flexDir={"column"} gap={"4px"}>
                                        {data.loading ? 
                                        <Skeleton width={"100px"} height={"15px"} />
                                        :
                                        <Text
                                        color={"gray.0"}
                                        fontSize={"14px"}
                                        fontFamily={"AVSB"}
                                        fontWeight={600}
                                        textAlign={"left"}
                                        overflowWrap={'break-word'} 
                                        wordBreak={'break-word'}
                                        >
                                            {data.isUserHostEvent ? "Check in guests" : data.eventAttendeesData?.orderID?.type === "POLYGON" ? shortedWalletAddress : "Credit/debit card"}
                                        </Text>
                                        }
                                    </Flex>
                                </Flex>
                                {!data.loading && <Button color={'gray.0'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'6px'} height={'32px'} padding={'8px 12px'} borderRadius={'8px'} bgColor={'green.800'} fontFamily={'IM'} fontSize={'14px'} fontWeight={500} _hover={{ bg: "gray.700" }} onClick={onOpen}>{data.isUserHostEvent ? "Scan QR code" : "See QR code"}</Button>}
                            </Flex>
                        </Flex>

                    </Flex>

                    {data.loading ? 
                    <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500} isDisabled={true}>Loading...</Button>
                    :
                    <Button onClick={buttonHandler} width={'100%'} color={'primary.900'} bgColor={'primary.600'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} padding={'12px 16px'} borderRadius={'8px'} fontFamily={'IM'} fontSize={'16px'} fontWeight={500}>Go to event</Button>
                    }

                </Flex>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent flexDirection={'column'} alignItems={'flex-start'} borderRadius={'16px'} bgColor={'gray.900'} width={'418px'} alignSelf={'center'}>
                        <ModalHeader>
                            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>{data.isUserHostEvent ? "Scan QR code" : "QR code"}</Text>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody alignSelf={'center'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                                {data.isUserHostEvent ? 
                                <QrCodeScanner eventId={eventId}/>
                                :
                                <>
                                    <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'24x'} padding={'0 24px'}>
                                        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'16px'}>
                                            <Image width={'218px'} height={'218px'} src={data.attendQrCode} borderRadius={'8px'} />
                                            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'4px'}>
                                                <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>{data.event?.title}</Text>
                                                <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400}>{shortedWalletAddress || "Credit/debit card"}</Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'} padding={'16px 24px'}>
                                        {/* <Button bgColor={'primary.600'} color={'primary.900'} display={'flex'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'8px'} padding={'12px 16px'} borderRadius={'8px'} leftIcon={<AppIcons.WalletGreen />} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} fontFamily={"IM"} fontSize={"16px"} fontWeight={500}>Add to wallet</Button> */}
                                        <Button bgColor={'green.800'} color={'gray.0'} display={'flex'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'8px'} padding={'12px 16px'} borderRadius={'8px'} leftIcon={<AppIcons.Share />} _hover={{ bg: "green.800" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'gray.700', color: 'gray.0'}} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} onClick={() => shareLink(`${window.location.origin}/user/events/${data.event?._id}`, data.event?.title, "Check out this event:")}>Share</Button>
                                    </Flex>
                                </>
                                }
                        </ModalBody>                
                    </ModalContent>
                </Modal>
            </Flex>
        </>
    );
};

export default TicketDetail;