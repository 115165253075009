//Chakra
import { Spinner, Flex, Text } from "@chakra-ui/react";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Interfaces
import { IEvents } from "lib/stores/user/UserStore";

//Components
import EventItem from "../parts/eventItem/EventItem";
import AppIcons from "assets/icons/AppIcons";
import EventSkeleton from "components/common/event-card-skeleton/EventSkeleton";
// import { useState } from "react";

const AttendEvents = ({ events, removeDeletedEvent, isLoading }: { events: IEvents[]; removeDeletedEvent: (eventId: string) => void; isLoading: boolean}) => {
  // const [showConfirmModal, setShowConfirmModal] = useState(false);

  const skeletonArray = Array.from({ length: 10 });

  return isLoading ? (
    <Flex flexDirection={"column"} padding={"16px"} gap={"12px"} rounded={"8px"} bg={"gray.900"} marginBottom={"20px"}>
      {skeletonArray.map((_, index) => (
        <EventSkeleton key={index} />
      ))}
    </Flex>
  ) : events?.length > 0 ? (
    <EventItem events={events} isAttend={true} removeDeletedEvent={removeDeletedEvent} />
  ) : (
    <Flex
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"12px"}
      padding={"16px"}
      borderRadius={"8px"}
      bgColor={"gray.900"}
      boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
    >
      <Flex flexDirection={"column"} alignItems={"center"} alignSelf={"stretch"} gap={"12px"} padding={"32px 0"}>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          gap={"10px"}
          width={"40px"}
          height={"40px"}
          padding={"4px"}
          borderRadius={"12px"}
          bgColor={"gray.700"}
        >
          <AppIcons.FileSearch />
        </Flex>
        <Text fontSize={"16px"} fontWeight={600} fontFamily={"AVR"} color={"gray.0"}>
          No projects to show
        </Text>
      </Flex>
    </Flex>
  );
};

export default AttendEvents;
