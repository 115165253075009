import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import { useEvents } from "functions/hooks/events/useEvents";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useUserStore } from "lib/stores/user/UserStore";
import IconBox from "components/common/iconInfoBox/iconBox";
import AppModal from "components/common/appModal/AppModal";
import src from "./image/QR_code_for_mobile_English_Wikipedia 1.png";

const CheckTicket = () => {
  const { eventId } = useParams();
  const { getEventDetail } = useEvents();
  const [event, setEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useMemo(async () => {
    if (eventId) {
      setLoading(true);
      const res = await getEventDetail(eventId);
      setEvent(res);
      setLoading(false);
    }
  }, [eventId]);

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <Flex
          position={"relative"}
          flexDirection={"column"}
          gap={"12px"}
          mb={"80px"}
        >
          <Flex
            flexDirection={"column"}
            bg={"gray.900"}
            color={"gray.0"}
            rounded={"16px"}
            padding={"16px"}
            gap={"16px"}
          >
            <Image rounded={"8px"} alt="event banner" src={event?.banner} />
            <Text
              fontSize={"22px"}
              lineHeight={"28px"}
              fontStyle={"normal"}
              fontWeight={"700"}
            >
              Early bird
            </Text>
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontStyle={"normal"}
              fontWeight={"600"}
            >
              Ticket Details
            </Text>

            <Flex
              border={"1px solid #434343"}
              width={"98%"}
              height={"120px"}
              borderRadius={"5px"}
              display={"flex"}
              flexDirection={"column"}
              margin={"0 auto"}
            >
              <Flex
                width={"95%"}
                margin={"10px auto"}
                marginTop={"10px"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Text
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#fff"}
                >
                  Early bird x1
                </Text>
                <Text
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#fff"}
                >
                  98 USD
                </Text>
              </Flex>
              <Flex
                width={"95%"}
                margin={"10px auto"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Text
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#fff"}
                >
                  Pay with
                </Text>
                <Text
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#fff"}
                >
                  Credit/debit card
                </Text>
              </Flex>
              <Flex
                width={"95%"}
                margin={"10px auto"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Text
                  display={"flex"}
                  flexDirection={"row"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#fff"}
                >
                  Fee{" "}
                  <Text marginTop={"3px"} marginLeft={"5px"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Text>
                </Text>
                <Text
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"16px"}
                  color={"#fff"}
                >
                  $0
                </Text>
              </Flex>
            </Flex>
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontStyle={"normal"}
              fontWeight={"600"}
            >
              QR Code
            </Text>
            <Flex
              cursor={"pointer"}
              width={"98%"}
              height={"60px"}
              margin={"0 auto"}
              marginTop={"3px"}
              border={"1px solid #434343"}
              borderRadius={"10px"}
              display={"flex"}
              flexDirection={"row"}
              onClick={() => setShowModal(true)}
            >
              <Button
                marginTop={"8px"}
                marginLeft={"15px"}
                backgroundColor={"#434343"}
                width={"40px"}
                height={"40px"}
              >
                <IconBox boxIcon={<AppIcons.QRCode />} />
              </Button>
              <Flex
                marginLeft={"15px"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Text
                  marginTop={"20px"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"20px"}
                  color={"#fff"}
                >
                  SP3VC...5C4Q
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <AppModal isOpen={showModal} setIsOpen={setShowModal}>
            <Box
              backgroundColor={"#292929"}
              width={"100%"}
              height={"100%"}
              borderRadius={"25px"}
            >
              <Flex
                marginTop={"20px"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-evenly"}
                width={"100%"}
              >
                <Text
                  width={"90%"}
                  fontSize={"16px"}
                  marginTop={"10px"}
                  marginLeft={"15px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"24px"}
                  color={"white"}
                >
                  QR Code
                </Text>
                <Text
                  width={"10%"}
                  fontSize={"20px"}
                  marginTop={"10px"}
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  &times;
                </Text>
              </Flex>

              <Flex
                width={"95%"}
                display={"flex"}
                flexDirection={"column"}
                margin={"0 auto"}
                backgroundColor={"#fff"}
                maxWidth={"218px"}
                maxHeight={"218px"}
                marginTop={"30px"}
              >
                <Flex>
                  <Image src={src}></Image>
                </Flex>
                <Flex
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  marginTop={"20px"}
                >
                  <Text
                    fontSize={"18px"}
                    fontWeight={"600"}
                    lineHeight={"28px"}
                    fontStyle={"normal"}
                    color={"#fff"}
                  >
                    Ticket NO.
                  </Text>
                  <Text
                    marginTop={"10px"}
                    fontSize={"14px"}
                    fontWeight={"400"}
                    lineHeight={"20px"}
                    fontStyle={"normal"}
                    color={"#fff"}
                  >
                    SP3VC...5C4Q
                  </Text>
                </Flex>
              </Flex>
              <Text marginTop={"10px"}>
                <Link to={`/purchase/receive/650be4778c2dfc768445318b`}>
                  <Button
                    bg={"primary.600"}
                    color={"gray.0"}
                    width={"full"}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    position={"absolute"}
                    bottom={"30%"}
                  >
                    <IconBox boxIcon={<AppIcons.Wallet />} />
                    Add to wallet
                  </Button>
                </Link>
              </Text>
              <Text marginTop={"10px"}>
                <Link to={`/purchase/receive/650be4778c2dfc768445318b`}>
                  <Button
                    bg={"gray.600"}
                    color={"gray.0"}
                    width={"full"}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    position={"absolute"}
                    bottom={"20%"}
                  >
                    <IconBox boxIcon={<AppIcons.Share />} />
                    Share
                  </Button>
                </Link>
              </Text>
            </Box>
          </AppModal>
        </Flex>
      )}
    </Box>
  );
};

export default CheckTicket;
