import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//Chakra
import { Avatar, Flex, IconButton, List, ListItem, Popover, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//User Data
import { useUserStore } from "lib/stores/user/UserStore";


const Navbar = ({ loading, setLoading }: { loading: boolean; setLoading: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  const [urlPath, setUrlPath] = useState<string[]>([""]);

  useEffect(() => {
    if (location.pathname.split("/") === urlPath) {
      return console.log("empty");
    } else {
      setLoading(true);
      setUrlPath(location.pathname.split("/"));
      setLoading(false);
    }
  }, [location.pathname]);

  const titleHandler = () => {
    switch (urlPath[2]) {
      case "home":
        return `Welcome ${user?.name?.split(" ")[0]}`;
      case "events":
        return "My events";
      case "profile":
        return "Profile";
      case "settings":
        return "Settings";
      case "create":
        return "Event base";

      default:
        return `Welcome ${user?.name?.split(" ")[0]}`;
    }
  };

  return (
    <nav>
      <Flex justifyContent={"space-between"} alignItems={"center"} py={"12px"} px={"16px"}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"10px"}>
          {(urlPath[2] !== "profile" && urlPath[2] !== "create") && (user?.image ? <Avatar bgColor={"primary.600"} src={user?.image} width={"32px"} height={"32px"} /> : <Avatar bgColor={"primary.600"} width={"32px"} height={"32px"} />)}
          <Text color={"gray.0"} fontFamily={"ISB"}>
            {!loading && titleHandler()}
          </Text>
        </Flex>
        <Flex gap={"8px"}>
          {urlPath[2] !== "profile" && <IconButton _hover={{ bg: "transparent" }} bg={"transparent"} aria-label="Notifications" icon={<AppIcons.Notification />} />}
          <Popover placement="bottom">
            {({ onClose }) => (
            <>
              <PopoverTrigger>
                <IconButton _hover={{ bg: "transparent" }} bg={"transparent"} aria-label="Menu" icon={<AppIcons.Menu />}/>
              </PopoverTrigger>
              <PopoverContent bgColor={'transparent'} border={'none'}>
                <List display={"flex"} flexDirection={"column"} position={'absolute'} right={'32px'} top={'0px'} zIndex={"999"} padding={"8px"} bg={"green.800"} rounded={"8px"} boxShadow={"0px 4px 6px -2px rgba(27, 27, 27, 0.03), 0px 12px 16px -4px rgba(27, 27, 27, 0.08)"}>
                    <Link to={`/user/home`} onClick={onClose}>
                      <ListItem display={"flex"} alignItems={"center"} padding={"12px"} gap={'8px'}>
                        <AppIcons.Home/>
                        <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                          Home
                        </Text>
                      </ListItem>
                    </Link>
                    <Link to={`/user/events`} onClick={onClose}>
                      <ListItem display={"flex"} alignItems={"center"} padding={"12px"} gap={'8px'} width={'full'}>
                        <AppIcons.HeartCalendar />
                        <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                          My Events
                        </Text>
                      </ListItem>
                    </Link>
                    <Link to={`/user/profile`} onClick={onClose}>
                      <ListItem display={"flex"} alignItems={"center"} padding={"12px"} gap={'8px'}>
                        <AppIcons.User />
                        <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                          Profile
                        </Text>
                      </ListItem>
                    </Link>
                    <Link to={`/user/settings`} onClick={onClose}>
                      <ListItem display={"flex"} alignItems={"center"} padding={"12px"} gap={'8px'}>
                        <AppIcons.Settings/>
                        <Text fontFamily={"AVR"} color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                          Settings
                        </Text>
                      </ListItem>
                    </Link>
                  </List>
              </PopoverContent>
            </>
            )}
          </Popover>
        </Flex>
      </Flex>
    </nav>
  );
};

export default Navbar;