import { Flex, IconButton, Text } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import React from "react";

const IconAndDetailBox = ({
  boxIcon,
  primaryTitle,
  secondaryTitle,
}: {
  primaryTitle: string;
  boxIcon:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  secondaryTitle?: string;
}) => {
  return (
    <Flex
      direction="column"
      justifyContent={"center"}
      alignItems={"center"}
      gap={"12px"}
      padding={'16px'}
    >
      <IconButton
        _hover={{ bg: "transparent" }}
        bg={"transparent"}
        aria-label="document"
        icon={boxIcon}
        cursor={'default'}
      />
      <Text
        color={"gray.0"}
        fontSize={"16px"}
        fontFamily={"AVSB"}
        textAlign={"center"}
      >
        {primaryTitle}
      </Text>
      <Text
        color={"gray.600"}
        fontSize={"14px"}
        textAlign={"center"}
        fontFamily={"AVR"}
        paddingX={"16px"}
      >
        {secondaryTitle}
      </Text>
    </Flex>
  );
};

export default IconAndDetailBox;
