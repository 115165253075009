//Chakra
import { Flex, Text } from "@chakra-ui/layout";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Component
import ExploreItem from "./ExploreItem";

import { IEvents, useUserStore } from "lib/stores/user/UserStore";
import { useQuery } from "@tanstack/react-query";
import ExploreSkeleton from "components/common/explore-card-skeleton/ExploreSkeleton";

const Explore = () => {
  const { getExploreEvents } = useEvents();
  const userId = useUserStore((state) => state.user?.id);
  const { data: events, isLoading, isError } = useQuery({ queryKey: ["exploreEvents"], queryFn: getExploreEvents, cacheTime: 60 * 60 * 1000 });

  return (
    <Flex alignItems={"flex-start"} alignSelf={"stretch"} flexDirection={"column"} height={"100%"} bg={"gray.900"} color={"gray.0"} rounded={"8px"} padding={"16px"} gap={"12px"}>
      <Text color={"gray.0"} fontWeight={"600"} fontSize={"18px"} fontFamily={"AVSB"}>
        Explore
      </Text>
      {isLoading ? 
      Array.from({ length: 10 }).map((_, index) => <ExploreSkeleton key={index} />)
      :
      events?.length > 0 ? (
        events.map((events: IEvents, index: number) => <ExploreItem events={events} key={index} currentUserId={userId} />)
      ) : (
        <Flex alignItems={"center"} alignSelf={"stretch"} justifyContent={"center"} flexDirection={"column"} gap={"12px"} flex={"1 0 0"} padding={"32px 0"}>
          <Flex alignItems={"center"} justifyContent={"center"} gap={"10px"} width={"40px"} height={"40px"} padding={"4px"} borderRadius={"10px"} bgColor={"gray.700"}>
            <AppIcons.FileSearch />
          </Flex>
          <Flex flexDirection={"column"} alignItems={"center"} gap={"4px"}>
            <Text fontSize={"16px"} fontWeight={600} fontFamily={"AVSB"}>
              No projects to show
            </Text>
            <Text fontSize={"14px"} fontWeight={400} fontFamily={"AVR"} color={"gray.600"} textAlign={"center"} width={"auto"} lineHeight={"20px"}>
              We’ll make sure to let you know you when new projects are released
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Explore;
