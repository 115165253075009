import { useMemo, useState, useReducer } from "react";
import { useParams, useNavigate } from "react-router-dom";

//Chakra
import { Button, Flex, Spinner, Text, Input, Tooltip, IconButton, Divider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";

//Components
import BackBtn from "components/common/back-btn/BackBtn";
import PaymentMethodItem from "pages/purchase/parts/PaymentMethodItem";
import SummaryItem from "pages/purchase/parts/SummaryItem";
import EventInfo from "pages/purchase/parts/EventInfo";
import EventForm from "pages/purchase/parts/EventForm";
import NextBtn from "components/desktop-common/next-btn/NextBtn";


//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { usePayment } from "functions/hooks/payment/usePayment";
import { useProfile } from "functions/hooks/user/useProfile";
import useAppToast from "functions/hooks/toast/useToast";

//Data
import { useUserStore } from "lib/stores/user/UserStore";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Reducer
import { initialState, purchaseReducer } from "pages/purchase/reducer/PurchaseReducer";


//Functions
import { calculateTicketPrice } from "pages/purchase/funcs/PurchaseFuncs";
import { appDevelopment } from "lib/utils/development/Development";

function Purchase() {
    const [ticketsState, ticketsDispatch] = useReducer(
        purchaseReducer,
        initialState
    );

    const { isOpen, onOpen, onClose } = useDisclosure()

    //Data
    const email = useUserStore((state) => state.user?.email);
    const name = useUserStore((state) => state.user?.name);

    //States
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [paymentMethod, setPaymentMethod] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<any>({
        event: null,
        loading: false,
        currentUserData: null,
        walletAddress: '',
        showPaymentMethods: false,
        polygonRate: 0,
    });


    //Custom Hooks
    const { getEventDetail, getUserAttendEvents } = useEvents();
    const { createOrders, payViaPolygon, payWithMatic, checkoutPaymentTransactions, handleFreeOrder } = usePayment();
    const { getCurrentUserData } = useProfile();
    const { showToast } = useAppToast();

    //react router dom Hooks
    const { eventId } = useParams();
    const navigate = useNavigate();

    const totalTicketCount = Object.values(ticketsState.ticketQuantities).reduce(
        (total: number, quantity: any) => total + quantity,
        0
    );

    const ticketDetail = {
        name,
        email: email ? email : userEmail,
        ticket: ticketsState.selectedTicketType,
        quantity: Object.values(ticketsState.ticketQuantities).reduce(
        (sum: number, qty: any) => sum + qty,
        0
        ),
    };

    const checkUserCredentials = async (selectedPaymentMethod: any) => {
        if (!ticketDetail.name) {
            navigate("/user/profile");
            showToast("Please complete your profile first", 'info');
        } else if (!ticketDetail.email) {
            setShowEmailModal(true)
        } else {
            if (ticketsState.price === 0) {
                const res = await createOrders(ticketDetail);
                if (res && res?.finalPrice === 0) {
                    const freePurchaseRes: any = handleFreeOrder(res?.id)
                    if (freePurchaseRes) {
                        showToast("Your ticket has been successfully purchased, enjoy!", "success") 
                        navigate("/user/events")
                    }
                }
            } else if (selectedPaymentMethod === 1) {
                const res = await createOrders(ticketDetail);
                res && navigate(`/user/payment/stripe/${res?.id}`);
            } else if (selectedPaymentMethod === 2) {
                setIsLoading(true)
                setShowEmailModal(false)
                const res = await createOrders(ticketDetail);
                if (res) {
                    const paymentRes: any = await payViaPolygon(res?.id);
                    // console.log("payment response ->", paymentRes);
                    try {
                        const polygonRes = await payWithMatic(
                        paymentRes?.receiverAddress,
                        paymentRes?.usdPrice,
                        appDevelopment ? "testnet" : "mainnet"
                        );
                        // console.log("polygon response ->", polygonRes);
                        const checkoutPaymentRes = await checkoutPaymentTransactions(res?.id, polygonRes)
                        // console.log("checkoutPaymentRes", checkoutPaymentRes);
                        if (checkoutPaymentRes) {
                            navigate(`/user/purchase/thank-you/${res?.id}`)
                        } else {
                            showToast("Payment failed. Please try again", 'error')
                        }
                    } catch (error) {
                        showToast(`You denied transaction signature.`, "info")
                    }
                }
                setIsLoading(false)
            }
        }
    };

    //Increase Ticket quantity
    const increaseTicketQuantity = (
        ticketId: string,
        total: number,
        sold: number,
        price: number,
        polygonPrice?: any,
    ) => {
        if (ticketsState.selectedTicketType === null) {
        ticketsDispatch({ type: "SET_TICKET_TYPE", payload: ticketId });

        // Increase ticket quantity and calc price
        ticketsDispatch({ type: "INCREASE_TICKET", payload: ticketId });
        const newTotal = calculateTicketPrice(
            price,
            ticketsState.ticketQuantities[ticketId] + 1
        );
        ticketsDispatch({ type: "CALC_TOTAL_PRICE", payload: newTotal });
        ticketsDispatch({ type: "TICKET_PRICE", payload: price });

        // Update Polygon price and total amount
        if (polygonPrice) {
            const newPolygonPrice = calculateTicketPrice(
                polygonPrice,
                ticketsState.ticketQuantities[ticketId] + 1
            );
            ticketsDispatch({
                type: "CALC_POLYGON_TOTAL_PRICE",
                payload: newPolygonPrice
            });
            ticketsDispatch({ type: "TICKET_POLYGON_PRICE", payload: polygonPrice})
        }

        ticketsDispatch({ type: "SET_IS_DISABLED", payload: false });
        } else if (ticketsState.selectedTicketType !== ticketId) {
        showToast(`You can't select two different ticket types`, "error");
        } else {
        if (ticketsState.ticketQuantities[ticketId] === total - sold) {
            showToast(`This ticket has no more than ${total - sold} available.`, "error");
        } else {
            ticketsDispatch({ type: "SET_IS_DISABLED", payload: false });

            // Increase ticket quantity and calc price
            ticketsDispatch({ type: "INCREASE_TICKET", payload: ticketId });
            const newTotal = calculateTicketPrice(
            price,
            ticketsState.ticketQuantities[ticketId] + 1
            );
            ticketsDispatch({ type: "CALC_TOTAL_PRICE", payload: newTotal });
            ticketsDispatch({ type: "TICKET_PRICE", payload: price });

            // Update Polygon price and total amount
            if (polygonPrice) {
                const newPolygonPrice = calculateTicketPrice(
                    polygonPrice,
                    ticketsState.ticketQuantities[ticketId] + 1
                );
                ticketsDispatch({
                    type: "CALC_POLYGON_TOTAL_PRICE",
                    payload: newPolygonPrice
                });
                ticketsDispatch({ type: "TICKET_POLYGON_PRICE", payload: polygonPrice})
            }
        }
        }
    };

    //Decrease Ticket quantity
    const decreaseTicketQuantity = (ticketId: string, price: number, polygonPrice?: any) => {
        if (ticketsState.ticketQuantities[ticketId] > 0) {
        if (ticketsState.selectedTicketType !== ticketId) {
            showToast(`You can't select two different ticket types`, "error");
        } else {
            ticketsDispatch({ type: "SET_IS_DISABLED", payload: false });

            // Decrease ticket quantity and calc price
            ticketsDispatch({ type: "DECREASE_TICKET", payload: ticketId });
            const newTotal = calculateTicketPrice(
            price,
            ticketsState.ticketQuantities[ticketId] - 1
            );

            ticketsDispatch({ type: "CALC_TOTAL_PRICE", payload: newTotal });
            ticketsDispatch({ type: "TICKET_PRICE", payload: price });

            // Update Polygon price and total amount
            if (polygonPrice) {
                const newPolygonPrice = calculateTicketPrice(polygonPrice, ticketsState.ticketQuantities[ticketId] - 1);
                ticketsDispatch({ type: "CALC_POLYGON_TOTAL_PRICE", payload: newPolygonPrice})
                ticketsDispatch({ type: "TICKET_POLYGON_PRICE", payload: polygonPrice})
            }

            if (ticketsState.ticketQuantities[ticketId] - 1 === 0) {
            ticketsDispatch({ type: "SET_TICKET_TYPE", payload: null });

            if (ticketsState.selectedTicketType !== ticketId) {
                showToast(`You can't select two different ticket types`, "error");
            } else {
                ticketsDispatch({ type: "SET_IS_DISABLED", payload: true });
            }
            }
        }
        } else if (ticketsState.ticketQuantities[ticketId] === 0) {
        if (ticketsState.selectedTicketType === null) {
            showToast(
            `You haven't selected a ticket yet. Please choose a ticket.`,
            "warning"
            );
        } else if (ticketsState.selectedTicketType !== ticketId) {
            showToast(`You can't select two different ticket types`, "error");
        } else {
            ticketsDispatch({ type: "SET_IS_DISABLED", payload: true });
        }
        }
    };

    const nextBtnHandler = () => {
        if (totalTicketCount === 0) {
            showToast("Please select at least one ticket", "warning");
        } else {
            setData((prevData: any) => ({ ...prevData, showPaymentMethod: true }));
        }
    };

    const getCommonTicketIds = (userAttendEvents: any, eventTicketIds: any) => {
        let commonTicketIds: any = [];
        userAttendEvents?.forEach((item: any) => {
            const commonIds = item?.tickets?.filter((ticket: any) => eventTicketIds.includes(ticket._id));
            commonTicketIds = [...commonTicketIds, ...commonIds];
        });
        return commonTicketIds;
    };

    useMemo(async () => {
        if (eventId) {
            setData((prevData: any) => ({ ...prevData, loading: true }));
            const currentUser = await getCurrentUserData();
            const res = await getEventDetail(eventId);
            const userAttendEvents = await getUserAttendEvents();
            
            const eventTicketIds = res?.tickets?.map((ticket: any) => ticket._id) || [];
            const commonTicketIds = getCommonTicketIds(userAttendEvents, eventTicketIds);
            
            if (commonTicketIds.length > 0) {
                showToast("You have already purchased this event", "info")
                navigate(`/user/ticketDetail/${eventId}`);
            } else {
                const ticketsId = res?.tickets?.map((item: any) => item.id) || [];
                ticketsDispatch({ type: "SET_TICKETS_ID", payload: ticketsId });
                let polygonAddress = currentUser?.paymentMethods?.filter((item: any) => item.name === "POLYGON")[0]?.address
                setData((prevData: any) => ({ ...prevData, event: res, currentUserData: currentUser, walletAddress: polygonAddress, loading: false }));
            }
        }
    }, [eventId]);

    const shortedWalletAddress = data?.walletAddress && `${data?.walletAddress?.slice(0, 4)}...${data?.walletAddress?.slice(38, )}`;

    return (
        <Flex gap={'16px'} flexDirection={'column'} width={'600px'}>
            {!data?.loading ? 
            <>
                <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
                    <BackBtn />
                    <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'30px'} fontWeight={600}>{data.showPaymentMethod ? "Complete purchase" : "Attend event"}</Text>
                </Flex>
                <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'} width={'100%'}>
                    <EventInfo event={data?.event} />
                    {!data.showPaymentMethod ?
                    <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'} padding={'16px'} borderRadius={'8px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                        {data?.event?.tickets?.length > 0 ? (
                            data?.event?.tickets?.map((ticket: any) => (
                            <EventForm
                                key={ticket.id}
                                event={ticket}
                                quantity={ticketsState.ticketQuantities[ticket.id]}
                                increaseTicketQuantity={increaseTicketQuantity}
                                decreaseTicketQuantity={decreaseTicketQuantity}
                                ticketRules={data?.event?.rules}
                                polygonRate={data?.polygonRate}
                            />
                            ))
                        ) : (
                            <Text textAlign={"center"}>
                                There are no tickets for this event.
                            </Text>
                        )}
                    </Flex>
                    :
                    <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'16px'} borderRadius={'8px'} padding={'16px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}>
                        <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'}>
                            <Flex justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'} gap={'16px'}>
                                <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Pay with</Text>
                                <Flex cursor={'pointer'} onClick={() => setData((prevData: any) => ({ ...prevData, showPaymentMethod: false }))}><AppIcons.XcloseWithoutBorder /></Flex>
                            </Flex>
                            <PaymentMethodItem paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} icon={<AppIcons.CreditCard />} title={"Credit/debit card"} totalPrice={ticketsState.totalPrice} paymentMethodNumber={1}/>
                            <PaymentMethodItem paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} icon={<AppIcons.MATICToken />} title={data?.walletAddress ? shortedWalletAddress : "Sign your wallet address"} totalPrice={ticketsState.totalPolygonPrice === 0 ? 0 : ticketsState.totalPolygonPrice.toFixed(2)} paymentMethodNumber={2} hasWalletAddress={data?.walletAddress ? true : false}/>
                        </Flex>
                        <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'}>
                            <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Summry</Text>
                            <Flex border={"1px solid #434343"} borderRadius={"12px"} display={"flex"} flexDirection={"column"} padding={"10px"} gap={"16px"} width={'100%'}>
                                <SummaryItem leftCol={`${data.event?.title} × ${totalTicketCount}`} rightCol={ticketsState.price === 0 ? "Free" : paymentMethod === 1 ? `${ticketsState.price} USD` : `${ticketsState.polygonPrice} MATIC`} />
                                <SummaryItem 
                                leftCol={"Pay with"} 
                                rightCol={paymentMethod === 1 ? "Credit/debit card"
                                    : 
                                    (
                                    <Flex alignItems={"center"} gap={"4px"}>
                                        <AppIcons.MATICToken />
                                        <Text fontSize={"12px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
                                            {data.walletAddress ? shortedWalletAddress : "Sign your wallet address"}
                                        </Text>
                                    </Flex>
                                    )
                                } 
                                />
                                <SummaryItem 
                                leftCol={(
                                    <Flex alignItems={"center"} gap={"4px"}>
                                        <Text fontSize={"14px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"gray.0"}>
                                            Fee{" "}
                                            <Tooltip
                                                hasArrow
                                                label="This is an estimated fee."
                                                placement="right"
                                                fontWeight={"400"}
                                                fontSize={"12px"}
                                                fontFamily={"IM"}
                                                lineHeight={"16px"}
                                                borderRadius={"8px"}
                                                padding={"8px 12px"}
                                                textAlign={"center"}
                                                alignSelf={"center"}
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                            >
                                                <IconButton
                                                    margin={0}
                                                    padding={0}
                                                    height={0}
                                                    minWidth={0}
                                                    _hover={{ bg: "transparent" }}
                                                    bg={"transparent"}
                                                    aria-label="document"
                                                    icon={<AppIcons.SmallInfo />}
                                                    //   onClick={}
                                                />
                                            </Tooltip>
                                        </Text>
                                    </Flex>
                                    )} 
                                rightCol={paymentMethod === 1 ? (
                                    <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"400"} lineHeight={"16px"} color={"gray.0"}>
                                        $ 0
                                    </Text>
                                ) : (
                                    <Flex flexDirection={"column"} alignItems={"flex-end"}>
                                        <Text fontSize={"12px"} fontWeight={400} fontFamily={"AVR"} color={"gray.0"}>
                                            0.00006 MATIC
                                        </Text>
                                        <Text fontSize={"10px"} fontWeight={400} fontFamily={"AVR"} color={"gray.400"}>
                                            ~0.0002 USD
                                        </Text>
                                    </Flex>
                                )} 
                                />
                                
                                <Divider height={"0.75px"} bg={"#434343"} orientation="horizontal" />
                                <Flex display={"flex"} justifyContent={"space-between"}>
                                    <Text display={"flex"} fontSize={"14px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"}>
                                        total
                                    </Text>
                                    {ticketsState.totalPrice === 0 ?
                                        <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"}>
                                            Free
                                        </Text> 
                                        :  
                                        paymentMethod === 1 ? (
                                            <Text fontSize={"12px"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"20px"} color={"gray.0"}>
                                            {`${ticketsState.totalPrice} USD`}
                                            </Text>
                                        ) : (
                                            <Flex flexDirection={"column"} alignItems={"flex-end"}>
                                            <Text fontSize={"12px"} fontWeight={600} fontFamily={"AVSB"} color={"gray.0"}>
                                                {`${ticketsState.totalPolygonPrice.toFixed(2)} MATIC`}
                                            </Text>
                                            <Text fontSize={"10px"} fontWeight={400} fontFamily={"AVR"} color={"gray.400"}>
                                                {`~${ticketsState.totalPrice} USD`}
                                            </Text>
                                            </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    }
                    {data.showPaymentMethod ? 
                        <Button bgColor={'primary.600'} color={'primary.900'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} width={'100%'} onClick={() => checkUserCredentials(paymentMethod)} isLoading={isLoading} isDisabled={isLoading}>
                            {`Pay ${paymentMethod === 1 ? `${ticketsState.totalPrice} USD` : `${ticketsState.totalPolygonPrice.toFixed(2)} MATIC`}`}
                        </Button> 
                        : 
                        <NextBtn onClick={nextBtnHandler} />
                    }
                </Flex> 
            </>
            :
            <Spinner color="primary.600" alignSelf={'center'} />
            }
        
            <Modal isOpen={showEmailModal} onClose={() => setShowEmailModal(false)}>
                <ModalOverlay />
                <ModalContent bgColor={'gray.900'} alignSelf={'center'}>
                    <ModalHeader>
                        <Text color={"gray.0"} fontFamily={"AVSB"} fontWeight={500}>
                            Please provide your email
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display={'flex'} alignItems={'center'} justifyContent={'center'} alignSelf={'center'} width={'100%'}>
                        <Input
                            type="text"
                            placeholder="Enter your email address"
                            color={"gray.0"}
                            bgColor={"gray.1000"}
                            padding={"10px"}
                            width={"100%"}
                            border={"none"}
                            borderRadius={"5px"}
                            outline={"none"}
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </ModalBody>

                    <ModalFooter display={'flex'} justifyContent={'space-evenly'} width={'100%'} gap={'16px'}>
                        <Button
                            isDisabled={false}
                            bg={"gray.500"}
                            color={"gray.0"}
                            width={"100%"}
                            fontFamily={"IM"}
                            fontSize={"16px"}
                            fontWeight={500}
                            onClick={() => setShowEmailModal(!showEmailModal)}
                        >
                            Cancel
                        </Button>
                        <Button
                            isDisabled={false}
                            bgColor={"primary.600"}
                            color={"gray.0"}
                            width={"100%"}
                            fontFamily={"IM"}
                            fontSize={"16px"}
                            fontWeight={500}
                            _hover={{ bgColor: "primary.900" }}
                            _active={{
                            bgColor: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E",
                            color: "gray.0",
                            }}
                            isLoading={isLoading}
                            onClick={() => checkUserCredentials(paymentMethod)}
                        >
                            Next
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
}
export default Purchase;
