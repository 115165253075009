import { useEffect, useState } from "react";

//Chakra
import { Avatar, Box, Button, ButtonGroup, Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Custom Hooks
import { IEvents, SocialMediaEnum, useUserStore } from "lib/stores/user/UserStore";
import { useEvents } from "functions/hooks/events/useEvents";

//Components
import ProfileMyEventsBtn from "./parts/buttons/ProfileMyEventsBtn";
import UserEventsInProfile from "./userEvent/UserEventsInProfile";
import AttendEventsInProfile from "./attendEvent/AttendEventsInProfile";
import { useProfile } from "functions/hooks/user/useProfile";

const Profile = () => {
  const user = useUserStore((state) => state.user);
  const { userClaimedRewards } = useProfile();
  const { isLoading, getUserEvents, getUserAttendEvents } = useEvents();
  const [linkedinLink, setLinkedinLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [selectedButton, setSelectedButton] = useState("Attend");
  const [attendEvents, setAttendEvents] = useState<IEvents[]>([]);
  const [hostEvents, setHostEvents] = useState<IEvents[]>([]);
  const [claimedRewards, setClaimedRewadrs] = useState<any>([])

  
  //Attend events
  const attendEventGetter = async () => {
    const res = await getUserAttendEvents();
    if (res) {
      setAttendEvents(res);
    }
  };

  //Host events
  const hostEventGetter = async () => {
    const res = await getUserEvents();
    if (res) {
      setHostEvents(res);
    }
  };

  //Claimed rewards
  const claimedRewardsGetter = async () => {
    const res = await userClaimedRewards()
    if (res) {
      setClaimedRewadrs(res)
    }
  }


  const handleButtonClick = (buttonText: string) => {
    setSelectedButton(buttonText);
  };

  useEffect(() => {
    user.socialMediasLink.forEach((item) => {
      item.name === SocialMediaEnum.LINKEDIN && setLinkedinLink(item.link);
      item.name === SocialMediaEnum.TWITTER && setTwitterLink(item.link);
      item.name === SocialMediaEnum.TELEGRAM && setTelegramLink(item.link);
      item.name === SocialMediaEnum.INSTAGRAM && setInstagramLink(item.link);
    });

    attendEventGetter();
    hostEventGetter();
    claimedRewardsGetter();

  }, [user.socialMediasLink]);

  return (
    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} gap={"12px"}>
      <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} rounded={"16px"} gap={"16px"} padding={"16px"} bg={"gray.900"}>
        <Avatar size={"xl"} bgGradient="linear(135deg, #2EC99E 7.03%, #B2FFEA 92.97%)" name="" src={user.image ? user.image : "https://bit.ly/tioluwani-kolawole"} />
        <Text fontSize={"20px"} fontFamily={"AVR"} color={"gray.0"} fontWeight={600}>
          {user.name ? user.name : "unknown"}
        </Text>  
        <Flex gap={"12px"}>
          <Text fontSize={"12px"} fontWeight={400} color={"gray.400"} fontFamily={"AVR"}>
            {hostEvents?.length} host
          </Text>
          <Text fontSize={"12px"} fontWeight={400} color={"gray.400"} fontFamily={"AVR"}>
            {attendEvents?.length} attend
          </Text>
          {/* <Text fontSize={"12px"} fontWeight={400} color={"gray.400"} fontFamily={"AVR"}>
            50 followers
          </Text> */}
        </Flex>
        <ChakraLink as={ReactRouterLink} to="editprofile/" style={{ textDecoration: "none" }}>
          <Button
            color={"gray.0"}
            bgColor={"green.800"}
            variant="solid"
            borderRadius={"8px"}
            height={"32px"}
            padding={"8px 12px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"6px"}
          >
            <AppIcons.Pencil />
            <Text fontSize={"14px"} fontWeight={500} fontFamily={"IM"} lineHeight={"20px"}>
              Edit profile
            </Text>
          </Button>
        </ChakraLink>
        <Flex alignItems={"center"} gap={"12px"}>
          <Link href={`https://www.linkedin.com/in/${linkedinLink}`} target="_blank">
            <AppIcons.Linkedin />
          </Link>
          <Link href={`https://www.twitter.com/${twitterLink}`} target="_blank">
            <AppIcons.Twitter />
          </Link>
          <Link href={`https://t.me/${telegramLink}`} target="_blank">
            <AppIcons.Telegram />
          </Link>
          <Link href={`https://www.instagram.com/${instagramLink}`} target="_blank">
            <AppIcons.Instagram />
          </Link>
        </Flex>
        <Text width={'100%'} color={"gray.600"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} textAlign={"center"} fontFamily={"AVR"}>
          {user.bio}
        </Text>
      </Flex>
      <Flex flexDirection={"column"} gap={"12px"}>
        <ButtonGroup width={"100%"} p={"8px"} gap={"4px"} bg={"gray.900"} rounded={"12px"} display={"flex"} justifyContent={"center"}>
          <ProfileMyEventsBtn isSelected={selectedButton === "Attend"} text="Attend" number={attendEvents?.length} isLoading={isLoading} onClick={() => handleButtonClick("Attend")} />
          <ProfileMyEventsBtn isSelected={selectedButton === "Host"} text="Host" number={hostEvents?.length} isLoading={isLoading} onClick={() => handleButtonClick("Host")} />
        </ButtonGroup>
        {selectedButton === "Host" ? <UserEventsInProfile events={hostEvents} isLoading={isLoading} /> : <AttendEventsInProfile events={attendEvents} claimedRewards={claimedRewards} isLoading={isLoading} />}
      </Flex>
    </Box>
  );
};

export default Profile;
