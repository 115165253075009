import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";

//Chakra
import { Flex, Text, Image as ChakraImage, Skeleton, Spinner } from "@chakra-ui/react";
import { IEvents } from "lib/stores/user/UserStore";
import { useEvents } from "functions/hooks/events/useEvents";
import { UTCConverter } from 'lib/utils/time/UTCConverter';
import EmptyPageMessage from 'components/desktop-common/empty-page-message/EmptyPageMessage';

const ComingUpEvents = ({events, hasFutureEvents}: {events: any, hasFutureEvents: boolean}) => {
  const img = new Image();
  const { isLoading, findNearestEvent } = useEvents()
  const [loading, setLoading] = useState(true)
  const [nearestEvent , setNearestEvent] = useState<IEvents>()
  

  useEffect(() => {
    const fetchNearestEvent = async () => {
      setLoading(true);
      try {
        const findNearestEventRes = await findNearestEvent(events);
        setNearestEvent(findNearestEventRes);
        img.src = `${findNearestEventRes?.banner}`;
        img.onload = () => setLoading(false);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching nearest event:", error);
        setLoading(false);
      }
    };
  
    fetchNearestEvent();
  }, [events]);
  

  return (
    <Flex flexDirection={"column"} bgColor={"gray.900"} color={"gray.0"} rounded={"16px"} padding={"16px"} gap={"16px"} alignItems={"flex-start"} width={"100%"} height={"auto"}>

      {hasFutureEvents ? 
        <>
          <Link to={`/user/events/${nearestEvent?._id}`} style={{width: '100%'}}>
            {nearestEvent?.banner ? 
            <ChakraImage borderRadius={"8px"} bgColor={'black'} alt="event banner" src={nearestEvent?.banner} width={"450px"} height={"160px"} display={"flex"} alignSelf={"stretch"} objectFit={'cover'} />
            : 
            <Skeleton borderRadius={'8px'} height={'160px'} width={'450px'}/>
            }
          </Link>

          {nearestEvent?.title ? 
          <Link to={`/user/events/${nearestEvent?._id}`}>
            <Text color={"gray.0"} fontWeight={"600"} fontSize={"30px"} fontFamily={"AVSB"} overflowWrap={'break-word'} wordBreak={'break-word'}>
              {nearestEvent?.title}
            </Text>
          </Link>
          :
          <Skeleton height={'45px'} width={'100px'}/>
          }
          
          <IconInfoBox
            boxIcon={<AppIcons.GreenCalendar />}
            label="Date"
            primaryTitle={nearestEvent?.start && `${UTCConverter(nearestEvent?.start)}`}
            secondaryTitle={nearestEvent?.end && `${UTCConverter(nearestEvent?.end)}` }
          />

          <IconInfoBox
            boxIcon={<AppIcons.GreenLocation />}
            label="Location"
            primaryTitle={nearestEvent?.location}
            // secondaryTitle="Piers Pier 62, New York, NY 10011, USA"
          />
        </>
        :
        // <EmptyPageMessage msg="You currently do not have any events" desc="Look in the explorer to find the event you want" icon={<AppIcons.FileSearch />} createBtn={false} />
        <>
          <ChakraImage src='https://placehold.co/450x160/292929/2EC99E/?text=Your+upcoming+event+banner&font=montserrat' borderRadius={'8px'} height={'160px'} width={'450px'} position={'relative'}/>
          {loading && <Spinner color={'primary.600'} position={'absolute'} margin={'10px'} />}

          <Text color={"gray.0"} fontWeight={"600"} fontSize={"30px"} fontFamily={"AVSB"} overflowWrap={'break-word'} wordBreak={'break-word'}>
            Your upcoming event title
          </Text>
          
          <IconInfoBox
            boxIcon={<AppIcons.GreenCalendar />}
            label="Date"
            primaryTitle={`The start date of your upcoming event`}
            secondaryTitle={`The end date of your upcoming event`}
          />

          <IconInfoBox
            boxIcon={<AppIcons.GreenLocation />}
            label="Location"
            primaryTitle={`The venue of your upcoming event`}
            // secondaryTitle="Piers Pier 62, New York, NY 10011, USA"
          />
        </>
      }

    </Flex>
  );
};

export default ComingUpEvents;
