import AppToastify from "components/common/button/toastify/AppToastify";
import AppDevbar from "components/common/devbar/AppDevbar";
import { appDevelopment } from "lib/utils/development/Development";
import { DeviceDetector } from "lib/utils/device/DeviceDetect";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import DesktopRoutes from "routes/desktop-routes";
import AppRoutes from "routes/routes";

function App() {
  const { isMobile } = DeviceDetector();
  const [view, setView] = useState<"DESKTOP" | "MOBILE">(isMobile ? "MOBILE" : "DESKTOP");

  console.log(appDevelopment, view);
  const ChangeView = (device: string) => {
    device === "DESKTOP" && setView("DESKTOP");
    device === "MOBILE" && setView("MOBILE");
  };
  return (
    <BrowserRouter>
      {appDevelopment && <AppDevbar ChangeView={ChangeView} view={view} />}
      <AppToastify />

      {appDevelopment ? view === "MOBILE" ? <AppRoutes /> : <DesktopRoutes /> : isMobile ? <AppRoutes /> : <DesktopRoutes />}
    </BrowserRouter>
  );
}

export default App;
