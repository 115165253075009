import { Link, useParams } from "react-router-dom";

//Chakra
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconDetailSaveDraft from "components/common/icon-and-detail-box/IconDetailSaveDraft";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

const SaveDraft = () => {
  const { isLoading } = useEvents();
  const { eventId } = useParams();

  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
        <IconDetailSaveDraft boxIcon={<AppIcons.Check />} primaryTitle="Save draft successfully" secondaryTitle="Your event has been saved." />
      </Box>
      <Link to={`/user/home`}>
        <Button bg={"green.800"} color={"gray.0"} _active={{color: 'gray.600'}} _hover={{ bg: "green.800" }} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} width={"100%"}>
          Go to Home
        </Button>
      </Link>
      <Link to={`/user/events/${eventId}`}>
        <Button bg={"primary.600"} color={"gray.900"} _active={{bgColor: 'primary.900'}} _hover={{ bg: "primary.900" }} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} width={"100%"}>
          {isLoading ? <Spinner color="white" /> : "Check event"}
        </Button>
      </Link>
    </Flex>
  );
};

export default SaveDraft;
