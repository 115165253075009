//Chakra
import { Box, Flex, Grid ,Spinner } from '@chakra-ui/react';

//Icons
import AppIcons from 'assets/icons/AppIcons';

//Components
import EmptyPageMessage from 'components/desktop-common/empty-page-message/EmptyPageMessage';
import AttendItem from './AttendItem';

//Interface
import { IEvents } from 'lib/stores/user/UserStore';
import SkeletonCard from 'components/desktop-common/skeleton-card/SkeletonCard';


const Attend = ({events , isLoading} : { events: IEvents[] , isLoading: boolean }) => {


    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={'32px'}
            flex={'1 0 0'}
        >
            <Flex
            alignItems={'flex-start'}
            alignSelf={'stretch'}
            flexDirection={"column"}
            height={'100%'}
            width={'100%'}
            color={"gray.0"}
            rounded={"8px"}
            gap={"24px"}
            >
                <Grid
                templateColumns='repeat(4, 1fr)' 
                gap={6}
                >
                    {isLoading ? 
                        Array.from({ length: 10 }).map((_, index) => <SkeletonCard key={index} />)
                        :
                        events.length > 0 ? 
                            events.map((events , index) => <AttendItem events={events} key={index} />)
                            :
                            <EmptyPageMessage
                                msg="No projects to show"
                                desc="We’ll make sure to let you know you when new projects are released" 
                                icon={<AppIcons.FileSearch />}
                                createBtn={false}
                            />
                    }
                </Grid>
            </Flex>
        </Box>
    );
};

export default Attend;