import { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

//Chakra
import { Button, Divider, Flex, Input, Text, Spinner, IconButton, Link } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Schemas
import { LoginSchema } from "schemas/schema";

//Custom Hooks
import { useProfile } from "functions/hooks/user/useProfile";

//Components
import { CustomForm, Label } from "components/common/form/form-style";


const Register = () => {
    const { onSubmitLogin, isLoading, loginViaStacks, loginViaPolygon, loginViaGoogle } = useProfile();
    const [showWallets, setShowWallets] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redicertUrl = queryParams.get("redirect-uri");
    redicertUrl && localStorage.clear()

    const { handleChange, values, handleSubmit } = useFormik({
        initialValues: {
        email: "",
        password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
        onSubmitLogin(redicertUrl ? {
            email: values.email,
            password: values.password,
            metadata: {
                "redirectUri": redicertUrl
            }
        }
        :
        {
            email: values.email,
            password: values.password,
        }
        );
        },
    });

    const loginWithGoogle = async () => {
        await loginViaGoogle(redicertUrl);
    }

    return (
        <>
            {!showWallets ? (
                <CustomForm autoComplete="off" onSubmit={handleSubmit}>
                    <Flex flexDirection={"column"} justifyContent={"center"} bg={"gray.900"} rounded={"8px"} width={'418px'}>
                        <Flex justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'} gap={'16px'} padding={'16px 24px'}>
                            <Text fontFamily="AVSB" color={"gray.0"} fontSize={'18px'} fontWeight={600}>
                                Login your account
                            </Text>
                            <Flex cursor={'pointer'} onClick={() => navigate('/')}><AppIcons.XcloseWithoutBorder /></Flex>
                        </Flex>
                        <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'24px'} padding={'0 24px'}>
                            <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={'12px'}>
                                <Button
                                    padding={'12px 16px'}
                                    width={'100%'}
                                    fontFamily={"IM"}
                                    fontSize={"16px"}
                                    fontWeight={"500"}
                                    bg={"green.800"}
                                    color={"gray.0"}
                                    rounded={"8px"}
                                    _hover={{ bg: "green.800" }}
                                    onClick={() => setShowWallets(true)}
                                >
                                    Connect a wallet
                                </Button>
                                <Button
                                    padding={'12px 16px'}
                                    width={'100%'}
                                    fontFamily={"IM"}
                                    fontSize={"16px"}
                                    fontWeight={"500"}
                                    bg={"transparent"}
                                    color={"gray.0"}
                                    border={"1px solid #434343"}
                                    rounded={"8px"}
                                    leftIcon={<AppIcons.Google/>}
                                    _hover={{ bg: "transparent" }}
                                    onClick={loginWithGoogle}
                                >
                                    Log in with Google
                                </Button>
                            </Flex>

                            <Flex alignItems={"center"} alignSelf={'stretch'} gap={'14px'}>
                                <Divider orientation="horizontal" color={'gray.700'} />
                                    <Text fontFamily={"AVR"} fontSize={"14px"} fontWeight={400} color={"gray.0"}>
                                        or
                                    </Text>
                                <Divider orientation="horizontal" color={'gray.700'} />
                            </Flex>

                            <Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={"20px"}>
                                <Label width={'100%'}>
                                    <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={'16px'}>
                                        Email
                                    </Text>
                                    <Input
                                        variant="unstyled"
                                        color={"gray.0"}
                                        bg={"gray.1000"}
                                        fontFamily={"AVR"}
                                        type="email"
                                        id="email"
                                        onChange={handleChange}
                                        placeholder="Email"
                                        padding={"12px"}
                                        _placeholder={{ color: "gray.800" }}
                                        value={values.email}
                                    />
                                </Label>
                                <Label width={'100%'}>
                                    <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={'16px'}>
                                        Password
                                    </Text>
                                    <Input
                                        variant="unstyled"
                                        color={"gray.0"}
                                        bg={"gray.1000"}
                                        fontFamily={"AVR"}
                                        type="password"
                                        id="password"
                                        onChange={handleChange}
                                        placeholder="password"
                                        padding={"12px"}
                                        _placeholder={{ color: "gray.800" }}
                                        value={values.password}
                                    />
                                </Label>
                                <Link href={"/forget-password"} fontFamily={"IM"} fontSize={"14px"} fontWeight={"400"} color={"gray.600"} textAlign={"right"} alignSelf={'flex-end'}>
                                    Forgot password?
                                </Link>
                            </Flex>
                        </Flex>
                        
                        
                        <Flex flexDirection={"column"} padding={'16px 24px'} alignItems={'center'} justifyContent={'center'} gap={'16px'}>
                            <Button type="submit" color={"primary.900"} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.800', color: 'green.800'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} fontFamily={"IM"} fontWeight={"500"} fontSize={"16px"} bg={"primary.600"} isDisabled={isLoading} width={'100%'}>
                                {isLoading ? <Spinner /> : "Login"}
                            </Button>
                            <Text fontFamily={"AVR"} fontSize={"12px"} textAlign={"center"} fontWeight={"400"} color={"grey.600"}>
                                No account?{" "}
                                <Link href={`/signup`} fontFamily={"AVR"} fontWeight={"600"} color={"primary.600"}>
                                    Register now
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </CustomForm>
            ) : (
                <Flex flexDirection={'column'} alignItems={'flex-start'} width={'418px'} borderRadius={'16px'} bgColor={'gray.900'} overflow={'hidden'}>
                    <Flex justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'} gap={'16px'} padding={'16px 24px'}>
                        <Text fontFamily="AVSB" color={"gray.0"} fontSize={'18px'} fontWeight={600}>
                            Choose a wallet
                        </Text>
                        <Flex cursor={'pointer'} onClick={() => setShowWallets(false)}><AppIcons.XcloseWithoutBorder /></Flex>
                    </Flex>

                    <Flex alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={'24px'} padding={'24px'}>
                        <Flex flexDirection={'column'} alignItems={'center'} gap={'8px'} flex={'1 0 0'} onClick={loginViaStacks}>
                            <IconButton height={'full'} overflow={'hidden'} rounded={'12px'} _hover={{ bg: "transparent" }} bgColor={"transparent"} aria-label="document" icon={<AppIcons.SampleWallet/>} />
                            <Text color={'gray.0'} fontFamily={"AVR"} fontSize={'14px'} fontWeight={600}>Leather</Text>
                        </Flex>
                        <Flex flexDirection={'column'} alignItems={'center'} gap={'8px'} flex={'1 0 0'} onClick={loginViaPolygon}>
                            <IconButton height={'full'} overflow={'hidden'} rounded={'12px'} _hover={{ bg: "transparent" }} bgColor={"transparent"} aria-label="document" icon={<AppIcons.MATICTokenSquare/>} />
                            <Text color={'gray.0'} fontFamily={"AVR"} fontSize={'14px'} fontWeight={600}>Polygon</Text>
                        </Flex>
                    </Flex>

                    <Flex flexDirection={'column'} alignItems={'center'} alignSelf={'stretch'} gap={'8px'} padding={'32px'} bgColor={'green.800'}>
                        <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'16px'} fontWeight={600}>What is a Wallet?</Text>
                        <Text color={'gray.600'} fontFamily={'AVR'} fontSize={'16px'} fontWeight={400}>A wallet is used to send, receive, store, and display digital assets. It’s also a new way to log in, without needing to create new accounts and passwords on every website.</Text>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default Register;
