import { useLocation } from 'react-router-dom'
import { Flex, Image, Skeleton, Text } from "@chakra-ui/react";
import { IEvents } from "lib/stores/user/UserStore";
import { Link } from "react-router-dom";

const AttendItems = ({ events, isUserProfile, isLoading, claimedRewards }: { events: IEvents[], isUserProfile?: boolean, isLoading?: boolean, claimedRewards?: any }) => {
  const location = useLocation();
  
  return (
    <Flex flexDirection={"column"} gap={"12px"} rounded={"8px"} bg={"gray.900"} padding={"16px"}>
      <Text fontWeight={"600"} fontSize={"18px"} lineHeight={"28px"} fontFamily={"AVR"} color={"gray.0"}>
        Tickets
      </Text>
      <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"} gap={"12px"}>
      {isLoading ? (
          Array.from({ length: 15 }).map((_, index) => (
            <Skeleton key={index} width={"70px"} height={"70px"} rounded={"10px"} />
          ))
        ) : (
          events?.map((stuff) => (
            <Link to={isUserProfile ? `/user/events/${stuff?.eventID}` : `ticketDetail/${stuff?._id}`} key={stuff?._id} style={{ textDecoration: "none" }}>
              <Flex gap={"12px"}>
                <Image width={"70px"} height={"70px"} rounded={"10px"} objectFit={"cover"} src={stuff?.banner || stuff?.eventBanner} />
              </Flex>
            </Link>
          ))
        )}
      </Flex>
      {claimedRewards?.length > 0 && 
      <>
      <Text
        fontWeight={"600"}
        fontSize={"18px"}
        lineHeight={"28px"}
        fontFamily={"AVR"}
        color={"gray.0"}
      >
        Rewards
      </Text>
      <Flex justifyContent={"center"} alignItems={"center"} flexWrap={"wrap"} gap={"12px"}>
      {isLoading ? (
          Array.from({ length: 15 }).map((_, index) => (
            <Skeleton key={index} width={"140px"} height={"140px"} rounded={"10px"} />
          ))
        ) : (
          claimedRewards?.map((item: any) => (
            <Link to={isUserProfile ? `/user/events/${item?.eventID}` : `ticketDetail/${item?.eventID}`} key={item?._id} style={{ textDecoration: "none" }}>
              <Flex gap={"12px"}>
                <Image width={"140px"} height={"140px"} rounded={"10px"} objectFit={"cover"} src={item?.nft_image} />
              </Flex>
            </Link>
          ))
          )}
      </Flex>
      </>
      }
    </Flex>
  );
};

export default AttendItems;
