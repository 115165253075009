import { statuesModule } from "./modules/statues";

interface IcheckPermission {
  user: Object;
  jwtToken: string | null;
}

interface IregisterGate extends IcheckPermission {
  redirect(to: string): void;
  pathname: string;
  to: string;
}

const DashboardGateModel = {
  checkPermission: ({ user, jwtToken }: IcheckPermission) => {
    const statues = new statuesModule(user, jwtToken);
    const userInfo = statues.userInfo();
    return userInfo;
  },

  registerGate: ({ to, redirect, pathname }: IregisterGate) => {
    if (pathname.includes("signup")) return false;
    const checkPath = pathname.includes(to); // check current path
    if (!checkPath) redirect(to);
  },
};

export default DashboardGateModel;
