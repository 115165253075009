import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "styles/fonts.css";
import App from "./App";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import reportWebVitals from "reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "styles/theme";
import * as MicroStacks from "@micro-stacks/react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <MicroStacks.ClientProvider appName="My stacks app" appIconUrl="APP_ICON.png" network={"testnet"}>
          <App />
        </MicroStacks.ClientProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
