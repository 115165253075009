import { useState } from "react";
import Html5QrcodePlugin from "./html5QrcodePlugin/Html5QrcodePlugin";
import { Flex, Text } from "@chakra-ui/react";
import { useEvents } from "functions/hooks/events/useEvents";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";
import useAppToast from "functions/hooks/toast/useToast";

const QrCodeScanner = (eventId: string | any) => {
    const [decodedData, setDecodedData] = useState<any>();
    const [ticketData, setTicketData] = useState<any>([]);
    const { isLoading, checkInAttendId } = useEvents();
    const { showToast } = useAppToast();

    
    const findTicketData = (ticketId: string, tickets: any) => {
        const desiredTicketData = tickets?.find((item: any) => ticketId === item?.id)
        console.log(desiredTicketData);
        setTicketData(desiredTicketData);
    }

    const onNewScanResult = (decodedText: any, decodedResult: any) => {
        setDecodedData(decodedText)
        // sentDataForCheckIn(decodedText)
    };

    const sentDataForCheckIn = async (attendId: string) => {
        const response = await checkInAttendId(eventId.eventId, attendId)
        if (response) {
            findTicketData(response?.updateAttendee?.ticketID, response?.event?.tickets)
            showToast("Your ticket successfully check in",'success')
        }
    }

    const checkInBtnHandler = () => {
        if (decodedData) {
            sentDataForCheckIn(decodedData)
        }else {
            showToast("Please scan the QR code first", 'warning')
        }
    }

    return (
        <Flex flexDirection={'column'} gap={'40px'} width={'100%'} marginBottom={'70px'}>
            <Flex flexDirection={'column'} bgColor={'green.800'} border={'2px #8B8B8B solid'} borderRadius={'10px'} overflow={'hidden'} className="QrCodeScanner">
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
                <Flex alignSelf={'center'}>
                    <Text color={'gray.500'} fontFamily={'AVSB'} fontSize={'16px'} fontWeight={400}>Attend ID: {decodedData}</Text>
                </Flex>
            </Flex>
            {ticketData?.length !== 0 &&
                <Flex flexDirection={'column'} gap={'8px'} width={'100%'} padding={'8px'}> 
                    <Text color={"gray.0"} fontFamily={"AVSB"} fontWeight={500}>
                        Ticket data
                    </Text>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={500}>Title:</Text>
                        <Text color={"gray.300"} fontFamily={"AVR"} fontWeight={500}>{ticketData?.title}</Text>
                    </Flex>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={500}>Price:</Text>
                        <Text color={"gray.300"} fontFamily={"AVR"} fontWeight={500}>${ticketData?.price}</Text>
                    </Flex>
                </Flex>
            }

            <PrimaryBtn btnText={'Check-in'} btnOnClick={checkInBtnHandler} btnTextColor={"primary.900"} isModalBtn={true} btnIsLodaing={isLoading}/>
        </Flex>
    );
};

export default QrCodeScanner;