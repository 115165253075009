import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//Chakra
import { useClipboard, Button, Flex, Image, Spinner, Text, Skeleton } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { useNavigator } from "functions/hooks/navigator/useNavigator";

//Components
import BackBtn from "components/common/back-btn/BackBtn";
import { useProfile } from "functions/hooks/user/useProfile";

const ProfileQrCode = () => {
    const { isLoading, getCurrentUserData } = useProfile();
    const [currentUserData, setCurrentUserData] = useState<any>([])
    const { shareLink } = useNavigator();
    const location = useLocation();

    const { hasCopied, onCopy } = useClipboard(`${window.location.origin}/user/profile/${currentUserData?._id}`);


    const copyBtnHandler = () => {
        onCopy()
    }

    useEffect(() => {
        const fetchData = async () => {
            setCurrentUserData(await getCurrentUserData());
        }

        fetchData()
    }, []);


    return (
        <Flex flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={'16px'} height={'100%'}>
            <BackBtn/>
            <Flex flexDirection={"column"} alignItems={'center'} justifyContent={'flex-end'} alignSelf={'stretch'} flex={'1 0 0'} padding={"16px"} gap={"80px"} borderRadius={'8px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'} height={'100vh'}>
                <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'16px'} flex={'1 0 0'} alignSelf={'stretch'}>
                    {currentUserData?.qr_code ? 
                    <Image width={'310px'} height={'310px'} src={currentUserData?.qr_code} borderRadius={'8px'} />
                    :
                    <Skeleton width={'310px'} height={'310px'} borderRadius={'8px'} />
                    }
                    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'stretch'} gap={'12px'}>
                        <Button
                            bg={"primary.600"}
                            color={"gray.50"}
                            fontFamily={"IM"}
                            fontSize={"16px"}
                            fontWeight={500}
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            alignSelf={'stretch'}
                            padding={'12px 16px'}
                            borderRadius={'8px'}
                            leftIcon={<AppIcons.Share2 />}
                            _hover={{ bg: "primary.600" }} 
                            _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
                            _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'gray.0'}}
                            onClick={() => shareLink(`${window.location.origin}/user/profile/${currentUserData?._id}`, "Profile", "Check out my profile in droplinked (event):")}
                        >
                            {isLoading ? <Spinner color="white" /> : "Share profile"}
                        </Button>
                        <Button
                            bg={"green.800"}
                            color={"gray.0"}
                            fontFamily={"IM"}
                            fontSize={"16px"}
                            fontWeight={500}
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            alignSelf={'stretch'}
                            padding={'12px 16px'}
                            borderRadius={'8px'}
                            leftIcon={<AppIcons.Copy />}
                            _hover={{ bg: "green.800" }} 
                            _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
                            _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), green.800', color: "gray.0"}}
                            onClick={copyBtnHandler}
                        >
                            Copy link
                        </Button>
                        <Text color={'primary.600'} fontFamily={'AVR'}>
                            {hasCopied ? "Copied!" : ""}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ProfileQrCode;
