import { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

//Chakra
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconDetailPublish from "components/common/icon-and-detail-box/IconDetailPublish";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import PublishItem from "pages/event-creation/publish-success-event/part/PublishItem";

const SuccessPublish = () => {
    const { isLoading, getEventDetail } = useEvents();
    const [event, setEvent] = useState<any>();
    const [loading, setLoading] = useState<boolean>();
    const { eventId } = useParams();
    const navigate = useNavigate();

    useMemo(async () => {
        if (eventId) {
            setLoading(true);
            const res = await getEventDetail(eventId);
            setEvent(res);
            setLoading(false);
        }
    }, [eventId]);

    return (
        <Flex flexDirection={"column"} gap={"12px"} width={'600px'}>
            <PublishItem event={event} loading={loading} />
            <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                <IconDetailPublish
                    boxIcon={<AppIcons.Check />}
                    primaryTitle="Publish event successfully"
                    secondaryTitle="Your event has been published and ready to view. Share the event, share the love."
                    thirdTitle={`${window.location.origin}/user/events/${eventId}`}
                />
            </Box>
            <Flex alignItems={'center'} gap={'16px'} width={'100%'} justifyContent={'center'}>
                <Button bg={"green.800"} color={"gray.0"} _active={{color: 'gray.600'}} _hover={{ bg: "green.800" }} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} width={"100%"} onClick={() => navigate("/user/home")}>
                    Go to Home
                </Button>
                <Button type="submit" bg={"primary.600"} color={"gray.900"} _active={{bgColor: 'primary.900'}} _hover={{ bg: "primary.900" }} fontFamily={"IM"} fontSize={"16px"} fontWeight={500} width={"100%"} onClick={() => navigate(`/user/events/${eventId}`)}>
                    {isLoading ? <Spinner color="white" /> : "Check event"}
                </Button>
            </Flex>
        </Flex>
    );
};

export default SuccessPublish;