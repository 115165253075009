import { useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//Chakra
import { Box, Flex, Avatar, Text, Link, ButtonGroup, Spinner, SkeletonCircle, Skeleton} from '@chakra-ui/react';
//Icons
import AppIcons from 'assets/icons/AppIcons';
//Custom Hooks
import { useProfile } from 'functions/hooks/user/useProfile';
//Components
import ProfileMyEventsBtn from "./parts/buttons/ProfileMyEventsBtn";
import UserEventsInProfile from "./userEvent/UserEventsInProfile";
import AttendEventsInProfile from "./attendEvent/AttendEventsInProfile";
import { useUserStore } from 'lib/stores/user/UserStore';


const UsersProfile = () => {
    const currentUserId = useUserStore((state) => state.user?.id);
    const {isLoading, getUsersProfileDetail } = useProfile();
    const [userData, setUserData] = useState<any>([]);
    const [selectedButton, setSelectedButton] = useState("Attend");
    const { userId } = useParams();
    const navigate = useNavigate()
    

    const handleButtonClick = (buttonText: string) => {
        setSelectedButton(buttonText);
    };
    
    useEffect(() => {
        if (userId) {
            if (userId === currentUserId) {
                navigate('/user/profile')
            } else {
                const fetchApi = async () => {
                    setUserData(await getUsersProfileDetail(userId));
                }
            
                fetchApi()
            }
        }
    },[userId])


    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} gap={"12px"}>
            <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} rounded={"16px"} gap={"16px"} padding={"16px"} bg={"gray.900"}>
                {isLoading ?
                <SkeletonCircle width={'70px'} height={'70px'} />
                :
                <Avatar width={'70px'} height={'70px'} bgGradient="linear(135deg, #2EC99E 7.03%, #B2FFEA 92.97%)" name={userData?.user?.name} src={userData?.user?.image ? userData?.user?.image : "https://bit.ly/tioluwani-kolawole"} />
                }
                {isLoading ?
                <Skeleton width={'100px'} height={'15px'} />
                :
                <Text fontSize={"20px"} fontFamily={"AVR"} color={"gray.0"} fontWeight={600}>
                    {userData?.user?.name ? userData?.user?.name : "unknown"}
                </Text>}
                <Flex gap={"12px"}>
                    <Text fontSize={"12px"} fontWeight={400} color={"gray.400"} fontFamily={"AVR"}>
                        {userData?.events?.length} host
                    </Text>
                    <Text fontSize={"12px"} fontWeight={400} color={"gray.400"} fontFamily={"AVR"}>
                        {userData?.attendances?.length} attend
                    </Text>
                    {/* <Text fontSize={"12px"} fontWeight={400} color={"gray.400"} fontFamily={"AVR"}>
                        50 followers
                    </Text> */}
                </Flex>

                <Flex alignItems={"center"} gap={"12px"}>
                    {userData?.user?.socialMediasLink?.map((item: any) => (
                        <Link key={item.name} href={`https://www.${item.name === "TELEGRAM" ? `t.me` : `${item.name.toLowerCase()}.com`}/${item.name === 'LINKEDIN' ? `in/` : ''}${item.link}`} target="_blank">
                            {item.name === "LINKEDIN" && <AppIcons.Linkedin/> }
                            {item.name === "TWITTER" && <AppIcons.Twitter/> }
                            {item.name === "TELEGRAM" && <AppIcons.Telegram/> }
                            {item.name === "INSTAGRAM" && <AppIcons.Instagram/> }
                        </Link>
                    ))}
                </Flex>
                
                <Text width={'100%'} color={"gray.600"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} textAlign={"center"} fontFamily={"AVR"}>
                    {userData?.user?.bio}
                </Text>
            </Flex>
            <Flex flexDirection={"column"} gap={"12px"}>
                <ButtonGroup width={"100%"} p={"8px"} gap={"4px"} bg={"gray.900"} rounded={"12px"} display={"flex"} justifyContent={"center"}>
                    <ProfileMyEventsBtn isSelected={selectedButton === "Attend"} text="Attend" number={userData?.attendances?.length} isLoading={isLoading} onClick={() => handleButtonClick("Attend")} />
                    <ProfileMyEventsBtn isSelected={selectedButton === "Host"} text="Host" number={userData?.events?.length} isLoading={isLoading} onClick={() => handleButtonClick("Host")} />
                </ButtonGroup>
                {selectedButton === "Host" ? <UserEventsInProfile events={userData?.events} isLoading={isLoading} isUserProfile={true} /> : <AttendEventsInProfile events={userData?.attendances} isLoading={isLoading} isUserProfile={true} />}
            </Flex>
        </Box>
    );
};

export default UsersProfile;