//Chakra
import { Button, IconButton } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Skeleton, Spinner } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";
import { useEvents } from "functions/hooks/events/useEvents";
import useAppToast from "functions/hooks/toast/useToast";
import { useProfile } from "functions/hooks/user/useProfile";
import { useEffect, useState } from "react";

const UnclaimedRewardItem = ({nftData}: {nftData: any}) => {
  const { isLoading, recieveUnclaimedRewards } = useProfile();
  const { getNewEventDetails } = useEvents();
  const { showToast } = useAppToast();

  const [eventDetail, setEventDetail] = useState<any>([])


  const claimBtnHandler = async () => {
    const recieveReward = await recieveUnclaimedRewards(nftData?._id)
    if (recieveReward) {
      showToast("Reward claimed successfully! Enjoy!", "success")
    }
  }

  const eventDetailGetter = async () => {
    const res = await getNewEventDetails(nftData?.eventID)
    setEventDetail(res)
  }

  useEffect(() => {
    eventDetailGetter()
  }, [])

  return (
    <Box
      display={"flex"}
      padding={"16px"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      alignSelf={"stretch"}
      gap={"12px"}
      borderRadius={"16px"}
      bgColor={"gray.900"}
      position={"relative"}
      width={"100%"}
      height={"auto"}
    >
      <IconButton
        _hover={{ bg: "transparent" }}
        bg={"transparent"}
        position={"absolute"}
        right={"20px"}
        top={"20px"}
        width={"32px"}
        height={"32px"}
        icon={<AppIcons.WhiteInfo />}
        aria-label="circle info"
        //   onClick={}
      />
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        bg={"green.800"}
        color={"gray.0"}
        rounded={"16px"}
        padding={"12px"}
        position={"absolute"}
        margin={"16px"}
        left={"12px"}
        right={"12px"}
        bottom={"12px"}
        // width={'468px'}
      >
        <Flex flexDirection={"column"} gap={"4px"}>
          {!eventDetail?.event?.title ?
            <Skeleton width={'50px'} height={'10px'} />
            :
            <Text fontSize={"14px"} fontWeight={"600"}>
              {`${eventDetail?.event?.title} #${nftData?.token_id}`}
            </Text>
          }
          <Text fontSize={"12px"} fontWeight={"400"}>
            {eventDetail?.event?.sold} Owners
          </Text>
        </Flex>
        <Button bg={"primary.600"} color={"primary.900"} fontFamily={"IM"} fontSize={"14px"} fontWeight={"500"} padding={"8px 12px"} _hover={{ bg: "primary.900", color: "green.800" }} onClick={claimBtnHandler} isLoading={isLoading} isDisabled={nftData?.status === "PENDING" ? true : false}>
        {nftData?.status === "PENDING" ? (
          <Flex gap={'4px'} alignItems={'center'}>
            <Text>Pending</Text>
            <Spinner size={'xs'} />
          </Flex>
          ) : (
            "Claim"
          )
        }
        </Button>
      </Flex>
      <Image width={"492px"} height={"192px"} rounded={"8px"} bgColor={'black'} alt="event banner" src={nftData?.nft_image} objectFit={"cover"} />
    </Box>
  );
};

export default UnclaimedRewardItem;
