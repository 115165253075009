import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import { Label } from "components/common/form/form-style";
import { FormikErrors } from "formik";

const EndDateTime = ({
  errors,
  values,
  handleChange,
  handleBlur,
}: {
  errors: FormikErrors<{
    event: string;
    title: string;
    description: string;
    banner: string;
    location: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
  }>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: any;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}) => {
  return (
    <Flex gap={"8px"} flexDirection={"column"}>
      <Text
        display={"flex"}
        alignItems={"center"}
        gap={"4px"}
        color={"gray.400"}
        fontFamily={"IM"}
        fontWeight={"600"}
      >
        End date{" "}
        <Text fontSize={"14px"} color={"gray.600"}>
          (Based on event local time)
        </Text>
      </Text>
      <Flex gap={"8px"}>
        <Label width={"50%"}>
          <InputGroup height={"48px"}>
            {/* <InputLeftElement mt={"3px"}>
              <AppIcons.Date />
            </InputLeftElement> */}
            <Input
              variant="unstyled"
              color={"gray.0"}
              bg={"gray.1000"}
              fontFamily={"IM"}
              type="date"
              id="endDate"
              fontSize={"14px"}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Date"
              _placeholder={{ color: "gray.800" }}
              value={values.endDate}
              padding={"12px"}
            />
          </InputGroup>
          {errors.endDate && <Text color={"red.600"}>{errors.endDate}</Text>}
        </Label>
        <Label width={"50%"}>
          <InputGroup height={"48px"}>
            {/* <InputLeftElement mt={"3px"}>
              <AppIcons.Time />
            </InputLeftElement> */}
            <Input
              type="time"
              variant="unstyled"
              color={"gray.0"}
              bg={"gray.1000"}
              fontSize={"14px"}
              fontFamily={"IM"}
              id="endTime"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Date"
              _placeholder={{ color: "gray.800" }}
              value={values.endTime}
              padding={"12px"}
            />
          </InputGroup>
          {errors.endTime && <Text color={"red.600"}>{errors.endTime}</Text>}
        </Label>
      </Flex>
    </Flex>
  );
};

export default EndDateTime;
