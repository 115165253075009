import {
  PostEventIntroduction,
  getUserEventsApi,
  UpdateEventLocationDate,
  PostEventTicket,
  getEvent,
  PostPublishEvent,
  getPublicEvents,
  getUserAttendEventsApi,
  PostEventRulesets,
  getNewEventDetail,
  deleteEvent,
  PutEventPaymentMethod,
  postEventRecord,
  getAttendees,
  postAttendId,
  getEventDetailViaTicketId,
  cancelEvent,
  UpdateEventIntroduction,
  getAttendeesData,
} from "lib/apis/events/eventsApi";
import { IEvents, useUserStore } from "lib/stores/user/UserStore";
import { useState } from "react";
import { dateConverter, stringToIdentifier } from "./utils";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "lib/apis/axiosConfig";
import { useApi } from "../useApi/useApi";
import { CircularProgress } from "@chakra-ui/react";

export function useEvents() {
  const { getApi, postApi, putApi, deleteApi } = useApi();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedin = useUserStore((state) => state.isLoggedIn);
  // const [events, setEvents] = useState<IEvents[]>();

  const getUserEvents = async () => {
    if (!isLoggedin) return [];
    if (!isLoading) {
      setIsLoading(true);
      const data: any = await getApi(getUserEventsApi());
      const events: IEvents[] = await data.as_creator;
      // setEvents(events);
      setIsLoading(false);
      return events;
    }
  };

  const getUserAttendEvents = async () => {
    if (!isLoggedin) return [];
    setIsLoading(true);
    const events: IEvents[] = await getApi(getUserAttendEventsApi()).then((data) => data?.myTicketsSerializeData);
    setIsLoading(false);
    return events;
  };

  const findNearestEvent = (events: IEvents[]) => {
    setIsLoading(true);
    const currentDate = new Date();

    const nearestEvent = events.reduce((nearest: IEvents | undefined, event: IEvents) => {
      const eventDate = new Date(event.start);

      if (eventDate > currentDate && (!nearest || eventDate < new Date(nearest.start))) {
        return event;
      }
      return nearest;
    }, undefined);

    setIsLoading(false);

    return nearestEvent;
  };

  const getEventDetail = async (eventId: string) => {
    const data = await getApi(getEvent(eventId));
    if (data) {
      const event = data?.event;
      return event;
    }
  };

  const SubmitBasicInfo = async (values: {
    event: "Event";
    title: string;
    description: string;
    banner: string;
    location: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    eventId?: string;
  }) => {
    setIsLoading(true);
    const start = dateConverter(values.startDate, values.startTime);
    const end = dateConverter(values.endDate, values.endTime);

    try {

      const props = {
        title: values.title,
        description: values.description,
        banner: values.banner,
        location: values.location,
        start: start,
        end: end,
      };

      let response;
      if (values.eventId) {
        response = await putApi(UpdateEventIntroduction(props, values.eventId));
      } else {
        response = await postApi(PostEventIntroduction(props));
      }

      if (response) {
        setIsLoading(false);
        navigate(`/user/create/tickets/${response?.event.id}`);
        return true;
      }
    } catch (err) {}
    setIsLoading(false);
  };

  const SubmitBasicTicketInfo = async (
    values: {
      title: string;
      description: string;
      price: number;
      total: number;
    }[],
    eventId: string
  ) => {
    setIsLoading(true);

    const ticketData = values.map((ticket) => ({
      title: ticket.title,
      description: ticket.description,
      price: ticket.price,
      total: ticket.total,
    }));

    try {
      const dataResponce = await putApi(PostEventTicket(eventId, ticketData));
      if (dataResponce) {
        navigate(`/user/create/rulesets/${eventId}`);
        return true;
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const SubmitRuleset = async (
    FinalValues: { gated: string; discount: number; nft_identifiers: string[]; tickets: string[] }[],
    eventId: string
  ) => {
    setIsLoading(true);

    const rulsetData = FinalValues.map((rules) => ({
      gated: rules.gated === "Gated",
      discount: rules.discount || 0,
      nft_identifiers: rules.nft_identifiers,
      tickets: rules.tickets,
      blockchain: "POLYGON",
      nftsCount: 1,
    }));

    try {
      const dataResponce = await putApi(PostEventRulesets(eventId, rulsetData));
      if (dataResponce) {
        navigate(`/user/create/acceptPayment/${eventId}`);
        return true;
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const onPaymentSubmit = async (
    values: {
      toggleSwitch: boolean;
    },
    eventId: string
  ) => {
    navigate(`/user/create/deployEvent/${eventId}`);
    return true;
  };

  const publishEvent = async (eventId: string) => {
    try {
      setIsLoading(true);
      const publishResponse = await postApi(PostPublishEvent(eventId));
      if (publishResponse) {
        // getUserEvents();
        setIsLoading(false);
        navigate(`/user/create/publishEvent/${eventId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Explore Events
  const getExploreEvents = async () => {
    setIsLoading(true);
    try {
      const response = await getApi(getPublicEvents());
      if (response) {
        setIsLoading(false);
        return response.events;
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const getNewEventDetails = async (eventId: string) => {
    setIsLoading(true);
    const res = await getApi(getNewEventDetail(eventId));
    if (res) {
      setIsLoading(false);
      return res;
    } else {
      setIsLoading(false);
    }
  };

  const deletingEvent = async (eventId: string | undefined) => {
    try {
      const deletedData = await deleteApi(deleteEvent(eventId));
      if (deletedData) {
        return true;
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const cancelingEvent = async (eventId: string) => {
    setIsLoading(true);
    const res = await deleteApi(cancelEvent(eventId));
    if (res) {
      console.log("event canceled");
    }
    setIsLoading(false);
  };

  const putPaymentMethod = async (eventId: string, paymentMethod: any) => {
    setIsLoading(true);
    const res = await putApi(PutEventPaymentMethod(eventId, paymentMethod));
    if (res) {
      navigate(`/user/create/deployEvent/${eventId}`);
    }
    setIsLoading(false);
  };

  const eventRecord = async (data: any) => {
    setIsLoading(true);
    const res = await postApi(postEventRecord("POLYGON", data));
    if (res) {
      console.log("event record success");
    }
    setIsLoading(false);
  };

  const attendeesData = async (id: string) => {
    setIsLoading(true);
    const res = await getApi(getAttendees(id));
    if (res) {
      return res.attendee;
    }
    setIsLoading(false);
  };

  const attendeesDataWithEventId = async (eventId: string) => {
    setIsLoading(true);
    const res = await getApi(getAttendeesData(eventId));
    if (res) {
      return res.attendee;
    }
    setIsLoading(false)
  }

  const checkInAttendId = async (eventId: string, attendId: string) => {
    setIsLoading(true);
    const res = await postApi(
      postAttendId(eventId, {
        attendee: attendId,
      })
    );
    if (res) {
      setIsLoading(false);
      return res;
    }
    setIsLoading(false);
  };

  const eventDetailViaTicketId = async (ticketId: string) => {
    setIsLoading(true);
    const res = await getApi(getEventDetailViaTicketId(ticketId));
    if (res) {
      return res[0];
    }
    setIsLoading(false);
  };

  return {
    getUserEvents,
    getUserAttendEvents,
    // events,
    SubmitBasicInfo,
    isLoading,
    SubmitBasicTicketInfo,
    onPaymentSubmit,
    getEventDetail,
    publishEvent,
    getExploreEvents,
    SubmitRuleset,
    findNearestEvent,
    getNewEventDetails,
    deletingEvent,
    putPaymentMethod,
    cancelEvent,
    eventRecord,
    attendeesData,
    checkInAttendId,
    eventDetailViaTicketId,
    cancelingEvent,
    attendeesDataWithEventId,
  };
}
