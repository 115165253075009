import { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

//Chakra
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";

//Components
import { CustomForm } from "components/common/form/form-style";
import EventsStepper from "components/common/stepper/EventsStepper";
import EventCreationBackBtn from "components/common/back-btn/EventCreationBackBtn";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Schemas
import { AcceptPaymentSchema } from "schemas/schema";
import { IUser, useUserStore } from "lib/stores/user/UserStore";
import AppIcons from "assets/icons/AppIcons";
import CustomSwitch from "components/common/custom-switch/CustomSwitch";
import { useState } from "react";
import useAppToast from "functions/hooks/toast/useToast";
import { useProfile } from "functions/hooks/user/useProfile";



const AcceptPayment = () => {
  const navigate = useNavigate();
  // const user = useUserStore((state) => state.user);
  const { isLoading, onPaymentSubmit, putPaymentMethod } = useEvents();
  const { showToast } = useAppToast();
  const { eventId } = useParams();
  const [stripeValue , setStipeValue] = useState(false)
  const { getCurrentUserData } = useProfile()
  const [currentUserData, setCurrentUserData] = useState<any>([])


  useEffect(() => {
    const fetchApi = async () => {
      setCurrentUserData(await getCurrentUserData());
    }

    fetchApi()
  }, [])
  

    // Navigate back to the rulesets Page route
    const handleBack = () => {
      navigate(`/user/create/rulesets/${eventId}`);
    };

  // const { values, handleChange, errors, touched, handleSubmit } = useFormik({
  //   initialValues: {
  //     toggleSwitch: stripeValue,
  //   },
  //   validationSchema: AcceptPaymentSchema,
  //   onSubmit: async () => {
  //     if (eventId) {
  //       onPaymentSubmit(
  //         {
  //           toggleSwitch: values.toggleSwitch,
  //         },
  //         eventId
  //       );
  //     }
  //   },
  // });


  //Switchs state
  const [switchStates, setSwitchStates] = useState<Record<string, boolean>>({
    // STACKS: false,
    POLYGON: false,
    // BTC: true,
    // ETH: true,
    // SOL: true,
  });

  //Handle Switchs value
  const handleSwitchClick = (name: string) => {
    setSwitchStates((prevSwitchStates) => ({
      ...prevSwitchStates,
      [name]: !prevSwitchStates[name],
    }));
  };

  const switchHandler = (name: string) => {
    if (name === "STRIPE") {
      setStipeValue(!stripeValue)
    }else {
      handleSwitchClick(name)
    }
  }

  const activePaymentMethods: string[] = [];
  if (stripeValue) {
    activePaymentMethods.push("STRIPE");
  }
  if (switchStates["POLYGON"]) {
    activePaymentMethods.push("POLYGON");
  }

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (eventId && (stripeValue || switchStates["POLYGON"])) {
      // onPaymentSubmit(
      //   {
      //     toggleSwitch: stripeValue,
      //   },
      //   eventId
      // );
      putPaymentMethod(eventId, activePaymentMethods);
    } else {
      showToast('You must choose at least one item','warning');
    }
  }
  

  const switchIcon = (content: string) => {
    let icon;
    
    switch (content) {
      case "STRIPE":
        icon = <></>;
      break;

      case "STACKS":
        icon = <AppIcons.STXToken/>;
      break;

      case "POLYGON":
        icon = <AppIcons.MATICToken/>;
      break;

      case "BTC":
        icon = <AppIcons.BTCToken/>;
      break;

      case "ETH":
        icon = <AppIcons.ETHToken/>
      break;

      case "SOL":
        icon = <AppIcons.SOLToken/>
      break;

      default:
          <AppIcons.STXToken />;
    }

    return icon;
  }

  return (
    <Box position={'relative'}>
      <EventCreationBackBtn onClick={handleBack} />
      <CustomForm autoComplete="off" onSubmit={submitHandler}>
        <EventsStepper step={3} />
        <Flex flexDirection={"column"} rounded={"16px"} gap={"16px"} padding={"16px"} bg={"gray.900"}>
          <Flex
            flexDirection={'column'}
            alignItems={'flex-start'}
            gap={'24px'}
            alignSelf={'stretch'}
          >
            {currentUserData?.paymentMethods?.length > 0 ? 
              currentUserData?.paymentMethods?.map((item: any) => (
                item?.isActive && (
                  <Flex key={item?.name} gap={"8px"} alignItems={"center"}>
                      <CustomSwitch isChecked={item?.name === "STRIPE" ? stripeValue : switchStates[item?.name]} onClickHandler={() => switchHandler(item?.name)} />
                      {switchIcon(item?.name)}
                      <Text color={"gray.0"} fontFamily={"AVR"} fontSize={"16px"} fontWeight={"400"}>
                          {item?.name === "STRIPE" ? "Stripe" : (item?.address?.length >= 20 ? `${item?.address?.slice(0, 4)}...${item?.address?.slice(38, )}` : item?.address)}
                      </Text>
                  </Flex>
              )
              ))
              :
              <Spinner color='primary.600'/>
            }
          </Flex>
        </Flex>
        <Button 
          type="submit" 
          bg={"primary.600"} 
          color={"primary.900"} 
          fontFamily={"IM"} 
          fontSize={"16px"} 
          fontWeight={500} 
          position={"fixed"} 
          bottom={"16px"} 
          right={"16px"} 
          left={"16px"}
          _hover={{bgColor: 'primary.600'}}
          _disabled={{bgColor: 'gray.900', color: 'gray.700'}}
          _active={{bgColor: 'primary.900'}}
          isDisabled={isLoading || !(stripeValue || switchStates["POLYGON"])}
        >
          {isLoading ? <Spinner color="white" /> : "Next"}
        </Button>
      </CustomForm>
    </Box>
  );
};

export default AcceptPayment;
