import { Link, useNavigate } from "react-router-dom";

//Chakra
import { Button, Flex, IconButton, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";
import { useUserStore } from "lib/stores/user/UserStore";

interface NavbarProps {
    title: string;
    dontShowCreateBtn?: boolean;
}

const Navbar : React.FC<NavbarProps> = ({title, dontShowCreateBtn}) => {
    const jwtToken = useUserStore((state) => state.jwtToken);

    const navigate = useNavigate();

    const btnClickHandler = (btnName: string) => {
        if (btnName === 'login') {
            navigate('/signin')
        } else if (btnName === 'register') {
            navigate('/signup')
        }
    }

    return (
        <nav>
            <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={'20px'}
            >
                <Flex 
                alignItems={'center'}
                justifyContent={'center'}
                gap={'10px'}
                >
                    <Text 
                    color={"gray.0"} 
                    fontFamily={"AVSB"} 
                    fontSize={'30px'} 
                    fontWeight={600} 
                    lineHeight={'36px'}
                    flex={'1 0 0'}
                    >
                        {title}
                    </Text>
                </Flex>
                {jwtToken ? 
                <Flex
                    gap={'12px'}
                    alignItems={'center'}
                    alignSelf={'stretch'}
                >
                    <IconButton
                        _hover={{ bg: "transparent" }}
                        bg={"transparent"}
                        aria-label="Notifications"
                        icon={<AppIcons.Notification />}
                    />
                    {!dontShowCreateBtn && 
                    <Link to={`/user/create/info`}>
                        <Button 
                        fontSize={'16px'} 
                        fontFamily={'IM'} 
                        fontWeight={500}
                        color={'primary.900'}
                        bgColor={'primary.600'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        alignSelf={'stretch'}
                        gap={'8px'}
                        padding={'12px 16px'}
                        borderRadius={'8px'}
                        _hover={{ bg: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E" }}
                        _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
                        _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
                        >
                            Create event
                        </Button>
                    </Link>}
                </Flex>
                :
                <Flex alignItems={'center'} gap={'12px'}>
                    <Button display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'8px 12px'} borderRadius={'8px'} bgColor={'green.800'} color={'gray.0'} height={'32px'} _hover={{ bg: "green.800" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'gray.900', color: 'gray.100'}} onClick={() => btnClickHandler('login')} fontFamily={'IM'} fontSize={'14px'} fontWeight={500}>Login</Button>
                    <Button display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'8px 12px'} borderRadius={'8px'} bgColor={'primary.600'} color={'primary.900'} height={'32px'} _hover={{ bg: "primary.600" }} _disabled={{bgColor: 'gray.900', color: 'gray.700'}} _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}} onClick={() => btnClickHandler('register')} fontFamily={'IM'} fontSize={'14px'} fontWeight={500}>Register</Button>
                </Flex>
                }
            </Flex>
        </nav>
    );
};

export default Navbar;
