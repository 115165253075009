import { useNavigate } from "react-router-dom";

//Chakra
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";

//Components
import AttendItems from "./attendItems/AttendItems";
import { useProfile } from "functions/hooks/user/useProfile";
import { useEffect, useState } from "react";

const AttendEventsInProfile = ({events , isLoading, isUserProfile, claimedRewards}: {events: any , isLoading: boolean, isUserProfile?: boolean, claimedRewards?: any}) => {
  const navigate = useNavigate();

  return isLoading ? (
    <AttendItems events={events} isUserProfile={isUserProfile} claimedRewards={claimedRewards} isLoading={isLoading} />
  ) : events?.length > 0 ? (
    <AttendItems events={events} isUserProfile={isUserProfile} claimedRewards={claimedRewards} />
  ) : (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      alignSelf={"stretch"}
      rounded={"16px"}
      gap={"12px"}
      padding={"16px"}
      bg={"gray.900"}
      height={"497px"}
    >
      <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} alignSelf={"stretch"} gap={"8px"}>
        <Text color={"gray.0"} textAlign={"center"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"} fontFamily={"AVR"}>
          No attend record to show
        </Text>
        {!isUserProfile && 
        <Text color={"gray.600"} textAlign={"center"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} fontFamily={"AVR"}>
          Check out events now!
        </Text>}
      </Flex>
      {!isUserProfile && 
      <Button
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"6px"}
        width={"120px"}
        height={'32px'}
        padding={"8px 12px"}
        borderRadius={"8px"}
        bgColor={"primary.600"}
        color={"gray.50"}
        fontFamily={"IM"}
        fontWeight={500}
        fontSize={"16px"}
        _hover={{ bg: "primary.600" }}
        _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
        _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E'}}
        onClick={() => navigate('/user/home')}
      >
        Find an event
      </Button>}
    </Box>
  );
};

export default AttendEventsInProfile;
