import { useNavigate } from 'react-router-dom';

//Chakra
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

const SaveDraftBtn = ({eventId}: string | any) => {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()


    return (
        <>
            <Button
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                alignSelf={"flex-end"}
                gap={"8px"}
                bg={"green.800"}
                color={"gray.0"}
                padding={"12px 16px"}
                onClick={() => onOpen()}
            >
                <Text
                    fontSize={"16px"}
                    fontWeight={500}
                    color={"gray.0"}
                    fontFamily={"IM"}
                >
                    Save draft
                </Text>
            </Button>


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={'gray.900'} alignSelf={'center'}>
                <ModalHeader>
                    <Text color={'gray.0'} fontFamily={'AVR'} fontSize={'18px'} fontWeight={600}>
                        Save as draft
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text color={'gray.600'} fontFamily={'IM'} fontSize={'14px'} fontWeight={400} alignSelf={'stretch'}>
                    Would you like to save your work before leaving this page?
                    </Text>
                </ModalBody>

                <ModalFooter display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'16px'} width={'100%'}>
                    <Button 
                    bgColor={'green.800'} 
                    color={'gray.0'}
                    width={'100%'}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    onClick={() => {
                        onClose()
                    }}
                    >
                        Close
                    </Button>
                    <Button
                    bgColor={"primary.600"}
                    color={"primary.900"}
                    width={'100%'}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    _hover={{ bgColor: "primary.600" }}
                    _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                    _active={{ bgColor: "primary.900" }}
                    onClick={() => {
                        navigate(`/user/create/saveDraft/${eventId}`)
                        onClose()
                    }}
                    >
                        Save as draft
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SaveDraftBtn;