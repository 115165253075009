import { useState, useEffect } from "react";

//Chakra
import { Badge, Box, Flex, Text } from "@chakra-ui/react";

//Components
import Navbar from "components/desktop-layout/navbar/Navbar";

//Components (Tabs)
import Host from "./host/Host";
import Attend from "./attend/Attend";

//Interfaces
import { IEvents, useUserStore } from "lib/stores/user/UserStore";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

const MyEvents = () => {
  const jwtToken = useUserStore((state) => state.jwtToken);

  const {isLoading, getUserAttendEvents, getUserEvents } = useEvents();
  const [selectedTab, setSelectedTab] = useState("attend")

  //Attend Events
  const [attendEvents, setAttendEvents] = useState<IEvents[]>([]);

  //Host Events
  const [hostEvents, setHostEvents] = useState<IEvents[]>([]);


  const removeDeletedEvent = (eventId: string) => {
    const eventToUpdate = hostEvents.find((event) => event._id === eventId);

    if (eventToUpdate && eventToUpdate.status === "PUBLISHED") {
      eventToUpdate.status = "Draft";
      setHostEvents([...hostEvents]);
    } else {
      const updatedEvents = hostEvents.filter((event) => event._id !== eventId);
      setHostEvents(updatedEvents);
    }
  };
  

  useEffect(() => {

    //Attend
    const fetchAttendData = async () => {
      const res = await getUserAttendEvents();
      if (res) {
        setAttendEvents(res);
      }
    };
    jwtToken && fetchAttendData();

    //Host
    const fetchHostData = async () => {
      const res = await getUserEvents();
      if (res) {
        setHostEvents(res);
      }
    };
    jwtToken && fetchHostData();
  }, []);

  useEffect(() => {}, []);

  return (
    <Box padding={"48px"}>
      <Navbar title="My events" />
      <Flex alignItems={"center"} gap={"4px"} width={"fit-content"} padding={'8px'} marginY={'20px'} borderRadius={'12px'} bgColor={'gray.900'}>
        <Flex
          alignItems={"center"}
          justifyContent={'center'}
          padding={'8px 12px'}
          gap={"4px"}
          cursor={"pointer"}
          width={'105px'}
          borderRadius={'8px'}
          bgColor={selectedTab === 'attend' ? 'green.800' : ''}
          onClick={() => {
            setSelectedTab("attend")
          }}
        >
          <Text fontFamily={"IM"} fontSize={"16px"} fontWeight={500} color={selectedTab === 'attend' ? "gray.0" : "gray.600"}>
            Attend
          </Text>
          {attendEvents?.length > 0 &&
          <Badge color={selectedTab === 'attend' ? "gray.900" : "gray.1000"} bgColor={selectedTab === 'attend' ? "gray.0" : "gray.600"} borderRadius={"27px"} padding={"0 4px"}>
            {attendEvents?.length}
          </Badge>}
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={'center'}
          padding={'8px 12px'}
          gap={"4px"}
          cursor={"pointer"}
          width={'105px'}
          borderRadius={'8px'}
          bgColor={selectedTab === 'host' ? 'green.800' : ''}
            onClick={() => {
              setSelectedTab("host")
          }}
        >
          <Text fontFamily={"IM"} fontSize={"16px"} fontWeight={500} color={selectedTab === 'host' ? "gray.0" : "gray.600"}>
            Host
          </Text>
          {hostEvents?.length > 0 && 
          <Badge color={selectedTab === 'host' ? "gray.900" : "gray.1000"} bgColor={selectedTab === 'host' ? "gray.0" : "gray.600"} borderRadius={"27px"} padding={"0 4px"}>
            {hostEvents?.length}
          </Badge>}
        </Flex>
      </Flex>
      {selectedTab === 'host' ? 
        <Host isLoading={isLoading} events={hostEvents} removeDeletedEvent={removeDeletedEvent} />
        :
        <Attend isLoading={isLoading} events={attendEvents} />
      }
    </Box>
  );
};

export default MyEvents;
