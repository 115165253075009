import { Flex, IconButton, Skeleton, Text } from "@chakra-ui/react";
import React from "react";

const IconQRcodeBox = ({
  primaryTitle,
  boxIcon,
  label,
  onClickShowModal,
  isLoading,
}: {
  primaryTitle: string;
  boxIcon:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  label?: string;
  onClickShowModal: () => void;
  isLoading: boolean;
}) => {
  return (
    <Flex flexDir={"column"} gap={"8px"} width={"100%"}>
      <Text
        color={"gray.0"}
        fontSize={"14px"}
        fontFamily={"AVSB"}
        fontWeight={600}
        textAlign={"left"}
      >
        {label}
      </Text>
      <Flex
        gap={"12px"}
        padding={"8px"}
        border={"1px"}
        borderColor={"gray.700"}
        alignItems={"center"}
        rounded={"12px"}
        width={"full"}
        onClick={onClickShowModal}
      >
        <IconButton
          _hover={{ bg: "transparent" }}
          bg={"transparent"}
          aria-label="document"
          icon={boxIcon}
        />
        {!isLoading ? 
        <Text
          color={"gray.0"}
          fontSize={"14px"}
          fontFamily={"AVSB"}
          fontWeight={600}
          textAlign={"left"}
        >
          {primaryTitle}
        </Text>
        :
        <Skeleton width={'80px'} height={'15px'} />
        }
      </Flex>
    </Flex>
  );
};

export default IconQRcodeBox;
