import { useState, useContext, useEffect, useCallback } from "react";
import { useFormik } from "formik";

//Chakra
import { Box, Flex, Input, InputGroup, InputLeftElement, Select, Text, Textarea, IconButton, Spinner } from "@chakra-ui/react";

//Components
import { CustomForm, Label } from "components/common/form/form-style";
import InputImage from "pages/event-creation/basic-info-page/ImageUpload";
import DoormanEmailInput from "./doorman-email-input/DoormanEmailInput";
import NextBtn from "components/desktop-common/next-btn/NextBtn";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Contexts
import { CreateEventDataContext } from "../../../../contexts/CreateEventDataContextProvider";

//Schemas
import { introPageSchema } from "schemas/schema";
import { useParams } from "react-router-dom";
import { IEvents } from "lib/stores/user/UserStore";


const BasicInfo = () => {
    const { updateEventData } = useContext(CreateEventDataContext);

    const [isBasicInfoCollaps, setIsBasicInfoCollaps] = useState(true);
    const [isTimeCollaps, setIsTimeCollaps] = useState(true);
    const [isDoormanCollaps, setIsDoormanCollaps] = useState(false);

    // Handle date and time value
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const updateDateTime = () => {
        const newDateTime = new Date();
        setCurrentDateTime(newDateTime);

        const newTime = newDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        setValues(prevValues => ({ ...prevValues, startTime: newTime, endTime: newTime }));
    };

    // Effect to start updating date and time every one minute
    useEffect(() => {
        const intervalId = setInterval(updateDateTime, 60000);

        return () => clearInterval(intervalId);
    }, []);

    // Extracting current date and time
    const currentDate = currentDateTime.toISOString().split("T")[0];
    const currentTime = currentDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    const { eventId } = useParams();
    const { getUserEvents } = useEvents();
    const [image, setImage] = useState("");
    const { SubmitBasicInfo, isLoading } = useEvents();
    const [eventsData, setEventsData] = useState<IEvents[]>([]);
    const initialFormValues = {
        event: "Event",
        title: "",
        description: "",
        banner: image,
        location: "",
        startDate: currentDate,
        startTime: currentTime,
        endDate: currentDate,
        endTime: currentTime,
    };
    // const navigate = useNavigate();

    const { handleChange, values, errors, handleSubmit, isValid, dirty, setValues, setFieldValue } = useFormik({
        initialValues: initialFormValues,
        validationSchema: introPageSchema,
        onSubmit: async () => {
            const ok = await SubmitBasicInfo({
                event: "Event",
                title: values.title,
                description: values.description,
                banner: image,
                location: values.location,
                startDate: values.startDate,
                startTime: values.startTime,
                endDate: values.endDate,
                endTime: values.endTime,
                eventId: eventId,
            });
            if (ok) {
                // Call the callback function to move to the next step
                // navigate(`../tickets/${eventId}`);
            }
        },
    });

    const inputsData = 
        {
            event: "Event",
            title: values.title,
            description: values.description,
            banner: image,
            location: values.location,
            startDate: values.startDate,
            startTime: values.startTime,
            endDate: values.endDate,
            endTime: values.endTime,
        };

    const initialFunction = useCallback(() => {
        const getEvent = async () => {
            if (eventId) {
                const events = await getUserEvents();
                if (events) {
                const filteredEventData = events.filter((event) => event._id === eventId);
                setEventsData(filteredEventData);
                setImage(filteredEventData[0].banner);
                if (filteredEventData) {
                    // Update the initial form values with the data from eventsData
                    setValues({
                    event: "Event",
                    title: filteredEventData[0].title || "",
                    description: filteredEventData[0].description || "",
                    banner: filteredEventData[0].banner || image,
                    location: filteredEventData[0].location || "",
                    startDate: filteredEventData[0].start
                        ? new Date(filteredEventData[0].start).toISOString().split("T")[0]
                        : "",
                    startTime: filteredEventData[0].start
                        ? new Date(filteredEventData[0].start).toISOString().split("T")[1].slice(0, 5)
                        : "",
                    endDate: filteredEventData[0].end ? new Date(filteredEventData[0].end).toISOString().split("T")[0] : "",
                    endTime: filteredEventData[0].end
                        ? new Date(filteredEventData[0].end).toISOString().split("T")[1].slice(0, 5)
                        : "",
                    });
                }
                }
            }
            };
    
            getEvent();
    }, [eventId])


    useEffect(() => {
        updateEventData(inputsData);
        
    }, [eventId, values, image]);   
    
    useEffect(() => {
        initialFunction()

    }, [eventId])

    if (eventId) {
        if (eventsData.length === 0) {
            <Spinner size="lg" color="primary.600" />;
        }
    }

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"24px"}
            flex={"1 0 0"}
            alignSelf={"stretch"}
            borderRadius={"12px"}
        >
            <Flex
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"8px"}
                alignSelf={"stretch"}
            >
                <Text
                    fontSize={"20px"}
                    fontWeight={600}
                    fontFamily={"AVSB"}
                    color={"gray.0"}
                    width={"343px"}
                >
                    Event details
                </Text>
                <Text
                    fontSize={"14px"}
                    fontWeight={400}
                    fontFamily={"AVSB"}
                    color={"gray.600"}
                    alignSelf={"stretch"}
                >
                    Setup your attractive event
                </Text>
            </Flex>
            <CustomForm autoComplete="off" onSubmit={handleSubmit} width={"100%"}>
                <Flex
                    flexDirection={"column"}
                    rounded={"16px"}
                    gap={"16px"}
                    padding={"16px"}
                    bg={"gray.900"}
                    boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
                >
                    <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        alignSelf={"stretch"}
                    >
                        <Text
                            fontSize={"18px"}
                            fontWeight={600}
                            fontFamily={"AVB"}
                            color={"gray.0"}
                        >
                            Basic Info
                        </Text>
                        <IconButton
                            aria-label="collapse button"
                            display={"flex"}
                            padding={"12px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"8px"}
                            borderRadius={"8px"}
                            bgColor={"green.800"}
                            onClick={() => setIsBasicInfoCollaps(!isBasicInfoCollaps)}
                        >
                        {isBasicInfoCollaps ? (
                            <AppIcons.ChevronUp />
                        ) : (
                            <AppIcons.ChevronDown />
                        )}
                        </IconButton>
                    </Flex>

                    {isBasicInfoCollaps && (
                        <>
                            {/* <Label>
                                <Text
                                    fontSize={"16px"}
                                    color={"gray.400"}
                                    fontFamily={"AVSB"}
                                    fontWeight={"600"}
                                >
                                    Event Type
                                </Text>
                                <Select
                                    _hover={{ backgroundColor: "gray.1000" }}
                                    size={"lg"}
                                    fontFamily={"AVR"}
                                    fontSize={"16px"}
                                    variant="filled"
                                    bg={"gray.1000"}
                                    rounded={"8px"}
                                    placeholder="Select Event Type"
                                    color={"gray.500"}
                                >
                                    <option value="Event">Event</option>
                                </Select>
                            </Label> */}
                            <Label>
                                <Text
                                    fontSize={"16px"}
                                    color={"gray.400"}
                                    fontFamily={"AVSB"}
                                    fontWeight={"600"}
                                >
                                    Name
                                </Text>
                                <Input
                                    variant="unstyled"
                                    border={errors?.title && "1px"}
                                    borderColor={errors?.title && "error.600"}
                                    color={"gray.0"}
                                    bg={"gray.1000"}
                                    fontFamily={"AVR"}
                                    type="text"
                                    id="title"
                                    onChange={handleChange}
                                    placeholder="Name"
                                    padding={"12px"}
                                    _placeholder={{ color: "gray.800" }}
                                    value={values.title}
                                />
                                <Flex justifyContent={"space-between"} alignItems={"center"}>
                                <Text
                                    color={values?.title?.length < 75 ? "gray.600" : "error.600"}
                                    textAlign={"right"}
                                    fontFamily={"IM"}
                                >
                                    {values?.title?.length}/75
                                </Text>
                                {errors.title && (
                                    <Text color={"red.600"}>{errors.title}</Text>
                                )}
                                </Flex>
                            </Label>
                            <Label>
                                <Text
                                    fontSize={"16px"}
                                    color={"gray.400"}
                                    fontFamily={"AVSB"}
                                    fontWeight={"600"}
                                >
                                    Description
                                </Text>
                                <Textarea
                                    variant="unstyled"
                                    border={errors?.title && "1px"}
                                    borderColor={errors?.title && "error.600"}
                                    height={"120px"}
                                    color={"gray.0"}
                                    bg={"gray.1000"}
                                    fontFamily={"AVR"}
                                    id="description"
                                    rounded={"12px"}
                                    onChange={handleChange}
                                    placeholder="Description"
                                    padding={"12px"}
                                    _placeholder={{ color: "gray.800" }}
                                    value={values?.description}
                                />
                                <Flex justifyContent={"space-between"} alignItems={"center"}>
                                <Text
                                    color={values?.description?.length < 1000 ? "gray.600" : "error.600"}
                                    textAlign={"right"}
                                    fontFamily={"IM"}
                                >
                                    {values?.description?.length}/1000
                                </Text>
                                {errors.description && (
                                    <Text color={"red.600"}>{errors.description}</Text>
                                )}
                                </Flex>
                            </Label>
                            <InputImage image={values.banner || image} setImage={setImage} setFieldValue={setFieldValue} />
                        </>
                    )}
                </Flex>
                <Flex
                    flexDirection={"column"}
                    rounded={"16px"}
                    gap={"16px"}
                    padding={"16px"}
                    bg={"gray.900"}
                >
                <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                >
                    <Text
                        fontSize={"18px"}
                        fontWeight={600}
                        fontFamily={"AVB"}
                        color={"gray.0"}
                    >
                        Location & Time
                    </Text>
                    <IconButton
                        aria-label="collapse button"
                        display={"flex"}
                        padding={"12px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"8px"}
                        borderRadius={"8px"}
                        bgColor={"green.800"}
                        onClick={() => setIsTimeCollaps(!isTimeCollaps)}
                    >
                    {isTimeCollaps ? (
                        <AppIcons.ChevronUp />
                    ) : (
                        <AppIcons.ChevronDown />
                    )}
                    </IconButton>
                </Flex>
                {isTimeCollaps && (
                    <Flex flexDirection={"column"} gap={"16px"}>
                        <Label>
                            <Text color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
                                Location
                            </Text>
                            <Text
                                color={"gray.600"}
                                fontSize={"14px"}
                                fontFamily={"AVR"}
                                fontWeight={"400"}
                            >
                                Help people in the area discover your event & allow attendees
                                auto check-in
                            </Text>
                            <InputGroup height={"48px"}>
                                <InputLeftElement mt={"2px"}>
                                    <AppIcons.Location />
                                </InputLeftElement>
                                <Input
                                    variant="unstyled"
                                    color={"gray.0"}
                                    bg={"gray.1000"}
                                    fontFamily={"AVR"}
                                    type="text"
                                    id="location"
                                    onChange={handleChange}
                                    placeholder="What's the address?"
                                    _placeholder={{ color: "gray.800" }}
                                    value={values?.location}
                                />
                            </InputGroup>
                            {errors.location && <Text color={"red.600"}>{errors.location}</Text>}
                        </Label>
                        <Flex gap={"8px"} flexDirection={"column"}>
                            <Text display={'flex'} alignItems={'center'} gap={'4px'} color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
                                Start date <Text fontSize={'14px'} color={"gray.600"}>(Based on event local time)</Text>
                            </Text>
                            <Flex gap={"8px"}>
                            <Label width={"50%"}>
                                <InputGroup height={"48px"}>
                                    {/* <InputLeftElement mt={"3px"}>
                                        <AppIcons.Date />
                                    </InputLeftElement> */}
                                    <Input
                                        variant="unstyled"
                                        color={"gray.0"}
                                        bg={"gray.1000"}
                                        fontFamily={"AVR"}
                                        type="date"
                                        id="startDate"
                                        fontSize={"14px"}
                                        onChange={handleChange}
                                        placeholder="Date"
                                        _placeholder={{ color: "gray.800" }}
                                        value={values?.startDate}
                                        padding={"12px"}
                                    />
                                </InputGroup>
                                {errors.startDate && <Text color={"red.600"}>{errors.startDate}</Text>}
                            </Label>
                            <Label width={"50%"}>
                                <InputGroup height={"48px"}>
                                    {/* <InputLeftElement mt={"3px"}>
                                        <AppIcons.Time />
                                    </InputLeftElement> */}
                                    <Input
                                        type="time"
                                        variant="unstyled"
                                        color={"gray.0"}
                                        bg={"gray.1000"}
                                        fontSize={"14px"}
                                        fontFamily={"AVR"}
                                        id="startTime"
                                        onChange={handleChange}
                                        placeholder="Time"
                                        _placeholder={{ color: "gray.800" }}
                                        value={values.startTime}
                                        padding={"12px"}
                                    />
                                </InputGroup>
                                {errors.startTime && <Text color={"red.600"}>{errors.startTime}</Text>}
                            </Label>
                            </Flex>
                        </Flex>
                        <Flex gap={"8px"} flexDirection={"column"}>
                            <Text display={'flex'} alignItems={'center'} gap={'4px'} color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
                                End date <Text fontSize={'14px'} color={"gray.600"}>(Based on event local time)</Text>
                            </Text>
                            <Flex gap={"8px"}>
                                <Label width={"50%"}>
                                    <InputGroup height={"48px"}>
                                        {/* <InputLeftElement mt={"3px"}>
                                            <AppIcons.Date />
                                        </InputLeftElement> */}
                                        <Input
                                            variant="unstyled"
                                            color={"gray.0"}
                                            bg={"gray.1000"}
                                            fontFamily={"AVR"}
                                            type="date"
                                            id="endDate"
                                            fontSize={"14px"}
                                            onChange={handleChange}
                                            placeholder="Date"
                                            _placeholder={{ color: "gray.800" }}
                                            value={values.endDate}
                                            padding={"12px"}
                                        />
                                    </InputGroup>
                                    {errors.endDate && <Text color={"red.600"}>{errors.endDate}</Text>}
                                </Label>
                                <Label width={"50%"}>
                                    <InputGroup height={"48px"}>
                                        {/* <InputLeftElement mt={"3px"}>
                                            <AppIcons.Time />
                                        </InputLeftElement> */}
                                        <Input
                                            type="time"
                                            variant="unstyled"
                                            color={"gray.0"}
                                            bg={"gray.1000"}
                                            fontSize={"14px"}
                                            fontFamily={"AVR"}
                                            id="endTime"
                                            onChange={handleChange}
                                            placeholder="Date"
                                            _placeholder={{ color: "gray.800" }}
                                            value={values.endTime}
                                            padding={"12px"}
                                        />
                                    </InputGroup>
                                    {errors.endTime && <Text color={"red.600"}>{errors.endTime}</Text>}
                                </Label>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
                </Flex>
                <Box
                    display={"flex"}
                    alignItems={"flex-start"}
                    alignSelf={"stretch"}
                    flexDirection={"column"}
                    gap={"16px"}
                    padding={"16px"}
                    borderRadius={"16px"}
                    bgColor={"gray.900"}
                >
                <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                >
                    <Text
                        fontSize={"18px"}
                        fontWeight={600}
                        fontFamily={"AVB"}
                        color={"gray.0"}
                    >
                        Additional info
                    </Text>
                    <IconButton
                        aria-label="collapse button"
                        display={"flex"}
                        padding={"12px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"8px"}
                        borderRadius={"8px"}
                        bgColor={"green.800"}
                        onClick={() => setIsDoormanCollaps(!isDoormanCollaps)}
                    >
                    {isDoormanCollaps ? (
                        <AppIcons.ChevronUp />
                    ) : (
                        <AppIcons.ChevronDown />
                    )}
                    </IconButton>
                </Flex>
                {isDoormanCollaps && (
                    <Flex
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        alignSelf={"stretch"}
                        gap={"4px"}
                        borderRadius={"4px"}
                    >
                        <Text
                            fontSize={"14px"}
                            fontWeight={600}
                            fontFamily={"AVSB"}
                            color={"gray.400"}
                        >
                            Doorman (Optional)
                        </Text>
                        <DoormanEmailInput />
                        {/* {errors.doormanEmail && <Text color={"red.600"}>{errors.doormanEmail}</Text>} */}
                    </Flex>
                )}
                </Box>
                <Flex
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={"12px"}
                    alignSelf={"stretch"}
                >
                    {/* {dirty && <SaveDraftBtn />} */}
                    <NextBtn isLoading={isLoading} isBtnDisabled={isLoading || !isValid || !dirty} />
                </Flex>
            </CustomForm>
        </Box>
    );
};

export default BasicInfo;
