import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import AppIcons from 'assets/icons/AppIcons';
import BackBtn from 'components/common/back-btn/BackBtn';
import EventCreationBackBtn from 'components/common/back-btn/EventCreationBackBtn';
import IconAndDetailBox from 'components/common/icon-and-detail-box/IconAndDetailBox';
import NextBtn from 'components/desktop-common/next-btn/NextBtn';
import SaveDraftBtn from 'components/desktop-common/save-draft-btn/SaveDraftBtn';
import { CreateEventDataContext } from 'contexts/CreateEventDataContextProvider';
import { FieldArray, Form, Formik, useFormik } from 'formik';
import { useEvents } from 'functions/hooks/events/useEvents';
import { IEvents } from 'lib/stores/user/UserStore';
import TicketComponent from 'pages/event-creation/ticket-details/parts/TicketComponent';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ticketDitailSchema } from 'schemas/schema';

export interface Ticket {
    title: string;
    description: string;
    price: number;
    total: number;
    id: string;
}

interface initialValuesType {
    tickets: Ticket[];
}

const initialValues: initialValuesType = {
    tickets: [],
};

const TicketDetails = () => {
    const { updateTicketData, updateEventData } = useContext(CreateEventDataContext);
    const { eventId } = useParams();
    const navigate = useNavigate();
    const { getUserEvents } = useEvents();
    const { isLoading, SubmitBasicTicketInfo } = useEvents();
    const [eventsData, setEventsData] = useState<IEvents[]>([]);
    const formikRef = useRef<any>(null);
    const [ticketData, setTicketData] = useState<any>()
    const [eventData, setEventData] = useState<any>()

    const { handleChange, values, errors, handleSubmit, isValid, dirty, setValues } = useFormik({
        initialValues: initialValues,
        onSubmit: async () => {
            // console.log("Form submitted:", values);
            if (eventId) {
                const result = await SubmitBasicTicketInfo(values.tickets, eventId);
                if (result) {
                    // do something
                }
            }
        },
        validationSchema: ticketDitailSchema,
    });


    useEffect(() => {
        const getEvent = async () => {
            if (eventId) {
                const events = await getUserEvents();
                if (events) {
                    const filteredEventData = events.filter((event) => event._id === eventId);
                    setEventsData(filteredEventData);
                    if (filteredEventData.length > 0) {
                        const initialTickets = filteredEventData[0].tickets.map((t) => ({
                        title: t.title,
                        description: t.description,
                        price: t.price,
                        total: t.total,
                        id: t.id,
                        }));

                        const initialEventData = {
                            title: filteredEventData[0].title || "",
                            description: filteredEventData[0].description || "",
                            banner: filteredEventData[0].banner || "",
                            location: filteredEventData[0].location || "",
                            startDate: filteredEventData[0].start
                                ? new Date(filteredEventData[0].start).toISOString().split("T")[0]
                                : "",
                            startTime: filteredEventData[0].start
                                ? new Date(filteredEventData[0].start).toISOString().split("T")[1].slice(0, 5)
                                : "",
                            endDate: filteredEventData[0].end ? new Date(filteredEventData[0].end).toISOString().split("T")[0] : "",
                            endTime: filteredEventData[0].end
                                ? new Date(filteredEventData[0].end).toISOString().split("T")[1].slice(0, 5)
                                : "",
                        }

                        setEventData(initialEventData)
                        
                        if (formikRef.current) {
                            formikRef.current.setValues({ tickets: initialTickets });
                        }
                    }
                }
            }
        };

        getEvent();
    }, [eventId]);

    useEffect(() => {
        updateTicketData(ticketData)
        if (eventData) {
            updateEventData(eventData)
        }

    }, [ticketData])

    
    if (eventId) {
        if (eventsData.length === 0) {
            <Spinner size="lg" color="primary.600" />;
        }
    }

    return (
        <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"24px"}
            flex={"1 0 0"}
            alignSelf={"stretch"}
            borderRadius={"12px"}
        >
            <Flex
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"8px"}
                alignSelf={"stretch"}
            >
                <Text
                    fontSize={"20px"}
                    fontWeight={600}
                    fontFamily={"AVSB"}
                    color={"gray.0"}
                    width={"343px"}
                >
                    Ticket details
                </Text>
                <Text
                    fontSize={"14px"}
                    fontWeight={400}
                    fontFamily={"AVSB"}
                    color={"gray.600"}
                    alignSelf={"stretch"}
                >
                    Create tickets for your event with custom options such as VIP or discounts. For free events, simply set the ticket price to 0.
                </Text>
            </Flex>
            <Box width={'100%'}>
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    onSubmit={async (values: any) => {
                        // console.log("values:", JSON.stringify(values.tickets, null, 2));
                        if (eventId) {
                            let result = await SubmitBasicTicketInfo(values.tickets, eventId);
                        }
                    }}
                    validationSchema={ticketDitailSchema}
                >
                    {({ values, errors, handleChange, isValid, dirty }) => (
                    <Form>
                        <FieldArray name="tickets">
                        {({ insert, remove, push }: { insert: any; remove: any; push: any }) => (
                            <Flex flexDirection={"column"} gap={"16px"} marginBottom={"55px"} width={'100%'}>
                            {values.tickets.length > 0 ? (
                                <>
                                    {setTicketData(values.tickets)}
                                    {values.tickets.map((t: Ticket, index: number) => (
                                    <Box key={index} rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                                        <TicketComponent
                                            index={index}
                                            remove={remove}
                                            errors={errors}
                                            t={t}
                                            handleChange={handleChange}
                                            />
                                    </Box>
                                    ))}
                                </>
                            ) : (
                                <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                                    <IconAndDetailBox
                                        boxIcon={<AppIcons.Stars />}
                                        primaryTitle="No ticket setup yet"
                                        secondaryTitle="Set up a ticket now"
                                        />
                                </Box>
                            )}
                                <Button
                                    padding={"8px"}
                                    height={"32px"}
                                    fontFamily={"IM"}
                                    fontSize={"14px"}
                                    color={"gray.0"}
                                    fontWeight={500}
                                    bg={"green.800"}
                                    onClick={() => push({ title: "", description: "", price: "", total: "" })}
                                >
                                    + Add new ticket
                                </Button>
                        
                        
                                <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    alignSelf={"stretch"}
                                >
                                    <Flex alignSelf={"center"}>
                                        <BackBtn optionalUrl={`/user/create/info/${eventId}`} />
                                    </Flex>
                                    <Flex
                                    justifyContent={"flex-end"}
                                    alignItems={"center"}
                                    gap={"12px"}
                                    >
                                        <SaveDraftBtn eventId={eventId} />
                                        <NextBtn isLoading={isLoading} isBtnDisabled={isLoading || !isValid || !dirty} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                        </FieldArray>
                    </Form>
                    )}
                </Formik>
            </Box>
        </Flex>
    );
};

export default TicketDetails;