import { Flex, IconButton, Input, InputGroup, InputLeftElement, Text, Textarea } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import { Label } from "components/common/form/form-style";
import { FormikErrors, getIn } from "formik";
import { Ticket } from "../InterfaceTicket";

const TicketComponent = ({ index, remove, errors, t, handleChange }: { index: number; remove: any; errors: FormikErrors<any>; t: Ticket; handleChange: (e: React.ChangeEvent<any>) => void }) => {
  return (
    <Flex flexDirection={"column"} gap={"16px"}>
      <Flex justifyContent={"space-between"}>
        <Text fontFamily={"AVB"} fontWeight={600} fontSize={"18px"} color={"gray.0"}>
          {`Ticket ${index + 1}`}
        </Text>
        <IconButton _hover={{ bg: "transparent" }} bg={"transparent"} icon={<AppIcons.Delete />} aria-label="upload icon" onClick={() => remove(index)} />
      </Flex>
      <Text color={"gray.600"} fontSize={"14px"} fontFamily={"AVR"} fontWeight={"400"}>
        The public ticket allows you to sell a ticket to anyone with the corresponding wallet address
      </Text>
      <Label>
        <Text color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
          Name
        </Text>
        <Input
          variant="unstyled"
          color={"gray.0"}
          bg={"gray.1000"}
          border={t?.title?.length >= 25 ? "1px" : "0px"}
          borderColor={t?.title?.length >= 25 ? "error.600" : ""}
          fontFamily={"AVR"}
          type="text"
          id="title"
          name={`tickets.${index}.title`}
          placeholder="Name"
          padding={"12px"}
          _placeholder={{ color: "gray.800" }}
          value={t.title}
          onChange={handleChange}
        />
        <Flex justifyContent={"space-between"} alignItems={"center"} marginBottom={"8px"}>
          {/* <Text color={"red.600"}>{getIn(errors, `tickets[${index}].title`)}</Text> */}
          <Text color={t?.title?.length < 25 ? "gray.600" : "error.600"} width={"100%"} textAlign={"right"} fontFamily={"IM"}>
            {t.title.length}/25
          </Text>
        </Flex>
      </Label>
      <Label>
        <Text color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
          Description
        </Text>
        <Textarea
          height={"20px"}
          variant="unstyled"
          color={"gray.0"}
          bg={"gray.1000"}
          border={t?.description?.length >= 1000 ? "1px" : "0px"}
          borderColor={t?.description?.length >= 1000 ? "error.600" : ""}
          fontFamily={"AVR"}
          id="description"
          rounded={"12px"}
          name={`tickets.${index}.description`}
          onChange={handleChange}
          placeholder="Description"
          padding={"12px"}
          _placeholder={{ color: "gray.800" }}
          value={t.description}
        />
        <Flex justifyContent={"space-between"} alignItems={"center"} marginBottom={"8px"}>
          {/* <Text color={"red.600"}>{getIn(errors, `tickets[${index}].description`)}</Text> */}
          <Text color={t?.description?.length < 100 ? "gray.600" : "error.600"} width={"100%"} textAlign={"right"} fontFamily={"IM"}>
            {t?.description?.length}/100
          </Text>
        </Flex>
      </Label>
      <Flex justifyContent={"space-between"} gap={"16px"}>
        <Flex flexDirection={"column"}>
          <Text color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
            Price
          </Text>
          <Label>
            <InputGroup height={"48px"}>
              <InputLeftElement mt={"3px"}>
                <AppIcons.CurrencyDollar />
              </InputLeftElement>
              <Input
                variant="unstyled"
                color={"gray.0"}
                bg={"gray.1000"}
                fontFamily={"AVR"}
                type="number"
                id="price"
                fontSize={"14px"}
                name={`tickets.${index}.price`}
                onChange={handleChange}
                placeholder="0"
                _placeholder={{ color: "gray.800" }}
                value={t.price}
              />
            </InputGroup>
            <Text color={"red.600"}>{getIn(errors, `tickets[${index}].price`)}</Text>
          </Label>
        </Flex>
        <Flex flexDirection={"column"}>
          <Text color={"gray.400"} fontFamily={"AVSB"} fontWeight={"600"}>
            # of ticket
          </Text>
          <Label>
            <InputGroup height={"48px"}>
              <InputLeftElement mt={"3px"}>
                <AppIcons.Ticket />
              </InputLeftElement>
              <Input
                type="number"
                variant="unstyled"
                color={"gray.0"}
                bg={"gray.1000"}
                fontSize={"14px"}
                fontFamily={"AVR"}
                id="total"
                name={`tickets.${index}.total`}
                onChange={handleChange}
                placeholder="0"
                _placeholder={{ color: "gray.800" }}
                value={t.total}
              />
            </InputGroup>
            <Text color={"red.600"}>{getIn(errors, `tickets[${index}].total`)}</Text>
          </Label>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TicketComponent;
