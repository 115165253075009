import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
//Main layout
import Layout from "../components/layout/Layout";
//Protected route
import Dashboard from "../pages/dashboard/Dashboard";
//Pages
import Register from "pages/register/Register";
import MyEvents from "pages/my-events/MyEvents";
import EventCreation from "pages/event-creation/EventCreation";
import IntroPage from "pages/event-creation/basic-info-page/IntroPage";
import AcceptPayment from "pages/event-creation/accept-payment/AcceptPayment";
import DeployEvent from "pages/event-creation/deploy-activate-event/DeployEvent";
import EventPage from "pages/my-events/event/EventPage";
import PublishEvent from "pages/event-creation/publish-success-event/PublishEvent";
import SaveDraft from "pages/event-creation/save-draft/SaveDraft";
import Purchase from "pages/purchase/Purchase";
import ReceiveTicket from "pages/purchase/receiveTicket/ReceiveTicket";
import CheckTicket from "pages/purchase/receiveTicket/CheckTicket";
import Login from "pages/register/Login";
import Profile from "pages/profile/Profile";
import EditProfile from "pages/profile/EditProfile";
import Settings from "pages/settings/Settings";
import StripePage from "pages/stripe/StripePage";
import LoginHome from "pages/home/logedinHomePage/LoginHome";
import PaymentSetup from "pages/settings/PaymentSetup";
import EmailConfirmation from "pages/register/EmailConfirmation";
import VerifyEmail from "pages/register/VerifyEmail";
import TicketDetail from "pages/profile/ticketDetail/TicketDetail";
import TicketDitailPage from "pages/event-creation/ticket-details/TicketDitailPage";
import RulesetsPage from "pages/event-creation/ticket-rulesets-new/RulesetsPage";
import ForgetPassword from "pages/register/ForgetPassword";
import ResetPassword from "pages/register/ResetPassword";
import UsersProfile from "pages/profile/UsersProfile";
import QrcodePage from "pages/profile/qrcodePage/QrcodePage";
import SuccessLogin from "pages/register/SuccessLogin";
import ErrorPage from "pages/error-page/ErrorPage";
import Onboarding from "pages/register/Onboarding";
import ProfileQrCode from "pages/settings/ProfileQrCode"
import ThanksPage from "pages/purchase/thanks-page/ThanksPage";

function AppRoutes() {
  return (
    <Suspense fallback={<h1>loading</h1>}>
      <Routes>
        <Route element={<Layout />} errorElement={<ErrorPage />}>
          <Route path="/">
            <Route index element={<LoginHome />}></Route>
            <Route path="/signup" element={<Register />}></Route>
            <Route path="/signin" element={<Login />}></Route>
            <Route path="/confirmation" element={<EmailConfirmation />}></Route>
            <Route path="/verify" element={<VerifyEmail />}></Route>
            <Route path="/forget-password" element={<ForgetPassword/>} />
            <Route path="/reset-password" element={<ResetPassword/>} />
            <Route path="/auth-callback" element={<SuccessLogin/>} />
            <Route path="/onboarding" element={<Onboarding/>} />
            <Route path="/oops" element={<ErrorPage/>} />
          </Route>

          <Route path="user/" element={<Dashboard />}>
            <Route path="home/" element={<LoginHome />}></Route>
            <Route path="purchase/:eventId" element={<Purchase />}></Route>
            <Route path="purchase/receive/:eventId" element={<ReceiveTicket />}></Route>
            <Route path="purchase/check/:eventId" element={<CheckTicket />}></Route>
            <Route path="purchase/thank-you/:orderId" element={<ThanksPage/>} />
            <Route path="profile/" element={<Profile />}></Route>
            <Route path="profile/:userId" element={<UsersProfile />}></Route>
            <Route path="profile/editprofile/" element={<EditProfile />}></Route>
            <Route path="profile/ticketDetail/:eventId" element={<TicketDetail />}></Route>
            <Route path="profile/qrscanner" element={<QrcodePage />} ></Route>
            <Route path="ticketDetail/:eventId" element={<TicketDetail />}></Route>
            <Route path="settings/" element={<Settings />} />
            <Route path="settings/paymentsetup" element={<PaymentSetup />} />
            <Route path="settings/share-profile" element={<ProfileQrCode/>} />
            <Route path="events/" element={<MyEvents />}></Route>
            <Route path="events/:eventId" element={<EventPage />}></Route>
            <Route path="create/" element={<EventCreation />}>
              <Route path="info/" element={<IntroPage />}></Route>
              <Route path="info/:eventId" element={<IntroPage />}></Route>
              <Route path="tickets/:eventId" element={<TicketDitailPage />}></Route>
              <Route path="rulesets/:eventId" element={<RulesetsPage />}></Route>
              <Route path="acceptPayment/:eventId" element={<AcceptPayment />}></Route>
              <Route path="deployEvent/:eventId" element={<DeployEvent />}></Route>
              <Route path="publishEvent/:eventId" element={<PublishEvent />}></Route>
              <Route path="saveDraft/:eventId" element={<SaveDraft />}></Route>
            </Route>
            <Route path="payment/stripe/:orderId" element={<StripePage />}></Route>
            {/* <Route path="basic-info" element={<IntroPage />}></Route> */}
          </Route>
          <Route path="*" element={<>notFound</>} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
