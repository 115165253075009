import { Ticket } from "./InterfaceTicket";
import { FieldArray, Form, Formik } from "formik";
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import TicketComponent from "./parts/TicketComponent";
import { useEvents } from "functions/hooks/events/useEvents";
import IconAndDetailBox from "components/common/icon-and-detail-box/IconAndDetailBox";
import AppIcons from "assets/icons/AppIcons";
// import BackBtn from "components/common/back-btn/BackBtn";
import EventsStepper from "components/common/stepper/EventsStepper";
import { ticketDitailSchema } from "schemas/schema";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { IEvents } from "lib/stores/user/UserStore";
import EventCreationBackBtn from "components/common/back-btn/EventCreationBackBtn";
import { getEvent, handleBack } from "./parts/functions/TicketFunctions";

interface initialValuesType {
  tickets: Ticket[];
}

const initialValues: initialValuesType = {
  tickets: [],
};

const TicketDitailPage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { getUserEvents } = useEvents();
  const { isLoading, SubmitBasicTicketInfo } = useEvents();
  const [eventsData, setEventsData] = useState<IEvents[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    if (eventId) {
      getEvent({ eventId, formikRef, setEventsData, getUserEvents })
        .then(() => {
          setDataLoaded(true);
        })
        .catch((error) => {
          console.error("Error fetching event data:", error);
        });
    }
  }, [eventId]);

  useEffect(() => {
    if (dataLoaded && eventsData.length > 0) {
      const initialTickets = eventsData[0].tickets.map((t) => ({
        title: t.title,
        description: t.description,
        price: t.price,
        total: t.total,
        id: t.id,
      }));
      if (formikRef.current) {
        formikRef.current.setValues({ tickets: initialTickets });
      }
    }
  }, [dataLoaded, eventsData]);

  if (!dataLoaded) {
    // if (eventsData.length === 0) {
      return <Spinner size="lg" color="primary.600" />;
    // }
  }

  return (
    <>
      <EventCreationBackBtn onClick={() => handleBack({ eventId, navigate })} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={async (values: any) => {
          // console.log("values:", JSON.stringify(values.tickets, null, 2));
          if (eventId) {
            let result = await SubmitBasicTicketInfo(values.tickets, eventId);
          }
        }}
        validationSchema={ticketDitailSchema}
      >
        {({ values, errors, handleChange, isValid, dirty }) => (
          <Form>
            <FieldArray name="tickets">
              {({ insert, remove, push }: { insert: any; remove: any; push: any }) => (
                <Flex flexDirection={"column"} gap={"16px"} marginBottom={"55px"}>
                  <EventsStepper step={1} />
                  {values.tickets.length > 0 ? (
                    values.tickets.map((t: Ticket, index: number) => (
                      <Box key={index} rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                        <TicketComponent
                          index={index}
                          remove={remove}
                          errors={errors}
                          t={t}
                          handleChange={handleChange}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                      <IconAndDetailBox
                        boxIcon={<AppIcons.Stars />}
                        primaryTitle="No ticket setup yet"
                        secondaryTitle="Set up a ticket now"
                      />
                    </Box>
                  )}
                  <Button
                    padding={"8px"}
                    height={"32px"}
                    fontFamily={"IM"}
                    fontSize={"14px"}
                    color={"gray.0"}
                    fontWeight={500}
                    bg={"green.800"}
                    onClick={() => push({ title: "", description: "", price: "", total: "" })}
                  >
                    + Add new ticket
                  </Button>
              
                  {values.tickets.length >= 1 &&
                  <Button
                    type="submit"
                    bg={"primary.600"}
                    color={"primary.900"}
                    fontFamily={"IM"}
                    fontSize={"16px"}
                    fontWeight={500}
                    _hover={{ bgColor: "primary.600" }}
                    _disabled={{ bgColor: "gray.900", color: "gray.700" }}
                    _active={{ bgColor: "primary.900" }}
                    isDisabled={isLoading || !isValid || !dirty}
                  >
                    {isLoading ? <Spinner color="white" /> : "Next"}
                  </Button>}
                </Flex>
              )}
            </FieldArray>
            {values.tickets.length < 1 && 
            <Button
              mt={"80%"}
              type="submit"
              bg={"primary.600"}
              color={"primary.900"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
              position={"fixed"}
              left={"16px"}
              right={"16px"}
              bottom={"16px"}
              _hover={{ bgColor: "primary.600" }}
              _disabled={{ bgColor: "gray.900", color: "gray.700" }}
              _active={{ bgColor: "primary.900" }}
              isDisabled={isLoading || !isValid || !dirty}
            >
              {isLoading ? <Spinner color="white" /> : "Next"}
            </Button>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TicketDitailPage;
