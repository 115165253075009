import { Box, useCheckbox, useRadio } from "@chakra-ui/react";

const ChooseTicketForRuleset = (props: any) => {
  const { getInputProps, getCheckboxProps, state, htmlProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" {...htmlProps}>
      <input {...input} name={props.name} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="100px"
        boxShadow="md"
        bg={state.isChecked ? "#2EC99E" : "gray.700"}
        color={state.isChecked ? "white" : "gray.0"}
        px={"13px"}
        py={"4px"}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ChooseTicketForRuleset;
