class AppStorage {
  static accessToken = () => {
    const userStore = localStorage.getItem("userStore");
    return userStore ? JSON.parse(userStore).state.jwtToken : null;
  };
  static clearStorage = () => {
    localStorage.clear();
  };
}

export default AppStorage;
