import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

//Chakra
import { Box, Flex, Spinner, Switch, Text } from '@chakra-ui/react';

//Components
import BackBtn from 'components/common/back-btn/BackBtn';
import SaveDraftBtn from 'components/desktop-common/save-draft-btn/SaveDraftBtn';
import NextBtn from 'components/desktop-common/next-btn/NextBtn';
import { CustomForm } from 'components/common/form/form-style';

//Custom Hooks
import { useEvents } from 'functions/hooks/events/useEvents';

//Schemas
import { AcceptPaymentSchema } from 'schemas/schema';
import CustomSwitch from 'components/common/custom-switch/CustomSwitch';
import useAppToast from 'functions/hooks/toast/useToast';
import { useContext, useEffect, useState } from 'react';
import { useProfile } from 'functions/hooks/user/useProfile';
import AppIcons from 'assets/icons/AppIcons';
import { CreateEventDataContext } from 'contexts/CreateEventDataContextProvider';


const PaymentMethods = () => {
    const { updateTicketData, updateEventData } = useContext(CreateEventDataContext);

    const navigate = useNavigate();
    // const user = useUserStore((state) => state.user);
    const { isLoading, onPaymentSubmit, putPaymentMethod, getUserEvents } = useEvents();
    const { showToast } = useAppToast();
    const { eventId } = useParams();
    const [stripeValue , setStipeValue] = useState(false)
    const { getCurrentUserData } = useProfile()
    const [currentUserData, setCurrentUserData] = useState<any>([])
    const [eventData, setEventData] = useState<any>()
    const [ticketData, setTicketData] = useState<any>()


    useEffect(() => {
        const fetchApi = async () => {
        setCurrentUserData(await getCurrentUserData());

        const events = await getUserEvents();
        if (events) {
            const filteredEventData = events.filter((event) => event._id === eventId);

            if (filteredEventData?.length > 0) {
            const initialTickets = filteredEventData[0].tickets.map((t) => ({
                title: t.title,
                description: t.description,
                price: t.price,
                total: t.total,
                id: t.id,
                }));

                const initialEventData = {
                    title: filteredEventData[0].title || "",
                    description: filteredEventData[0].description || "",
                    banner: filteredEventData[0].banner || "",
                    location: filteredEventData[0].location || "",
                    startDate: filteredEventData[0].start
                        ? new Date(filteredEventData[0].start).toISOString().split("T")[0]
                        : "",
                    startTime: filteredEventData[0].start
                        ? new Date(filteredEventData[0].start).toISOString().split("T")[1].slice(0, 5)
                        : "",
                    endDate: filteredEventData[0].end ? new Date(filteredEventData[0].end).toISOString().split("T")[0] : "",
                    endTime: filteredEventData[0].end
                        ? new Date(filteredEventData[0].end).toISOString().split("T")[1].slice(0, 5)
                        : "",
                }

                setEventData(initialEventData)
                setTicketData(initialTickets)
            }
            
            
        }
        
        }

        fetchApi()
    }, [eventId])

    useEffect(() => {
        if (eventData && ticketData) {
            updateEventData(eventData)
            updateTicketData(ticketData)
        }
    }, [eventData, ticketData])

    //Switchs state
    const [switchStates, setSwitchStates] = useState<Record<string, boolean>>({
        // STACKS: false,
        POLYGON: false,
        // BTC: true,
        // ETH: true,
        // SOL: true,
    });

    //Handle Switchs value
    const handleSwitchClick = (name: string) => {
        setSwitchStates((prevSwitchStates) => ({
        ...prevSwitchStates,
        [name]: !prevSwitchStates[name],
        }));
    };

    const switchHandler = (name: string) => {
        if (name === "STRIPE") {
        setStipeValue(!stripeValue)
        }else {
        handleSwitchClick(name)
        }
    }

    const activePaymentMethods: string[] = [];
    if (stripeValue) {
        activePaymentMethods.push("STRIPE");
    }
    if (switchStates["POLYGON"]) {
        activePaymentMethods.push("POLYGON");
    }

    const submitHandler = (e: any) => {
        e.preventDefault();
        if (eventId && (stripeValue || switchStates["POLYGON"])) {
        onPaymentSubmit(
            {
            toggleSwitch: stripeValue,
            },
            eventId
        );
        putPaymentMethod(eventId, activePaymentMethods);
        } else {
        showToast('You must choose at least one item','warning');
        }
    }
    

    const switchIcon = (content: string) => {
        let icon;
        
        switch (content) {
        case "STRIPE":
            icon = <></>;
        break;

        case "STACKS":
            icon = <AppIcons.STXToken/>;
        break;

        case "POLYGON":
            icon = <AppIcons.MATICToken/>;
        break;

        case "BTC":
            icon = <AppIcons.BTCToken/>;
        break;

        case "ETH":
            icon = <AppIcons.ETHToken/>
        break;

        case "SOL":
            icon = <AppIcons.SOLToken/>
        break;

        default:
            <AppIcons.STXToken />;
        }

        return icon;
    }

    return (
        <Box
            display={'flex'}
            alignItems={'flex-start'}
            alignSelf={'stretch'}
            gap={'24px'}
            flexDirection={'column'}
            width={"100%"}
        >
            <Flex
                flexDirection={'column'}
                alignItems={'flex-start'}
                gap={'8px'}
                alignSelf={'stretch'}
            >
                <Text fontSize={'20px'} fontWeight={600} fontFamily={'AVSB'} color={'gray.0'}>Acceptance payment methods</Text>
                <Text fontSize={'14px'} fontWeight={400} fontFamily={'AVR'} color={'gray.600'} alignSelf={'stretch'}>Choose the payment methods you would like to use for receiving payments.</Text>
            </Flex>
            <CustomForm autoComplete="off" onSubmit={submitHandler} width={'100%'}>
                <Flex flexDirection={"column"} rounded={"16px"} gap={"16px"} padding={"16px"} bg={"gray.900"}>
                    <Flex flexDirection={'column'} gap={"16px"} alignItems={'flex-start'}>
                        {currentUserData?.paymentMethods?.length > 0 ? 
                            currentUserData?.paymentMethods?.map((item: any) => (
                                <Flex key={item?.name} gap={"8px"} alignItems={"center"}>
                                <CustomSwitch isChecked={item?.name === "STRIPE" ? stripeValue : switchStates[item?.name]} onClickHandler={() => switchHandler(item?.name)} />
                                {switchIcon(item?.name)}
                                <Text color={"gray.0"} fontFamily={"AVR"} fontSize={"16px"} fontWeight={"400"}>
                                    {item?.name === "STRIPE" ? "Stripe" : (item?.address?.length >= 20 ? `${item?.address?.slice(0, 4)}...${item?.address?.slice(38, )}` : item?.address)}
                                </Text>
                                </Flex>
                            ))
                            :
                            <Spinner color='primary.600'/>
                        }
                    </Flex>
                </Flex>

                <Flex
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    alignSelf={'stretch'}
                >
                    <Flex alignSelf={'center'}>
                        <BackBtn optionalUrl={`/user/create/rulesets/${eventId}`}/>
                    </Flex>
                    <Flex
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        gap={'12px'}
                    >
                        <SaveDraftBtn eventId={eventId} />
                        <NextBtn isLoading={isLoading} isBtnDisabled={isLoading || !(stripeValue || switchStates["POLYGON"])}/>
                    </Flex>
                </Flex>
            </CustomForm>
        </Box>
    );
};

export default PaymentMethods;