import { Box, Flex, IconButton, Skeleton, Text } from "@chakra-ui/react";
import React from "react";

const IconInfoBox = ({
  primaryTitle,
  boxIcon,
  label,
  secondaryTitle,
  isInTicketDetail,
}: {
  boxIcon:
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | undefined;
  primaryTitle?: string;
  label?: string;
  secondaryTitle?: string;
  isInTicketDetail?: boolean;
}) => {
  return (
    <Flex flexDir={"column"} gap={"8px"} width={'100%'}>
      <Text
        color={"gray.0"}
        fontSize={"14px"}
        fontFamily={"AVSB"}
        fontWeight={600}
        textAlign={"left"}
      >
        {label}
      </Text>
      <Flex
        gap={"12px"}
        padding={"8px"}
        border={"1px"}
        borderColor={"gray.700"}
        alignItems={"center"}
        rounded={"12px"}
        width={"full"}
        justifyContent={'space-between'}
      >
        <Flex alignItems={'center'} gap={'12px'}>
          <IconButton
            _hover={{ bg: "transparent" }}
            bg={"transparent"}
            aria-label="document"
            icon={boxIcon}
            cursor={'default'}
          />
          <Flex flexDir={"column"} gap={"4px"}>
            {primaryTitle ? 
            <Text
              color={"gray.0"}
              fontSize={"14px"}
              fontFamily={"AVSB"}
              fontWeight={600}
              textAlign={"left"}
              overflowWrap={'break-word'} 
              wordBreak={'break-word'}
            >
              {primaryTitle}
            </Text>
            :
            <Skeleton width={'150px'} height={'10px'} />
            }
            {secondaryTitle ? 
              <Text
                color={"gray.500"}
                fontSize={"12px"}
                fontFamily={"AVR"}
                fontWeight={400}
                textAlign={"left"}
              >
                {secondaryTitle}
              </Text>
              : 
              (label !== "Location" && label !== "Event") && (<Skeleton width={'100px'} height={'5px'} />)
            }
          </Flex>
        </Flex>
        {isInTicketDetail && <Text fontSize={'14px'} fontFamily={'AVSB'} fontWeight={400} color={'primary.600'}>Details</Text>}
      </Flex>
    </Flex>
  );
};

export default IconInfoBox;
