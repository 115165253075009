import { useEffect, useState } from "react";

//Chakra
import { Box, Button, Flex, Input, InputGroup, InputLeftAddon, Text, Textarea } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Custom Hooks
import { useProfile } from "functions/hooks/user/useProfile";

//Components
import UserProfileIMG from "./parts/UserProfileIMG";
import BackBtn from "components/common/back-btn/BackBtn";

import { SocialMediaEnum, useUserStore } from "lib/stores/user/UserStore";
import PrimaryBtn from "components/common/primary-btn/PrimaryBtn";

const EditProfile = () => {
  const user = useUserStore((state) => state.user);

  const [image, setImage] = useState(user.image);
  const [nameValue, setNameValue] = useState(user.name);
  const [bioValue, setBioValue] = useState(user.bio);
  const [linkedinLink, setLinkedinLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");

  useEffect(() => {
    user.socialMediasLink.forEach((item) => {
      item.name === SocialMediaEnum.LINKEDIN && setLinkedinLink(item.link);
      item.name === SocialMediaEnum.TWITTER && setTwitterLink(item.link);
      item.name === SocialMediaEnum.TELEGRAM && setTelegramLink(item.link);
      item.name === SocialMediaEnum.INSTAGRAM && setInstagramLink(item.link);
    });
  }, [user.socialMediasLink]);

  const { isLoading, updateProfile } = useProfile();

  const submitHandler = () => {
    updateProfile({
      name: nameValue,
      bio: bioValue,
      image: image,
      socialMediasLink: [
        {
          name: SocialMediaEnum.LINKEDIN,
          link: linkedinLink,
        },
        {
          name: SocialMediaEnum.TWITTER,
          link: twitterLink,
        },
        {
          name: SocialMediaEnum.TELEGRAM,
          link: telegramLink,
        },
        {
          name: SocialMediaEnum.INSTAGRAM,
          link: instagramLink,
        },
      ],
    });
  };

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gap={"12px"} position={"relative"}>
      <BackBtn />
      <Flex
        width={"100%"}
        minWidth={"300px"}
        height={"718px"}
        padding={"16px"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"16px"}
        borderRadius={"12px"}
        bgColor={"gray.900"}
        marginBottom={"60px"}
      >
        <Text color={"gray.0"} fontSize={"18px"} fontWeight={600} lineHeight={"28px"}>
          Edit profile
        </Text>
        <Flex alignItems={"flex-start"} flexDirection={"column"} gap={"8px"} alignSelf={"stretch"}>
          <Text color={"gray.0"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"}>
            Profile pic
          </Text>
          <UserProfileIMG image={image} setImage={setImage} />
        </Flex>
        <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"4px"} alignSelf={"stretch"} borderRadius={"4px"}>
          <Flex alignItems={"center"} gap={"8px"}>
            <Text color={"gray.400"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"}>
              Name
            </Text>
          </Flex>
          <Input
            alignItems={"center"}
            alignSelf={"stretch"}
            gap={"10px"}
            padding={"12px"}
            bgColor={"gray.1000"}
            placeholder="Name"
            border={"none"}
            borderRadius={"8px"}
            color={"gray.0"}
            flex={"1 0 0"}
            value={nameValue}
            onChange={(e: any) => setNameValue(e.target.value)}
          />
        </Flex>
        <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"4px"} alignSelf={"stretch"} borderRadius={"4px"}>
          <Flex alignItems={"center"} gap={"8px"}>
            <Text color={"gray.400"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"}>
              Bio
            </Text>
          </Flex>
          <Textarea
            alignItems={"center"}
            alignSelf={"stretch"}
            gap={"10px"}
            padding={"12px"}
            bgColor={"gray.1000"}
            placeholder="about me"
            border={"none"}
            borderRadius={"12px"}
            color={"gray.0"}
            flex={"1 0 0"}
            height={"120px"}
            value={bioValue}
            onChange={(e: any) => setBioValue(e.target.value)}
          />
          <Text color={bioValue.length < 100 ? "gray.800" : "red"} textAlign={"right"} fontFamily={"IM"} fontSize={"14px"} fontWeight={400} display={"flex"} alignSelf={"flex-end"}>
            {bioValue.length}/100
          </Text>
        </Flex>
        <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"12px"} alignSelf={"stretch"}>
          <Text color={"gray.400"} fontSize={"16px"} fontWeight={600} lineHeight={"24px"}>
            Social Media
          </Text>
          <Flex flexDirection={"column"} width={"100%"} minWidth={"300px"} alignItems={"flex-start"} flex={"1 0 0"} gap={"12px"} borderRadius={"4px"}>
            <InputGroup>
              <InputLeftAddon display={"flex"} alignItems={"center"} gap={"5px"} bgColor={"gray.850"} border={"none"} width={"220px"}>
                <AppIcons.Linkedin width={"20px"} height={"20px"} />
                <Text color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                  https://linkedin.com/
                </Text>
              </InputLeftAddon>
              <Input
                padding={"12px"}
                alignItems={"center"}
                gap={"10px"}
                alignSelf={"stretch"}
                border={"none"}
                borderRadius={"8px"}
                bgColor={"gray.1000"}
                color={"gray.300"}
                placeholder="Linkedin ID"
                fontSize={"16px"}
                fontWeight={400}
                value={linkedinLink}
                onChange={(e) => setLinkedinLink(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon display={"flex"} alignItems={"center"} gap={"5px"} bgColor={"gray.850"} border={"none"} width={"220px"}>
                <AppIcons.Twitter width={"20px"} height={"20px"} />
                <Text color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                  https://twitter.com/
                </Text>
              </InputLeftAddon>
              <Input
                padding={"12px"}
                alignItems={"center"}
                gap={"10px"}
                alignSelf={"stretch"}
                border={"none"}
                borderRadius={"8px"}
                bgColor={"gray.1000"}
                color={"gray.300"}
                placeholder="Twitter ID"
                fontSize={"16px"}
                fontWeight={400}
                value={twitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon display={"flex"} alignItems={"center"} gap={"5px"} bgColor={"gray.850"} border={"none"} width={"220px"}>
                <AppIcons.Telegram width={"20px"} height={"20px"} />
                <Text color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                  https://t.me/
                </Text>
              </InputLeftAddon>
              <Input
                padding={"12px"}
                alignItems={"center"}
                gap={"10px"}
                alignSelf={"stretch"}
                border={"none"}
                borderRadius={"8px"}
                bgColor={"gray.1000"}
                color={"gray.300"}
                placeholder="Telegram ID"
                fontSize={"16px"}
                fontWeight={400}
                value={telegramLink}
                onChange={(e) => setTelegramLink(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon display={"flex"} alignItems={"center"} gap={"5px"} bgColor={"gray.850"} border={"none"} width={"220px"}>
                <AppIcons.Instagram width={"20px"} height={"20px"} />
                <Text color={"gray.0"} fontSize={"16px"} fontWeight={400}>
                  https://instagram.com/
                </Text>
              </InputLeftAddon>
              <Input
                padding={"12px"}
                alignItems={"center"}
                gap={"10px"}
                alignSelf={"stretch"}
                border={"none"}
                borderRadius={"8px"}
                bgColor={"gray.1000"}
                color={"gray.300"}
                placeholder="Instagram ID"
                fontSize={"16px"}
                fontWeight={400}
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
              />
            </InputGroup>
          </Flex>
        </Flex>
      </Flex>
      <PrimaryBtn
        btnText={'save'}
        btnOnClick={submitHandler}
        btnTextColor={"gray.0"}
        btnHasBg={true}
        btnIsLodaing={isLoading}
      />
    </Box>
  );
};

export default EditProfile;
