import { Box, Text, Image, Input, Spinner, IconButton, Button } from "@chakra-ui/react";
import { useRef, useState, ChangeEvent, DragEvent, useEffect } from "react";
import axios from "axios";
import { Label } from "components/common/form/form-style";
import AppIcons from "assets/icons/AppIcons";
import useAppToast from "functions/hooks/toast/useToast";

interface InputImageProps {
  image: string;
  setImage: (image: string) => void;
  label?: string;
  imageUse?: string;
  setFieldValue: (field: string, value: any) => void;
}

const InputImage: React.FC<InputImageProps> = ({ image, setImage, imageUse = "a banner", setFieldValue }) => {
  const { showToast } = useAppToast();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  const clickInputFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const changeImage = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    setLoading(true);
    try {
      const response = await axios.post("https://d2kpv1k2vro2sk.cloudfront.net/upload", formData);
      setLoading(false);
      showToast("Image Uploaded Successfully", "success");
      setImage(response.data.original);
      setFieldValue('banner', response.data.original);
      setTimeout(() => {
        setButtonsVisible(true);
      }, 3000);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      changeImage(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      changeImage(file);
    }
  };

  const handleRemoveImage = () => {
    setImage("");
    setFieldValue('banner', "");
    setButtonsVisible(false);
  };

  useEffect(() => {
    if (image !== "") {
      setTimeout(() => {
        setButtonsVisible(true);
      }, 3000);
    }
  }, [image]);

  return (
    <>
      <Label>
        <Text fontFamily={"AVB"}>Hero image</Text>
        <Input id="imageUpload" type="file" accept=".jpg, .png, .jpeg" display="none" ref={inputFile} onChange={handleFileChange} />
      </Label>
      {image === "" ? (
        <Box
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
          border={"2px dashed"}
          borderColor={"gray.700"}
          rounded={"12px"}
          padding={"40px"}
          bgColor={"gray.1000"}
          opacity={dragActive ? "0.5" : "1"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"8px"}
          width={"100%"}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <IconButton _hover={{ bg: "transparent" }} bg={"transparent"} icon={<AppIcons.UploadImage />} aria-label="upload icon" />
              <Text fontFamily={"AVR"} fontSize={"14px"} fontWeight={400} paddingX={"40px"} textAlign={"center"}>
                Browse or drag and drop your image file here.
              </Text>
              <Button onClick={clickInputFile} fontFamily={"IM"} fontSize={"14px"} fontWeight={500} rounded={"8px"} color={"gray.0"} bg={"gray.800"} padding={"8px 12px"}>
                Browse
              </Button>
            </>
          )}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"} position={"relative"}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Image rounded={"12px"} src={image} alt="banner" />
              {buttonsVisible && (
                <>
                  <Button
                    onClick={clickInputFile}
                    fontFamily={"IM"}
                    fontSize={"14px"}
                    fontWeight={500}
                    rounded={"8px"}
                    color={"gray.0"}
                    bg={"gray.800"}
                    padding={"8px 12px"}
                    position={"absolute"}
                    inset={0}
                    margin={"auto"}
                    height={"32px"}
                    width={"20%"}
                  >
                    Change
                  </Button>
                  <IconButton 
                    onClick={handleRemoveImage}
                    icon={<AppIcons.X/>} 
                    aria-label="remove image" 
                    position={"absolute"}
                    top={2.5}
                    right={2.5}
                    bgColor={"gray.800"}
                  />
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default InputImage;
