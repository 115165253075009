//Chakra
import { Button, Spinner, Text } from '@chakra-ui/react';

//Icons
import AppIcons from 'assets/icons/AppIcons';

const NextBtn = ({isLoading , onClick, isBtnDisabled} : any) => {
    return (
        <Button
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            alignSelf={"flex-end"}
            gap={"8px"}
            type="submit"
            bg={"primary.600"}
            color={"gray.0"}
            padding={"12px 16px"}
            _hover={{ bg: "primary.600" }} 
            _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
            _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
            onClick={onClick}
            isDisabled={isBtnDisabled}
        >
            {isLoading ? (
                <Spinner color="white" />
            ) : (
                <>
                    <Text
                        fontSize={"16px"}
                        fontWeight={500}
                        color={"primary.900"}
                        fontFamily={"IM"}
                    >
                        Next
                    </Text>
                    <AppIcons.ArrowRight />
                </>
            )}
            </Button>
    );
};

export default NextBtn;