export const dateConverter = (date: string, time: string) => {
  const combinedDateTimeString = date + " " + time;
  const combinedDateTime: Date = new Date(combinedDateTimeString);

  return combinedDateTime;
};

export const stringToIdentifier = (str: any) => {
  let addressArray = str.replace(/\s+/g, "").split(",");
  addressArray = addressArray.filter((address: any) => {
    return address !== "";
  });
  return addressArray;
};
