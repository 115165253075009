import { Flex, IconButton } from "@chakra-ui/react";
import React from "react";

const IconBox = ({
  boxIcon,
}: {
  boxIcon:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}) => {
  return (
    <Flex flexDir={"column"} gap={"8px"}>
      <IconButton
        _hover={{ bg: "transparent", color: "transparent" }}
        bg={"transparent"}
        aria-label="document"
        icon={boxIcon}
      />
    </Flex>
  );
};

export default IconBox;
