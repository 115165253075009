import { Chain, Network } from "./Chains";
import { EVMProvider } from "./providers/evm/evmProvider";

export class WalletNotFoundException {
    public readonly message: string = "";
    constructor(field: string) {
        this.message = field;
    }
}
export class AccountChangedException {
    public readonly message: string = "";
    constructor(field: string) {
        this.message = field;
    }
}

export class ChainNotImplementedException {
    public readonly message: string = "";
    constructor(field: string) {
        this.message = field;
    }
}

export interface ChainProvider {
    walletLogin(): Promise<any>;
    mintNFT(properties: any, title: string, description: string, imageUrl: string, price: number, amount: number, apiKey: string): Promise<string>;
    setAddress(address: string): ChainProvider;
}

let chainMapping = {
    [Chain.BINANCE]: {
        [Network.TESTNET]: new EVMProvider(Chain.BINANCE, Network.TESTNET),
        [Network.MAINNET]: new EVMProvider(Chain.BINANCE, Network.MAINNET),
    },
    [Chain.POLYGON]: {
        [Network.TESTNET]: new EVMProvider(Chain.POLYGON, Network.TESTNET),
        [Network.MAINNET]: new EVMProvider(Chain.POLYGON, Network.MAINNET),
    },
    [Chain.NEAR]: {
        [Network.TESTNET]: new EVMProvider(Chain.NEAR, Network.TESTNET),
        [Network.MAINNET]: new EVMProvider(Chain.NEAR, Network.MAINNET)
    },
    [Chain.RIPPLESIDECHAIN]: {
        [Network.TESTNET]: new EVMProvider(Chain.RIPPLESIDECHAIN, Network.TESTNET),
        [Network.MAINNET]: new EVMProvider(Chain.RIPPLESIDECHAIN, Network.MAINNET),
    },
    [Chain.STACKS]: {
        [Network.TESTNET]: null,
        [Network.MAINNET]: null,
    },
    [Chain.SKALE]: {
        [Network.TESTNET]: new EVMProvider(Chain.SKALE, Network.TESTNET),
        [Network.MAINNET]: new EVMProvider(Chain.SKALE, Network.MAINNET),
    },
};

export function getNetworkProvider(chain: Chain, network: Network, address: string) {
    if (chainMapping[chain][network] == null)
        throw new ChainNotImplementedException("The given chain is not implemented yet");
    return chainMapping[chain][network]?.setAddress(address);
}