import { Box, Button } from "@chakra-ui/react";

const ProfileMyEventsBtn = ({ isSelected, text, number, onClick, isLoading }: { isSelected: boolean; text: string; number: number; isLoading?: boolean; onClick: () => void }) => {
  return (
    <Button width={"100%"} colorScheme="" color={"gray.0"} fontFamily={"AVSB"} fontSize={"14px"} bg={isSelected ? "gray.800" : "transparent"} gap={"4px"} onClick={onClick}>
      {text}
      {!isLoading && number > 0 &&
      <Box
        bg={isSelected ? "gray.900" : "gray.700"}
        width={"22px"}
        height={"16px"}
        rounded={"60%"}
        fontFamily={"IM"}
        fontSize={"10px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {number}
      </Box>
      }
    </Button>
  );
};

export default ProfileMyEventsBtn;
