import React from "react";
import { useLocation } from "react-router-dom";

//Chakra
import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconAndDitailRegisteration from "components/common/icon-and-detail-box/IconAndDitailRegisteration";

//Custom Hooks
import { useProfile } from "functions/hooks/user/useProfile";


const EmailConfirmation = () => {
  const email = localStorage.getItem("email");
  const { isLoading, onSubmit } = useProfile();
  const location = useLocation()

  const resendHandler = () => {
    onSubmit({
      email: location?.state?.email,
      password: location?.state?.password,
      terms: location?.state?.terms,
    }, true);
  }
  
  return (
    <Box width={"full"}>
      <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={"16px"}>
        <Flex rounded={"8px"} bg={"gray.900"} py={"100px"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <IconAndDitailRegisteration
            boxIcon={<AppIcons.Email />}
            primaryTitle="Check your email 
        for a link"
            secondaryTitle={`Verification link has been sent to ${email}
          `}
          />
        </Flex>
        <Button bg={"gray.900"} color={"gray.0"} fontFamily={"IM"} width={"100%"} fontSize={"16px"} fontWeight={500} onClick={resendHandler}>
          {isLoading ? <Spinner color="white" /> : "Redsend Email"}
        </Button>
      </Flex>
    </Box>
  );
};

export default EmailConfirmation;
