import { Flex, Input, InputGroup, Text } from "@chakra-ui/react";
import { FormikErrors } from "formik";

const StartDateTime = ({
  errors,
  values,
  handleChange,
  handleBlur,
  setValues,
}: {
  errors: FormikErrors<{
    event: string;
    title: string;
    description: string;
    banner: string;
    location: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
  }>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: any;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  setValues: (values: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
}) => {
  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const [hours, minutes] = value.split(":");
    const startDate = new Date(values.startDate);
    startDate.setHours(Number(hours));
    startDate.setMinutes(Number(minutes));
    const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000);
    setValues((prev: any) => ({
      ...prev,
      startTime: value,
      endTime: `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`,
    }));
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const startDate = new Date(value);
    const startTime = new Date(`${value}T${values.startTime}`);
    const endDate = new Date(startTime.getTime() + 2 * 60 * 60 * 1000);
    setValues((prev: any) => ({
      ...prev,
      startDate: value,
      endDate: value,
      endTime: `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`,
    }));
  };

  return (
    <Flex gap={"8px"} flexDirection={"column"}>
      <Text
        display={"flex"}
        alignItems={"center"}
        gap={"4px"}
        color={"gray.400"}
        fontFamily={"IM"}
        fontWeight={"600"}
      >
        Start date{" "}
        <Text fontSize={"14px"} color={"gray.600"}>
          (Based on event local time)
        </Text>
      </Text>
      <Flex gap={"8px"}>
        <InputGroup height={"48px"}>
          <Input
            variant="unstyled"
            color={"gray.0"}
            bg={"gray.1000"}
            fontFamily={"IM"}
            type="date"
            id="startDate"
            fontSize={"14px"}
            onChange={handleStartDateChange}
            onBlur={handleBlur}
            placeholder="Date"
            _placeholder={{ color: "gray.800" }}
            value={values?.startDate}
            padding={"12px"}
          />
        </InputGroup>
        <InputGroup height={"48px"}>
          <Input
            type="time"
            variant="unstyled"
            color={"gray.0"}
            bg={"gray.1000"}
            fontSize={"14px"}
            fontFamily={"IM"}
            id="startTime"
            onChange={handleStartTimeChange}
            onBlur={handleBlur}
            placeholder="Time"
            _placeholder={{ color: "gray.800" }}
            value={values.startTime}
            padding={"12px"}
          />
        </InputGroup>
      </Flex>
    </Flex>
  );
};

export default StartDateTime;
