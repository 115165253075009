import Html5QrcodePlugin from "./parts/plugin/Html5QrcodePlugin";

const QrcodePage = () => {
  const onNewScanResult = (decodedText: any, decodedResult: any) => {
    // handle decoded results here
    console.log(`Code matched = ${decodedText}`, decodedResult);
  };
  return <Html5QrcodePlugin fps={10} qrbox={250} disableFlip={false} qrCodeSuccessCallback={onNewScanResult} />;
};

export default QrcodePage;
