import { Input, Text } from "@chakra-ui/react";
import { Label } from "components/common/form/form-style";
import { FormikErrors } from "formik";

const Name = ({
  errors,
  values,
  handleChange,
  handleBlur,
}: {
  errors: FormikErrors<any>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: any;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
}) => {
  return (
    <Label>
      <Text color={"gray.400"} fontFamily={"IM"} fontWeight={"600"}>
        Name
      </Text>
      <Input
        variant="unstyled"
        border={errors?.title && "1px"}
        borderColor={errors?.title && "error.600"}
        color={"gray.0"}
        bg={"gray.1000"}
        fontFamily={"IM"}
        type="text"
        id="title"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Name"
        padding={"12px"}
        _placeholder={{ color: "gray.800" }}
        value={values.title}
      />
      <Text color={values?.title?.length < 75 ? "gray.600" : "error.600"} textAlign={"right"} fontFamily={"IM"}>
        {values?.title?.length}/75
      </Text>
    </Label>
  );
};

export default Name;
