import { Flex, Text } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import UnclaimedRewardItem from "./UnclaimedRewardItem";
import { useProfile } from "functions/hooks/user/useProfile";
import { useEvents } from "functions/hooks/events/useEvents";
import useAppToast from "functions/hooks/toast/useToast";

const UnclaimedRewards = ({unclaimedRewardsData}: any) => {  
  const firstItem = unclaimedRewardsData?.slice(0, 1)?.[0];
  const { isLoading, recieveUnclaimedRewards } = useProfile();
  const { getNewEventDetails } = useEvents();
  const { showToast } = useAppToast();

  const [eventDetail, setEventDetail] = useState<any>([])
  const [refreshKey, setRefreshKey] = useState(0);
  
  const claimBtnHandler = async () => {
    const recieveReward = await recieveUnclaimedRewards(firstItem?._id)
    if (recieveReward) {
      showToast("Reward claimed successfully! Enjoy!", "success")
      setRefreshKey(prevKey => prevKey + 1);
    }
  }

  const eventDetailGetter = async () => {
    const res = await getNewEventDetails(firstItem?.eventID)
    setEventDetail(res)
  }

  useEffect(() => {
    eventDetailGetter()
  },[refreshKey]);


  return (
    <Flex
      flexDirection={"column"}
      bg={"gray.900"}
      color={"gray.0"}
      rounded={"16px"}
      padding={"16px"}
      gap={"16px"}
    >
      <Text
        color={"gray.0"}
        fontWeight={"600"}
        fontSize={"18px"}
        fontFamily={"AVSB"}
      >
        Unclaimed rewards
      </Text>
        <UnclaimedRewardItem nftData={firstItem} claimHandler={claimBtnHandler} eventData={eventDetail} isLoading={isLoading} />
    </Flex>
  );
};

export default UnclaimedRewards;
