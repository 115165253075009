import { Link } from 'react-router-dom';

//Chakra
import { Button, Flex, Text } from '@chakra-ui/react';

interface EmptyPageMessageProps {
    msg: string;
    desc: string;
    icon: React.ReactElement;
    createBtn: boolean
}

const EmptyPageMessage : React.FC<EmptyPageMessageProps> = ({ msg, desc, icon , createBtn}) => {
    return (
        <Flex
            alignItems={'flex-start'}
            alignSelf={'stretch'}
            flexDirection={'column'}
            gap={'24px'}
            flex={'1 0 0'}
            padding={'120px 0'}
            >
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'10px'}
                    width={'48px'}
                    height={'48px'}
                    padding={'6px'}
                    borderRadius={'12px'}
                    bgColor={'gray.700'}
                    >
                    {icon}
                </Flex>
                <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'4px'} width={'522px'}>
                    <Text fontSize={'36px'} fontWeight={600} fontFamily={'AVSB'}>
                        {msg}
                    </Text>
                    <Text
                        fontSize={'16px'}
                        fontWeight={400}
                        fontFamily={'AVR'}
                        color={'gray.600'}
                        textAlign={'left'}
                        width={'100%'}
                        lineHeight={'20px'}
                    >
                        {desc}
                    </Text>
                </Flex>
                {createBtn && 
                <Link to={`/user/create/info`}>
                    <Button
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={'8px'}
                        padding={'8px 16px'}
                        bgColor={'primary.600'}
                        borderRadius={'8px'}
                        color={'primary.900'}
                        fontFamily={'IM'}
                        fontSize={'16px'}
                        fontWeight={500}
                        _active={{bgColor: 'primary.900'}}
                    >
                        Create event
                    </Button>
                </Link>
                }
            </Flex>
    );
};

export default EmptyPageMessage;