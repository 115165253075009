import { useContext } from "react";

//Chakra
import { Flex, Text, Image, Avatar, Box, Divider } from "@chakra-ui/react";

//Contexts
import { CreateEventDataContext } from "../../../../../contexts/CreateEventDataContextProvider";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import { UTCConverter } from "lib/utils/time/UTCConverter";

//Data
import { useUserStore } from "lib/stores/user/UserStore";


const EventPreview = () => {
    const { eventData , ticketData } = useContext(CreateEventDataContext);
    const user = useUserStore((state) => state.user);

    const convertToISOString = (dateString: string, timeString: string) => {
        const [year, month, day] = dateString?.split("-");
        const [hour, minute] = timeString?.split(":");
    
        const date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));
    
        // Convert to ISO 8601 format
        const isoString = date.toISOString();
    
        return isoString;
    }
    
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"12px"}
            flexShrink={"0"}
            width={"100%"}
        >
            <Flex
                alignItems={"center"}
                flexDirection={"column"}
                gap={"16px"}
                padding={"16px"}
                borderRadius={"20px"}
                bgColor={"gray.900"}
                border={"1px solid #24292E"}
                width={"100%"}
            >
                <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={"140px"}
                    borderRadius={"16px"}
                    bgColor={"dark.bg"}
                    boxShadow={
                        "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.05)"
                    }
                >
                    {eventData?.banner !== "" && eventData?.banner !== undefined ? (
                        <Image
                            src={eventData?.banner}
                            width={"inherit"}
                            height={"inherit"}
                            borderRadius={"inherit"}
                            objectFit={"cover"}
                        />
                    ) : ( 
                        <Text
                            fontSize={"16px"}
                            fontWeight={600}
                            fontFamily={"AVR"}
                            textAlign={"center"}
                            color={"dark.500"}
                        >
                            Preview Section
                        </Text>
                    )}
                </Flex>
                {eventData?.title && (
                <Flex 
                    alignItems={"center"} 
                    gap={"8px"} 
                    alignSelf={"stretch"}
                    overflowWrap={'break-word'}
                    wordBreak={'break-word'}
                >
                    <Text
                        fontSize={"30px"}
                        fontWeight={600}
                        fontFamily={"AVR"}
                        color={"gray.0"}
                        flex={"1 0 0"}
                    >
                        {eventData.title}
                    </Text>
                    <AppIcons.Share2 />
                </Flex>
                )}
                {eventData?.title && (
                <Flex alignItems={"center"} gap={"4px"} alignSelf={"stretch"}>
                    {user.image ? (
                    <Avatar
                        bgColor={"primary.600"}
                        src={user.image}
                        width={"24px"}
                        height={"24px"}
                    />
                    ) : (
                    <Avatar bgColor={"primary.600"} width={"24px"} height={"24px"} />
                    )}
                    <Text
                        fontSize={"14px"}
                        fontFamily={"AVR"}
                        fontWeight={400}
                        color={"gray.400"}
                    >
                        Host by
                    </Text>
                    <Text
                        fontSize={"14px"}
                        fontFamily={"AVSB"}
                        fontWeight={600}
                        color={"gray.0"}
                    >
                        {user.name}
                    </Text>
                </Flex>
                )}
                {eventData?.title && (
                <Flex flexDirection={"column"} alignItems={"flex-start"} gap={"12px"} width={'100%'}>
                    {eventData?.startDate && (
                    <IconInfoBox
                        boxIcon={<AppIcons.GreenCalendar />}
                        label="Date"
                        primaryTitle={eventData?.startDate && UTCConverter(convertToISOString(eventData?.startDate, eventData?.startTime))}
                        secondaryTitle={eventData?.endDate && UTCConverter(convertToISOString(eventData?.endDate, eventData?.endTime))}
                        // secondaryTitle={eventData?.endDate && UTCConverter(eventData?.endDate)}
                    />
                    )}
                    {eventData?.location && (
                    <IconInfoBox
                        boxIcon={<AppIcons.GreenLocation />}
                        label="Location"
                        primaryTitle={eventData?.location}
                        // secondaryTitle="Piers Pier 62, New York, NY 10011, USA"
                    />
                    )}
                </Flex>
                )}
            </Flex>
            {eventData?.description && (
                <Flex
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    gap={"12px"}
                    padding={"16px"}
                    borderRadius={"20px"}
                    bgColor={"gray.900"}
                    width={"100%"}
                    overflowWrap={'break-word'}
                    wordBreak={'break-word'}
                >
                    <Text
                        fontSize={"18px"}
                        fontWeight={600}
                        fontFamily={"AVSB"}
                        color={"gray.0"}
                        lineHeight={"28px"}
                    >
                        About
                    </Text>
                    <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        fontFamily={"AVR"}
                        color={"gray.0"}
                        alignSelf={"stretch"}
                        width={"100%"}
                        lineHeight={"28px"}
                        whiteSpace={"pre-line"}
                    >
                        {eventData?.description}
                    </Text>
                </Flex>
            )}
            {eventData?.title && 
            <Flex
                alignItems={"flex-start"}
                flexDirection={"column"}
                gap={"12px"}
                padding={"16px"}
                borderRadius={"20px"}
                bgColor={"gray.900"}
                width={"100%"}
            >
                <Text
                    fontSize={"18px"}
                    fontWeight={600}
                    fontFamily={"AVSB"}
                    color={"gray.0"}
                    lineHeight={"28px"}
                >
                    Guests (0)
                </Text>
            </Flex>
            }
            {ticketData?.length > 0 && 
            <Flex
                alignItems={"flex-start"}
                flexDirection={"column"}
                gap={"12px"}
                padding={"16px"}
                borderRadius={"20px"}
                bgColor={"gray.900"}
                width={"100%"}
            >
                <Text
                    fontSize={"18px"}
                    fontWeight={600}
                    fontFamily={"AVSB"}
                    color={"gray.0"}
                    lineHeight={"28px"}
                >
                    Ticket
                </Text>
                {ticketData?.map((item: any, index: number) => (
                    <Flex
                    key={index}
                    alignItems={'center'}
                    alignSelf={'stretch'}
                    gap={'12px'}
                    padding={'16px'}
                    border={'1px solid var(--grey-700-borders, #434343)'}
                    borderRadius={'12px'}
                >
                    <Flex
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        gap={'8px'}
                        flex={'1 0 0'}
                    >
                        <Flex
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            alignSelf={'stretch'}
                        >
                            <Text fontSize={'16px'} fontWeight={600} fontFamily={'AVSB'} color={'gray.0'} alignSelf={'center'} width={'194px'}>{item.title}</Text>
                            <Flex
                                alignItems={'center'}
                                gap={'8px'}
                            >
                                <Box 
                                    bgColor={'gray.700'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    borderRadius={'4px'}
                                    width={'24px'}
                                    height={'24px'}
                                >
                                    <AppIcons.Minus/>
                                </Box>
                                <Text
                                    overflowWrap={'break-word'}
                                    wordBreak={'break-word'} 
                                    fontSize={'14px'} 
                                    fontWeight={600} 
                                    fontFamily={'AVSB'} 
                                    color={'gray.0'}
                                >
                                    {item.amount || 0}
                                </Text>
                                <Box
                                    bgColor={'primary.600'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    borderRadius={'4px'}
                                    width={'24px'}
                                    height={'24px'}
                                >
                                    <AppIcons.Plus/>
                                </Box>
                            </Flex>
                        </Flex>
                        <Divider height={'1px'} bgColor={'gray.700'}/>
                        <Flex
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                            gap={'4px'}
                            alignSelf={'stretch'}
                        >
                            <Flex
                                alignItems={'flex-end'}
                                gap={'4px'}
                            >
                                <AppIcons.MATICToken/>
                                <Text fontSize={"14px"} fontWeight={600} lineHeight={"16px"} marginTop={"14px"} fontFamily={"AVR"} color={"gray.600"}>{item.price === 0 ? "Free" : "Polygon equivalent"}</Text>
                            </Flex>
                            <Text fontSize={"14px"} fontWeight={"400"} lineHeight={"16px"} marginTop={"14px"} fontFamily={"AVR"} color={"gray.0"}>{item.price === 0 ? "Free" : `~ ${item.price || 0} USD`}</Text>
                        </Flex>
                        <Text 
                            overflowWrap={'break-word'}
                            wordBreak={'break-word'}  
                            fontSize={'14px'} 
                            fontWeight={400} 
                            fontFamily={'AVR'} 
                            color={'gray.600'}    
                        >
                            {item.description}
                        </Text>
                    </Flex>
                </Flex>
                ))}
            </Flex>
            }
        </Box>
    );
};

export default EventPreview;
