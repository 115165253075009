import { chakra, keyframes } from "@chakra-ui/react";

const animationKeyframes = keyframes`
  0% { transform: translateY(1000px); opacity: 0; }
  100% {  transform: translateY(0px); opacity: 1;}
`;

const animation = `${animationKeyframes} 1s`;

export const ModalWrapperComponent = chakra("div", {
  baseStyle: {
    position: "fixed",
    padding: "0px",
    zIndex: "30",
    left: "0px",
    bottom: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    bg: " rgba(43, 43, 43, 0.2) ",
    backdropFilter: "brightness(30%)",
    // maxW: "100%",
  },
});

export const ModalContent = chakra("div", {
  baseStyle: {
    zIndex: "40",
    pos: "absolute",
    bottom: "-100px",
    w: "100%",
    h: "110vh",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: animation,
    border: "10px solid transparent",
  },
});

export const ModalContainer = chakra("div", {
  baseStyle: {
    w: "100%",
    h: "100%",
    p: "40px 20px 36px 20px",
    bg: "#27262B",
    borderRadius: "32px 32px 0px 0px",
    pos: "relative",
  },
});

export const ModalWrapperComponent2 = chakra("div", {
  baseStyle: {
    position: "fixed",
    padding: "0px",
    zIndex: "30",
    left: "0px",
    bottom: "30%",
    width: "100%",
    height: "40%",
    overflow: "hidden",
    bg: " rgba(43, 43, 43, 0.2) ",
    // backdropFilter: "blur(5px)",
    // maxW: "100%",
  },
});

export const ModalContent2 = chakra("div", {
  baseStyle: {
    zIndex: "40",
    pos: "absolute",
    // bottom: "-100px",
    w: "100%",
    h: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: animation,
    border: "10px solid transparent",
  },
});

export const ModalWrapperComponent3 = chakra("div", {
  baseStyle: {
    position: "fixed",
    padding: "16px",
    zIndex: "9999",
    left: "0px",
    bottom: "0px",
    top: "0px",
    right: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "rgba(27, 27, 27, 0.15)",
  },
});

export const ModalContent3 = chakra("div", {
  baseStyle: {
    zIndex: "40",
    w: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
