import { useContext, useEffect, useReducer, useRef, useState } from "react";

//Chakra
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import BackBtn from "components/common/back-btn/BackBtn";
import SaveDraftBtn from "components/desktop-common/save-draft-btn/SaveDraftBtn";
import NextBtn from "components/desktop-common/next-btn/NextBtn";
import { CustomForm } from "components/common/form/form-style";
import TicketRulesetComponent from "./ticket-ruleset-component/TicketRulesetComponent";
import IconAndDetailBox from "components/common/icon-and-detail-box/IconAndDetailBox";

//Custom Hooks
import { useEvents } from "functions/hooks/events/useEvents";
import { FieldArray, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { IEvents } from "lib/stores/user/UserStore";
import { rulesetSchema } from "schemas/schema";
import RulesetItem from "pages/event-creation/ticket-rulesets-new/parts/rulset-item/RulesetItem";
import { CreateEventDataContext } from "contexts/CreateEventDataContextProvider";

// Reducer
const rulesetsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_RULE":
      return [
        ...state,
        {
          id: state.length + 1,
          rulesetType: "Discount",
          blockchain: "STACKS",
          discount: 0,
          walletAddress: "",
          tickets: [],
          nft_identifiers: [],
        },
      ];

    case "REMOVE_RULE":
      return Array.from(state).filter(
        (item: any) => item.id !== action.payload.id
      );

    case "CHANGE_DISCOUNT":
      return state.map((item: any) => {
        if (item.id === action.id) {
          return { ...item, discount: action.discount };
        } else {
          return item;
        }
      });

    case "CHANGE_ADDRESS":
      return state.map((item: any) => {
        if (item.id === action.id) {
          return { ...item, walletAddress: action.walletAddress };
        } else {
          return item;
        }
      });

    case "UPDATE_INCLUDEDTICKET":
      return state.map((item: any) => {
        if (item.id === action.id) {
          return { ...item, tickets: action.tickets };
        } else {
          return item;
        }
      });

    default:
      return state;
  }
};

export interface Ruleset {
  gated: string;
  discount: number;
  nft_identifiers: string[];
  tickets: [];
  blockchain: any;
  id: number;
}

interface initialValuesType {
  rules: Ruleset[];
}

const initialValues: initialValuesType = {
  rules: [],
};

const TicketRulesets = () => {
  const { updateTicketData, updateEventData } = useContext(CreateEventDataContext);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { isLoading, SubmitRuleset, getUserEvents } = useEvents();
  const [eventsData, setEventsData] = useState<IEvents[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [ruleType, setRuleType] = useState<string | null>(null);
  const formikRef = useRef<any>(null);
  const [eventData, setEventData] = useState<any>()
  const [ticketData, setTicketData] = useState<any>()

  // Navigate back to the Ticket Page route
  const handleBack = () => {
    navigate(`/user/create/tickets/${eventId}`);
  };

  useEffect(() => {
    const getEvent = async () => {
      const events = await getUserEvents();
      if (events) {
        const filteredEventData = events.filter((event) => event._id === eventId);
        setEventsData(filteredEventData);

        if (filteredEventData?.length > 0) {
          const initialTickets = filteredEventData[0].tickets.map((t) => ({
            title: t.title,
            description: t.description,
            price: t.price,
            total: t.total,
            id: t.id,
            }));

            const initialEventData = {
              title: filteredEventData[0].title || "",
              description: filteredEventData[0].description || "",
              banner: filteredEventData[0].banner || "",
              location: filteredEventData[0].location || "",
              startDate: filteredEventData[0].start
                  ? new Date(filteredEventData[0].start).toISOString().split("T")[0]
                  : "",
              startTime: filteredEventData[0].start
                  ? new Date(filteredEventData[0].start).toISOString().split("T")[1].slice(0, 5)
                  : "",
              endDate: filteredEventData[0].end ? new Date(filteredEventData[0].end).toISOString().split("T")[0] : "",
              endTime: filteredEventData[0].end
                  ? new Date(filteredEventData[0].end).toISOString().split("T")[1].slice(0, 5)
                  : "",
          }

            setEventData(initialEventData)
            setTicketData(initialTickets)
        }
        
        const ticketTitles = filteredEventData?.map((event) => event?.tickets?.map((ticket) => ticket.title)).flat();
        setOptions([...ticketTitles]);

        if (filteredEventData.length > 0) {
          // console.log("eventRules:", filteredEventData[0].rules);

          const hasGatedRules = filteredEventData[0].rules?.some((r) => r.gated === true);
          setRuleType(hasGatedRules ? "Gated" : "Discount");

          const initialRulesets = filteredEventData[0].rules?.map((r) => ({
            gated: r.gated === true ? "Gated" : "Discount",
            discount: r.discount,
            nft_identifiers: r.nft_identifiers,
            tickets: r.tickets?.map((ticketId: any) => {
              const ticket = filteredEventData[0].tickets?.find((t) => t.id === ticketId);
              return ticket ? ticket?.title.split(",") : "";
            }),
            blockchain: r.blockchain,
            id: r.id,
          }));
          if (formikRef.current) {
            formikRef.current.setValues({ rules: initialRulesets });
          }
        }
      }
    };
    getEvent();
  }, [eventId]);

  useEffect(() => {
    if (eventData && ticketData) {
      updateEventData(eventData)
      updateTicketData(ticketData)
    }
  }, [eventData, ticketData])
  

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      alignSelf={"stretch"}
      gap={"24px"}
      width={"100%"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        gap={"8px"}
      >
        <Text
          fontSize={"20px"}
          fontFamily={"AVSB"}
          fontWeight={600}
          color={"gray.0"}
        >
          Ticket rulesets
        </Text>
        <Box>
          <Text fontSize={"14px"} fontWeight={400} fontFamily={"AVR"}>
            Set your rules for ticket discounts or limit access based on a
            whitelist.
          </Text>
          <Text
            fontSize={"14px"}
            fontWeight={400}
            fontFamily={"AVR"}
            color={"primary.600"}
          >
            Learn more
          </Text>
        </Box>
      </Flex>
      <Box width={'100%'}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={async (values: any) => {
            const FinalValues = values.rules.map((rules: any) => ({
              gated: rules.gated,
              discount: rules.discount || 0,
              nft_identifiers: rules.nft_identifiers,

              tickets: rules.tickets
                ?.map((ticketTitle: any) => {
                  const mainTickets = String(ticketTitle);
                  const ticketIds: any[] = [];
                  if (mainTickets) {
                    eventsData.forEach((eventData) => {
                      const foundTicket = eventData?.tickets?.find((t) => t.title === mainTickets);
                      if (foundTicket) {
                        ticketIds.push(foundTicket.id);
                      }
                    });
                  }
                  return ticketIds.length > 0 ? ticketIds : null;
                })
                .flat()
                .filter(Boolean),
              blockchain: "POLYGON",
            }));
            // console.log("FinalValues", FinalValues);
            if (eventId) {
              let result = await SubmitRuleset(FinalValues, eventId);
            }
          }}
          validationSchema={rulesetSchema}
        >
          {({ values, errors, handleChange, isValid, dirty, setFieldValue }) => (
            <Form>
              <FieldArray name="rules">
                {({ remove, push }: { remove: any; push: any }) => (
                  <Flex flexDirection={"column"} gap={"16px"} marginBottom={"55px"}>
                    {values?.rules.length > 0 ? (
                      values?.rules.map((r: Ruleset, index: number) => (
                        <Box key={index} rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                          <RulesetItem
                            r={r}
                            index={index}
                            remove={remove}
                            handleChange={handleChange}
                            errors={errors}
                            eventsData={eventsData}
                            options={options}
                            ruleType={ruleType}
                            setRuleType={setRuleType}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                      ))
                    ) : (
                      <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
                        <IconAndDetailBox
                          boxIcon={<AppIcons.Stars />}
                          primaryTitle="No rule set up yet"
                          secondaryTitle="Set up a ruleset now"
                        />
                      </Box>
                    )}
                    <Button
                      padding={"8px"}
                      height={"32px"}
                      fontFamily={"IM"}
                      fontSize={"14px"}
                      color={"gray.0"}
                      fontWeight={500}
                      bg={"gray.900"}
                      onClick={() => push({ gated: "", discount: "", nft_identifiers: [], tickets: [] })}
                    >
                      + Add new rule
                    </Button>

                    <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        alignSelf={"stretch"}
                    >
                        <Flex alignSelf={"center"}>
                            <BackBtn optionalUrl={`/user/create/tickets/${eventId}`} />
                        </Flex>
                        <Flex
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        gap={"12px"}
                        >
                            <SaveDraftBtn eventId={eventId} />
                            {values?.rules.length === 0 ? 
                            <Button
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              alignSelf={"flex-end"}
                              gap={"8px"}
                              type="submit"
                              bg={"primary.600"}
                              color={"gray.0"}
                              padding={"12px 16px"}
                              _hover={{ bg: "primary.600" }} 
                              _disabled={{bgColor: 'gray.900', color: 'gray.700'}} 
                              _active={{bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2EC99E', color: 'primary.900'}}
                              onClick={() => navigate(`/user/create/acceptPayment/${eventId}`)}
                              isDisabled={isLoading}
                            >
                              <Text
                                  fontSize={"16px"}
                                  fontWeight={500}
                                  color={"primary.900"}
                                  fontFamily={"IM"}
                              >
                                  Skip
                              </Text>
                              <AppIcons.ArrowRight />
                            </Button>
                            :
                            <NextBtn isLoading={isLoading} isBtnDisabled={isLoading || !isValid || !dirty} />
                            }
                        </Flex>
                    </Flex>
                  </Flex>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default TicketRulesets;
