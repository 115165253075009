//Chakra
import { Avatar, AvatarGroup, Box, Flex, Image, Text } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'

//Icons
import AppIcons from 'assets/icons/AppIcons';

//Time Converter
import { UTCConverter } from 'lib/utils/time/UTCConverter';


const AttendItem = (events : any) => {

    return (
        <ChakraLink 
            as={ReactRouterLink} 
            to={`/user/events/${events.events?._id}`} 
            style={{textDecoration : 'none'}}
        >
            <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            alignSelf={'stretch'}
            gap={'12px'}
            padding={'16px'}
            border={'1px solid #434343'}
            borderRadius={'8px'}
            width={'100%'}
            height={'100%'}
            flex={'1 0 0'}
            bgColor={'gray.1000'}
            >
                <Image src={events.events?.banner} width={'100%'} height={'130px'} display={'flex'} alignSelf={'stretch'} borderRadius={'8px'} objectFit={'cover'} />
                <Flex
                alignItems={'flex-start'}
                alignSelf={'stretch'}
                flexDirection={'column'}
                gap={'8px'}
                >
                    <Text fontFamily={"AVR"} fontSize={"12px"} fontWeight={400} color={"gray.300"}>
                        {events.events?.start && UTCConverter(events.events?.start)}
                    </Text>
                    <Text fontFamily={'AVR'} fontSize={'14px'} fontWeight={600} color={'gray.0'}>
                        {events.events?.title}
                    </Text>
                    <Flex
                    alignItems={'center'}
                    alignSelf={'stretch'}
                    gap={'4px'}
                    >
                        <AppIcons.Location width={'13px'} height={'16px'} strokeWidth={'1.5px'}/>
                        <Text fontFamily={'AVR'} fontSize={'12px'} fontWeight={400} color={'gray.300'}>
                            {events.events?.location}
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </ChakraLink>
    )
}

export default AttendItem;