import { Box, Flex } from "@chakra-ui/layout";
import AppIcons from "assets/icons/AppIcons";
import IconAndDitailRegisteration from "components/common/icon-and-detail-box/IconAndDitailRegisteration";
import { useProfile } from "functions/hooks/user/useProfile";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const { verifyEmail } = useProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const jwt = queryParams.get("token");
  useEffect(() => {
    const verifyEmailFunction = async () => {
      if (jwt) await verifyEmail(jwt);
    };
    verifyEmailFunction();
    setTimeout(() => {
      navigate("/signin")
    }, 3000);
  }, [jwt]);
  return (
    <Flex flexDirection={"column"} gap={"12px"}>
      <Box rounded={"8px"} bg={"gray.900"} padding={"16px"}>
        <IconAndDitailRegisteration boxIcon={<AppIcons.CheckVerified />} primaryTitle="Email verified" secondaryTitle="You will be redirected shortly..." />
      </Box>
    </Flex>
  );
};

export default VerifyEmail;
