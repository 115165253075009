import { string } from "yup";
import {
  IEventIntroduction,
  IEventLocationDate,
  IEventRulesets,
  IEventTicket,
  IUpdateIntroduction,
} from "./Interfaces";

export const getUserEventsApi = () => {
  return { url: `events/my-events` };
};

export const getUserAttendEventsApi = () => {
  return { url: `events/my-tickets` };
};

//Explore Events
export const getPublicEvents = () => {
  return { url: `events/` };
};

export const getEvent = (eventId: string) => {
  return { url: `events/${eventId}` };
};

export const getNewEventDetail = (eventId: string) => {
  return { url: `events/${eventId}/detail` };
};

export const PostEventIntroduction = (props: IEventIntroduction) => {
  return { url: `events`, props };
};

export const UpdateEventIntroduction = (props: IUpdateIntroduction, eventId: string) => {
  return { url: `events/${eventId}/info`, props};
};

export const UpdateEventLocationDate = (eventId: string, props: IEventLocationDate) => {
  return { url: `events/${eventId}/date`, props };
};

export const PostEventTicket = (eventId: string, props: IEventTicket[]) => {
  return {
    url: `events/${eventId}/tickets`,
    props: {
      tickets: props,
    },
  };
};

export const PostEventRulesets = (eventId: string, props: IEventRulesets[]) => {
  return {
    url: `events/${eventId}/rules`,
    props: {
      rules: props,
    },
  };
};

export const PutEventPaymentMethod = (eventId: string, props: any) => {
  return {
    url: `events/${eventId}/payment-method`,
    props: {
      paymentMethods: props,
    },
  };
};

export const PostPublishEvent = (eventId: string) => {
  return { url: `events/${eventId}/publish` };
};

export const deleteEvent = (eventId: string | undefined) => {
  return { url: `events/${eventId}` };
};

export const cancelEvent = (eventId: string | undefined) => {
  return { url: `events/${eventId}/cancel` };
};

export const postEventRecord = (chain: string, props: any) => {
  return { url: `events/record/${chain}`, props };
};

export const getAttendees = (attendId: string) => {
  return { url: `/attendees/${attendId}` };
};

export const getAttendeesData = (eventId: string) => {
  return { url: `/attendees/events/${eventId}`}
}

export const postAttendId = (eventId: string, props: any) => {
  return { url: `/events/${eventId}/check-in`, props };
};

export const getEventDetailViaTicketId = (ticketId: string) => {
  return { url: `/events/tickets/${ticketId}` };
};
