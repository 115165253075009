import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import AppIcons from "assets/icons/AppIcons";
import IconInfoBox from "components/common/iconInfoBox/IconInfoBox";
import { useEvents } from "functions/hooks/events/useEvents";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useUserStore } from "lib/stores/user/UserStore";
import IconBox from "components/common/iconInfoBox/iconBox";

const ReceiveTicket = () => {
  const { eventId } = useParams();
  const { getEventDetail } = useEvents();
  const [event, setEvent] = useState<any>();
  const [loading, setLoading] = useState<boolean>();

  useMemo(async () => {
    if (eventId) {
      setLoading(true);
      const res = await getEventDetail(eventId);
      setEvent(res);
      setLoading(false);
    }
  }, [eventId]);

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <Flex
          position={"relative"}
          flexDirection={"column"}
          gap={"12px"}
          mb={"80px"}
        >
          <Flex
            flexDirection={"row"}
            height={"100px"}
            bg={"gray.900"}
            color={"gray.0"}
            rounded={"16px"}
            gap={"16px"}
          >
            <Flex
              flexDirection={"column"}
              bg={"gray.900"}
              color={"gray.0"}
              rounded={"16px"}
              gap={"16px"}
            >
              <Image
                maxWidth={"80px"}
                maxHeight={"80px"}
                minWidth={"80px"}
                minHeight={"80px"}
                rounded={"8px"}
                marginLeft={"7px"}
                marginTop={"10px"}
                alt="event banner"
                src={event?.banner}
              />
            </Flex>
            <Flex
              flexDirection={"column"}
              color={"gray.0"}
              rounded={"16px"}
              padding={"5px"}
              gap={"10px"}
              marginTop={"6px"}
            >
              <Text
                color={"gray.0"}
                fontSize={"16px"}
                fontFamily={"AVSB"}
                fontWeight={600}
                textAlign={"left"}
              >
                {event?.title}
              </Text>
              <Text
                color={"gray.0"}
                fontSize={"12px"}
                fontFamily={"AVR"}
                fontWeight={400}
                textAlign={"left"}
                marginTop={"0"}
              >
                {event?.end}
              </Text>
            </Flex>
          </Flex>
          <Flex
            display={"flex"}
            flexDirection={"column"}
            bg={"gray.900"}
            color={"gray.0"}
            rounded={"16px"}
            padding={"16px"}
            gap={"16px"}
            justifyContent={"center"}
            alignItems={"center"}
            paddingY={"12%"}
          >
            <Flex
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button width={"22px"} height={"38px"} bg={"#434343"}>
                <IconBox boxIcon={<AppIcons.CheckCircle />} />
              </Button>
              <Text
                fontSize={"30px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"36px"}
                textAlign={"center"}
              >
                Thank you for your purchase
              </Text>

              <Text
                textAlign={"center"}
                fontSize={"14px"}
                fontWeight={"600"}
                fontStyle={"normal"}
                color={"#7c7c7c"}
              >
                Here is your unclaimed ticket
              </Text>
            </Flex>
            <Flex
              border={"1px solid gray"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderRadius={"10px"}
              width={"95%"}
              height={"40px"}
              marginTop={"10px"}
            >
              <Text marginLeft={"10px"}>Early bird</Text>
              <Text marginRight={"10px"}>x1</Text>
            </Flex>
          </Flex>
          <Flex
            justifyContent={"center"}
            width={"full"}
            position={"fixed"}
            left={"0px"}
            padding={"16px"}
            bottom={"50px"}
          >
            <Button
              bg={"gray.600"}
              color={"gray.0"}
              width={"full"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
            >
              <Link to={`/user/events/${eventId}`}>Check event</Link>
            </Button>
          </Flex>
          <Flex
            justifyContent={"center"}
            width={"full"}
            position={"fixed"}
            left={"0px"}
            padding={"16px"}
            bottom={"0px"}
          >
            <Button
              bg={"primary.600"}
              color={"gray.0"}
              width={"full"}
              fontFamily={"IM"}
              fontSize={"16px"}
              fontWeight={500}
            >
              <Link to={`/user/events/${eventId}`}>Check ticket</Link>
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default ReceiveTicket;
