import React, { ChangeEvent, useRef, useState } from "react";
import axios from "axios";

//Chakra
import { Avatar, Box, Flex, Input, Spinner } from "@chakra-ui/react";

//Icons
import AppIcons from "assets/icons/AppIcons";

//Components
import ToastMessage from "components/common/toastMessage/ToastMessage";

//Custom Hooks
import useAppToast from "functions/hooks/toast/useToast";

interface UserProfileIMGProps {
  image: string;
  setImage: (image: string) => void;
  label?: string;
  imageUse?: string;
}

const UserProfileIMG: React.FC<UserProfileIMGProps> = ({ image, setImage, imageUse = "a artwork" }) => {
  const { showToast } = useAppToast();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  const clickInputFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const changeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      try {
        const response = await axios.post("https://d2kpv1k2vro2sk.cloudfront.net/upload", formData);
        setLoading(false);
        showToast("Image Uploaded Successfuly", "success");
        setImage(response.data.original);
      } catch (error: any) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Input id="imageUpload" type="file" display="none" ref={inputFile} onChange={changeImage} />
      {image === "" || image === "no image" ? (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"} position={"relative"}>
          <Flex
            position={"absolute"}
            zIndex={"10"}
            borderRadius={"50%"}
            width={"50%"}
            height={"50%"}
            justifyContent="center"
            alignItems="center"
            inset="0"
            margin={"auto"}
            bgColor={"rgba(67, 67, 67, 0.40)"}
            cursor={'pointer'}
            onClick={clickInputFile}
          >
            <AppIcons.CircleUploadImage color="white" />
          </Flex>
          {loading ? <Spinner /> : <Avatar color={"gray.0"} bg={"primary.600"} width={'62px'} height={'62px'} />}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"} position={"relative"}>
          <Flex
            position={"absolute"}
            zIndex={"10"}
            borderRadius={"50%"}
            width={"50%"}
            height={"50%"}
            justifyContent="center"
            alignItems="center"
            inset="0"
            margin={"auto"}
            bgColor={"rgba(67, 67, 67, 0.40)"}
            cursor={'pointer'}
            onClick={() => setImage("no image")}
          >
            <AppIcons.Trash color="white" />
          </Flex>
          {loading ? <Spinner /> : <Avatar src={image} width={'62px'} height={'62px'} />}
        </Box>
      )}
    </>
  );
};

export default UserProfileIMG;
