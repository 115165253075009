import { useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
//Chakra
import { Box, VStack } from "@chakra-ui/react";
//Components
import Navbar from "./navbar/Navbar";
//Data
import { useUserStore } from "lib/stores/user/UserStore";

function Layout() {
  const jwtToken = useUserStore((state) => state.jwtToken);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { eventId } = useParams()
  const hiddenRoutes = ["purchase", "paymentsetup", `events/${eventId}`, "editprofile", "signin", "signup", "password", "ticketDetail", "auth-callback", "oops", "onboarding", "share-profile", "stripe"];

  return (
    <VStack align={"stretch"} gap={"12px"} p={"16px"}>
      {jwtToken && !hiddenRoutes.some((route) => location.pathname.includes(route)) && !loading && (
        <Box backgroundColor={"gray.900"} rounded={"8px"}>
          <Navbar loading={loading} setLoading={setLoading} />
        </Box>
      )}
      <Box>
        <Outlet />
      </Box>
    </VStack>
  );
}

export default Layout;
