export function useNavigator(){

    //Share event page link
    const shareLink = (url: string, title: string, text: string) => {
        try {
            const shareData: ShareData = {
                url,
                title,
                text,
            };
    
            navigator.share(shareData).catch((error) => {
                console.error("Sharing failed:", error);
            });
        } catch (error) {
            console.log(error);
        }
    };

    return {
        shareLink
    }
}