import { useState } from "react";
import { useFormik } from "formik";
import { Link, useLocation } from 'react-router-dom'
//Chakra
import { Box, Button, Divider, Flex, Icon, Input, Text } from "@chakra-ui/react";
//Icons
import AppIcons from "assets/icons/AppIcons";
//Custom Hooks
import { useProfile } from "functions/hooks/user/useProfile";
//Components
import { CustomForm, Label } from "components/common/form/form-style";
import WalletItem from "./parts/WalletItem";
import WhatIsWallet from "./parts/WhatIsWallet";
import RegisterBtn from "components/common/register-btn/RegisterBtn";
import { Vaul } from "components/common/vaul/Vaul";
//Schemas
import { LoginSchema } from "schemas/schema";

const Login = () => {
  const { onSubmitLogin, isLoading, loginViaStacks, loginViaPolygon, loginViaGoogle } = useProfile();
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redicertUrl = queryParams.get("redirect-uri");
  redicertUrl && localStorage.clear()

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      onSubmitLogin(redicertUrl ? {
        email: values.email,
        password: values.password,
        metadata: {
            "redirectUri": redicertUrl
        }
      }
      :
      {
          email: values.email,
          password: values.password,
      }
    );
    },
  });

  const loginWithGoogle = async () => {
    await loginViaGoogle(redicertUrl);
  }

  return (
    <CustomForm autoComplete="off" onSubmit={handleSubmit}>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} bg={"gray.900"} padding={"16px"} gap={"40px"} rounded={"8px"} marginBottom={'100px'}>
        <Text fontFamily="AVSB" color={"gray.0"} fontSize={'18px'} fontWeight={600}>
          Log in your account
        </Text>
        <Flex justifyContent={"center"} flexDirection={"column"} gap={"12px"}>
          <Button
            px={"16"}
            py={"12px"}
            fontFamily={"IM"}
            fontSize={"16px"}
            fontWeight={"500"}
            bg={"gray.800"}
            _hover={{ bg: "gray.800" }}
            color={"gray.0"}
            rounded={"8px"}
            onClick={() => setShowModal(!showModal)}
          >
            Connect a wallet
          </Button>
          <Vaul isOpen={showModal} setIsOpen={setShowModal} headerTitle={"Choose a Wallet"}>
            <Flex flexDirection={'column'}>
              <Flex justifyContent={'flex-start'} padding={"16px"} flexWrap={"wrap"} gap={"16px"}>
                <WalletItem title={'Hiro'} itemOnClick={loginViaStacks} itemIcon={<AppIcons.SampleWallet />} />
                <WalletItem title={"Polygon"} itemOnClick={loginViaPolygon} itemIcon={<AppIcons.MATICTokenSquare/>} itemBgColor={'#6F41D8'} />
              </Flex>
              <WhatIsWallet />
            </Flex>
          </Vaul>
          <Button
            px={"16"}
            py={"12px"}
            fontFamily={"IM"}
            fontSize={"16px"}
            fontWeight={"500"}
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            color={"gray.0"}
            border={"1px"}
            borderColor={"#434343"}
            gap={"8px"}
            rounded={"8px"}
            onClick={loginWithGoogle}
          >
            <Icon>
              <AppIcons.Google />
            </Icon>
            Log in with Google
          </Button>
        </Flex>
        <Box display={"flex"} alignItems={"center"} gap={"14px"}>
          <Divider orientation="horizontal" />
          <Text fontFamily={"AVR"} fontSize={"14px"} color={"gray.0"}>
            or
          </Text>
          <Divider orientation="horizontal" />
        </Box>
        <Flex flexDirection={"column"} gap={"20px"}>
          <Label>
            <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={'16px'}>
              Email
            </Text>
            <Input
              variant="unstyled"
              color={"gray.0"}
              bg={"gray.1000"}
              fontFamily={"AVR"}
              type="email"
              id="email"
              onChange={handleChange}
              placeholder="Email"
              padding={"12px"}
              _placeholder={{ color: "gray.800" }}
              value={values.email}
            />
          </Label>
          <Label>
            <Text color={"gray.400"} fontFamily={"AVR"} fontWeight={"600"} fontSize={'16px'}>
              Password
            </Text>
            <Input
              variant="unstyled"
              color={"gray.0"}
              bg={"gray.1000"}
              fontFamily={"AVR"}
              type="password"
              id="password"
              onChange={handleChange}
              placeholder="password"
              padding={"12px"}
              _placeholder={{ color: "gray.800" }}
              value={values.password}
            />
          </Label>
          <Link to={"/forget-password"}>
            <Text fontFamily={"IM"} fontSize={"14px"} fontWeight={"400"} color={"gray.600"} textAlign={"right"} >
              Forgot password?
            </Text>
          </Link>
        </Flex>
      </Box>
      <RegisterBtn isLoading={isLoading} btnTitle={'Log in'} btnSubtitle={'No account?'} btnSubtitleLink={'signup'} btnlinkTitle={'Register now'}/>
    </CustomForm>
  );
};

export default Login;
