import { Box, Flex, Select } from "@chakra-ui/react";
import { appDevelopment } from "lib/utils/development/Development";
import { DeviceDetector } from "lib/utils/device/DeviceDetect";
import React, { useState } from "react";

const AppDevbar = ({
  ChangeView,
  view,
}: {
  ChangeView: (device: string) => void;
  view: "DESKTOP" | "MOBILE";
}) => {
  return (
    <>
      {appDevelopment ? (
        <Flex backgroundColor={"#000"} width="100%" justifyContent={"center"}>
          <Box>
            <Select
              placeholder="Select Device"
              backgroundColor={"#000"}
              boxShadow={"none !important"}
              border={"none"}
              value={view}
              color="#FFF"
              onChange={(e) => ChangeView(e.target.value)}
            >
              <option value="DESKTOP">Desktop View</option>
              <option value="MOBILE">Mobile View</option>
            </Select>
          </Box>
        </Flex>
      ) : null}
    </>
  );
};

export default AppDevbar;
