import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

//Chakra
import { Flex, Text } from '@chakra-ui/react'

//Icons
import AppIcons from 'assets/icons/AppIcons';

//Components
import PrimaryBtn from 'components/common/primary-btn/PrimaryBtn';

const Onboarding = () => {
    const [selectedItem, setSelectedItem] = useState('host')
    const navigate = useNavigate()

    const clickHandler = (selectedItemValue: string) => {
        setSelectedItem(selectedItem);
        
        if (selectedItemValue === 'host') {
            navigate("/user/create/info", { state: { isFromOnboarding: true } })
        } else {
            navigate("/")
        }
    }

    return (
        <Flex flexDirection={'column'} alignItems={'center'} flexShrink={0} gap={'16px'} height={'80vh'} width={'100%'}>
            <Flex flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={'24px'} flex={'1 0 0'} padding={'16px'} borderRadius={'8px'} bgColor={'gray.900'} boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'} width={'100%'}>
                <Flex flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={'8px'} alignSelf={'stretch'}>
                    <Text textAlign={'left'} color={'gray.0'} fontFamily={'AVR'} fontSize={'16px'} fontWeight={600}>What would you like to next?</Text>
                    <Text color={'gray.600'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400}>Let’s get started by hosting or attending any events!</Text>
                </Flex>
                <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} alignSelf={'stretch'}>
                    <Flex onClick={() => clickHandler('host')} alignItems={'center'} gap={'12px'} alignSelf={'stretch'} padding={'16px'} borderRadius={'12px'} border={selectedItem === 'host' ? '1px solid #084836' : '1px solid #434343'} bgColor={selectedItem === 'host' ? 'rgba(46, 201, 158, 0.05)' : ''}>
                        <Flex alignItems={'center'} gap={'12px'} flex={'1 0 0'}>
                            <AppIcons.GreenCalendar/>
                            <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'4px'} flex={'1 0 0'}>
                                <Text color={selectedItem === 'host' ? 'primary.600' : 'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Be a host</Text>
                                <Text color={selectedItem === 'host' ? 'primary.600' : 'gray.600'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400}>Create an event with few clicks!</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex onClick={() => clickHandler('attend')} alignItems={'center'} gap={'12px'} alignSelf={'stretch'} padding={'16px'} borderRadius={'12px'} border={selectedItem === 'attend' ? '1px solid #084836' : '1px solid #434343'} bgColor={selectedItem === 'attend' ? 'rgba(46, 201, 158, 0.05)' : ''}>
                        <Flex alignItems={'center'} gap={'12px'} flex={'1 0 0'}>
                            <AppIcons.GreenCalendar/>
                            <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'4px'} flex={'1 0 0'}>
                                <Text color={selectedItem === 'attend' ? 'primary.600' : 'gray.0'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={600}>Attend an event</Text>
                                <Text color={selectedItem === 'attend' ? 'primary.600' : 'gray.600'} fontFamily={'AVR'} fontSize={'14px'} fontWeight={400}>RSVP, enjoy, and earn digital collectibles!</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            {/* <PrimaryBtn btnText={'Get Started'} btnOnClick={() => btnClickHandler(selectedItem)} btnTextColor={'primary.900'} /> */}
        </Flex>
    );
};

export default Onboarding;