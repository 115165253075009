import { ethers } from "ethers";
import * as yup from "yup";

const ErrorMessages = {
    email: "Please enter a valid email",
    emailRequired: "Email is required",
    password: "Password is required",
    event: "Event is required",
    title: "Title is required",
    description: "Description is required",
    location: "Address is required",
    location_min: "Address is too short",
    location_max: "Address is too long",
    startDate: "Start Date is required",
    startTime: "Start Time is required",
    endDate: "End Date is required",
    endTime: "End Time is required",
    price: "Price is required",
    price_num: "Price must be a number",
    price_pos: "Price must be a positive number",
    price_min: "Price must be at least 0",
    price_max: "Price must not exceed 1000",
    total: "Total tickets is required",
    total_min: "Total tickets must be at least 1",
    total_max: "Total tickets must not exceed 100",
    toggleSwitch: "You must choose at least one item",
};

export const RegisterSchema = yup.object().shape({
    email: yup.string().email(ErrorMessages.email).required(ErrorMessages.emailRequired),

    password: yup.string().min(8).required(ErrorMessages.password),

    terms: yup.boolean(),
});

export const EmailValidation = yup.object().shape({
    email: yup.string().email(ErrorMessages.email).required(ErrorMessages.emailRequired),
});

export const LoginSchema = yup.object().shape({
    email: yup.string().email(ErrorMessages.email).required(ErrorMessages.emailRequired),

    password: yup.string().min(8).required(ErrorMessages.password),
});

export const introPageSchema = yup.object().shape({
    event: yup.string().required(ErrorMessages.event),
    title: yup.string().max(75).required(),
    description: yup.string().max(1000).required(ErrorMessages.description),
    // banner: yup.
    location: yup.string().min(5, ErrorMessages.location_min).max(100, ErrorMessages.location_max).required(ErrorMessages.location),
    startDate: yup.string().required(ErrorMessages.startDate),
    startTime: yup.string().required(ErrorMessages.startTime),
    endDate: yup.string().required(ErrorMessages.endDate),
    endTime: yup.string().required(ErrorMessages.endTime),
});

export const ticketDitailSchema = yup.object().shape({
    tickets: yup.array().of(
        yup.object().shape({
            title: yup.string().max(25).required(ErrorMessages.title),
            description: yup.string().max(1000).required(ErrorMessages.description),
            price: yup.number().typeError(ErrorMessages.price_num).required(ErrorMessages.price).positive(ErrorMessages.price_pos).min(0, ErrorMessages.price_min).max(1000, ErrorMessages.price_max),
            total: yup.number().min(1, ErrorMessages.total_min).max(100, ErrorMessages.total_max).required(ErrorMessages.total),
        })
    ),
});

export const AcceptPaymentSchema = yup.object().shape({
    toggleSwitch: yup.boolean().oneOf([true], ErrorMessages.toggleSwitch),
});

export const nftIdentifierSchema = yup
    .string()
    .required("At least one NFT identifier is required.")
    .test({
        name: "validAddress",
        message: `Invalid NFT identifier`,
        test: function (value) {
            return ethers.utils.isAddress(value);
        },
    });

export const rulesetSchema = yup.object().shape({
    rules: yup
        .array()
        .of(
            yup.object().shape({
                gated: yup.string().required("Rule Type is required"),
                discount: yup.number().test({
                    name: "isDiscount",
                    message: "Discount is required and should be less than or equal to 100",
                    test: function (value) {
                        // Only require 'discount' if 'gated' is 'Discount'
                        const gatedValue = this.parent.gated;
                        if (gatedValue === "Discount") {
                            return value !== undefined && value <= 100;
                        }
                        return true;
                    },
                }),
                nft_identifiers: yup.array().of(nftIdentifierSchema),
                tickets: yup.array().of(yup.array()).required("Ticket for the ruleset is required"),
            })
        )
        .test({
            name: "hasRules",
            message: "At least one rule is required",
            test: function (value) {
                return value !== undefined && value.length > 0;
            },
        }),
});
