import { Link } from "react-router-dom";

//Icon
import AppIcons from "assets/icons/AppIcons";

//Chakra
import { Flex, Text } from "@chakra-ui/react";

const BackBtn = ({ optionalUrl }: { optionalUrl?: string }) => {
  return (
    <Flex justifyContent="flex-start" alignItems="center" alignSelf="flex-start" gap="6px">
      {optionalUrl ? (
        <Link to={optionalUrl}>
          <Text display="inline-flex" gap="6px" alignItems="center" justifyContent="center" color="primary.600" fontSize="14px" fontWeight={400} lineHeight="20px" cursor="pointer" fontFamily="AVSB">
            <AppIcons.ChevronLeft />
            Back
          </Text>
        </Link>
      ) : (
        <Text onClick={() => window.history.back()} display="inline-flex" gap="6px" alignItems="center" justifyContent="center" color="primary.600" fontSize="14px" fontWeight={400} lineHeight="20px" cursor="pointer" fontFamily="AVSB">
          <AppIcons.ChevronLeft />
          Back
        </Text>
      )}
    </Flex>
  );
};

export default BackBtn;
