import { usePayment } from "functions/hooks/payment/usePayment";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StripeComponent from "./StripeComponent";
import { Divider } from "@chakra-ui/react";

const StripePage = () => {
  const navigate = useNavigate();
  const { clientSecret, payViaStripe, isLoading } = usePayment();
  const location = useLocation();
  const { orderId } = useParams()

  // const [compState, setCompState] = useState(false);

  // useEffect(() => {
  //   setCompState((prev) => !prev);
  // }, [clientSecret, localStorage.getItem("orderId")]);
  useEffect(() => {
    localStorage.getItem("orderId") && payViaStripe();
  }, []);

  return (
    <>
      {!isLoading ? (
        clientSecret !== null && clientSecret !== "" ? (
          <StripeComponent clientSecret={clientSecret} appea={"night"} orderId={orderId} />
        ) : (
          clientSecret === "" && navigate("/user/events")
        )
      ) : (
        <div>Loading stripe</div>
      )}
    </>
  );
};

export default StripePage;
