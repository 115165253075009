import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: "#141414",
      },
    }),
  },
  colors: {
    button: "#353535",
    green: {
      50: "#edfaf6",
      100: "#b4ecdc",
      200: "#6bd9ba",
      300: "#2bbb93",
      400: "#26a783",
      500: "#2EC99E",
      600: "#7C7C7C",
      700: "#165f4b",
      800: "#292929",
      900: "#0d3a2e",
    },
    primary: {
      600: "#2EC99E",
      900: "#084836",
    },
    gray: {
      0: "#FFFFFF",
      50: "#FAFAFA",
      100: "#e0e0e0",
      200: "#c5c5c5",
      300: "#D8D8D8",
      400: "#A8A8A8",
      500: "#8B8B8B",
      600: "#7C7C7C",
      700: "#434343",
      800: "#474747",
      850: "#303030",
      900: "#1C1C1C",
      1000: "#141414",
    },
    error: {
      600: "#940404"
    },
    dark: {
      200: "#24292E",
      500: "#656E76",
      700: "#A4ABB2",
      900: "#FFFFFF",
      interactive: "#1A1D21",
      bg: "#08090A",
    }
  },
});
