import { Route, Routes } from "react-router-dom";

//Main Layout
import Layout from "components/desktop-layout/layout";

//Components
import Home from "pages/desktop-pages/home/Home";
import MyEvents from "pages/desktop-pages/my-events/MyEvents";
import CreateEventLayout from "pages/desktop-pages/create-event/create-event-layout/CreateEventLayout";
import BasicInfo from "pages/desktop-pages/create-event/basic-info/BasicInfo";
import TicketRulesets from "pages/desktop-pages/create-event/ticket-rulesets/TicketRulesets";
import PaymentMethods from "pages/desktop-pages/create-event/payment-methods/PaymentMethods";
import DeployEvent from "pages/desktop-pages/create-event/deploy-event/DeployEvent";
import Dashboard from "pages/dashboard/Dashboard";
import Register from "pages/desktop-pages/register/Register";
import Login from "pages/desktop-pages/register/Login";
import SimpleLayout from "components/desktop-layout/SimpleLayout";
import Onboarding from "pages/desktop-pages/register/Onboarding";
import VerifyEmail from "pages/desktop-pages/register/VerifyEmail";
import EmailConfirmation from "pages/desktop-pages/register/EmailConfirmation";
import SuccessLogin from "pages/desktop-pages/register/SuccessLogin";
import ResetPassword from "pages/desktop-pages/register/ResetPassword";
import ForgetPassword from "pages/desktop-pages/register/ForgetPassword";
import Settings from "pages/desktop-pages/settings/Settings";
import Profile from "pages/desktop-pages/profile/Profile";
import HeaderLayout from "components/desktop-layout/HeaderLayout";
import EditProfile from "pages/desktop-pages/profile/EditProfile";
import EventPage from 'pages/desktop-pages/my-events/event-page/EventPage'
import Purchase from "pages/desktop-pages/purchase/Purchase";
import StripePage from "pages/stripe/StripePage";
import TicketDetail from "pages/desktop-pages/profile/ticket-detail/TicketDetail";
import UsersProfile from "pages/desktop-pages/profile/UsersProfile";
import ErrorPage from "pages/desktop-pages/error-page/ErrorPage";
import TicketDetails from "pages/desktop-pages/create-event/ticket-details/TicketDetails";
import SuccessPublish from "pages/desktop-pages/create-event/success-publish/SuccessPublish";
import ThanksPage from "pages/desktop-pages/purchase/thanks-page/ThanksPage";
import SaveDraft from "pages/desktop-pages/create-event/save-draft/SaveDraft";

const DesktopRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="user/" element={<Dashboard />}>
          <Route path="home" element={<Home />} />
          <Route path="events" element={<MyEvents />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Route>
      <Route path="/" element={<SimpleLayout/>}>
        <Route path="signup" element={<Register/>} />
        <Route path="signin" element={<Login/>} />
        <Route path="verify" element={<VerifyEmail/>} />
        <Route path="confirmation" element={<EmailConfirmation/>} />
        <Route path="forget-password" element={<ForgetPassword/>} />
        <Route path="reset-password" element={<ResetPassword/>} />
        <Route path="auth-callback" element={<SuccessLogin/>} />
        <Route path="onboarding" element={<Onboarding/>} />
        <Route path="oops" element={<ErrorPage/>} />
      </Route>
      <Route path="/user" element={<Dashboard />} errorElement={<ErrorPage/>}>
        <Route element={<CreateEventLayout />}>
          <Route path="create">
            <Route path="info" element={<BasicInfo />} />
            <Route path="info/:eventId" element={<BasicInfo />} />
            <Route path="tickets/:eventId" element={<TicketDetails />} />
            <Route path="rulesets/:eventId" element={<TicketRulesets />} />
            <Route path="acceptPayment/:eventId" element={<PaymentMethods />} />
            <Route path="deployEvent/:eventId" element={<DeployEvent />} />
          </Route>
        </Route>
        <Route element={<HeaderLayout/>}>
          <Route path="profile" element={<Profile/>} />
          <Route path="profile/:userId" element={<UsersProfile/>} />
          <Route path="profile/editprofile" element={<EditProfile/>} />
          <Route path="profile/ticketDetail/:eventId" element={<TicketDetail/>} />
          <Route path="ticketDetail/:eventId" element={<TicketDetail/>} />
          <Route path="events/:eventId" element={<EventPage />} />
          <Route path="purchase/:eventId" element={<Purchase />} />
          <Route path="payment/stripe/:orderId" element={<StripePage/>} />
          <Route path="create/publishEvent/:eventId" element={<SuccessPublish/>} />
          <Route path="create/saveDraft/:eventId" element={<SaveDraft />} />
          <Route path="purchase/thank-you/:orderId" element={<ThanksPage/>} />
        </Route>
      </Route>
    </Routes>
  );
};

export default DesktopRoutes;
